import React, { useState } from "react";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import { getAllPayments, searchPayment } from "../../../../api/apiServices";
import Pagination from '../../../../services/Pagination';
import toast, { Toaster } from 'react-hot-toast'
import addCommas from "../../../../services/addCommas";


const PaymentTransactions = (props) => {

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/payment_transactions";

    const [search, setSearch] = useState(false);
    const [payment, setPayment] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [postPerPage, setPostPerPage] = useState(20);

    const [tenant_code, setTenantCode] = useState("");
    const [contact_number, setContactNumber] = useState("");
    const [first_name, setFirstName] = useState("");
    const [last_name, setLastName] = useState("");

    const queryClient = useQueryClient();

    const {
        isLoading,
        isError,
        error,
        data: payments
    } = useQuery('payments', getAllPayments, {
        select: data => data.sort((a, b) => b.id - a.id)
    });

    let total_payments = payments?.length

    const handleSearch = async (e) => {
        e.preventDefault();
        const searchData = {
            tenant_code: tenant_code,
            contact_number: contact_number,
            first_name: first_name,
            last_name: last_name,
        }
        try {
            await searchPayment(searchData).then((res) => {
                if (res.length > 0) {
                    setPayment(res)
                    setTenantCode("");
                    setContactNumber("");
                    setFirstName("");
                    setLastName("");
                    setSearch(true)
                } else {
                    toast.info("No Payment found for member")
                    setSearch(false)
                }
            }).catch((err) => {
                toast.error("Provide at least one search parameter ")
                return false
            })
        } catch (err) {
            toast.error(err)
        }
    }
    const handleCancel = () => {
        setTenantCode("");
        setContactNumber("");
        setFirstName("");
        setLastName("");
        setSearch(false);
        navigate(from, { replace: true });
    }

    let currentPosts = []
    const indexOfLastPage = currentPage * postPerPage;
    const indexOfFirstPage = indexOfLastPage - postPerPage;
    if (!search) {
        currentPosts = payments?.slice(indexOfFirstPage, indexOfLastPage);
    } else {
        currentPosts = payment?.slice(indexOfFirstPage, indexOfLastPage);
    }

    // const addCommas = (number) => {
    //     let numFormat = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    //     return numFormat
    // }

    let searchContent = (
        <form onSubmit={handleSearch} >
            <div className="form-group row">
                <div className="col-sm-2">
                    <input
                        className="form-control"
                        type="text"
                        placeholder="Tenant Code"
                        style={{ textTransform: "uppercase" }}
                        onChange={(e) => setTenantCode(e.target.value)}
                        value={tenant_code}
                    />
                </div>
                <div className="col-sm-2">
                    <input
                        className="form-control"
                        type="text"
                        // style={{ textTransform: "uppercase" }}
                        placeholder="Contact Number"
                        onChange={(e) => setContactNumber(e.target.value)}
                        value={contact_number}
                    />
                </div>
                <div className="col-sm-3">
                    <input
                        className="form-control"
                        type="text"
                        placeholder="FirstName"
                        style={{ textTransform: "uppercase" }}
                        onChange={(e) => setFirstName(e.target.value)}
                        value={first_name}
                    />
                </div>
                <div className="col-sm-3">
                    <input
                        className="form-control"
                        type="text"
                        placeholder="LastName"
                        style={{ textTransform: "uppercase" }}
                        onChange={(e) => setLastName(e.target.value)}
                        value={last_name}
                    />
                </div>
                <button
                    type="submit"
                    className="btn btn-success "
                > <i className="fa fa-search"></i> Search Tenant</button>
            </div>

        </form>
    )



    let content
    if (isLoading) {
        content = <p>Loading Payment Transactions...</p>
    } else if (isError) {
        content = <p>{error.message}</p>
    } else {
        content =
            <div className="table-responsive-lg">
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col" style={{ textAlign: 'center' }} >#</th>
                            <th scope="col" style={{ textAlign: "center" }}>Year</th>
                            <th scope="col" style={{ whiteSpace: 'nowrap' }}>Tenant Code</th>
                            <th scope="col" style={{ whiteSpace: 'normal' }}>Name</th>
                            <th scope="col" style={{ whiteSpace: 'normal', textAlign: "center" }}>Payment Date</th>
                            <th scope="col" style={{ whiteSpace: 'normal', textAlign: "center" }}>Amount Paid</th>
                            <th scope="col" style={{ whiteSpace: 'normal', textAlign: "left" }}>Payment Mode</th>
                            <th scope="col" style={{ whiteSpace: 'normal', textAlign: "center" }}>Receipt Number</th>
                            <th scope="col" style={{ textAlign: "center" }}>ACTION</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentPosts.map((paymt, index) =>

                            <tr key={paymt.id}>
                                <td style={{ textAlign: 'center' }}>{postPerPage * (currentPage - 1) + index + 1}</td>
                                <td style={{ textAlign: 'center' }}>{paymt?.year}</td>
                                <td style={{ whiteSpace: 'nowrap' }}>{paymt?.tenant_code}</td>
                                <td style={{ whiteSpace: 'nowrap' }}>{paymt?.fullname}</td>
                                <td style={{ textAlign: 'center' }}>{paymt?.payment_date}</td>
                                <td style={{ textAlign: 'right' }}>{addCommas(paymt?.payment_amount)}</td>                                
                                <td style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>{paymt?.description}</td>
                                <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{paymt?.receipt_number}</td>
                                <td style={{ whiteSpace: 'nowrap', textAlign: "center" }}>
                                    <div style={{ display: 'inline-block' }}>
                                        <Link to={`/view_payment/${paymt?.tenant_code}`} className="small"><i className='fa fa-eye'></i> view</Link>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody >
                </table >
            </div>
    }

    return (
        <>
            <section className="content-header">
                <Toaster toastOptions={{ position: "top-center" }} />
                <h1>Payment Transactions - {total_payments}</h1>
            </section>
            <section className="content">
                <div className="box">
                    <div className="box-body">
                        <div className="panel panel-default panel-br-4">
                            <div className="panel-heading">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <h4 className="panel-title">
                                            {searchContent}
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="panel-body">
                                <div>
                                    <Link className="btn btn-success" to="/payments_by_period"><i className='fa fa-eye'></i>&nbsp; &nbsp;Period Payment</Link>&nbsp; &nbsp;
                                    <Link className="btn btn-success" to="/payments_by_month"><i className='fa fa-eye'></i>&nbsp; &nbsp;Monthly Payment</Link>&nbsp; &nbsp;
                                    <Link className="btn btn-success" to="/payments_by_mode"><i className='fa fa-eye'></i>&nbsp; &nbsp;Payments By Mode</Link>
                                </div><br />

                                {content}
                            </div>
                            <div className="panel-footer">
                                {payments && !search ? (

                                    <Pagination
                                        data={payments}
                                        postPerPage={postPerPage}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                        setPostPerPage={setPostPerPage}
                                    />

                                ) : (
                                    <div className="search-group">
                                        <button
                                            type="submit"
                                            className="btn btn-danger "
                                            onClick={handleCancel}
                                        > <i className="fa fa-search"></i> Cancel</button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="box-footer"></div>
                </div>
            </section>
        </>
    )
}

export default PaymentTransactions;


