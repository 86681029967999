import Nav from "./Nav"

const Header = ({ currentBankId, setCurrentBankId }) => {
    return (
        <header>
            <Nav
                currentBankId={currentBankId}
                setCurrentBankId={setCurrentBankId}
            />
        </header>
    )
}
export default Header