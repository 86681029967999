import React from "react";
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { getTenantById } from "../../../api/apiServices";
import { useQuery } from 'react-query';

const ViewApprovedTenant = () => {

    const params = useParams();
    const id = parseInt(params.id)

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/approved_tenants";

    const {
        isLoading,
        isError,
        error,
        data: tenant
    } = useQuery(['tenant', id], () => getTenantById(id));

    let fullname = tenant?.title + " " + tenant?.first_name + " " + tenant?.middle_name + " " + tenant?.last_name

    const mapLocation = {
        address: tenant?.residential_address,
        lat: tenant?.latitude,
        lng: tenant?.longitude,
    }

    const handleViewLocation = () => {
        let locationData = [];
        function extractData() {
            locationData = Object.keys(mapLocation).map(function (key) {
                return mapLocation[key];
            }).join('&');
        }
        extractData();
        navigate(`/location_map?map=${locationData}`);
    }

    const handleSatelliteView = () => {
        let locationData = [];
        function extractData() {
            locationData = Object.keys(mapLocation).map(function (key) {
                return mapLocation[key];
            }).join('&');
        }
        extractData();
        navigate(`/satellite_view?map=${locationData}`);
    }

    const handleCancel = () => {
        navigate(from, { replace: true });
    }


    let content
    if (isLoading) {
        content = <p>Loading Tenat Detail...</p>
    } else if (isError) {
        content = <p>{error.message}</p>
    } else {
        content = (
            <>
                <legend style={{ textAlign: "center", fontSize: "24px", fontWeight: "700", color: "brown" }}>TENANT INFORMATION</legend>
                <div className="photo">
                    <img src={`/images/tenants/${tenant?.photo}`} className="image"
                        alt="" />
                    <div className="tenant-code">
                        <h1>Tenant Code :</h1>
                        <h2>{tenant?.tenant_code ? tenant.tenant_code : "Not Yet Generated"}</h2>
                    </div>
                    <div className="location-view">
                        <button type="button" className="btn btn-success" onClick={handleViewLocation}>Location View</button><br />
                        <button type="button" className="btn btn-danger" onClick={handleSatelliteView}>Satellite Location View</button><br />
                    </div>
                </div>
                <div className="table-responsive lg">
                    <table className="table table-borderless">
                        <caption style={{ textAlign: "center", fontSize: "18px", fontWeight: "bold", color: "var(--white)", background: "var(--brown)" }}> PERSONAL INFORMATION </caption>
                        <tbody>
                            <tr>
                                <td style={{ fontSize: "16px", fontWeight: "700", color: "#000b41", align: "left" }}>Gender</td>
                                <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "left", whiteSpace: "pre-wrap" }}>{tenant?.gender}</td>
                                <td style={{ fontSize: "16px", fontWeight: "700", color: "#000b41", align: "left" }}>ID Type</td>
                                <td colSpan="3" style={{ fontSize: "14px", fontWeight: "500", textAlign: "left", whiteSpace: "pre-wrap" }}>{tenant?.identification_type}</td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: "16px", fontWeight: "700", color: "#000b41", align: "left" }}>Date of Birth</td>
                                <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "left", whiteSpace: "pre-wrap" }}>{tenant?.date_of_birth}</td>
                                <td style={{ fontSize: "16px", fontWeight: "700", color: "#000b41", align: "left" }}>ID Number</td>
                                <td colSpan="3" style={{ fontSize: "14px", fontWeight: "500", textAlign: "left", whiteSpace: "pre-wrap" }}>{tenant?.id_number}</td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: "16px", fontWeight: "700", color: "#000b41", align: "left" }}>Nationality</td>
                                <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "left", whiteSpace: "pre-wrap" }}>{tenant?.nationality}</td>
                                <td style={{ fontSize: "16px", fontWeight: "700", color: "#000b41", align: "left" }}>Date Issued</td>
                                <td colSpan="3" style={{ fontSize: "14px", fontWeight: "500", textAlign: "left", whiteSpace: "pre-wrap" }}>{tenant?.date_issued}</td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: "16px", fontWeight: "700", color: "#000b41", align: "left" }}>Occupation</td>
                                <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "left", whiteSpace: "pre-wrap" }}>{tenant?.occupation}</td>
                                <td style={{ fontSize: "16px", fontWeight: "700", color: "#000b41", align: "left" }}>Expiry Date</td>
                                <td colSpan="3" style={{ fontSize: "14px", fontWeight: "500", textAlign: "left", whiteSpace: "pre-wrap" }}>{tenant?.expiry_date}</td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: "16px", fontWeight: "700", color: "#000b41", align: "left" }}>Marital Status</td>
                                <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "left", whiteSpace: "pre-wrap" }}>{tenant?.marital_status}</td>
                                <td style={{ fontSize: "16px", fontWeight: "700", color: "#000b41", align: "left" }}>Spouse Contact Number</td>
                                <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "left", whiteSpace: "pre-wrap" }}>{tenant?.spouse_contact_number}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="table-responsive lg">
                    <table className="table table-borderless">
                        <caption style={{ textAlign: "center", fontSize: "18px", fontWeight: "bold", color: "var(--white)", background: "var(--brown)" }}>CONTACT INFORMATION </caption>
                        <tbody>
                            <tr>
                                <td style={{ fontSize: "16px", fontWeight: "700", color: "#000b41", align: "left" }}>Contact Number</td>
                                <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "left", whiteSpace: "pre-wrap" }}>{tenant?.contact_number}</td>
                                <td style={{ fontSize: "16px", fontWeight: "700", color: "#000b41", align: "left" }}>Postal Address</td>
                                <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "left", whiteSpace: "pre-wrap" }}>{tenant?.postal_address}</td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: "16px", fontWeight: "700", color: "#000b41", align: "left" }}>Email Address</td>
                                <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "left", whiteSpace: "pre-wrap" }}>{tenant?.email}</td>
                                <td style={{ fontSize: "16px", fontWeight: "700", color: "#000b41", align: "left" }}>Residential Address (GPS)</td>
                                <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "left", whiteSpace: "pre-wrap" }}>{tenant?.residential_address}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="row">
                    <div className="col-md-6 col-sm-6 col-xs-6">
                        <div className="table-responsive lg">
                            <table className="table table-borderless">
                                <caption style={{ textAlign: "center", fontSize: "18px", fontWeight: "bold", color: "var(--white)", background: "var(--brown)" }}>NEXT OF KIN </caption>
                                <tbody>
                                    <tr>
                                        <td style={{ fontSize: "16px", fontWeight: "700", color: "#000b41", align: "left" }}>Next of Kin</td>
                                        <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "left", whiteSpace: "pre-wrap" }}>{tenant?.next_of_kin}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontSize: "16px", fontWeight: "700", color: "#000b41", align: "left" }}>Contact Number</td>
                                        <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "left", whiteSpace: "pre-wrap" }}>{tenant?.kin_contact_number}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontSize: "16px", fontWeight: "700", color: "#000b41", align: "left" }}>Relationship</td>
                                        <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "left", whiteSpace: "pre-wrap" }}>{tenant?.kin_relationship}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-6">
                        <div className="table-responsive lg">
                            <table className="table table-borderless">
                                <caption style={{ textAlign: "center", fontSize: "18px", fontWeight: "bold", color: "var(--white)", background: "var(--brown)" }}>CONTACT PERSON/REFERENCE </caption>
                                <tbody>
                                    <tr>
                                        <td style={{ fontSize: "16px", fontWeight: "700", color: "#000b41", align: "left" }}>Contact Person</td>
                                        <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "left", whiteSpace: "pre-wrap" }}>{tenant?.contact_person}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontSize: "16px", fontWeight: "700", color: "#000b41", align: "left" }}>Address/GPS</td>
                                        <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "left", whiteSpace: "pre-wrap" }}>{tenant?.contact_address}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontSize: "16px", fontWeight: "700", color: "#000b41", align: "left" }}>Relationship</td>
                                        <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "left", whiteSpace: "pre-wrap" }}>{tenant?.contact_relationship}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontSize: "16px", fontWeight: "700", color: "#000b41", align: "left" }}>Contact Person Telephone</td>
                                        <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "left", whiteSpace: "pre-wrap" }}>{tenant?.contact_telephone}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="table-responsive lg">
                    <table className="table table-borderless">
                        <caption style={{ textAlign: "center", fontSize: "18px", fontWeight: "bold", color: "var(--white)", background: "var(--brown)" }}>LAND DETAILS </caption>
                        <tbody>
                            <tr>
                                <td style={{ fontSize: "16px", fontWeight: "700", color: "#000b41", align: "left" }}>Deeds Registration No./Land Title Certificate</td>
                                <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "left", whiteSpace: "pre-wrap" }}>{tenant?.deeds_number}</td>
                                <td style={{ fontSize: "16px", fontWeight: "700", color: "#000b41", align: "left" }}>New Indenture Number</td>
                                <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "left", whiteSpace: "pre-wrap" }}>{tenant?.new_indenture_number}</td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: "16px", fontWeight: "700", color: "#000b41", align: "left" }}>Building Permit Number</td>
                                <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "left", whiteSpace: "pre-wrap" }}>{tenant?.building_permit}</td>
                                <td style={{ fontSize: "16px", fontWeight: "700", color: "#000b41", align: "left" }}>Date of Acquisition</td>
                                <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "left", whiteSpace: "pre-wrap" }}>{tenant?.acquisition_date}</td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: "16px", fontWeight: "700", color: "#000b41", align: "left" }}>Approved Date</td>
                                <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "left", whiteSpace: "pre-wrap" }}>{tenant?.approved_date}</td>
                                <td style={{ fontSize: "16px", fontWeight: "700", color: "#000b41", align: "left" }}>Number of Plots</td>
                                <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "left", whiteSpace: "pre-wrap" }}>{tenant?.number_of_plots}</td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: "16px", fontWeight: "700", color: "#000b41", align: "left" }}>Land Use</td>
                                <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "left", whiteSpace: "pre-wrap" }}>{tenant?.land_use}</td>
                                <td style={{ fontSize: "16px", fontWeight: "700", color: "#000b41", align: "left" }}>Land Location</td>
                                <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "left", whiteSpace: "pre-wrap" }}>{tenant?.land_location}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="table-responsive lg">
                    <table className="table table-borderless">
                        <caption style={{ textAlign: "center", fontSize: "18px", fontWeight: "bold", color: "var(--white)", background: "var(--brown)" }}>MODE OF PAYMENT </caption>
                        <tbody>
                            <tr>
                                <td style={{ fontSize: "16px", fontWeight: "700", color: "#000b41", align: "left" }}>Payment Type</td>
                                <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "left", whiteSpace: "pre-wrap" }}>{tenant?.payment_type}</td>
                                <td style={{ fontSize: "16px", fontWeight: "700", color: "#000b41", align: "left" }}>Initial Deposit</td>
                                <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "left", whiteSpace: "pre-wrap" }}>GHS{tenant?.initial_deposit.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: "16px", fontWeight: "700", color: "#000b41", align: "left" }}>Duration</td>
                                <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "left", whiteSpace: "pre-wrap" }}>{tenant?.durtion}</td>
                                <td style={{ fontSize: "16px", fontWeight: "700", color: "#000b41", align: "left" }}>Payment Mode</td>
                                <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "left", whiteSpace: "pre-wrap" }}>{tenant?.payment_mode}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="panel-footer">
                    <div className="form-group row">
                        <div className="col-sm-1">
                            <button type="submit" className="btn btn-danger" onClick={handleCancel}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            <section className="content-header">
                <h1>{fullname} - {tenant?.purpose} </h1>
            </section>
            <section className="content">
                <div className="box">
                    <div className="box-body">
                        <div className="panel panel-default panel-br-4">
                            <div className="panel-heading">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <h4 className="panel-title">
                                            <span></span>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="panel-body">
                                {content}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ViewApprovedTenant;


