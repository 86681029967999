import { useRef, useState } from "react";
import useRegistrationContext from "../../../hooks/useRegistrationContext"

const Purpose = () => {

    const { data, handleChange } = useRegistrationContext()

    const [state, setState] = useState({
        installmentsPay: false,
        outrightPay: false,
    })

    const handleType = e => {
        const type = e.target.type
        const name = e.target.name

        const value = type === "checkbox"
            ? e.target.checked
            : e.target.value

        if (value === "Installments") {
            setState(prevData => ({
                ...prevData,
                installmentsPay: true,
                outrightPay: false,
            }))
        }
        if (value === "Outright") {
            setState(prevData => ({
                ...prevData,
                installmentsPay: false,
                outrightPay: true,
            }))
        }
    }

    const content = (
        <>
            <div className="form-group row">
                <label htmlFor="purpPurchaser" className="col-sm-3">
                    New Purchaser &nbsp;&nbsp;&nbsp;&nbsp;
                        <input
                            type="radio"
                            id="purchaser"
                            name="mainPurpose"
                            required
                            value={"Purchaser"}
                            checked={data.mainPurpose === "Purchaser"}
                            onChange={handleChange}
                        />
                </label>
                <label htmlFor="regularization" className="col-sm-3">
                    Regularization &nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                        type="radio"
                        id="regularization"
                        name="mainPurpose"
                        required
                        value={"Regularization"}
                        checked={data.mainPurpose === "Regularization"}
                        onChange={handleChange}
                    />
                </label>
                <label htmlFor="transfer" className="col-sm-3">
                    Transfer &nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                        type="radio"
                        id="transfer"
                        name="mainPurpose"
                        required
                        value={"Transfer"}
                        checked={data.mainPurpose === "Transfer"}
                        onChange={handleChange}
                    />
                </label>
                <label htmlFor="others" className="col-sm-3">
                    Others(Specify) &nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                        type="radio"
                        id="others"
                        name="mainPurpose"
                        required
                        value={"Others"}
                        checked={data.mainPurpose === "Others"}
                        onChange={handleChange}
                    />
                </label>
            </div>
        </>
    )
    // const content = (
    //     <>
    //         <div className="flex-col">
    //             <div className="split-container">
    //                 <div className="flex-col">
    //                     <label htmlFor="purpPurchaser">
    //                         New Purchaser &nbsp;&nbsp;&nbsp;&nbsp;
    //                         <input
    //                             type="radio"
    //                             id="purchaser"
    //                             name="mainPurpose"
    //                             required
    //                             value={"Purchaser"}
    //                             checked={data.mainPurpose === "Purchaser"}
    //                             onChange={handleChange}
    //                         />
    //                     </label>
    //                 </div>
    //                 <div className="flex-col">
    //                     <label htmlFor="regularization">
    //                         Regularization &nbsp;&nbsp;&nbsp;&nbsp;
    //                         <input
    //                             type="radio"
    //                             id="regularization"
    //                             name="mainPurpose"
    //                             required
    //                             value={"Regularization"}
    //                             checked={data.mainPurpose === "Regularization"}
    //                             onChange={handleChange}
    //                         />
    //                     </label>
    //                 </div>
    //                 <div className="flex-col">
    //                     <label htmlFor="transfer">
    //                         Transfer &nbsp;&nbsp;&nbsp;&nbsp;
    //                         <input
    //                             type="radio"
    //                             id="transfer"
    //                             name="mainPurpose"
    //                             required
    //                             value={"Transfer"}
    //                             checked={data.mainPurpose === "Transfer"}
    //                             onChange={handleChange}
    //                         />
    //                     </label>
    //                 </div>
    //                 <div className="flex-col">
    //                     <label htmlFor="others">
    //                         Others(Specify) &nbsp;&nbsp;&nbsp;&nbsp;
    //                         <input
    //                             type="radio"
    //                             id="others"
    //                             name="mainPurpose"
    //                             required
    //                             value={"Others"}
    //                             checked={data.mainPurpose === "Others"}
    //                             onChange={handleChange}
    //                         />
    //                     </label>
    //                 </div>
    //             </div>
    //         </div>
    //     </>
    // )

    return content
}
export default Purpose