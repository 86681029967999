import { createContext, useState } from "react"
import toast, { Toaster } from 'react-hot-toast'

const UpdateContext = createContext({})

export const UpdateProvider = ({ children }) => {
    let curr = new Date();
    curr.setDate(curr.getDate());
    let date = curr.toISOString().substring(0, 10);

    const title = {
        0: 'UPDATE PERSONAL INFORMATION',
        1: 'UPDATE CONTACT INFORMATION',
        2: 'UPDATE NEXT OF KIN',
        3: 'UPDATE CONTACT PERSON/REFERENCE',
    }

    const [page, setPage] = useState(0)
    const [phone_name, setPhoneName] = useState("")
    
    const [data, setData] = useState({
        persTitle: "", 
        persLastname:"", 
        persFirstname:"", 
        persMiddlename:"", 
        persGender:"", 
        persDOB: "",
        persNationality:"", 
        persOccupation:"", 
        persSpouseContactNumber: "", 
        persIdentificationType: "", 
        persIdNumber: "", 
        persDateIssued: "",
        persExpiryDate: "",
        persMaritalStatus: "", 
        persPhoto: "", 
        infoContactNumber: "", 
        infoEmail: "", 
        infoPostalAddress: "", 
        nkinNextOfKin: "", 
        nkinContactNumber: "", 
        nkinRelationship: "", 
        refpContactPerson: "", 
        refpContactAddress: "", 
        refpRelationship: "", 
        refpContactNumber: "",
    })


    const handlePhoto = (e) => {
        let file = e.target.files[0];

        const name = e.target.name

        if (file) {
            if (maxSelectedImage(e) && checkMimeType(e) && checkFileSize(e)) {
                setData(prevData => ({
                    ...prevData,
                    [name]: file
                }))
                
            }
        }
    };

    const maxSelectedImage = (e) => {
        let file = e.target.files[0];
        if (file.length > 1) {
            const msg = 'Only 1 images can be uploaded at a time';
            e.target.value = null; // discard selected file
            toast.error(msg)
            return false;
        } else {
            return true;
        }
    }
    const checkMimeType = (e) => {
        // get file object
        let file = e.target.files[0];
        let err = [];
        const types = ["image/jpg", "image/jpeg", "image/png", "image/gif", "image/svc"]
        for (var i = 0; i < file.length; i++) {
            // eslint-disable-next-line
            if (types.every(type => file[i].type !== type)) {
                err[i] = file[i].type + ' is not a supported format\n';
            };
        };
        for (var x = 0; x < err.length; x++) {
            e.target.value = null;
            toast.error(err[x])
        }
        return true;
    }
    const checkFileSize = (e) => {
        let file = e.target.files[0];
        let size = 2000000;
        let err = [];
        for (var i = 0; i < file.length; i++) {
            if (file[i].size > size) {
                err[i] = file[i].type + ' is too large. Please pick a small image\n';
            };
        };
        for (var x = 0; x < err.length; x++) {
            e.target.value = null;
            toast.error(err[x])
        }
        return true;
    }

    const handleChange = e => {
       
        const name = e.target.name

        const value = e.target.value
        
        setData(prevData => ({
            ...prevData,
            [name]: value
        }))
    }

    const {
        persTitle,
        persMiddlename,
        persMaritalStatus,
        persPhoto,
        infoPostalAddress,
        ...requiredInputs } = data   
    
    

    // const canSubmit = [...Object.values(requiredInputs)].every(Boolean) && page === Object.keys(title).length - 1
    const canSubmit = page === Object.keys(title).length - 1

    const canNextPage1 = Object.keys(data)
        .filter(key => key.startsWith('pers'))
        // &&
        //     key !==
        //     'persTitle',
        //     'persMiddlename',
        //     'persMaritalStatus',
        //     'persPhoto',
        // )
        .map(key => data[key])
        // .every(Boolean)

     const canNextPage2 = Object.keys(data)
         .filter(key => key.startsWith('info')
            //  && key !== 'infoPostalAddress'
         )
        .map(key => data[key])
        // .every(Boolean)

    const canNextPage3 = Object.keys(data)
        .filter(key => key.startsWith('nkin') )
        .map(key => data[key])
        // .every(Boolean)
    
    const canNextPage4 = Object.keys(data)
        .filter(key => key.startsWith('refp') )
        .map(key => data[key])
        // .every(Boolean)

    
    const disablePrev = page === 0

    const disableNext =
        (page === Object.keys(title).length - 1)
        || (page === 0 && !canNextPage1)
        || (page === 1 && !canNextPage2)
        || (page === 2 && !canNextPage3)
        || (page === 3 && !canNextPage4)
        
    
    const prevHide = page === 0 && "remove-button"

    const nextHide = page === Object.keys(title).length - 1 && "remove-button"

    const submitHide = page !== Object.keys(title).length - 1 && "remove-button"

    return (
        <UpdateContext.Provider value={{
            title,
            page,
            setPage,
            data,
            setData,
            canSubmit,
            handleChange,
            handlePhoto,
            disablePrev,
            disableNext,
            prevHide,
            nextHide,
            submitHide
        }}>
            <Toaster toastOptions={{ position: "top-center" }} />
            {children}
        </UpdateContext.Provider>
    )
}

export default UpdateContext 