import React from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { getTenantById } from "../../../api/apiServices";
import { useQuery } from 'react-query';

const ViewPaymentStatus = () => {
  const params = useParams();
  const id = parseInt(params.id)

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/global_tenants";

  const {
    isLoading,
    isError,
    error,
    data: tenant
  } = useQuery(['tenant', id], () => getTenantById(id));

  let fullname = tenant?.title + " " + tenant?.first_name + " " + tenant?.middle_name + " " + tenant?.last_name

  const handleCancel = () => {
    navigate(-1);
  }


  const content = (
  <></>
)
  return (
    <>
      <section className="content-header">
        <h1>{fullname} - {tenant?.purpose} </h1>
      </section>
      <section className="content">
        <div className="box">
          <div className="box-body">
            <div className="panel panel-default panel-br-4">
              <div className="panel-heading">
                <div className="row">
                  <div className="col-sm-10">
                    <h4 className="panel-title">
                      <span> Tenant Payment Details </span>
                    </h4>
                  </div>
                  <div className="col-sm-2">
                    <button type="submit" className="btn btn-danger" onClick={handleCancel}>
                      Cancel
                    </button>
                  </div>

                </div>
              </div>
              <div className="panel-body">
                {content}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ViewPaymentStatus