const UserRoles = {
    SuperAdmin: 1000,
    SystemAdmin: 2000,
    Director: 3000,
    OperationsMgr: 4000,
    AccountsMgr: 5000,
    LegalMgr: 6000,
    FrontDeskUser: 7000,
    Tenant: 9999,
}

export default UserRoles