import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { getPayments } from "../../../../api/apiServices";
import toast, { Toaster } from 'react-hot-toast'
import { useMutation } from 'react-query';
import Pagination from '../../../../services/Pagination';
import { format, sub } from "date-fns";
import { read, utils, writeFile } from 'xlsx';
import ExcelServices from '../../../../services/ExcelServices'

const PaymentByMonth = (props) => {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/payment_transactions";

    const year = (new Date()).getFullYear() - 5;
    const years = Array.from(new Array(20), (val, index) => index + year)

    var curr = new Date();
    curr.setDate(curr.getDate());
    var date = curr.toISOString().substring(0, 10);

    const [search, setSearch] = useState(false);
    const [payments, setPayments] = useState([]);
    const [payment_year, setPaymentYear] = useState("");
    const [month, setMonth] = useState("");
    const [payMonth, setPayMonth] = useState("");
    let begin_date = "";
    let end_date = "";

    const [currentPage, setCurrentPage] = useState(1);
    const [postPerPage, setPostPerPage] = useState(20);


    const searchMutation = useMutation(getPayments, {
        onSuccess: async (res) => {
            if (res.length > 0) {
                setPayments(res)
                setSearch(true)
            }
            if (res.length <= 0) {
                setSearch(true)
            }
        },
        onError: async (err) => {
            toast.error(err.message, {
                duration: 3000,
            })
        }
    })

    const handleBack = () => {
        setSearch(false);
        navigate(from, { replace: true });
    }

    const handleSearch = async (e) => {
        e.preventDefault();
        const dateFormat = "yyyy-MM-dd";
        let beginDate = new Date(month + '/01/' + payment_year)
        let endDate = new Date(beginDate.getFullYear(), beginDate.getMonth() + 1, 0)
        let paymentMonth = await getMonth(month)
        setPayMonth(paymentMonth)
        begin_date = format(beginDate, dateFormat);
        end_date = format(endDate, dateFormat);

        const searchData = {
            begin_date: begin_date,
            end_date: end_date,
        }
        searchMutation.mutate(searchData)
    }

    let total_amount_paid = 0
    let excelData = [];
    for (var i = 0; i < payments?.length; i++) {
        total_amount_paid += payments[i].payment_amount
        let monthlyData = {
            serial_no: i + 1,
            year: payments[i].year,
            payment_date: payments[i].payment_date,
            receipt_number: payments[i].receipt_number,
            pf_number: payments[i].pf_number,
            description: payments[i].description,
            payment_amount: payments[i].payment_amount
        }
        excelData.push(monthlyData)
    }

    let filename = payMonth + "_" + payment_year + "_Payment_Transactions.xlsx"
    const headings = [[
        "serial_no",
        "year",
        "payment_date",
        "receipt_number",
        "pf_number",
        "description",
        "payment_amount"
    ]];
    
    const handleExportExcel = () => {
        ExcelServices({ headings, excelData, filename })
    }

    const indexOfLastPage = currentPage * postPerPage;
    const indexOfFirstPage = indexOfLastPage - postPerPage;
    const currentPosts = payments?.slice(indexOfFirstPage, indexOfLastPage);

    const handleCancel = () => {
        setPaymentYear("");
        setMonth("");
        setPayments([])
        setSearch(false)
    }

    const searchContent = (
        <>
            <form onSubmit={handleSearch}>
                <div className="form-group row">
                    <label htmlFor="payment_year" className="col-sm-2">Year</label>
                    <div className="col-sm-3">
                        <select
                            type="text"
                            id="payment_year"
                            autoComplete="off"
                            onChange={(e) => setPaymentYear(e.target.value)}
                            required
                            value={payment_year}
                            name="payment_year"
                            className="form-control"
                        >
                            <option>Select Year</option>
                            {years.map((year, index) => <option value={year} key={`year${index}`}>{year}</option>
                            )}
                        </select>
                    </div>
                    <label htmlFor="month" className="col-sm-2">Month</label>
                    <div className="col-sm-3">
                        <select
                            type="text"
                            name='month'
                            id='month'
                            required
                            onChange={(e) => setMonth(e.target.value)}
                            value={month}
                            className="form-control"
                        >
                            <option>Month</option>
                            <option value="01">January - 01</option>
                            <option value="02">February - 02</option>
                            <option value="03">March - 03</option>
                            <option value="04">April - 04</option>
                            <option value="05">May - 05</option>
                            <option value="06">June - 06</option>
                            <option value="07">July - 07</option>
                            <option value="08">August - 08</option>
                            <option value="09">September - 09</option>
                            <option value="10">October - 10</option>
                            <option value="11">November - 11</option>
                            <option value="12">December - 12</option>
                        </select>
                    </div>
                    <div className="col-sm-2">
                        <button
                            type="submit"
                            className="btn btn-success "
                        > <i className="fa fa-search"></i> Search Payments</button>
                    </div>
                </div>
            </form>
        </>
    )

    const content = currentPosts.length > 0 && search ?
        <>
            <legend>Payment Transactions</legend>
            <div>
                <Link onClick={handleExportExcel} className="btn btn-success" ><i className='fa fa-upload'></i> Generate Excel File</Link>
            </div><br />
            <div className="table-responsive-lg">
                <table className="table table-bordered ">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Year</th>
                            <th scope="col">Payment Date</th>
                            <th>Receipt Number</th>
                            <th>PF Number</th>
                            <th>Description</th>
                            <th style={{ textAlign: "right" }}>Amount Paid</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentPosts.map((mem, index) =>
                            <tr key={mem.id}>
                                <td style={{ textAlign: 'center' }}>{postPerPage * (currentPage - 1) + index + 1}</td>
                                <td>{mem.year}</td>
                                <td>{mem.payment_date}</td>
                                <td>{mem.receipt_number}</td>
                                <td>{mem.pf_number}</td>
                                <td>{mem.description}</td>
                                <td style={{ textAlign: "right" }}>{mem.payment_amount.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                            </tr>
                        )}
                    </tbody>
                    <tbody>
                        <tr>
                            <th colSpan={"6"} style={{ fontSize: "16px", fontWeight: "900", color: "brown" }}>Total payment transactions for the month of {payMonth} {payment_year}</th>
                            <th style={{ fontSize: "16px", fontWeight: "900", color: "brown", textAlign: "right" }}>{total_amount_paid.toLocaleString(undefined, { minimumFractionDigits: 2 })}</th>
                            <th></th>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
        :
        null


    return (
        <>
            <section className="content-header">
                <Toaster toastOptions={{ position: "top-center" }} />
                <h1>Payment Transactions - By Month</h1>
            </section>
            <section className="content">
                <div className="box">
                    <div className="box-body">
                        <div className="panel panel-default panel-br-4">
                            <div className="panel-heading">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <h4 className="panel-title">
                                            {searchContent}
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            {currentPosts.length > 0 && search ?
                                <>
                                    <div className="panel-body">
                                        {content}
                                    </div>
                                    <div className="panel-footer">
                                        <Pagination
                                            data={payments}
                                            postPerPage={postPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            setPostPerPage={setPostPerPage} />
                                    </div>
                                    <div className="box-footer">
                                        <div className="col-sm-2">
                                            <button onClick={handleCancel} className="btn btn-success">
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </>
                                :
                                currentPosts.length <= 0 && search ?
                                    <>
                                        <div className="panel-body">
                                            <h4>No payment transactions for {<b>{begin_date}</b>} and {<b>{end_date}</b>}</h4>
                                        </div>
                                        <div className="box-footer">
                                            <div className="col-sm-2">
                                                <button onClick={handleCancel} className="btn btn-success">
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <div className="box-footer">
                                        <div className="col-sm-2">
                                            <button onClick={handleBack} className="btn btn-success">
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default PaymentByMonth;

async function getMonth(code) {
    let month = ""
    if (code === '01') {
        month = 'January'
    } else if (code === '02') {
        month = 'February'
    } else if (code === '03') {
        month = 'March'
    } else if (code === '04') {
        month = 'April'
    } else if (code === '05') {
        month = 'May'
    } else if (code === '06') {
        month = 'June'
    } else if (code === '07') {
        month = 'July'
    } else if (code === '08') {
        month = 'August'
    } else if (code === '09') {
        month = 'September'
    } else if (code === '10') {
        month = 'October'
    } else if (code === '11') {
        month = 'November'
    } else {
        month = 'December'
    }
    return month
}
