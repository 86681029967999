import React from 'react'
import GoogleMapReact from 'google-map-react'
import { Icon } from '@iconify/react'
import locationIcon from '@iconify/icons-mdi/map-marker'
import { useNavigate } from 'react-router-dom';

const SatelliteView = ({ location, zoomLevel }) => {
    console.log(location)
    const navigate = useNavigate();

    const handleExit = () => {
        navigate(-1);
    }

    return (
        <>
            <section className="content-header">
                <h1>Satellite View</h1>
            </section>
            <section className="content">
                <div className="box">
                    <div className="box-body">
                        <div className="panel panel-default panel-br-4">
                            <div className="panel-heading">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <h4 className="panel-title">
                                            Tenant Satellite View - {location.address}
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="panel-body">
                                <div className="google-map">
                                    <GoogleMapReact
                                        bootstrapURLKeys={{ key: 'AIzaSyAXNHHswPqRNC3QHQrO-u4nlP2vjvKAnlM' }}
                                        defaultCenter={location}
                                        defaultZoom={zoomLevel}
                                        options={(map) => ({ mapTypeId: map.MapTypeId.SATELLITE })}
                                        draggable={false}
                                    >
                                        <LocationPin
                                            lat={location.lat}
                                            lng={location.lng}
                                            text={location.address} />
                                    </GoogleMapReact>
                                </div>
                            </div>
                            <div className="panel-footer">
                                <div className="location-view">
                                    <button type='button' className='btn btn-danger' onClick={handleExit}>Exit Satellite Location View</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>

    )
}
const LocationPin = ({ text }) => (
    <div className="pin">
        <Icon icon={locationIcon} className="satellite-pin-icon" />
        <p className="satellite-pin-text">{text}</p>
    </div>
)

export default SatelliteView