export const SuperAdminSidebarData = [
    {
        title: 'Dashboard',
        path: '/admin/dashboard',
    },
    {
        title: 'Tenants Management',
        subNav: [
            {
                title: 'Registration',
                path: '/#',
                subNav: [
                    {
                        title: 'Sales & Regularization',
                        path: '/sales_regularization',
                    },
                    {
                        title: 'Tenant Update',
                        path: '/tenant_updates',
                    },
                ],
            },
            {
                title: 'Enquiry',
                path: '/#',
                subNav: [
                    {
                        title: 'Global Tenants',
                        path: '/global_tenants',
                    },
                    {
                        title: 'New Purchase Tenants',
                        path: '/new_purchase_tenants',
                    },
                    {
                        title: 'Regularization Tenants',
                        path: '/regularization_tenants',
                    },
                    {
                        title: 'Transfer Tenants',
                        path: '/transfer_tenants',
                    },
                    {
                        title: 'Others Tenants',
                        path: '/others_tenants',
                    },
                    {
                        title: 'Zonal Tenants',
                        path: '/zonal_tenants',
                    },
                ],
            },
            {
                title: 'Receive Payment',
                path: '/tenant_payments',
            },
        ]
    },
    {
        title: 'Operations Management',
        subNav: [
            {
                title: 'Approvals',
                path: '/#',
                subNav: [
                    {
                        title: 'Pending Approvals',
                        path: '/pending_approvals',
                    },
                    {
                        title: 'Approved Tenants',
                        path: '/approved_tenants',
                    },
                    {
                        title: 'Rejected Tenants',
                        path: '/rejected_tenants',
                    },
                ],
            },
        ]
    },
    {
        title: 'Accounts Management',
        path: '/#',
        subNav: [
            {
                title: 'Receive Payment',
                path: '/receive_payments',
            },
            {
                title: 'Refund Payment',
                path: '/refund_payment',
            },
            {
                title: 'Payments',
                path: '/#',
                subNav: [
                    {
                        title: 'Payment Transactions',
                        path: '/payment_transactions',
                    },
                    {
                        title: 'Payment Clearance',
                        path: '/payment_clearance',
                    },
                    {
                        title: 'Payment Types',
                        path: '/payment_types',
                    },
                    {
                        title: 'Yearly Payments',
                        path: '/yearly_payments',
                    },
                ],
            },
            {
                title: 'Ledgers',
                path: '/#',
                subNav: [
                    {
                        title: 'Ledger Accounts',
                        path: '/ledger_accounts',
                    },
                    {
                        title: 'Yearly Ledger',
                        path: '/yearly_ledger',
                    },
                ],
            },
            {
                title: 'Expenditure',
                path: '/#',
                subNav: [
                    {
                        title: 'Expenditure Items',
                        path: '/expenditure',
                    },
                ],
            },
            {
                title: 'Revenue',
                path: '/#',
                subNav: [
                    {
                        title: 'Revenue Items',
                        path: '/revenue',
                    },
                ],
            },
            {
                title: 'Accounts Report',
                path: '/accounts_report',
            },

        ]
    },
    {
        title: 'Legal Issues',
        subNav: [
            {
                title: 'Court Cases',
                path: '/court_cases',
            },
            {
                title: 'Pending Cases',
                path: '/pending_cases',
            },
            {
                title: 'Resolved Cases',
                path: '/resolved_cases',
            },
        ]
    },
    {
        title: 'General Reports',
        subNav: [
            {
                title: 'Operational Reports',
                path: '/operational_reports',
            },
        ]
    },
    {
        title: 'Statements',
        subNav: [
            {
                title: 'Detailed Statement',
                path: '/detailed_statement',
            },
            {
                title: 'Summary Statement',
                path: '/summary_statement',
            },
        ]
    },
    {
        title: 'System Admin',
        subNav: [
            {
                title: 'System Setup',
                subNav: [
                    {
                        title: 'System Users',
                        path: '/system_users',
                    },
                    {
                        title: 'System Roles',
                        path: '/system_roles',
                    },
                ],
            },
            {
                title: 'General Setup',
                path: '/general_setup',
            },
        ]
    },
    {
        title: 'Logout',
        path: '/logout',
    },
];

