import React, { useEffect, useState, useContext } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { useQuery } from 'react-query';
import { getTenantPaymentByCode } from '../../../../api/apiServices';
import addCommas from "../../../../services/addCommas";

const ViewPayment = ({ tenantCode }) => {

    const params = useParams();
    const id = parseInt(params.id)

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/payment_transactions";

    const {
        isLoading,
        isError,
        error,
        data: tenantPayments
    } = useQuery(['tenantPayments', tenantCode], () => getTenantPaymentByCode(tenantCode), {
        keepPreviousData: true,
    });

    let totalPayment = 0
    {
        tenantPayments?.payments?.map(paymtData => {
            totalPayment += parseFloat(paymtData.payment_amount)
        })
    }

    const handleCancel = () => {
        navigate(-1);
    }

    const FullName = tenantPayments?.tenant?.title + " " + tenantPayments?.tenant?.first_name + " " + tenantPayments?.tenant?.middle_name + " " + tenantPayments?.tenant?.last_name


    const memberInfo = (
        <>
            <legend style={{ color: "var(--white)" }}>Member Information</legend>
            <div className='table-responsive-lg'>
                <table className='table table-borderled'>
                    <>
                        <tbody>
                            <tr>
                                <td>FULL NAME</td>
                                <td><b>{FullName}</b></td>
                                <td>ZONE CODE</td>
                                <td><b>{tenantPayments?.tenant?.zone_code}</b></td>
                            </tr>
                            <tr>
                                <td>TENANT CODE</td>
                                <td><b>{tenantPayments?.tenant?.tenant_code}</b></td>
                                <td>LOCATION</td>
                                <td><b>{tenantPayments?.tenant?.land_location}</b></td>
                            </tr>
                            <tr>
                                <td>CONTACT NUMBER</td>
                                <td><b>{tenantPayments?.tenant?.contact_number}</b></td>
                                <td>GPS ADDRESS</td>
                                <td><b>{tenantPayments?.tenant?.residential_address}</b></td>
                            </tr>
                            <tr>
                                <td>EMAIL ADDRESS</td>
                                <td><b>{tenantPayments?.tenant?.email}</b></td>
                                <td>N. OF PLOTS</td>
                                <td><b>{tenantPayments?.tenant?.number_of_plots}</b></td>
                            </tr>
                            <tr>
                                <td>OCCUPATION</td>
                                <td><b>{tenantPayments?.tenant?.occupation}</b></td>
                                <td>LAND USE</td>
                                <td><b>{tenantPayments?.tenant?.land_use}</b></td>
                            </tr>
                        </tbody>
                    </>
                </table>
            </div>
        </>
    )
    let content
    if (isLoading) {
        content = <p>Loading Member Payment Transactions...</p>
    }
    else if (isError) {
        content = <p>{error.message}</p>
    } else {
        content =
            (
                <>
                    {/* Total Payment Transactions  */}
                    <legend>Payment Transactions</legend>
                    <div className="table-responsive lg">
                        <table className='table table-borderless table-striped'>
                            <thead>
                                <tr>
                                    <th style={{ textAlign: "center" }}>Year</th>
                                    <th style={{ textAlign: "center" }}>Payment Date</th>
                                    <th style={{}}>Receipt Number</th>
                                    <th style={{}}>Payment Type</th>
                                    <th style={{ whiteSpace: "nowrap" }}>Payment Mode</th>
                                    <th style={{ textAlign: "right" }}>Amount (GHS)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tenantPayments?.payments?.map(payment => (
                                    <tr key={payment.id}>
                                        <td style={{ textAlign: "center" }}>{payment.year}</td>
                                        <td style={{ textAlign: "center" }}>{payment.payment_date}</td>
                                        <td style={{ textTransform: "uppercase" }}>{payment.receipt_number}</td>
                                        <td style={{ textTransform: "uppercase" }}>{payment.payment_type}</td>
                                        <td style={{ whiteSpace: "nowrap" }}>{payment.description}</td>
                                        <td style={{ textAlign: "right" }}>{addCommas(payment.payment_amount).toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                                    </tr>
                                ))}
                            </tbody>
                            <tbody>
                                <tr>
                                    <td colSpan="6" style={{ backgroundColor: "white" }}></td>
                                </tr>
                                <tr>
                                    <td colSpan="5" className="border-bottom" style={{ width: "55%", textAlign: "left", fontSize: "16px", fontWeight: "700", color: "darkred" }}>
                                        TOTAL PAYMENT
                                    </td>
                                    {totalPayment < 0 ? (
                                        <td className="border-bottom" style={{ width: "40%", textAlign: "right", fontSize: "16px", fontWeight: "700", color: "darkred" }}>
                                            ({(totalPayment * -1).toLocaleString(undefined, { minimumFractionDigits: 2 })}) </td>
                                    ) : (
                                        <td className="border-bottom" style={{ width: "40%", textAlign: "right", fontSize: "16px", fontWeight: "700", color: "darkred" }}>
                                            {totalPayment.toLocaleString(undefined, { minimumFractionDigits: 2 })} </td>
                                    )}
                                </tr>
                                <tr>
                                    <td colSpan="7" style={{ width: "95%", borderBottom: "5px" }}></td>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                </>
            )
    }

    return (
        <>
            <section className="content-header">
                <h1>Tenant Payment Transactions</h1>
            </section>
            <section className="content">
                <div className="box">
                    <div className="box-body">
                        <div className="panel panel-default panel-br-4">
                            <div className="panel-heading">
                                <div className="row">
                                    <div className="col-sm-12">
                                        {/* <h4 className="panel-title"> */}
                                        {memberInfo}
                                        {/* </h4> */}
                                    </div>
                                </div>
                            </div>
                            <div className="panel-body">
                                {content}
                            </div>
                            <div className="panel-footer">
                            </div>
                            <div className="box-footer">
                                <div className="col-sm-2">
                                    <button onClick={handleCancel} className="btn btn-success">
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ViewPayment