import axios from './axios';

const delay = () => new Promise((res) => setTimeout(() => res(), 800));

// BANKS API
export const banksUrlEndpoint = '/banks/get_banks';
export const bankByIdUrlEndpoint = '/banks/get_bank';
export const branchesUrlEndpoint = '/banks/get_branches_by_bank_id';


export async function getBanks() {
    await delay()
    const response = await axios.get(banksUrlEndpoint, {
        withCredentials: true
    })
    return response.data;
}

export async function getBankById( url, BankId ) {
    await delay()
    console.log(`${url}/${BankId}`)
    const response = await axios.get(`${url}/${BankId}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}

export async function getBranchesByBankId(url, BankId) {
    await delay()
    const response = await axios.get(`${url}/${BankId}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
