import { createContext, useState } from "react"
import toast, { Toaster } from 'react-hot-toast'
import { useQuery } from 'react-query';
import {
    getBankBranchBySortcode,
    getLastIndentureNumber
} from '../api/apiServices';
const RegistrationContext = createContext({})

export const RegistrationProvider = ({ children }) => {
    let curr = new Date('1900-01-01');
    curr.setDate(curr.getDate());
    let date = curr.toISOString().substring(0, 10);

    const individualTitle = {
        0: 'TYPE OF TENANT',
        1: 'PURPOSE',
        2: 'PAYMENT CHOICE',
        3: 'INDIVIDUAL / INSTITUTION INFORMATION',
        4: 'CONTACT INFORMATION',
        5: 'NEXT OF KIN',
        6: 'CONTACT PERSON/REFERENCE',
        7: 'LAND DETAILS',
        8: 'MODE OF PAYMENT',
    }
    const institutionTitle = {
        0: 'TYPE OF TENANT',
        1: 'PURPOSE',
        2: 'PAYMENT CHOICE',
        3: 'INDIVIDUAL / INSTITUTION INFORMATION',
        4: 'CONTACT INFORMATION',
        5: 'LAND DETAILS',
        6: 'MODE OF PAYMENT',
    }

    let nYear = 10
    const [page, setPage] = useState(0)
    const [phone_name, setPhoneName] = useState("")

    const [data, setData] = useState({
        isChequeAdvice: false,
        installmentsPay: false,
        outrightPay: false,
        showBalance: false,
        interestRate: "",

        tenantType: "",
        individual: true,
        institution: false,


        officePaymentType: "",
        officePropertyPrice: "",
        officePaymentDuration: "",
        officeInitialDeposit: "",
        officePaymentMode: "",
        officeChequeAdviceNumber: "",
        officeBankBranchName: "",
        officeChequeAdviceDate: "",
        officeSaleRep: "",
        officeZoneName: "",
        officeLandLocation: "",
        officeOutstandingBalance: "",
        officeInterestRate: 0,
        officeInterestAmount: 0,
        officeSignature: "",
        officeSupervisorSignature: "",
        officeAccountantSignature: "",

        mainPurpose: "",
        persTitle: "",
        persLastname: "",
        persFirstname: "",
        persMiddlename: "",
        persGender: "",
        persDOB: date,
        persNationality: "",
        persOccupation: "",
        persSpouseContactNumber: "",
        persIdentificationType: "",
        persIdNumber: "",
        persDateIssued: "",
        persExpiryDate: "",
        persMaritalStatus: "",
        persPhoto: "",

        infoContactNumber: "",
        infoEmail: "",
        infoPostalAddress: "",
        infoResidentialAddress: "",
        infoLatitude: "",
        infoLongitude: "",

        nkinNextOfKin: "",
        nkinContactNumber: "",
        nkinRelationship: "",
        refpContactPerson: "",
        refpContactAddress: "",
        refpRelationship: "",
        refpContactNumber: "",
        landDeedsNumber: "",
        landBuildingPermit: "",
        landApprovedDate: date,
        landNewIndentureNumber: "",
        landAcquisitionDate: date,
        landNumberOfPlots: "",
        landLandUse: "",
        landLandLocation: "",

        modePropertyPrice: "",
        modePaymentType: "",
        modePaymentDuration: "",
        modeInitialDeposit: "",
        modePaymentMode: "",
        modeOutstandingBalance: "",

        persName: "",
        persDOE: "",
        persContactPerson: "",
        persType: ""
    })
    
    useQuery('lastIndentureNumber', () => getLastIndentureNumber(), {
        onSuccess: async (res) => {
              setData(prevData => ({
                ...prevData,
                landNewIndentureNumber: res,
            }))
        }
    });

    const handleTenantType = (e) => {
        const type = e.target.type
        const name = e.target.name
        const value = type === "checkbox"
            ? e.target.checked
            : e.target.value

        if (value === "individual") {
            setData(prevData => ({
                ...prevData,
                [name]: value,
                individual: true,
                institution: false
            }))
        } else if (value === "institution") {
            setData(prevData => ({
                ...prevData,
                [name]: value,
                individual: false,
                institution: true
            }))
        }
    }

    const handlePayChoice = e => {
        const type = e.target.type
        const name = e.target.name
        const value = type === "checkbox"
            ? e.target.checked
            : e.target.value
        if (value === "Installments") {
            setData(prevData => ({
                ...prevData,
                [name]: value,
                installmentsPay: true,
                outrightPay: false
            }))
        }
        if (value === "Outright") {
            setData(prevData => ({
                ...prevData,
                [name]: value,
                installmentsPay: false,
                outrightPay: true
            }))
        }
    }

    const handleOnPhoneInput = (e) => {
        const name = e.target.placeholder
        setPhoneName(name)
    }

    const handlePhoneInput = (e) => {
        const value = e
        const name = phone_name ? phone_name : undefined
        setData(prevData => ({
            ...prevData,
            [name]: value
        }))
    }

    const handlePhoto = (e) => {
        let file = e.target.files[0];
        const name = e.target.name
        if (file) {
            if (maxSelectedImage(e) && checkMimeType(e) && checkFileSize(e)) {
                setData(prevData => ({
                    ...prevData,
                    [name]: file
                }))

            }
        }
    };

    const maxSelectedImage = (e) => {
        let file = e.target.files[0];
        if (file.length > 1) {
            const msg = 'Only 1 images can be uploaded at a time';
            e.target.value = null; // discard selected file
            toast.error(msg)
            return false;
        } else {
            return true;
        }
    }
    const checkMimeType = (e) => {
        // get file object
        let file = e.target.files[0];
        let err = [];
        const types = ["image/jpg", "image/jpeg", "image/png", "image/gif", "image/svc"]
        for (var i = 0; i < file.length; i++) {
            // eslint-disable-next-line
            if (types.every(type => file[i].type !== type)) {
                err[i] = file[i].type + ' is not a supported format\n';
            };
        };
        for (var x = 0; x < err.length; x++) {
            e.target.value = null;
            toast.error(err[x])
        }
        return true;
    }
    const checkFileSize = (e) => {
        let file = e.target.files[0];
        let size = 2000000;
        let err = [];
        for (var i = 0; i < file.length; i++) {
            if (file[i].size > size) {
                err[i] = file[i].type + ' is too large. Please pick a small image\n';
            };
        };
        for (var x = 0; x < err.length; x++) {
            e.target.value = null;
            toast.error(err[x])
        }
        return true;
    }

    const handlePaymentMode = e => {
        const name = e.target.name
        const value = e.target.value
        if (value === "CHEQUE" || value.replace(" ", "_") === "BANKERS_DRAFT") {
            setData(prevData => ({
                ...prevData,
                [name]: value,
                isChequeAdvice: true,
            }))
        } else {
            setData(prevData => ({
                ...prevData,
                [name]: value,
                isChequeAdvice: false,
            }))
        }
    }
    const handleChequeNumber = (e) => {
        const name = e.target.name
        const value = e.target.value
        let bankSortcode = value.substring(0, 6)
        getBankBranchBySortcode(bankSortcode).then((bank) => {
            setData(prevData => ({
                ...prevData,
                ["officeBankBranchName"]: bank.branch_name
            }))
        })
    }

    const handleInterestRate = e => {
        const name = e.target.name
        const value = e.target.value
        if (value === "3-MONTHS") {
            const rate = 0
            setData(prevData => ({
                ...prevData,
                [name]: value,
                interestRate: rate,
                officeInterestRate: rate,
            }))

        } else if (value === "6-MONTHS") {
            const rate = 2
            setData(prevData => ({
                ...prevData,
                [name]: value,
                interestRate: rate,
                officeInterestRate: rate,
            }))
        } else if (value === "AFTER-6-MONTHS") {
            const rate = 10

            setData(prevData => ({
                ...prevData,
                [name]: value,
                interestRate: rate,
                officeInterestRate: rate,
            }))
        }
    }

    const handleOutrightBalances = e => {
        const name = e.target.name
        const value = e.target.value

        if (data.outrightPay) {
            setData(prevData => ({
                ...prevData,
                [name]: value,
                officeInitialDeposit: parseFloat(value),
                officeOutstandingBalance: parseFloat(data.officePropertyPrice) - parseFloat(value),
            }))
        }
    }

    const handleInitialDeposit = e => {
        const name = e.target.name
        const value = e.target.value
        setData(prevData => ({
            ...prevData,
            [name]: value,
            officeOutstandingBalance: parseFloat(data.officePropertyPrice) - parseFloat(value),
        }))
    }

    const handleShowBalance = () => {
        let interestAmount = 0
        if (data.installmentsPay) {
            interestAmount = parseFloat(data.officeOutstandingBalance) * ((parseFloat(data.interestRate)) / 100)
        }
        setData(prevData => ({
            ...prevData,
            showBalance: true,
            officeInterestAmount: interestAmount
        }))

    }
    const addYears = (dDate, nyr) => {
        let issuedDate = new Date(dDate)
        let backDate = new Date(issuedDate.setDate(issuedDate.getDate() - 1))
        return new Date(backDate.setFullYear(backDate.getFullYear() + nyr));
    }

    const handleExpiryDate = e => {
        const type = e.target.type
        const name = e.target.name
        const value = type === "checkbox"
            ? e.target.checked
            : e.target.value

        const expDate = addYears(value, nYear)
        if (data.individual) {
            setData(prevData => ({
                ...prevData,
                [name]: value,
                persExpiryDate: expDate.toLocaleDateString("sv-SE"),
            }))
        }
        if (data.institution) {
            setData(prevData => ({
                ...prevData,
                [name]: value,
                persExpiryDate: expDate.toLocaleDateString("sv-SE")
            }))
        }
    }

    const handleChange = e => {

        const type = e.target.type
        const name = e.target.name
        const value = type === "checkbox"
            ? e.target.checked
            : e.target.value

        setData(prevData => ({
            ...prevData,
            [name]: value
        }))
    }


    const {
        officeChequeAdviceNumber,
        officeBankBranchName,
        officeChequeAdviceDate,
        officeOutstandingBalance,
        officeInterestRate,
        officeInterestAmount,
        officePaymentDuration,

        persTitle,
        persLastname,
        persFirstname,
        persMiddlename,
        persDOB,
        persOccupation,
        persSpouseContactNumber,
        persMaritalStatus,
        persPhoto,
        persName,
        persDOE,
        persType,
        persContactPerson,

        infoPostalAddress,
        infoLatitude,
        infoLongitude,

        installmentsPay,
        outrightPay,
        showBalance,

        modePropertyPrice,
        modeInitialDeposit,
        modePaymentDuration,
        modePaymentType,
        modePaymentMode,
        modeOutstandingBalance,

        landLandLocation,
        interestRate,
        ...requiredInputs
    } = data

    // const canSubmit = [...Object.values(requiredInputs)].every(Boolean) && page === Object.keys(title).length - 1

    let canSubmit
    if (data.individual) {
        canSubmit = page === Object.keys(individualTitle).length - 1
    }
    if (data.institution) {
        canSubmit = page === Object.keys(institutionTitle).length - 1
    }

    let canNextPage0
    let canNextPage1
    let canNextPage2
    let canNextPage3
    let canNextPage4
    let canNextPage5
    let canNextPage6
    let canNextPage7
    let canNextPage8

    let disableNext

    if (data.individual) {
        // TENANT TYPE
        canNextPage0 = Object.keys(data)
            .filter(key => key.startsWith('tena'))
            .map(key => data[key])
            .every(Boolean)

        // MAIN PURPOSE
        canNextPage1 = Object.keys(data)
            .filter(key => key.startsWith('main'))
            .map(key => data[key])
            .every(Boolean)

        // OFFICE USE (PAYMENT CHOICE)
        canNextPage2 = Object.keys(data)
            .filter(key => key.startsWith('office')
                && key !== 'officePaymentDuration'
                && key !== 'officeOutstandingBalance'
                && key !== 'officeInterestRate'
                && key !== 'officeInterestAmount'
                && key !== 'officeChequeAdviceNumber'
                && key !== 'officeBankBranchName'
                && key !== 'officeChequeAdviceDate'
            )
            .map(key => data[key])
            .every(Boolean)

        //  INDIVIDUAL / INSTITUTION INFORMATION
        canNextPage3 = Object.keys(data)
            .filter(key => key.startsWith('pers')
                && key !== 'persDOB'
                && key !== 'persDOE'
                && key !== 'persFirstname'
                && key !== 'persLastname'
                && key !== 'persMaritalStatus'
                && key !== 'persMiddlename'
                && key !== 'persName'
                && key !== 'persOccupation'
                && key !== 'persPhoto'
                && key !== 'persSpouseContactNumber'
                && key !== 'persTitle'
                && key !== 'persType'
                && key !== 'persContactPerson'
            )
            .map(key => data[key])
            .every(Boolean)

        // CONTACT INFORMATION
        canNextPage4 = Object.keys(data)
            .filter(key => key.startsWith('info')
                && key !== 'infoPostalAddress'
                && key !== 'infoLatitude'
                && key !== 'infoLongitude'
            )
            .map(key => data[key])
            .every(Boolean)

        canNextPage5 = Object.keys(data)
            .filter(key => key.startsWith('nkin'))
            .map(key => data[key])
            .every(Boolean)

        // 'CONTACT PERSON/REFERENCE',
        canNextPage6 = Object.keys(data)
            .filter(key => key.startsWith('refp'))
            .map(key => data[key])
            .every(Boolean)


        //  LAND DETAILS
        canNextPage7 = Object.keys(data)
            .filter(key => key.startsWith('land')
                && key !== 'landLandLocation'
                && key !== 'landBuildingPermit'
                && key !== 'landDeedsNumber'
            )
            .map(key => data[key])
            .every(Boolean)

        // MODE OF PAYMENT
        canNextPage8 = Object.keys(data)
            .filter(key => key.startsWith('mode')
                && key !== 'modePropertyPrice'
                && key !== 'modePaymentDuration'
                && key !== 'modeInitialDeposit'
                && key !== 'modePaymentType'
                && key !== 'modePaymentMode'
                && key !== 'modeOutstandingBalance'
            )
            .map(key => data[key])
            .every(Boolean)

        disableNext =
            (page === Object.keys(individualTitle).length - 1)
            || (page === 0 && !canNextPage0)
            || (page === 1 && !canNextPage1)
            || (page === 2 && !canNextPage2)
            || (page === 3 && !canNextPage3)
            || (page === 4 && !canNextPage4)
            || (page === 5 && !canNextPage5)
            || (page === 6 && !canNextPage6)
            || (page === 7 && !canNextPage7)
            || (page === 8 && !canNextPage8)

    }

    if (data.institution) {
        // TENANT TYPE
        canNextPage0 = Object.keys(data)
            .filter(key => key.startsWith('tena'))
            .map(key => data[key])
            .every(Boolean)

        // MAIN PURPOSE
        canNextPage1 = Object.keys(data)
            .filter(key => key.startsWith('main'))
            .map(key => data[key])
            .every(Boolean)

        // OFFICE USE (PAYMENT CHOICE)
        canNextPage2 = Object.keys(data)
            .filter(key => key.startsWith('office')
                && key !== 'officePaymentDuration'
                && key !== 'officeOutstandingBalance'
                && key !== 'officeInterestRate'
                && key !== 'officeInterestAmount'
                && key !== 'officeChequeAdviceNumber'
                && key !== 'officeBankBranchName'
                && key !== 'officeChequeAdviceDate'
            )
            .map(key => data[key])
            .every(Boolean)

        //  INDIVIDUAL / INSTITUTION INFORMATION
        canNextPage3 = Object.keys(data)
            .filter(key => key.startsWith('pers')
                && key !== 'persDOB'
                && key !== 'persDOE'
                && key !== 'persFirstname'
                && key !== 'persLastname'
                && key !== 'persMaritalStatus'
                && key !== 'persMiddlename'
                && key !== 'persName'
                && key !== 'persOccupation'
                && key !== 'persPhoto'
                && key !== 'persSpouseContactNumber'
                && key !== 'persTitle'
                && key !== 'persType'
                && key !== 'persContactPerson'
            )
            .map(key => data[key])
            .every(Boolean)

        // CONTACT INFORMATION
        canNextPage4 = Object.keys(data)
            .filter(key => key.startsWith('info')
                && key !== 'infoPostalAddress'
                && key !== 'infoLatitude'
                && key !== 'infoLongitude'
            )
            .map(key => data[key])
            .every(Boolean)

        canNextPage5 = Object.keys(data)
            .filter(key => key.startsWith('nkin'))
            .map(key => data[key])
            .every(Boolean)

        // 'CONTACT PERSON/REFERENCE',
        canNextPage6 = Object.keys(data)
            .filter(key => key.startsWith('refp'))
            .map(key => data[key])
            .every(Boolean)


        //  LAND DETAILS
        canNextPage7 = Object.keys(data)
            .filter(key => key.startsWith('land')
                && key !== 'landLandLocation'
                && key !== 'landBuildingPermit'
                && key !== 'landDeedsNumber'
            )
            .map(key => data[key])
            .every(Boolean)

        // MODE OF PAYMENT
        canNextPage8 = Object.keys(data)
            .filter(key => key.startsWith('mode')
                && key !== 'modePropertyPrice'
                && key !== 'modePaymentDuration'
                && key !== 'modeInitialDeposit'
                && key !== 'modePaymentType'
                && key !== 'modePaymentMode'
                && key !== 'modeOutstandingBalance'
            )
            .map(key => data[key])
            .every(Boolean)

        disableNext =
            (page === Object.keys(institutionTitle).length - 1)
            || (page === 0 && !canNextPage0)
            || (page === 1 && !canNextPage1)
            || (page === 2 && !canNextPage2)
            || (page === 3 && !canNextPage3)
            || (page === 4 && !canNextPage4)
            || (page === 7 && !canNextPage7)
            || (page === 8 && !canNextPage8)
    }

    const disablePrev = page === 0
    const prevHide = page === 0 && "remove-button"

    let nextHide
    let submitHide
    if (data.individual) {
        nextHide = page === Object.keys(individualTitle).length - 1 && "remove-button"
        submitHide = page !== Object.keys(individualTitle).length - 1 && "remove-button"
    }
    if (data.institution) {
        nextHide = page === Object.keys(institutionTitle).length - 1 && "remove-button"
        submitHide = page !== Object.keys(institutionTitle).length - 1 && "remove-button"
    }
    return (
        <RegistrationContext.Provider value={{
            individualTitle,
            institutionTitle,
            page,
            setPage,
            data,
            setData,
            canSubmit,
            handleInterestRate,
            handleOutrightBalances,
            handleInitialDeposit,
            handlePayChoice,
            handleOnPhoneInput,
            handleChange,
            handlePhoneInput,
            handlePhoto,
            handleExpiryDate,
            handlePaymentMode,
            handleChequeNumber,
            handleShowBalance,
            handleTenantType,
            disablePrev,
            disableNext,
            prevHide,
            nextHide,
            submitHide
        }}>
            <Toaster toastOptions={{ position: "top-center" }} />
            {children}
        </RegistrationContext.Provider>
    )
}

export default RegistrationContext 