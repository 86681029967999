import UpdateFormSetup from "./UpdateFormSetup"
import { UpdateProvider } from '../../../../context/UpdateContext'

function UpdateForm() {

    return (
        <>
            <UpdateProvider>
                <UpdateFormSetup />
            </UpdateProvider>
        </>
    )
}

export default UpdateForm;