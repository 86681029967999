import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getAllLedgers, searchMemberLedger } from "../../../../api/apiServices";
import Pagination from '../../../../services/Pagination';
import 'react-confirm-alert/src/react-confirm-alert.css';
import toast, { Toaster } from 'react-hot-toast'


const LedgerAccounts = (props) => {

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/ledger_accounts";

    const [search, setSearch] = useState(false);
    const [ledger, setLedger] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [postPerPage, setPostPerPage] = useState(20);

    const [tenant_code, setPFNumber] = useState("");
    const [contact_number, setMobileNumber] = useState("");
    const [first_name, setFirstName] = useState("");
    const [last_name, setLastName] = useState("");

    const {
        isLoading,
        isError,
        error,
        data: ledgers
    } = useQuery('ledgers', getAllLedgers, {
        select: data => data.sort((a, b) => b.id - a.id)
    });

    let total_ledgers = ledgers?.length

    const handleSearch = async (e) => {
        e.preventDefault();
        const searchData = {
            "tenant_code": tenant_code,
            "contact_number": contact_number,
            "first_name": first_name,
            "last_name": last_name,
        }
        try {
            await searchMemberLedger(searchData).then((res) => {
                if (res.length > 0) {
                    setLedger(res)
                    setPFNumber("");
                    setMobileNumber("");
                    setFirstName("");
                    setLastName("");
                    setSearch(true)
                } else {
                    toast.info("No Ledger found for member")
                    setSearch(false)
                }
            }).catch((err) => {
                toast.error("Provide at least one search parameter ")
                return false
            })
        } catch (err) {
            toast.error(err)
        }
    }
    const handleCancel = () => {
        setPFNumber("");
        setMobileNumber("");
        setFirstName("");
        setLastName("");
        setSearch(false);
        navigate(from, { replace: true });
    }

    let currentPosts = []
    const indexOfLastPage = currentPage * postPerPage;
    const indexOfFirstPage = indexOfLastPage - postPerPage;
    if (!search) {
        currentPosts = ledgers?.slice(indexOfFirstPage, indexOfLastPage);
    } else {
        currentPosts = ledger?.slice(indexOfFirstPage, indexOfLastPage);
    }


    let searchContent = (
        <form onSubmit={handleSearch} >
            <div className="form-group row">
                <div className="col-sm-2">
                    <input
                        className="form-control"
                        type="text"
                        placeholder="PF Number"
                        style={{ textTransform: "uppercase" }}
                        onChange={(e) => setPFNumber(e.target.value)}
                        value={tenant_code}
                    />
                </div>
                <div className="col-sm-2">
                    <input
                        className="form-control"
                        type="text"
                        // style={{ textTransform: "uppercase" }}
                        placeholder="Mobile Number"
                        onChange={(e) => setMobileNumber(e.target.value)}
                        value={contact_number}
                    />
                </div>
                <div className="col-sm-3">
                    <input
                        className="form-control"
                        type="text"
                        placeholder="FirstName"
                        style={{ textTransform: "uppercase" }}
                        onChange={(e) => setFirstName(e.target.value)}
                        value={first_name}
                    />
                </div>
                <div className="col-sm-3">
                    <input
                        className="form-control"
                        type="text"
                        placeholder="LastName"
                        style={{ textTransform: "uppercase" }}
                        onChange={(e) => setLastName(e.target.value)}
                        value={last_name}
                    />
                </div>
                <button
                    type="submit"
                    className="btn btn-success "
                > <i className="fa fa-search"></i> Search Member</button>
            </div>

        </form>
    )

    let content
    if (isLoading) {
        content = <p>Loading Ledger Accounts...</p>
    } else if (isError) {
        content = <p>{error.message}</p>
    } else {
        content =
            currentPosts?.length <= 0 ?
                <div className="table-responsive-lg">
                    <table className="table table-bordered">
                        <tbody>
                            <tr>
                                <td style={{ fontSize: "20px", fontWeight: "700", color: "#000", textAlign: "center" }}>
                                    There are no ledger accounts for Tenants
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                :
                <div className="table-responsive-lg">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th style={{ textAlign: 'center' }} >#</th>
                                <th style={{ whiteSpace: 'nowrap' }}>Tenant Code</th>
                                <th style={{ whiteSpace: 'nowrap' }}>Name</th>
                                <th style={{ whiteSpace: 'nowrap' }}>Year</th>
                                <th style={{ whiteSpace: 'normal', textAlign: "center" }}>Opening Balance</th>
                                <th style={{ whiteSpace: 'normal', textAlign: "center" }}>Expected Amount</th>
                                <th style={{ whiteSpace: 'normal', textAlign: "center" }}>Amount Paid</th>
                                <th style={{ whiteSpace: 'normal', textAlign: "center" }}>Closing Balance</th>
                                <th style={{ textAlign: "center" }}>ACTION</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentPosts?.map((ledger, index) =>
                                <tr key={ledger?.id}>
                                    <td style={{ textAlign: 'center' }}>{postPerPage * (currentPage - 1) + index + 1}</td>
                                    <td style={{ whiteSpace: 'nowrap' }}>{ledger?.tenant_code}</td>
                                    <td style={{ whiteSpace: 'nowrap' }}>{ledger?.tenants?.first_name} {ledger?.tenants?.middle_name} {ledger?.tenants?.last_name}</td>
                                    <td style={{ whiteSpace: 'nowrap' }}>{ledger?.year}</td>
                                    {ledger?.opening_balance < 0 ?
                                        <td style={{ textAlign: "right" }}>({(ledger?.opening_balance * -1).toLocaleString(undefined, { minimumFractionDigits: 2 })})</td>
                                        :
                                        <td style={{ textAlign: "right" }}>{ledger?.opening_balance.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                                    }
                                    {ledger?.expected_total_amount < 0 ?
                                        <td style={{ whiteSpace: 'nowrap', textAlign: "right" }}>({(ledger?.expected_total_amount * -1).toLocaleString(undefined, { minimumFractionDigits: 2 })})</td>
                                        :
                                        <td style={{ whiteSpace: 'nowrap', textAlign: "right" }}>{ledger?.expected_total_amount.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                                    }
                                    <td style={{ textAlign: "right" }}>{ledger?.total_amount_paid.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                                    {ledger?.closing_balance < 0 ?
                                        <td style={{ textAlign: "right" }}>({(ledger?.closing_balance * -1).toLocaleString(undefined, { minimumFractionDigits: 2 })})</td>
                                        :
                                        <td style={{ textAlign: "right" }}>{ledger?.closing_balance.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                                    }
                                    <td style={{ whiteSpace: 'nowrap', textAlign: "center" }}>
                                        <div style={{ display: 'inline-block' }}>
                                            <Link to={`/view_ledger/${ledger?.tenant_id}`} className="small"><i className='fa fa-eye'></i> view</Link>
                                        </div>
                                    </td>
                                </tr >
                            )}
                        </tbody>
                    </table>
                </div>
    }

    return (
        <>
            <section className="content-header">
                <Toaster toastOptions={{ position: "top-center" }} />
                <h1>Ledger Accounts - {total_ledgers}</h1>
            </section>
            <section className="content">
                <div className="box">
                    <div className="box-body">
                        <Link className="btn btn-danger" to="/arrears_tenants"><i className='fa fa-upload'></i>&nbsp; &nbsp;Tenants In Arrears</Link>&nbsp; &nbsp;
                        <Link className="btn btn-success" to="/good_standing_tenants"><i className='fa fa-plus'></i>&nbsp; &nbsp;Tenants In GoodStanding</Link><br /><br />

                        <div className="panel panel-default panel-br-4">
                            <div className="panel-heading">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <h4 className="panel-title">
                                            {searchContent}
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="panel-body">
                                {content}
                            </div>
                            {currentPosts?.length > 0 && !search ?
                                <div className="panel-footer">
                                    <Pagination
                                        data={ledgers}
                                        postPerPage={postPerPage}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                        setPostPerPage={setPostPerPage}
                                    />
                                </div>
                                : currentPosts?.length > 0 && search ?
                                    <div className="panel-footer">
                                        <div className="form-group row">
                                            <div className="col-sm-2">
                                                <button
                                                    type="submit"
                                                    className="btn btn-danger "
                                                    onClick={handleCancel}
                                                > <i className="fa fa-search"></i> Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                    : null
                            }
                        </div>
                        <div className="box-footer"></div>
                    </div>
                </div>
            </section>
        </>
    )

}
export default LedgerAccounts;


