export const FrontDeskSidebarData = [
    {
        title: 'Dashboard',
        path: '/admin/dashboard',
    },
    {
        title: 'Tenants Management',
        subNav: [
            {
                title: 'Registration',
                path: '/#',
                subNav: [
                    {
                        title: 'Sales & Regularization',
                        path: '/sales_regularization',
                    },
                ],
            },
            {
                title: 'Enquiry',
                path: '/#',
                subNav: [
                    {
                        title: 'Global Tenants',
                        path: '/global_tenants',
                    },
                    {
                        title: 'New Purchase Tenants',
                        path: '/new_purchase_tenants',
                    },
                    {
                        title: 'Regularization Tenants',
                        path: '/regularization_tenants',
                    },
                    {
                        title: 'Transfer Tenants',
                        path: '/transfer_tenants',
                    },
                    {
                        title: 'Others Tenants',
                        path: '/others_tenants',
                    },
                    {
                        title: 'Zonal Tenants',
                        path: '/zonal_tenants',
                    },
                ],
            },
            {
                title: 'Tenant Update',
                path: '/tenant_updates',
            },

        ]
    },
    {
        title: 'Payments',
        path: '/#',
        subNav: [
            {
                title: 'Receive Payment',
                path: '/tenant_payments',
            },

            {
                title: 'Refund Payment',
                path: '/refund_payment',
            },
            {
                title: 'View Payments',
                path: '/payment_transactions',
            },
        ]
    },
    {
        title: 'Expenditure',
        path: '/#',
        subNav: [
            {
                title: 'Capture Expenses',
                path: '/capture_expenses',
            },
        ]
    },
    {
        title: 'Statements',
        subNav: [
            {
                title: 'Tenant Detailed Statement',
                path: '/tenant_detailed_statement',
            },
            {
                title: 'Tenant Summary Statement',
                path: '/tenant_summary_statement',
            },
        ]
    },
    {
        title: 'Logout',
        path: '/logout',
    },

];

