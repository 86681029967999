import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { getPayments } from "../../../../api/apiServices";
import toast, { Toaster } from 'react-hot-toast'
import { useMutation } from 'react-query';
import Pagination from '../../../../services/Pagination';
import { read, utils, writeFile } from 'xlsx';
import ExcelServices from '../../../../services/ExcelServices'

const PaymentByPeriod = (props) => {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/payment_transactions";


    var curr = new Date();
    curr.setDate(curr.getDate());
    var date = curr.toISOString().substring(0, 10);

    const [search, setSearch] = useState(false);
    const [payments, setPayments] = useState([]);
    const [begin_date, setBeginDate] = useState(date);
    const [end_date, setEndDate] = useState(date);

    const [currentPage, setCurrentPage] = useState(1);
    const [postPerPage, setPostPerPage] = useState(20);


    const searchMutation = useMutation(getPayments, {
        onSuccess: async (res) => {
            if (res.length > 0) {
                setPayments(res)
                setSearch(true)
            }
            if (res.length <= 0) {
                setSearch(true)
            }
        },
        onError: async (err) => {
            toast.error(err.message, {
                duration: 3000,
            })
        }
    })

    const handleBack = () => {
        setSearch(false);
        navigate(from, { replace: true });
    }
    const handleSearch = async (e) => {
        e.preventDefault();

        const searchData = {
            begin_date: begin_date,
            end_date: end_date,
        }
        searchMutation.mutate(searchData)
    }

    let total_amount_paid = 0
    let excelData = [];
    for (var i = 0; i < payments?.length; i++) {
        total_amount_paid += payments[i].payment_amount
        let monthlyData = {
            serial_no: i + 1,
            year: payments[i].year,
            payment_date: payments[i].payment_date,
            receipt_number: payments[i].receipt_number,
            pf_number: payments[i].pf_number,
            description: payments[i].description,
            payment_amount: payments[i].payment_amount
        }
        excelData.push(monthlyData)
    }

    let filename = `Payment_Transactions_${begin_date} - ${end_date}.xlsx`
    const headings = [[
        "serial_no",
        "year",
        "payment_date",
        "receipt_number",
        "pf_number",
        "description",
        "payment_amount"
    ]];

    const handleExportExcel = () => {
        ExcelServices({ headings, excelData, filename })
    }

    const indexOfLastPage = currentPage * postPerPage;
    const indexOfFirstPage = indexOfLastPage - postPerPage;
    const currentPosts = payments?.slice(indexOfFirstPage, indexOfLastPage);


    const handleCancel = () => {
        setBeginDate(date);
        setEndDate(date);
        setPayments([])
        setSearch(false)
    }

    const searchContent = (
        <>
            <form onSubmit={handleSearch}>
                <div className="form-group row">
                    <label htmlFor="pf_number" className="col-sm-2">Begin Date</label>
                    <div className="col-sm-3">
                        <input
                            className="form-control"
                            type="date"
                            onChange={(e) => setBeginDate(e.target.value)}
                            required
                            value={begin_date}
                        />
                    </div>
                    <label htmlFor="pf_number" className="col-sm-2">End Date</label>
                    <div className="col-sm-3">
                        <input
                            className="form-control"
                            type="date"
                            onChange={(e) => setEndDate(e.target.value)}
                            required
                            value={end_date}
                        />
                    </div>
                    <button
                        type="submit"
                        className="btn btn-success "
                    > <i className="fa fa-search"></i> Search Payments</button>
                </div>
            </form>
        </>
    )

    const content = currentPosts.length > 0 && search ?
        <>
            <legend>Payment Transactions</legend>
            <div>
                <Link onClick={handleExportExcel} className="btn btn-success"><i className='fa fa-upload'></i>&nbsp; &nbsp;Generate Excel File</Link>&nbsp; &nbsp;
            </div>
            <div className="table-responsive-lg">
                <table className="table table-bordered ">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Year</th>
                            <th scope="col">Payment Date</th>
                            <th>Receipt Number</th>
                            <th>PF Number</th>
                            <th>Description</th>
                            <th style={{ textAlign: "right" }}>Amount Paid</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentPosts.map((mem, index) =>
                            <tr key={mem.id}>
                                <td style={{ textAlign: 'center' }}>{postPerPage * (currentPage - 1) + index + 1}</td>
                                <td>{mem.year}</td>
                                <td>{mem.payment_date}</td>
                                <td>{mem.receipt_number}</td>
                                <td>{mem.pf_number}</td>
                                <td>{mem.description}</td>
                                <td style={{ textAlign: "right" }}>{mem.payment_amount.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                            </tr>
                        )}
                    </tbody>
                    <tbody>
                        <tr>
                            <th colSpan={"6"} style={{ fontSize: "16px", fontWeight: "900", color: "brown" }}>Total payment transactions for the specified period</th>
                            <th style={{ fontSize: "16px", fontWeight: "900", color: "brown", textAlign: "right" }}>{total_amount_paid.toLocaleString(undefined, { minimumFractionDigits: 2 })}</th>
                            <th></th>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
        :
        null


    return (
        <>
            <section className="content-header">
                <Toaster toastOptions={{ position: "top-center" }} />
                <h1>Payment Transactions - By Period</h1>
            </section>
            <section className="content">
                <div className="box">
                    <div className="box-body">
                        <div className="panel panel-default panel-br-4">
                            <div className="panel-heading">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <h4 className="panel-title">
                                            {searchContent}
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            {currentPosts.length > 0 && search ?
                                <>
                                    <div className="panel-body">
                                        {content}
                                    </div>
                                    <div className="panel-footer">
                                        <Pagination
                                            data={payments}
                                            postPerPage={postPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            setPostPerPage={setPostPerPage} />
                                    </div>
                                    <div className="box-footer">
                                        <div className="col-sm-2">
                                            <button onClick={handleCancel} className="btn btn-success">
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </>
                                :
                                currentPosts.length <= 0 && search ?
                                    <>
                                        <div className="panel-body">
                                            <h4>No payment transactions between {<b>{begin_date}</b>} and {<b>{end_date}</b>}</h4>
                                        </div>
                                        <div className="box-footer">
                                            <div className="col-sm-2">
                                                <button onClick={handleCancel} className="btn btn-success">
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <div className="box-footer">
                                        <div className="col-sm-2">
                                            <button onClick={handleBack} className="btn btn-success">
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default PaymentByPeriod;


