import useUpdateContext from "../../../../hooks/useUpdateContext"
import useGeneralSearchContext from "../../../../hooks/useGeneralSearchContext"
import { useQuery } from 'react-query';
import {
    getRelationship
} from "../../../../api/apiServices";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

const NextOfKin = () => {
    const {
        searchResult
    } = useGeneralSearchContext()

    let tenantData = []
    searchResult?.tenant?.map(tenant => {
        tenantData.push({
            nkinNextOfKin: tenant.next_of_kin,
            nkinContactNumber: tenant.kin_contact_number,
            nkinRelationship: tenant.kin_relationship,
        })
    })

    const { handleOnPhoneInput, handleChange, handlePhoneInput } = useUpdateContext()
    const {
        data: relation,
    } = useQuery('relation', getRelationship);

    const content = (
        <>
            <div className="form-group row">
                <label htmlFor="next_of_kin" className="col-sm-3 col-form-label">Next of Kin<span>&nbsp;&nbsp;*</span></label>
                <div className="col-sm-9">
                    <input
                        type="text"
                        id="next_of_kin"
                        placeholder='Name of Next of Kin'
                        autoComplete="off"
                        style={{ textTransform: "uppercase" }}
                        onChange={handleChange}
                        defaultValue={tenantData[0].nkinNextOfKin}
                        name="nkinNextOfKin"
                        className='form-control'
                    />
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="kin_contact_number" className="col-sm-3 col-form-label">Contact Number<span>&nbsp;&nbsp;*</span></label>
                <div className="col-sm-9">
                    <input
                        type="number"                      
                        id="kin_contact_number"
                        defaultValue={tenantData[0].nkinContactNumber}
                        name="nkinContactNumber"
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="kin_relationship" className="col-sm-3 col-form-label">Relationship<span>&nbsp;&nbsp;*</span></label>
                <div className="col-sm-9">
                    <select
                        type="text"
                        id="kin_relationship"
                        placeholder='Relationship'
                        autoComplete="off"
                        style={{ textTransform: "uppercase" }}
                        onChange={handleChange}
                        defaultValue={tenantData[0].nkinRelationship}
                        name="nkinRelationship"
                        className='form-control'
                    >
                        <option>Select Option</option>
                        {relation?.map((item) =>
                            <option key={item.id} value={item.relationship}>{item.relationship}</option>)}
                    </select>
                </div>
            </div>
        </>
    )

    return content
}
export default NextOfKin