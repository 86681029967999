import { useEffect } from "react";
import useRegistrationContext from "../../../hooks/useRegistrationContext"



const LandDetails = () => {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    const { data, handleChange } = useRegistrationContext()

    const content = (
        <>
            <legend style={{
                fontWeight: "bold",
                color: "var(--white)",
                background: "var(--brown)"
            }}>LAND INFORMATION</legend>
            <div className="form-group row">
                <fieldset className="col-sm-6">
                    <div className="form-group row">
                        <label htmlFor="deeds_number" className="col-sm-4 col-form-label">Deeds Registration No. / Land Title Certificate</label>
                        <div className="col-sm-8">
                            <input
                                type="text"
                                id="deeds_number"
                                autoComplete="off"
                                onChange={handleChange}
                                style={{
                                    height: "40px",
                                    textTransform: "uppercase",
                                }}
                                value={data.landDeedsNumber}
                                name="landDeedsNumber"
                                className='form-control'
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="building_permit" className="col-sm-4 col-form-label">Building Permit Number</label>
                        <div className="col-sm-8">
                            <input
                                type="text"
                                id="building_permit"
                                autoComplete="off"
                                onChange={handleChange}
                                style={{
                                    height: "40px",
                                    textTransform: "uppercase",
                                }}
                                value={data.landBuildingPermit}
                                name="landBuildingPermit"
                                className='form-control'
                            />
                        </div>
                    </div>
                </fieldset>
                <fieldset className="col-sm-6">
                    <div className="form-group row">
                        <label htmlFor="approved_date" className="col-sm-4 col-form-label">Approved Date<span>&nbsp;&nbsp;*</span></label>
                        <div className="col-sm-8">
                            <input
                                type="date"
                                id="approved_date"
                                autoComplete="off"
                                onChange={handleChange}
                                required
                                style={{
                                    height: "40px",
                                    textTransform: "uppercase",
                                }}
                                value={data.landApprovedDate}
                                name="landApprovedDate"
                                className='form-control'
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="land_location" className="col-sm-4 col-form-label">Land Location<span>&nbsp;&nbsp;*</span></label>
                        <div className="col-sm-8">
                            <input
                                type="text"
                                id="land_location"
                                autoComplete="off"
                                onChange={handleChange}
                                disabled
                                style={{
                                    height: "40px",
                                    textTransform: "uppercase",
                                }}
                                value={data.officeLandLocation}
                                name="landLandLocation"
                                className='form-control'
                            />
                        </div>
                    </div>
                </fieldset>
            </div>
            <legend style={{
                fontWeight: "bold",
                color: "var(--white)",
                background: "var(--brown)"
            }}>LAND INFORMATION</legend>
            <div className="form-group row">
                <fieldset className="col-sm-6">
                    <div className="form-group row">
                        <label htmlFor="new_indenture_number" className="col-sm-4 col-form-label">New Identure Number<span>&nbsp;&nbsp;*</span></label>
                        <div className="col-sm-8">
                            <input
                                type="text"
                                id="new_indenture_number"
                                autoComplete="off"
                                onChange={handleChange}
                                required
                                disabled
                                style={{
                                    height: "40px",
                                    textTransform: "uppercase",
                                    fontWeight: "bold",
                                }}
                                value={data.landNewIndentureNumber}
                                name="landNewIndentureNumber"
                                className='form-control'
                            />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label htmlFor="acquisition_date" className="col-sm-4 col-form-label">Date of Aquisition<span>&nbsp;&nbsp;*</span></label>
                        <div className="col-sm-8">
                            <input
                                type="date"
                                id="acquisition_date"
                                autoComplete="off"
                                onChange={handleChange}
                                required
                                style={{
                                    height: "40px",
                                    textTransform: "uppercase",
                                }}
                                value={data.landAcquisitionDate}
                                name="landAcquisitionDate"
                                className='form-control'
                            />
                        </div>
                    </div>
                </fieldset>
                <fieldset className="col-sm-6">
                    <div className="form-group row">
                        <label htmlFor="number_of_plots" className="col-sm-4 col-form-label">Number of Plots<span>&nbsp;&nbsp;*</span></label>
                        <div className="col-sm-8">
                            <input
                                type="number"
                                id="number_of_plots"
                                autoComplete="off"
                                onChange={handleChange}
                                required
                                style={{
                                    height: "40px",
                                    textTransform: "uppercase",
                                }}
                                value={data.landNumberOfPlots}
                                name="landNumberOfPlots"
                                className='form-control'
                            />
                        </div>
                    </div>
                    {data.individual ?
                        <div className="form-group row">
                            <label htmlFor="land_use" className="col-sm-4 col-form-label">Land Use<span>&nbsp;&nbsp;*</span></label>
                            <div className="col-sm-8">
                                <select
                                    type="text"
                                    id="land_use"
                                    autoComplete="off"
                                    value={data.landLandUse}
                                    name="landLandUse"
                                    onChange={handleChange}
                                    required
                                    style={{
                                        height: "40px",
                                        textTransform: "uppercase",
                                    }}
                                    className='form-control'
                                >
                                    <option>Select Option</option>
                                    <option value="Residential">Residential</option>
                                    <option value="School">School</option>
                                    <option value="Hospital">Hospital</option>
                                    <option value="Factory">Factory</option>
                                    <option value="Bar">Bar</option>
                                    <option value="Church">Church</option>
                                    <option value="Mosque">Mosque</option>
                                    <option value="Hotel">Hotel</option>
                                    <option value="Restaurant">Restaurant</option>
                                    <option value="Store">Store</option>
                                    <option value="Office">Office</option>
                                    <option value="Filling Station">Filling Station</option>
                                    <option value="Warehouse">Warehouse</option>
                                    <option value="Commercial">Commercial</option>
                                </select>
                            </div>
                        </div>
                        : data.institution ?
                            < div className="form-group row">
                                <label htmlFor="land_use" className="col-sm-4 col-form-label">Land Use<span>&nbsp;&nbsp;*</span></label>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        id="land_use"
                                        autoComplete="off"
                                        value={data.persType}
                                        name="landLandUse"
                                        onChange={handleChange}
                                        required
                                        style={{
                                            height: "40px",
                                            textTransform: "uppercase",
                                        }}
                                        className='form-control'
                                    />
                                </div>
                            </div>
                            : null
                    }

                </fieldset>
            </div >
        </>
    )

    return content
}
export default LandDetails