import { useEffect } from "react";
import useRegistrationContext from "../../../hooks/useRegistrationContext"

const ModeOfPayment = () => {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    const { data, handleChange } = useRegistrationContext()

    const content = (
        <>
            {data.installmentsPay ?
                <>
                    <fieldset>
                        <legend style={{
                            fontWeight: "bold",
                            color: "var(--white)",
                            background: "var(--brown)"
                        }}>INSTALLMENT PAYMENT DETAILS</legend>
                    </fieldset>
                    <div className="form-group row">
                        <fieldset className="col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="payment_type" className="col-sm-3 col-form-label">Payment Type<span>&nbsp;&nbsp;*</span></label>
                                <div className="col-sm-9">
                                    <input
                                        type="text"
                                        id='payment_type'
                                        disabled
                                        value={data?.officePaymentType}
                                        name='modePaymentType'
                                        onChange={handleChange}
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="payment_duration" className="col-sm-3 col-form-label">Duration<span>&nbsp;&nbsp;*</span></label>
                                <div className="col-sm-9">
                                    <input
                                        type="text"
                                        id="payment_duration"
                                        autoComplete="off"
                                        disabled
                                        onChange={handleChange}
                                        style={{ textTransform: "uppercase" }}
                                        value={data?.officePaymentDuration}
                                        name="officePaymentDuration"
                                        className='form-control'
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="property_price" className="col-sm-3 col-form-label">Property Price<span>&nbsp;&nbsp;*</span></label>
                                <div className="col-sm-9">
                                    <input
                                        type="number"
                                        id="property_price"
                                        autoComplete="off"
                                        style={{ textTransform: "uppercase" }}
                                        disabled
                                        value={data?.officePropertyPrice}
                                        name="officePropertyPrice"
                                        className='form-control'
                                    />
                                </div>
                            </div>
                        </fieldset>
                        <fieldset className="col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="initial_deposit" className="col-sm-3 col-form-label">Initial Deposit<span>&nbsp;&nbsp;*</span></label>
                                <div className="col-sm-9">
                                    <input
                                        type="number"
                                        id="initial_deposit"
                                        autoComplete="off"
                                        style={{ textTransform: "uppercase" }}
                                        onChange={handleChange}
                                        disabled
                                        value={data?.officeInitialDeposit}
                                        name="officeInitialDeposit"
                                        className='form-control'
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="outstanding_balance" className="col-sm-3 col-form-label">Outstanding Balance<span>&nbsp;&nbsp;*</span></label>
                                <div className="col-sm-9">
                                    <input
                                        type="number"
                                        id="outstanding_balance"
                                        autoComplete="off"
                                        style={{ textTransform: "uppercase" }}
                                        // onChange={handleChange}
                                        disabled
                                        value={data?.officeOutstandingBalance}
                                        name="modeOutstandingBalance"
                                        className='form-control'
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="payment_mode" className="col-sm-3">Payment Mode</label>
                                <div className="col-sm-9">
                                    <input
                                        type="text"
                                        id="payment_mode"
                                        placeholder='payment_mode'
                                        autoComplete="off"
                                        style={{ textTransform: "uppercase" }}
                                        // onChange={handleChange}
                                        disabled
                                        value={data.officePaymentMode}
                                        name="modePaymentMode"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </>
                : null
            }

            {data.outrightPay ?
                <>
                    <fieldset>
                        <legend style={{
                            fontWeight: "bold",
                            color: "var(--white)",
                            background: "var(--brown)"
                        }}>OUTRIGHT PAYMENT DETAILS</legend>
                    </fieldset>
                    <div className="form-group row">
                        <fieldset className="col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="payment_type" className="col-sm-3 col-form-label">Payment Type<span>&nbsp;&nbsp;*</span></label>
                                <div className="col-sm-9">
                                    <input
                                        type="text"
                                        id='payment_type'
                                        disabled
                                        value={data.officePaymentType}
                                        onChange={handleChange}
                                        name='modePaymentType'
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="property_price" className="col-sm-3 col-form-label">Property Price<span>&nbsp;&nbsp;*</span></label>
                                <div className="col-sm-9">
                                    <input
                                        type="number"
                                        id="property_price"
                                        autoComplete="off"
                                        style={{ textTransform: "uppercase" }}
                                        disabled
                                        value={data.officePropertyPrice}
                                        name="modePropertyPrice"
                                        className='form-control'
                                    />
                                </div>
                            </div>
                        </fieldset>
                        <fieldset className="col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="initial_deposit" className="col-sm-3 col-form-label">Initial Deposit<span>&nbsp;&nbsp;*</span></label>
                                <div className="col-sm-9">
                                    <input
                                        type="number"
                                        id="initial_deposit"
                                        autoComplete="off"
                                        style={{ textTransform: "uppercase" }}
                                        onChange={handleChange}
                                        disabled
                                        value={data.officeInitialDeposit}
                                        name="modeInitialDeposit"
                                        className='form-control'
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="payment_mode" className="col-sm-3">Payment Mode</label>
                                <div className="col-sm-9">
                                    <input
                                        type="text"
                                        id="payment_mode"
                                        placeholder='payment_mode'
                                        autoComplete="off"
                                        style={{ textTransform: "uppercase" }}
                                        onLoad={handleChange}
                                        required
                                        disabled
                                        value={data?.officePaymentMode}
                                        name="modePaymentMode"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                        </fieldset>
                    </div>

                </>
                : null}

        </>
    )

    return content
}
export default ModeOfPayment