import { useEffect, useRef, useState } from "react";
import { useQuery } from 'react-query';
import useRegistrationContext from "../../../hooks/useRegistrationContext"
import {
    getSalesReps,
    getSignatures,
    allZones,
    getLocationByZoneName,
    getInterestRates,
    getPaymentModes,
} from "../../../api/apiServices";
import addCommas from "../../../services/addCommas";

const OfficeUseOnly = () => {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    const inputRef = useRef();
    const [zoneName, setZoneName] = useState("");

    const {
        data,
        handleChange,
        handlePayChoice,
        handleInterestRate,
        handleOutrightBalances,
        handleInitialDeposit,
        handlePaymentMode,
        handleChequeNumber,
        handleShowBalance
    } = useRegistrationContext()

    const {
        data: salesReps,
    } = useQuery('salesReps', () => getSalesReps());

    const {
        data: signatures,
    } = useQuery('signatures', () => getSignatures());

    const {
        data: zones,
    } = useQuery('zones', () => allZones());

    const {
        data: zoneLocations,
    } = useQuery(
        zoneName ? ['zoneLocations', zoneName] : null,
        () => getLocationByZoneName(zoneName));

    const {
        data: interestRates,
    } = useQuery('interestRates', () => getInterestRates());
    const {
        data: paymentMode,
    } = useQuery('paymentMode', () => getPaymentModes());

    const handleZoneName = (e) => {
        let zone_Name = e.target.value;
        setZoneName(zone_Name)
    }

    const content = (
        <>
            <div className="form-group row">
                <label className='col-sm-3'>
                    Installments &nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                        type="radio"
                        id="installmentsType"
                        required
                        value={"Installments"}
                        name="officePaymentType"
                        checked={data.officePaymentType === "Installments"}
                        onChange={handlePayChoice}
                    />
                </label>
                <label className='col-sm-3' htmlFor="outrightType">
                    Outright Payment &nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                        type="radio"
                        id="outrightType"
                        required
                        value={"Outright"}
                        name="officePaymentType"
                        checked={data.officePaymentType === "Outright"}
                        onChange={handlePayChoice}
                    />
                </label>
            </div>

            {data.installmentsPay ?
                <>
                    <legend style={{
                        fontWeight: "bold",
                        color: "var(--white)",
                        background: "var(--brown)"
                    }}>FOR OFFICE USE ONLY</legend>
             
                    <div className="form-group row">
                        <fieldset className="col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="payment_duration" className="col-sm-3 col-form-label">Duration<span>&nbsp;&nbsp;*</span></label>
                                <div className="col-sm-9">
                                    <select
                                        type="text"
                                        id="payment_duration"
                                        autoComplete="off"
                                        onChange={handleInterestRate}
                                        style={{ height: "40px", textTransform: "uppercase" }}
                                        value={data.officePaymentDuration}
                                        name="officePaymentDuration"
                                        className='form-control'
                                    >
                                        <option>Select Option</option>
                                        {interestRates?.map(rate =>
                                            <option key={rate.id} value={rate.duration}>{rate.duration}</option>
                                        )}
                                    </select>
                                    {data.officeInterestRate ?
                                        <div className="rate-info">{data.officeInterestRate + "%"} interest shall be charged
                                            on any oustanding balance after {data.officePaymentDuration} </div>
                                        : null}

                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="property_price" className="col-sm-3 col-form-label">Property Price<span>&nbsp;&nbsp;*</span></label>
                                <div className="col-sm-9">
                                    <input
                                        type="number"
                                        ref={inputRef}
                                        placeholder="Property Price"
                                        style={{ height: "40px", textTransform: "uppercase" }}
                                        id="property_price"
                                        autoComplete="off"
                                        onChange={handleChange}
                                        required
                                        value={data.officePropertyPrice}
                                        name="officePropertyPrice"
                                        className='form-control' />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="initial_deposit" className="col-sm-3 col-form-label">Initial Deposit<span>&nbsp;&nbsp;*</span></label>
                                <div className="col-sm-9">
                                    <input
                                        type="number"
                                        placeholder="Initial Deposit"
                                        id="initial_deposit"
                                        autoComplete="off"
                                        onChange={handleChange}
                                        onKeyUp={handleInitialDeposit}
                                        required
                                        style={{ height: "40px", textTransform: "uppercase" }}
                                        value={data.officeInitialDeposit}
                                        name="officeInitialDeposit"
                                        className='form-control' />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="payment_mode" className="col-sm-3">Payment Mode</label>
                                <div className="col-sm-9">
                                    <select
                                        type="text"
                                        id="payment_mode"
                                        placeholder='payment_mode'
                                        autoComplete="off"
                                        style={{ height: "40px", textTransform: "uppercase" }}
                                        onChange={handlePaymentMode}
                                        required
                                        value={data.officePaymentMode}
                                        name="officePaymentMode"
                                        className="form-control"
                                    >
                                        <option>Select Option</option>
                                        {paymentMode?.map(mode =>
                                            <option key={mode.id} value={mode.description}>
                                                {mode.description}
                                            </option>
                                            )}
                                    </select>
                                </div>
                            </div>
                            {data.isChequeAdvice ?
                                <>
                                    <div className="form-group row">
                                        <label htmlFor="cheque_number" className="col-sm-3 col-form-label">Cheque/Advice Number<span>&nbsp;&nbsp;*</span></label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                placeholder="Cheque/Advice Number"
                                                id="cheque_number"
                                                autoComplete="off"
                                                onBlur={handleChequeNumber}
                                                onChange={handleChange}
                                                style={{ height: "40px", textTransform: "uppercase" }}
                                                value={data.officeChequeAdviceNumber}
                                                name="officeChequeAdviceNumber"
                                                className='form-control' />
                                        </div>
                                    </div>
                                </>
                                : null}
                        </fieldset>
                        <fieldset className="col-sm-6">
                            {data.isChequeAdvice ?
                                <>
                                    <div className="form-group row">
                                        <label htmlFor="bank_branch_name" className="col-sm-3 col-form-label">Bank Name<span>&nbsp;&nbsp;*</span></label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                id="bank_branch_name"
                                                autoComplete="off"
                                                style={{ height: "40px", textTransform: "uppercase" }}
                                                onChange={handleChange}
                                                disabled
                                                value={data.officeBankBranchName}
                                                name="officeBankBranchName"
                                                className='form-control'
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="cheque_date" className="col-sm-3 col-form-label">Cheque Date<span>&nbsp;&nbsp;*</span></label>
                                        <div className="col-sm-9">
                                            <input
                                                type="date"
                                                id="cheque_date"
                                                autoComplete="off"
                                                onChange={handleChange}
                                                style={{ height: "40px", textTransform: "uppercase" }}
                                                value={data.officeChequeAdviceDate}
                                                name="officeChequeAdviceDate"
                                                className='form-control' />
                                        </div>
                                    </div>
                                </>
                                : null}
                            <div className="form-group row">
                                <label htmlFor="sale_rep" className="col-sm-3 col-form-label">Sales Rep</label>
                                <div className="col-sm-9">
                                    <select
                                        type="text"
                                        placeholder="Sale Rep"
                                        id="sale_rep"
                                        autoComplete="off"
                                        onChange={handleChange}
                                        style={{ height: "40px", textTransform: "uppercase" }}
                                        value={data.officeSaleRep}
                                        name="officeSaleRep"
                                        className='form-control'
                                    >
                                        <option>Select Option</option>
                                        {salesReps?.map(rep => (
                                            <option key={rep.id} value={rep.name}>{rep.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="zone_code" className="col-sm-3 col-form-label">Zone<span>&nbsp;&nbsp;*</span></label>
                                <div className="col-sm-9">
                                    <select
                                        type="text"
                                        id="zone_code"
                                        placeholder='Zone'
                                        autoComplete="off"
                                        style={{ height: "40px", textTransform: "uppercase" }}
                                        onChange={handleChange}
                                        onBlur={handleZoneName}
                                        required
                                        value={data.officeZoneName}
                                        name="officeZoneName"
                                        className='form-control'
                                    >
                                        <option>Select Option</option>
                                        {zones?.map(item =>
                                            <option key={item.id} value={item.zone_name}>{item.id} - {item.zone_name}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="land_location" className="col-sm-3 col-form-label">Land Location<span>&nbsp;&nbsp;*</span></label>
                                <div className="col-sm-9">
                                    <select
                                        type="text"
                                        placeholder="Land Location"
                                        id="land_location"
                                        autoComplete="off"
                                        onChange={handleChange}
                                        onBlur={handleShowBalance}
                                        required
                                        style={{ height: "40px", textTransform: "uppercase" }}
                                        value={data.officeLandLocation}
                                        name="officeLandLocation"
                                        className='form-control'
                                    >
                                        <option>Select Location</option>
                                        {zoneLocations?.map((location, index) =>
                                            <option key={location.id} value={location.location_name}>
                                                {location.id} - {location.location_name}
                                            </option>
                                        )}

                                    </select>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </>
                : null}
            {
                data.outrightPay ?
                    <>
                        <legend style={{
                            fontWeight: "bold",
                            color: "var(--white)",
                            background: "var(--brown)"
                        }}>FOR OFFICE USE ONLY</legend>
             
                        <div className="form-group row">
                            <fieldset className="col-sm-6">
                                <div className="form-group row">
                                    <label htmlFor="property_price" className="col-sm-3 col-form-label">Property Price<span>&nbsp;&nbsp;*</span></label>
                                    <div className="col-sm-9">
                                        <input
                                            ref={inputRef}
                                            type="number"
                                            placeholder="Property Price"
                                            id="property_price"
                                            autoComplete="off"
                                            onChange={handleChange}
                                            onKeyUp={handleOutrightBalances}
                                            required
                                            style={{ height: "40px", textTransform: "uppercase" }}
                                            value={data.officePropertyPrice}
                                            name="officePropertyPrice"
                                            className='form-control' />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="initial_deposit" className="col-sm-3 col-form-label">Initial Deposit<span>&nbsp;&nbsp;*</span></label>
                                    <div className="col-sm-9">
                                        <input
                                            type="number"
                                            placeholder="Initial Deposit"
                                            id="initial_deposit"
                                            autoComplete="off"
                                            onChange={handleChange}
                                            onKeyUp={handleInitialDeposit}
                                            required
                                            disabled
                                            style={{ height: "40px", textTransform: "uppercase" }}
                                            value={data.officeInitialDeposit}
                                            name="officeInitialDeposit"
                                            className='form-control' />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="payment_mode" className="col-sm-3">Payment Mode</label>
                                    <div className="col-sm-9">
                                        <select
                                            type="text"
                                            id="payment_mode"
                                            placeholder='payment_mode'
                                            autoComplete="off"
                                            style={{ height: "40px", textTransform: "uppercase" }}
                                            onChange={handlePaymentMode}
                                            required
                                            value={data.officePaymentMode}
                                            name="officePaymentMode"
                                            className="form-control"
                                        >
                                            <option>Select Option</option>
                                            {paymentMode?.map(mode =>
                                                <option key={mode.id} value={mode.description}>
                                                    {mode.description}
                                                </option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                                {data.isChequeAdvice ?
                                    <>
                                        <div className="form-group row">
                                            <label htmlFor="cheque_number" className="col-sm-3 col-form-label">Cheque/Advice Number<span>&nbsp;&nbsp;*</span></label>
                                            <div className="col-sm-9">
                                                <input
                                                    type="text"
                                                    placeholder="Cheque/Advice Number"
                                                    id="cheque_number"
                                                    autoComplete="off"
                                                    onBlur={handleChequeNumber}
                                                    onChange={handleChange}
                                                    style={{ height: "40px", textTransform: "uppercase" }}
                                                    value={data.officeChequeAdviceNumber}
                                                    name="officeChequeAdviceNumber"
                                                    className='form-control' />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="bank_branch_name" className="col-sm-3 col-form-label">Bank Name<span>&nbsp;&nbsp;*</span></label>
                                            <div className="col-sm-9">
                                                <input
                                                    type="text"
                                                    id="bank_branch_name"
                                                    autoComplete="off"
                                                    style={{ height: "40px", textTransform: "uppercase" }}
                                                    onChange={handleChange}
                                                    disabled
                                                    value={data.officeBankBranchName}
                                                    name="officeBankBranchName"
                                                    className='form-control'
                                                />
                                            </div>
                                        </div>
                                    </>
                                    : null}
                            </fieldset>
                            <fieldset className="col-sm-6">
                                {data.isChequeAdvice ?
                                    <>
                                        <div className="form-group row">
                                            <label htmlFor="cheque_date" className="col-sm-3 col-form-label">Cheque Date<span>&nbsp;&nbsp;*</span></label>
                                            <div className="col-sm-9">
                                                <input
                                                    type="date"
                                                    id="cheque_date"
                                                    autoComplete="off"
                                                    onChange={handleChange}
                                                    style={{ height: "40px", textTransform: "uppercase" }}
                                                    value={data.officeChequeAdviceDate}
                                                    name="officeChequeAdviceDate"
                                                    className='form-control' />
                                            </div>
                                        </div>
                                    </>
                                    : null}
                                <div className="form-group row">
                                    <label htmlFor="sale_rep" className="col-sm-3 col-form-label">Sales Rep</label>
                                    <div className="col-sm-9">
                                        <select
                                            type="text"
                                            placeholder="Sale Rep"
                                            id="sale_rep"
                                            autoComplete="off"
                                            required
                                            onChange={handleChange}
                                            style={{ height: "40px", textTransform: "uppercase" }}
                                            value={data.officeSaleRep}
                                            name="officeSaleRep"
                                            className='form-control'
                                        >
                                            <option>Select Option</option>
                                            {salesReps?.map(rep => (
                                                <option key={rep.id} value={rep.name}>{rep.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="zone_code" className="col-sm-3 col-form-label">Zone<span>&nbsp;&nbsp;*</span></label>
                                    <div className="col-sm-9">
                                        <select
                                            type="text"
                                            id="zone_code"
                                            placeholder='Zone'
                                            autoComplete="off"
                                            style={{ height: "40px", textTransform: "uppercase" }}
                                            onChange={handleChange}
                                            onBlur={handleZoneName}
                                            required
                                            value={data.officeZoneName}
                                            name="officeZoneName"
                                            className='form-control'
                                        >
                                            <option>Select Option</option>
                                            {zones?.map(item =>
                                                <option key={item.id} value={item.zone_name}>{item.id} - {item.zone_name}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="land_location" className="col-sm-3 col-form-label">Land Location<span>&nbsp;&nbsp;*</span></label>
                                    <div className="col-sm-9">
                                        <select
                                            type="text"
                                            placeholder="Land Location"
                                            id="land_location"
                                            autoComplete="off"
                                            onChange={handleChange}
                                            onBlur={handleShowBalance}
                                            required
                                            style={{ height: "40px", textTransform: "uppercase" }}
                                            value={data.officeLandLocation}
                                            name="officeLandLocation"
                                            className='form-control'
                                        >
                                            <option>Select Location</option>
                                            {zoneLocations?.map((location, index) =>
                                                <option key={location.id} value={location.location_name}>
                                                    {location.id} - {location.location_name}
                                                </option>
                                            )}

                                        </select>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </>
                    : null}
            {
                data.officePropertyPrice && data.installmentsPay && data.showBalance ?
                    <>
                        <legend style={{
                            fontWeight: "bold",
                            color: "var(--white)",
                            background: "var(--brown)"
                        }}>INSTALLMENTS PAYMENT TERMS</legend>
                        <div className="form-group row">
                            <div className="col-sm-6">
                            <label htmlFor="outstanding_balance" className="col-form-label">Outstanding Balance<span>&nbsp;&nbsp;*</span></label>
                                <input
                                    type="number"
                                    id="outstanding_balance"
                                    autoComplete="off"
                                    onChange={handleChange}
                                    disabled
                                    style={{
                                        height: "40px",
                                        textTransform: "uppercase",
                                        fontSize: "16px",
                                        fontWeight: "bold"
                                    }}
                                    value={data.officeOutstandingBalance}
                                    name="officeOutstandingBalance"
                                    className='form-control' />
                                {data.officeInterestRate ?
                                    <div className="rate-info">{data.officeInterestRate + "%"} interest shall be charged
                                        on {addCommas(data.officeOutstandingBalance)} after the duration period  </div>
                                    : null
                                }
                            </div>
                            <div className="col-sm-6">
                            <label htmlFor="interest_amount" className="col-form-label">Interest Amount<span>&nbsp;&nbsp;*</span></label>
                                <input
                                    type="number"
                                    id="interest_amount"
                                    autoComplete="off"
                                    onChange={handleChange}
                                    required
                                    disabled
                                    style={{
                                        height: "40px",
                                        textTransform: "uppercase",
                                        fontSize: "16px",
                                        fontWeight: "bold"
                                    }}
                                    value={data.officeInterestAmount}
                                    name="officeInterestAmount"
                                    className='form-control' />
                                {data.officeInterestRate ?
                                    <div className="rate-info">Interest amount of {addCommas(data.officeInterestAmount)} to be charged
                                        after {data.modePaymentDuration}  </div>
                                    : null
                                }
                            </div>
                        </div>
                    </>
                    : null}

            {
                data.officePropertyPrice && data.outrightPay && data.showBalance ?
                    <>
                        <legend style={{
                            fontWeight: "bold",
                            color: "var(--white)",
                            background: "var(--brown)"
                        }}>OUTRIGHT PAYMENT TERMS</legend>
                        <div className="form-group row">
                            <div className="col-sm-6">
                                <label htmlFor="outstanding_balance" className="col-form-label">Outstanding Balance<span>&nbsp;&nbsp;*</span></label>
                                <input
                                    type="number"
                                    id="outstanding_balance"
                                    autoComplete="off"
                                    onChange={handleChange}
                                    disabled
                                    style={{
                                        height: "40px",
                                        textTransform: "uppercase",
                                        fontSize: "16px",
                                        fontWeight: "bold"
                                    }}
                                    value={data.officeOutstandingBalance}
                                    name="officeOutstandingBalance"
                                    className='form-control' />
                            </div>
                            <div className="col-sm-6">
                                <label htmlFor="interest_amount" className="col-form-label">Interest Amount<span>&nbsp;&nbsp;*</span></label>
                                <input
                                    type="number"
                                    id="interest_amount"
                                    autoComplete="off"
                                    onChange={handleChange}
                                    disabled
                                    style={{
                                        height: "40px",
                                        textTransform: "uppercase",
                                        fontSize: "16px",
                                        fontWeight: "bold"
                                    }}
                                    value={data.officeInterestAmount}
                                    name="officeInterestAmount"
                                    className='form-control' />
                            </div>
                        </div>
                    </>
                    : null
            }

            {
                data.outrightPay || data.installmentsPay ?
                    <>
                        <legend style={{
                            fontWeight: "bold",
                            color: "var(--white)",
                            background: "var(--brown)"
                        }}>SIGNATORIES</legend>

                        <fieldset className="col-sm-12">
                            <div className="form-group row">
                                <div className="col-sm-4">
                                    <label htmlFor="signature" >Signature</label>
                                    <select
                                        type="text"
                                        id="signature"
                                        autoComplete="off"
                                        required
                                        style={{
                                            height: "40px",
                                            textTransform: "uppercase",
                                        }}
                                        onChange={handleChange}
                                        value={data.officeSignature}
                                        name="officeSignature"
                                        className='form-control'
                                    >
                                        <option>Select Option</option>
                                        {signatures?.map((signature, index) =>
                                            <option key={signature.id} value={signature.name}>
                                                {signature.name}
                                            </option>
                                        )}
                                    </select>
                                </div>
                                <div className="col-sm-4">
                                    <label htmlFor="supervisor_signature">Supervisor Signature</label>
                                    <select
                                        type="text"
                                        id="supervisor_signature"
                                        style={{
                                            height: "40px",
                                            textTransform: "uppercase",
                                        }}
                                        autoComplete="off"
                                        required
                                        onChange={handleChange}
                                        value={data.officeSupervisorSignature}
                                        name="officeSupervisorSignature"
                                        className='form-control'
                                    >
                                        <option>Select Option</option>
                                        {signatures?.map((signature, index) =>
                                            <option key={signature.id} value={signature.name}>
                                                {signature.name}
                                            </option>
                                        )}
                                    </select>
                                </div>
                                <div className="col-sm-4">
                                    <label htmlFor="accountant_signature">Accountant Signature</label>
                                    <select
                                        type="text"
                                        id="accountant_signature"
                                        autoComplete="off"
                                        required
                                        style={{
                                            height: "40px",
                                            textTransform: "uppercase",
                                        }}
                                        onChange={handleChange}
                                        value={data.officeAccountantSignature}
                                        name="officeAccountantSignature"
                                        className='form-control'
                                    >
                                        <option>Select Option</option>
                                        {signatures?.map((signature, index) =>
                                            <option key={signature.id} value={signature.name}>
                                                {signature.name}
                                            </option>
                                        )}
                                    </select>
                                </div>
                            </div>
                        </fieldset>
                    </>
                    : null
            }
        </>
    )

    return content
}
export default OfficeUseOnly