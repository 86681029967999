import { useNavigate, useLocation, useParams } from 'react-router-dom';

const ViewReport = ({ report }) => {

	const navigate = useNavigate();
	const location = useLocation();
	const from = location.state?.from?.pathname || "/regional_report_list";

	const handleClose = () => {
		navigate(from, { replace: true });
	}

	return (
		  <><section className="content-header">
			<h1>Regional Operations Report</h1>
		</section>
			<section className="content">
				<div className="box">
					<div className="box-body">
						<div className="panel panel-default panel-br-4">
							<div className="panel-heading">
								<div className="row">
									<div className="col-sm-9">
										<div className="col-sm-6">
											<h4 className="panel-title">
												<i className="fa fa-file" aria-hidden="true"></i>
												<span> REGIONAL OPERATIONS REPORT</span>
											</h4>
										</div>
										<div className="col-sm-6" style={{ display: "flex", justtifyContent: "right" }}>
											<span>
												<form onSubmit={handleClose}>
													<button className="btn btn-success">
														CLOSE REPORT
													</button>
												</form>
											</span>
										</div>
									</div>
								</div>
							</div>
							<div className="panel-body">
								<div className="report-image">
									<div><img src="/images/logos/proforum.jpg" alt="" className="logo" /></div>
								</div>
								<article className="regional_reports">
									<h3>{report.region} : {report.quarter.toUpperCase()} REPORT</h3>
									<div className="report-details">
										<h4>YEAR :</h4>
										<p>{report.year}</p>
										<h4>REGIONAL ACTIVITIES :</h4>
										<p> {report.regional_activities}</p>
										<h4>CONSTITUENCY ACTIVITIES :</h4>
										<p> {report.constituency_activities}</p>
										<h4>BRANCH ACTIVITIES :</h4>
										<p> {report.branch_activities}</p>
									</div>
								</article>
							</div>
						</div>
					</div>
					<div className="box-footer"></div>
				</div>
			</section>
			</>
	)
}

export default ViewReport