import React from "react";
import useUpdateContext from "../../../../hooks/useUpdateContext"
import useGeneralSearchContext from "../../../../hooks/useGeneralSearchContext"
import { useQuery } from 'react-query';
import {
    allZones,
} from "../../../../api/apiServices";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

const ContactInformation = () => {
    const {
        searchResult
    } = useGeneralSearchContext()
    console.log(searchResult?.tenant)
    let tenantData = []
    searchResult?.tenant?.map(tenant => {
        tenantData.push({
            infoContactNumber: tenant.contact_number,
            infoEmail: tenant.email,
            infoPostalAddress: tenant.postal_address,
            infoResidentialAddress: tenant.residential_address,
            infoLatitude: tenant.latitude,
            infoLongitude: tenant.longitude,
            infoZoneCode: tenant.zone_code,
            infoLandLocation: tenant.land_location,
        })
    })
    const { handleOnPhoneInput, handleChange, handlePhoneInput } = useUpdateContext()
    const {
        data: zones,
    } = useQuery('zones', allZones);


    const content = (
        <>
            <div className="form-group row">
                <fieldset className="col-sm-6">
                    <div className="form-group row">
                        <label htmlFor="contact_number" className="col-sm-3 col-form-label">Contact Number<span>&nbsp;&nbsp;*</span></label>
                        <div className="col-sm-9">
                            <input
                                type="number"
                                id="contact_number"
                                defaultValue={tenantData[0].infoContactNumber}
                                name="infoContactNumber"
                                onChange={handleChange}
                               className="form-control"
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="email" className="col-sm-3 col-form-label">Email Address<span>&nbsp;&nbsp;*</span></label>
                        <div className="col-sm-9">
                            <input
                                type="email"
                                id="email"
                                placeholder='Email Address'
                                autoComplete="off"
                                onChange={handleChange}
                                defaultValue={tenantData[0].infoEmail}
                                name="infoEmail"
                                className='form-control'
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="postal_address" className="col-sm-3 col-form-label">Postal Address</label>
                        <div className="col-sm-9">
                            <input
                                type="text"
                                id="postal_address"
                                placeholder='Postal Address'
                                autoComplete="off"
                                style={{ textTransform: "uppercase" }}
                                onChange={handleChange}
                                defaultValue={tenantData[0].infoPostalAddress}
                                name="infoPostalAddress"
                                className='form-control'
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="zone_code" className="col-sm-3 col-form-label">Zone<span>&nbsp;&nbsp;*</span></label>
                        <div className="col-sm-9">
                            <input
                                type="text"
                                id="zone_code"
                                placeholder='Zone'
                                autoComplete="off"
                                readOnly
                                style={{ textTransform: "uppercase" }}
                                onChange={handleChange}
                                defaultValue={tenantData[0].infoZoneCode}
                                name="infoZoneCode"
                                className='form-control'
                            />
                        </div>
                    </div>
                </fieldset>
                <fieldset className="col-sm-6">
                    <div className="form-group row">
                        <label htmlFor="residential_address" className="col-sm-3 col-form-label">Residential Address (GPS)<span>&nbsp;&nbsp;*</span></label>
                        <div className="col-sm-9">
                            <input
                                type="text"
                                id="residential_address"
                                placeholder='Residential Address'
                                autoComplete="off"
                                style={{ textTransform: "uppercase" }}
                                onChange={handleChange}
                                readOnly
                                defaultValue={tenantData[0].infoResidentialAddress}
                                name="infoResidentialAddress"
                                className='form-control'
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="latitude" className="col-sm-3 col-form-label">Latitude<span>&nbsp;&nbsp;*</span></label>
                        <div className="col-sm-9">
                            <input
                                type="text"
                                id="latitude"
                                placeholder='Latitude'
                                autoComplete="off"
                                readOnly
                                style={{ textTransform: "uppercase" }}
                                onChange={handleChange}
                                defaultValue={tenantData[0].infoLatitude}
                                name="infoLatitude"
                                className='form-control'
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="longitude" className="col-sm-3 col-form-label">Longitude<span>&nbsp;&nbsp;*</span></label>
                        <div className="col-sm-9">
                            <input
                                type="text"
                                id="longitude"
                                placeholder='Longitude'
                                autoComplete="off"
                                readOnly
                                style={{ textTransform: "uppercase" }}
                                onChange={handleChange}
                                defaultValue={tenantData[0].infoLongitude}
                                name="infoLongitude"
                                className='form-control'
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="land_location" className="col-sm-3 col-form-label">Land Location<span>&nbsp;&nbsp;*</span></label>
                        <div className="col-sm-9">
                            <input
                                type="text"
                                id="land_location"
        
                                autoComplete="off"
                                readOnly
                                style={{ textTransform: "uppercase" }}
                                onChange={handleChange}
                                defaultValue={tenantData[0].infoLandLocation}
                                name="infoLandLocation"
                                className='form-control'
                            />
                        </div>
                    </div>
                </fieldset>
            </div >
        </>
    )

    return content
}
export default ContactInformation