import { useState, useRef, useEffect } from "react";
import useRegistrationContext from "../../../hooks/useRegistrationContext"
import { useQuery } from 'react-query';
import {
    getTitles,
    getNationality,
    getIdentification,
} from "../../../api/apiServices";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

const PersonalInformation = () => {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    const inputRef = useRef();
    const [image, setImage] = useState();

    const {
        data,
        handleOnPhoneInput,
        handleChange,
        handleExpiryDate,
        handlePhoneInput,
        handlePhoto } = useRegistrationContext()

    function handleImage(e) {
        if (e.target.files.length !== 0) {
            setImage(URL.createObjectURL(e.target.files[0]));
        }
    }
    const {
        data: titles,
    } = useQuery('titles', () => getTitles());
    const {
        data: citizenship,
    } = useQuery('nationality', () => getNationality());

    const {
        data: identity,
    } = useQuery('identity', () => getIdentification());


    const content = (
        <>
            {data.individual ?
                <>
                    <div className="tenant-photo">
                        <img src={image} alt='' />
                    </div>
                    <div className="form-group row">
                        <fieldset className="col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="photo" className="col-sm-3 col-form-label">Tenant Photo</label>
                                <div className="col-sm-9">
                                    <input
                                        type="file"
                                        name="persPhoto"
                                        id="photo"
                                        style={{ height: "40px", textTransform: "uppercase" }}
                                        autoComplete="off"
                                        onBlur={handleImage}
                                        onChange={handlePhoto}
                                        className='form-control'
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="tenant_title" className="col-sm-3 col-form-label">Title<span>&nbsp;&nbsp;*</span></label>
                                <div className="col-sm-9">
                                    <select
                                        type="text"
                                        id="tenant_title"
                                        autoComplete="off"
                                        style={{ height: "40px", textTransform: "uppercase" }}
                                        onChange={handleChange}
                                        value={data.persTitle}
                                        required
                                        name="persTitle"
                                        className='form-control'
                                    >
                                        <option value="">Select Option</option>
                                        {titles?.map((item, index) =>
                                            <option value={item.title} key={index}>
                                                {item.title}
                                            </option>
                                        )}
                                    </select>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="last_name" className="col-sm-3 col-form-label">Last Name<span>&nbsp;&nbsp;*</span></label>
                                <div className="col-sm-9">
                                    <input
                                        ref={inputRef}
                                        type="text"
                                        placeholder="Last Name"
                                        id="last_name"
                                        autoComplete="off"
                                        onChange={handleChange}
                                        required
                                        style={{ height: "40px", textTransform: "uppercase" }}
                                        value={data.persLastname}
                                        name="persLastname"
                                        className='form-control'
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="middle_name" className="col-sm-3 col-form-label">Middle Name</label>
                                <div className="col-sm-9">
                                    <input
                                        type="text"
                                        placeholder="Middle Name"
                                        id="middle_name"
                                        autoComplete="off"
                                        onChange={handleChange}
                                        style={{ height: "40px", textTransform: "uppercase" }}
                                        value={data.persMiddlename}
                                        name="persMiddlename"
                                        className='form-control'
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="first_name" className="col-sm-3 col-form-label">First Name<span>&nbsp;&nbsp;*</span></label>
                                <div className="col-sm-9">
                                    <input
                                        type="text"
                                        placeholder="First Name"
                                        id="first_name"
                                        autoComplete="off"
                                        onChange={handleChange}
                                        required
                                        style={{ height: "40px", textTransform: "uppercase" }}
                                        value={data.persFirstname}
                                        name="persFirstname"
                                        className='form-control'
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="gender" className="col-sm-3 col-form-label">Gender<span>&nbsp;&nbsp;*</span></label>
                                <div className="col-sm-9">
                                    <select
                                        type="text"
                                        id="gender"
                                        autoComplete="off"
                                        style={{ height: "40px", textTransform: "uppercase" }}
                                        onChange={handleChange}
                                        required
                                        value={data.persGender}
                                        name="persGender"
                                        className='form-control'
                                    >
                                        <option value="">Select Option</option>
                                        <option value="Male">Male</option>
                                        <option value='Female'>Female</option>
                                    </select>
                                </div>
                            </div>

                            <div className="form-group row">
                                <label htmlFor="date_of_birth" className="col-sm-3 col-form-label">Date of Birth<span>&nbsp;&nbsp;*</span></label>
                                <div className="col-sm-9">
                                    <input
                                        type="date"
                                        id="date_of_birth"
                                        autoComplete="off"
                                        style={{ height: "40px", textTransform: "uppercase" }}
                                        onChange={handleChange}
                                        required
                                        value={data.persDOB}
                                        name="persDOB"
                                        className='form-control'
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="nationality" className="col-sm-3 col-form-label">Nationality<span>&nbsp;&nbsp;*</span></label>
                                <div className="col-sm-9">
                                    <select
                                        type="text"
                                        id="nationality"
                                        autoComplete="off"
                                        style={{ height: "40px", textTransform: "uppercase" }}
                                        onChange={handleChange}
                                        value={data.persNationality}
                                        required
                                        name="persNationality"
                                        className='form-control'
                                    >
                                        <option>Select Option</option>
                                        {citizenship?.map(item =>
                                            <option key={item.id} value={item.nationality}>{item.nationality}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                        </fieldset>
                        <fieldset className="col-sm-6">
                            <div className="form-group row">
                                <label htmlFor="spouse_contact_number" className="col-sm-3 col-form-label">Spouse Contact Number</label>
                                <div className="col-sm-9">
                                    <PhoneInput
                                        type="tel"
                                        id="spouse_contact_number"
                                        inputStyle={{ width: '100%', height: "34px" }}
                                        country={"gh"}
                                        placeholder="persSpouseContactNumber"
                                        enableSearch={true}
                                        value={data.persSpouseContactNumber}
                                        name="persSpouseContactNumber"
                                        onChange={(e) => handlePhoneInput(e)}
                                        onKeyDown={(e) => handleOnPhoneInput(e)}

                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="identification_type" className="col-sm-3 col-form-label">Identification Type<span>&nbsp;&nbsp;*</span></label>
                                <div className="col-sm-9">
                                    <select
                                        type="text"
                                        id="identification_type"
                                        autoComplete="off"
                                        onChange={handleChange}
                                        required
                                        style={{ height: "40px", textTransform: "uppercase" }}
                                        value={data.persIdentificationType}
                                        name="persIdentificationType"
                                        className='form-control'
                                    >
                                        <option>Select Option</option>
                                        {identity?.map(item =>
                                            <option key={item.id} value={item.identification}>{item.identification}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="id_number" className="col-sm-3 col-form-label">ID Number<span>&nbsp;&nbsp;*</span></label>
                                <div className="col-sm-9">
                                    <input
                                        type="text"
                                        id="id_number"
                                        placeholder='ID Number'
                                        autoComplete="off"
                                        style={{ height: "40px", textTransform: "uppercase" }}
                                        onChange={handleChange}
                                        required
                                        value={data.persIdNumber}
                                        name="persIdNumber"
                                        className='form-control'
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="date_issued" className="col-sm-3 col-form-label">Date Issued<span>&nbsp;&nbsp;*</span></label>
                                <div className="col-sm-9">
                                    <input
                                        type="date"
                                        id="date_issued"
                                        autoComplete="off"
                                        onChange={handleExpiryDate}
                                        style={{ height: "40px", textTransform: "uppercase" }}
                                        required
                                        value={data.persDateIssued}
                                        // onKeyUpCapture={handleExpiryDate}
                                        name="persDateIssued"
                                        className='form-control'
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="expiry_date" className="col-sm-3 col-form-label">Expiry Date<span>&nbsp;&nbsp;*</span></label>
                                <div className="col-sm-9">
                                    <input
                                        type="date"
                                        id="expiry_date"
                                        autoComplete="off"
                                        style={{ height: "40px", textTransform: "uppercase" }}
                                        // onChange={handleChange}
                                        required
                                        disabled
                                        value={data.persExpiryDate}
                                        name="persExpiryDate"
                                        className='form-control'
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="marital_status" className="col-sm-3 col-form-label">Marital Status<span>&nbsp;&nbsp;*</span></label>
                                <div className="col-sm-9">
                                    <select
                                        type="text"
                                        id="marital_status"
                                        autoComplete="off"
                                        onChange={handleChange}
                                        required
                                        value={data.persMaritalStatus}
                                        style={{ height: "40px", textTransform: "uppercase" }}
                                        name="persMaritalStatus"
                                        className='form-control'
                                    >
                                        <option value="">Select Option</option>
                                        <option value="Single">Single</option>
                                        <option value='Married'>Married</option>
                                        <option value='Divorced'>Divorced</option>
                                        <option value='Separated'>Separated</option>
                                        <option value='Widowed'>Widowed</option>
                                        <option value='Prefer Not to Say'>Prefer Not to Say</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="occupation" className="col-sm-3 col-form-label">Occupation<span>&nbsp;&nbsp;*</span></label>
                                <div className="col-sm-9">
                                    <input
                                        type="text"
                                        placeholder="Occupation"
                                        id="occupation"
                                        autoComplete="off"
                                        style={{ height: "40px", textTransform: "uppercase" }}
                                        onChange={handleChange}
                                        value={data.persOccupation}
                                        required
                                        name="persOccupation"
                                        className='form-control'
                                    />
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </>
                : data.institution ?
                    <>
                        <div className="form-group row">
                            <fieldset className="col-sm-6">
                                <div className="form-group row">
                                    <label htmlFor="institution_name" className="col-sm-3 col-form-label">Institution Name<span>&nbsp;&nbsp;*</span></label>
                                    <div className="col-sm-9">
                                        <input
                                            ref={inputRef}
                                            type="text"
                                            placeholder="Institution Name"
                                            id="institution_name"
                                            autoComplete="off"
                                            onChange={handleChange}
                                            required
                                            style={{ height: "40px", textTransform: "uppercase" }}
                                            value={data.persName}
                                            name="persName"
                                            className='form-control'
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="institution_type" className="col-sm-3 col-form-label">Institution Type<span>&nbsp;&nbsp;*</span></label>
                                    <div className="col-sm-9">
                                        <select
                                            type="text"
                                            id="institution_type"
                                            autoComplete="off"
                                            style={{ height: "40px", textTransform: "uppercase" }}
                                            onChange={handleChange}
                                            value={data.persType}
                                            required
                                            name="persType"
                                            className='form-control'
                                        >
                                            <option></option>
                                            <option value="School">School</option>
                                            <option value="Hospital">Hospital</option>
                                            <option value="Factory">Factory</option>
                                            <option value="Bar">Bar</option>
                                            <option value="Church">Church</option>
                                            <option value="Mosque">Mosque</option>
                                            <option value="Hotel">Hotel</option>
                                            <option value="Restaurant">Restaurant</option>
                                            <option value="Store">Store</option>
                                            <option value="Office">Office</option>
                                            <option value="Filling Station">Filling Station</option>
                                            <option value="Warehouse">Warehouse</option>
                                            <option value="Commercial">Commercial</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="establishment_date" className="col-sm-3 col-form-label">Establishment Date<span>&nbsp;&nbsp;*</span></label>
                                    <div className="col-sm-9">
                                        <input
                                            type="date"
                                            id="establishment_date"
                                            autoComplete="off"
                                            style={{ height: "40px", textTransform: "uppercase" }}
                                            onChange={handleChange}
                                            required
                                            value={data.persDOE}
                                            name="persDOE"
                                            className='form-control'
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="contact_person" className="col-sm-3 col-form-label">Contact Person</label>
                                    <div className="col-sm-9">
                                        <input
                                            type="text"
                                            placeholder="Contact Person"
                                            id="contact_person"
                                            autoComplete="off"
                                            onChange={handleChange}
                                            style={{ height: "40px", textTransform: "uppercase" }}
                                            value={data.persContactPerson}
                                            name="persContactPerson"
                                            className='form-control'
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="gender" className="col-sm-3 col-form-label">Gender<span>&nbsp;&nbsp;*</span></label>
                                    <div className="col-sm-9">
                                        <select
                                            type="text"
                                            id="gender"
                                            autoComplete="off"
                                            style={{ height: "40px", textTransform: "uppercase" }}
                                            onChange={handleChange}
                                            required
                                            value={data.persGender}
                                            name="persGender"
                                            className='form-control'
                                        >
                                            <option></option>
                                            <option value="Male">Male</option>
                                            <option value='Female'>Female</option>
                                        </select>
                                    </div>
                                </div>

                            </fieldset>
                            <fieldset className="col-sm-6">
                                <div className="form-group row">
                                    <label htmlFor="nationality" className="col-sm-3 col-form-label">Nationality<span>&nbsp;&nbsp;*</span></label>
                                    <div className="col-sm-9">
                                        <select
                                            type="text"
                                            id="nationality"
                                            autoComplete="off"
                                            style={{ height: "40px", textTransform: "uppercase" }}
                                            onChange={handleChange}
                                            value={data.persNationality}
                                            required
                                            name="persNationality"
                                            className='form-control'
                                        >
                                            <option>Select Option</option>
                                            {citizenship?.map(item =>
                                                <option key={item.id} value={item.nationality}>{item.nationality}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="identification_type" className="col-sm-3 col-form-label">Identification Type<span>&nbsp;&nbsp;*</span></label>
                                    <div className="col-sm-9">
                                        <select
                                            type="text"
                                            id="identification_type"
                                            autoComplete="off"
                                            onChange={handleChange}
                                            required
                                            style={{ height: "40px", textTransform: "uppercase" }}
                                            value={data.persIdentificationType}
                                            name="persIdentificationType"
                                            className='form-control'
                                        >
                                            <option>Select Option</option>
                                            {identity?.map(item =>
                                                <option key={item.id} value={item.identification}>{item.identification}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="id_number" className="col-sm-3 col-form-label">ID Number<span>&nbsp;&nbsp;*</span></label>
                                    <div className="col-sm-9">
                                        <input
                                            type="text"
                                            id="id_number"
                                            placeholder='ID Number'
                                            autoComplete="off"
                                            style={{ height: "40px", textTransform: "uppercase" }}
                                            onChange={handleChange}
                                            required
                                            value={data.persIdNumber}
                                            name="persIdNumber"
                                            className='form-control'
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="date_issued" className="col-sm-3 col-form-label">Date Issued<span>&nbsp;&nbsp;*</span></label>
                                    <div className="col-sm-9">
                                        <input
                                            type="date"
                                            id="date_issued"
                                            autoComplete="off"
                                            style={{ height: "40px", textTransform: "uppercase" }}
                                            required
                                            value={data.persDateIssued}
                                            onChange={handleExpiryDate}
                                            // onKeyUpCapture={handleExpiryDate}
                                            name="persDateIssued"
                                            className='form-control'
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="expiry_date" className="col-sm-3 col-form-label">Expiry Date<span>&nbsp;&nbsp;*</span></label>
                                    <div className="col-sm-9">
                                        <input
                                            type="date"
                                            id="expiry_date"
                                            autoComplete="off"
                                            style={{ height: "40px", textTransform: "uppercase" }}
                                            // onChange={handleChange}
                                            required
                                            disabled
                                            value={data.persExpiryDate}
                                            name="persExpiryDate"
                                            className='form-control'
                                        />
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </>
                    : null
            }
        </>
    )

    return content
}
export default PersonalInformation