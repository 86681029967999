import React, { useEffect } from 'react';
// import { Button } from '../Button/Button';
// import { Link } from 'react-router-dom';
import './Navbar.css';

function Navbar() {
  // const [click, setClick] = useState(false);
  // const [button, setButton] = useState(true);

  // const handleClick = () => setClick(!click);
  // const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      // setButton(false);
    } else {
      // setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton);

  return (
    <>
      <nav className='navigation-bar'>
        <div className="navimage-container">
          <img src='/images/logos/niiodaintow2.jpg' alt='niiodaintow' />
        </div>
      </nav>
    </>
  );
}

export default Navbar;
