import React, { useState } from 'react';
import useRegistrationContext from "../../../hooks/useRegistrationContext"
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import {
    addTenant,
} from "../../../api/apiServices";
import toast, { Toaster } from 'react-hot-toast'
import IndividualFormInputs from './IndividualFormInputs';
import InstitutionFormInputs from './InstitutionFormInputs';

const FormSetup = () => {

    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/registration_form";
    const [confirm, setConfirm] = useState(false);

    const {
        page,
        setPage,
        data,
        individualTitle,
        institutionTitle,
        canSubmit,
        disablePrev,
        disableNext,
        prevHide,
        nextHide,
        submitHide
    } = useRegistrationContext()

    const addTenantMutation = useMutation(addTenant, {
        onSuccess: async (res) => {
            if (res.status === false) {
                toast.error(res.message, {
                    duration: 5000,
                    className: 'toast-message'
                })
            } else {
                // Invalidate cached data
                queryClient.invalidateQueries("tenants")
                toast.success(res.message, {
                    duration: 5000,
                    className: 'toast-message'
                })
            }
        },
        onError: async (err) => {
            toast.error(err.message, {
                duration: 5000,
                className: 'toast-message'
            })
        }
    })

    const handlePrevious = () => setPage(prev => prev - 1)

    const handleNext = () => setPage(prev => prev + 1)

    const handleSubmit = async () => {
        let formData = new FormData();

        formData.append('file', data.persPhoto)
        formData.set("purpose", data.mainPurpose);
        formData.set("title", data.persTitle);
        formData.set("last_name", data.persLastname);
        formData.set("first_name", data.persFirstname);
        formData.set("middle_name", data.persMiddlename);
        formData.set("gender", data.persGender);
        formData.set("date_of_birth", data.persDOB);
        formData.set("nationality", data.persNationality);
        formData.set("occupation", data.persOccupation);
        formData.set("spouse_contact_number", data.persSpouseContactNumber);
        formData.set("identification_type", data.persIdentificationType);
        formData.set("id_number", data.persIdNumber);
        formData.set("date_issued", data.persDateIssued);
        formData.set("expiry_date", data.persExpiryDate);
        formData.set("marital_status", data.persMaritalStatus);
        formData.set("contact_number", data.infoContactNumber);
        formData.set("email", data.infoEmail);
        formData.set("postal_address", data.infoPostalAddress);
        formData.set("residential_address", data.infoResidentialAddress);
        formData.set("latitude", data.infoLatitude);
        formData.set("longitude", data.infoLongitude);
        formData.set("zone_code", data.officeZoneName);
        formData.set("next_of_kin", data.nkinNextOfKin);
        formData.set("kin_contact_number", data.nkinContactNumber);
        formData.set("kin_relationship", data.nkinRelationship);
        formData.set("contact_person", data.refpContactPerson);
        formData.set("contact_address", data.refpContactAddress);
        formData.set("contact_relationship", data.refpRelationship);
        formData.set("contact_telephone", data.refpContactNumber);
        formData.set("deeds_number", data.landDeedsNumber);
        formData.set("building_permit", data.landBuildingPermit);
        formData.set("approved_date", data.landApprovedDate);
        formData.set("new_indenture_number", data.landNewIndentureNumber);
        formData.set("acquisition_date", data.landAcquisitionDate);
        formData.set("number_of_plots", data.landNumberOfPlots);
        formData.set("land_use", data.landLandUse);
        formData.set("land_location", data.officeLandLocation);
        formData.set("payment_type", data.officePaymentType);
        formData.set("property_price", data.officePropertyPrice);
        formData.set("initial_deposit", data.officeInitialDeposit);
        formData.set("outstanding_balance", data.officeOutstandingBalance);
        formData.set("duration", data.officePaymentDuration);
        formData.set("payment_mode", data.officePaymentMode);
        formData.set("cheque_advice_number", data.officeChequeAdviceNumber);
        formData.set("cheque_advice_date", data.officeChequeAdviceDate);
        formData.set("bank_name", data.officeBankBranchName);
        formData.set("interest_rate", data.officeInterestRate);
        formData.set("interest_amount", data.officeInterestAmount);
        formData.set("sales_rep", data.officeSaleRep);
        formData.set("signature", data.officeSignature);
        formData.set("supervisor_signature", data.officeSupervisorSignature);
        formData.set("accountant_signature", data.officeAccountantSignature);

        formData.set("institution_name", data.persName);
        formData.set("tenant_type", data.tenantType);
        formData.set("institution_rep", data.persContactPerson);
        formData.set("establishment_date", data.persDOE);
        formData.set("institution_land_use", data.persType);

        formData.set("individual", data.individual);
        formData.set("institution", data.institution);

        addTenantMutation.mutate(formData)
    }

    const content = (
        <>
            <section className="content-header">
                <Toaster toastOptions={{ position: "top-center" }} />
                <h1>Sales & Regularization</h1>
            </section>
            <section className="content">
                <div className="box">
                    <div className="box-body">
                        <div className="panel panel-default panel-br-4">
                            <div className="panel-heading">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <h4 className="panel-title">
                                            {data.individual ?
                                                individualTitle[page]
                                                :
                                                institutionTitle[page]
                                            }
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="panel-body">
                                <form onSubmit={handleSubmit}>
                                    {data.individual ?
                                        <IndividualFormInputs />
                                        : data.institution ?
                                            <InstitutionFormInputs />
                                            : null
                                    }
                                    <div className="panel-footer">
                                        <div className="button-container">
                                            <button
                                                type="button"
                                                className={`btn btn-info ${prevHide}`} onClick={handlePrevious}
                                                disabled={disablePrev}>
                                                Previous
                                            </button>
                                            <button
                                                type="button"
                                                className={`btn btn-danger ${nextHide}`}
                                                onClick={handleNext}
                                                disabled={disableNext}>
                                                Next
                                            </button>
                                            <button
                                                type="submit"
                                                className={`btn btn-success ${submitHide}`}
                                                disabled={!canSubmit}>
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )

    return content
}
export default FormSetup