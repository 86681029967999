import React, { useState } from "react";
import { useQuery, useMutation } from 'react-query';
import {
    getPaymentTypes,
    getPaymentModes,
    getBankBranchBySortcode,
    tenantExists,
    savePayment,
} from "../../../../api/apiServices";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import toast, { Toaster } from 'react-hot-toast'
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import generateTransactionId from "../../../../services/generateTransactionId";
import addCommas from "../../../../services/addCommas";
import PaymentReceipt from "./PaymentReceipt.js";

const TenantPayments = () => {
    const navigate = useNavigate();
    const [tenant_id, setTenantId] = useState('');
    const [tenant_code, setTenantCode] = useState('');
    const [indenture_number, setIndentureNumber] = useState('');
    const [payment_type, setPaymentType] = useState('');
    const [payment_method, setPaymentMethod] = useState('');
    const [fullname, setFullname] = useState('');
    const [mobile_number, setMobileNumber] = useState('');
    const [email, setEmail] = useState('');
    const [payment_amount, setPaymentAmount] = useState('');
    const [payment_mode, setPaymentMode] = useState('');

    const [isChequeAdvice, setIsChequeAdvice] = useState(false);
    const [cheque_advice_number, setChequeAdviceNumber] = useState("")
    const [bank_branch_name, setBankBranchName] = useState("")
    const [cheque_advice_date, setChequeAdviceDate] = useState("")

    const [transaction_id, setTransactionId] = useState("");
    const [paySuccess, setPaySuccess] = useState(false);
    const [paymentData, setPaymentData] = useState([]);

    const {
        data: paymentMode,
    } = useQuery('paymentMode', () => getPaymentModes());

    const {
        data: payment_types,
    } = useQuery('payment_types', () => getPaymentTypes());

    const saveMutation = useMutation(savePayment, {
        onSuccess: async (res) => {
            if (res.status === true) {
                toast.success(res.message, {
                    duration: 5000,
                    className: 'toast-message'
                });
                setPaymentData({
                    fullname: fullname.toLocaleUpperCase(),
                    email: email,
                    year: new Date().getFullYear(),
                    transaction_id: res.transaction_id,
                    receipt_number: res.receipt_number,
                    payment_type: payment_type,
                    payment_amount: addCommas(payment_amount),
                });
                setPaySuccess(true)
                setPaymentType("");
                setPaymentMode("");
                setPaymentAmount("");
            } else {
                toast.error(res.message, {
                    duration: 5000,
                    className: 'toast-message'
                })
            }
        },
        onError: async (err) => {
            toast.error(err.message, {
                duration: 5000,
            })
        }
    })

    const tenantExistsMutattion = useMutation(tenantExists, {
        onSuccess: async (res) => {
            console.log(res)
            if (res.success === true) {
                let tenant_type = res.data.tenant_type
                let fName = ""
                if (tenant_type === "INDIVIDUAL") {
                    fName = res.data.first_name + " " + res.data.middle_name + " " + res.data.last_name  
                }
                if (tenant_type === "INSTITUTION") {
                    fName = res.data.institution_name 
                }
                setTenantId(res.data.id)
                setTenantCode(res.data.tenant_code)
                setFullname(fName.toLocaleUpperCase())
                setIndentureNumber(res.data.new_indenture_number.toLocaleUpperCase())
                setPaymentMethod(res.data.office_use[0]?.payment_type)
            } else {
                setTenantId()
                setTenantCode()
                setIndentureNumber()
            }
            generateTransactionId().then((result) => {
                setTransactionId(result)
            })
        },
        onError: async (err) => {
            toast.error(err.message, {
                duration: 5000,
                className: "toast-message"
            })
        }
        
    })

    const handlePaymentMode = e => {
        const value = e.target.value
        if (value === "CHEQUE" || value.replace(" ", "_") === "BANKERS_DRAFT") {
            setPaymentMode(value)
            setIsChequeAdvice(true)
        } else {
            setPaymentMode(value)
            setIsChequeAdvice(false)
        }
    }
    const handleChequeNumber = (e) => {
        const value = e.target.value
        let bankSortcode = value.substring(0, 6)
        getBankBranchBySortcode(bankSortcode).then((bank) => {
            setBankBranchName(bank.branch_name)
        })
    }

    const handleMemberExists = (e) => {
        const checkMember = {
            contact_number: mobile_number,
            email: email
        }
        tenantExistsMutattion.mutate(checkMember)
    }

    const handleConfirm = (e) => {
        e.preventDefault();
        if (payment_mode === "") {
            toast.error("Payment mode is required. Select Payment Mode to continue", {
                duration: 5000
            })
            return false;
        }
        else {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className='confirm-payment'>
                            <div className="title">
                                PAYMENT CONFIRMATION
                            </div>

                            <table className="table table-borderless">
                                <tbody>
                                    <tr className="details">
                                        <td className="name">PAYMENT TYPE</td>
                                        <td className="value">{payment_type.toLocaleUpperCase()}</td>
                                    </tr>
                                    <tr className="details">
                                        <td className="name">FULL NAME</td>
                                        <td className="value">{fullname.toLocaleUpperCase()}</td>
                                    </tr>
                                    <tr className="details">
                                        <td className="name">MOBILE NUMBER</td>
                                        <td className="value">{mobile_number}</td>
                                    </tr>
                                    <tr className="details">
                                        <td className="name">EMAIL ADDRESS</td>
                                        <td className="value">{email}</td>
                                    </tr>
                                    <tr className="details">
                                        <td className="name">PAYMENT AMOUNT</td>
                                        <td className="value">{addCommas(payment_amount).toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                                    </tr>
                                    <tr className="details">
                                        <td className="name">PAYMENT MODE</td>
                                        <td className="value">{payment_mode.toLocaleUpperCase()}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <div className="bottom">
                                <button
                                    onClick={() => { onClose(); handleSubmit(); }} className="btn btn-success"
                                >
                                    CONFIRM
                                </button>&nbsp;&nbsp;&nbsp;&nbsp;
                                <button className="btn btn-danger" onClick={onClose}>
                                    CANCEL
                                </button>
                            </div>
                        </div>
                    );
                }
            });
        }
    }

    const handleSubmit = (e) => {
        const saveData = {
            tenant_id: tenant_id,
            tenant_code: tenant_code,
            indenture_number: indenture_number,
            year: new Date().getFullYear(),
            payment_type: payment_type.toLocaleUpperCase(),
            payment_method: payment_method.toLocaleUpperCase(),
            fullname: fullname.toLocaleUpperCase(),
            contact_number: mobile_number,
            email: email,
            payment_amount: parseFloat(payment_amount),
            payment_mode: payment_mode.toLocaleUpperCase(),
            cheque_advice_number: cheque_advice_number,
            bank_branch_name: bank_branch_name,
            cheque_advice_date: cheque_advice_date,
            transaction_id: transaction_id,
        }
        saveMutation.mutate(saveData)
    }

    const handleCancel = () => {
        navigate(-1);
    }

    const paymentForm =
        <><form onSubmit={handleConfirm}>
            <div className="form-group row">
                <fieldset className="col-sm-6">
                    <div className="form-group row">
                        <label htmlFor="payment_type" className="col-sm-3">Payment Type</label>
                        <div className="col-sm-9">
                            <select
                                type="text"
                                id="payment_type"
                                autoComplete="off"
                                style={{ height: "40px", textTransform: "uppercase" }}
                                onChange={(e) => setPaymentType(e.target.value)}
                                required
                                value={payment_type}
                                name="payment_type"
                                className="form-control"
                            >
                                <option>Payment Type</option>
                                {payment_types?.map((type, index) =>
                                    <option key={index} value={type.description}>
                                        {type.description}
                                    </option>
                                )}
                            </select>
                        </div>
                    </div>
                    <div className='form-group row'>
                        <label htmlFor="mobile_number" className="col-sm-3">Mobile Number<span>&nbsp;&nbsp;*</span></label>
                        <div className="col-sm-9">
                            <PhoneInput
                                type="tel"
                                id="mobile_number"
                                inputStyle={{ width: '100%', height: "34px" }}
                                country={"gh"}
                                enableSearch={true}
                                value={mobile_number}
                                name="mobile_number"
                                onChange={(mobile_number) => setMobileNumber(mobile_number)}
                            />
                        </div>
                    </div>
                    <div className='form-group row'>
                        <label htmlFor="email" className="col-sm-3">Email Address<span>&nbsp;&nbsp;*</span></label>
                        <div className="col-sm-9">
                            <input
                                type="email"
                                name='email'
                                id='email'
                                placeholder='Email'
                                style={{
                                    height: "40px",
                                }}
                                required
                                autoComplete='off'
                                value={email}
                                onBlur={handleMemberExists}
                                onChange={(e) => setEmail(e.target.value)}
                                className="form-control" />
                        </div>
                    </div>
                    <div className='form-group row'>
                        <label htmlFor="fullname" className="col-sm-3">Full Name<span>&nbsp;&nbsp;*</span></label>
                        <div className="col-sm-9">
                            <input
                                type="text"
                                name='fullname'
                                id='fullname'
                                placeholder='Full Name'
                                style={{
                                    height: "40px",
                                    textTransform: "uppercase",
                                }}
                                required
                                autoComplete='off'
                                value={fullname}
                                onChange={(e) => setFullname(e.target.value)}
                                className="form-control" />
                        </div>
                    </div>
                    <div className='form-group row'>
                        <label htmlFor="payment_amount" className="col-sm-3">Amount<span>&nbsp;&nbsp;*</span></label>
                        <div className="col-sm-9">
                            <input
                                type="number"
                                name='payment_amount'
                                id='payment_amount'
                                style={{
                                    height: "40px",
                                    textTransform: "uppercase",
                                }}
                                placeholder='Payment Amount'
                                required
                                value={payment_amount}
                                onChange={(e) => setPaymentAmount(e.target.value)}
                                className="form-control" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="tenant_code" className="col-sm-3">Payment Mode</label>
                        <div className="col-sm-9">
                            <select
                                type="text"
                                id="payment_mode"
                                placeholder='payment_mode'
                                autoComplete="off"
                                style={{ height: "40px", textTransform: "uppercase" }}
                                onChange={handlePaymentMode}
                                required
                                value={payment_mode}
                                name="payment_mode"
                                className="form-control"
                            >
                                <option>Select Option</option>
                                {paymentMode?.map(mode =>
                                    <option key={mode.id} value={mode.description}>
                                        {mode.description}
                                    </option>
                                )}
                            </select>
                        </div>
                    </div>
                </fieldset>
                <fieldset className="col-sm-6">
                    {isChequeAdvice ?
                        <>
                            <div className="form-group row">
                                <label htmlFor="cheque_advice_number" className="col-sm-3 col-form-label">Cheque/Advice Number<span>&nbsp;&nbsp;*</span></label>
                                <div className="col-sm-9">
                                    <input
                                        type="text"
                                        placeholder="Cheque/Advice Number"
                                        id="cheque_advice_number"
                                        autoComplete="off"
                                        onBlur={handleChequeNumber}
                                        onChange={(e) => setChequeAdviceNumber(e.target.value)}
                                        style={{ textTransform: "uppercase" }}
                                        value={cheque_advice_number}
                                        name="cheque_advice_number"
                                        className='form-control' />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="bank_branch_name" className="col-sm-3 col-form-label">Bank Name<span>&nbsp;&nbsp;*</span></label>
                                <div className="col-sm-9">
                                    <input
                                        type="text"
                                        id="bank_branch_name"
                                        autoComplete="off"
                                        style={{ textTransform: "uppercase" }}
                                        onChange={(e) => setBankBranchName(e.target.value)}
                                        disabled
                                        value={bank_branch_name}
                                        name="bank_branch_name"
                                        className='form-control'
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="cheque_advice_date" className="col-sm-3 col-form-label">Cheque Date<span>&nbsp;&nbsp;*</span></label>
                                <div className="col-sm-9">
                                    <input
                                        type="date"
                                        id="cheque_advice_date"
                                        autoComplete="off"
                                        onChange={(e) => setChequeAdviceDate(e.target.value)}
                                        style={{ textTransform: "uppercase" }}
                                        value={cheque_advice_date}
                                        name="cheque_advice_date"
                                        className='form-control' />
                                </div>
                            </div>
                        </>
                        : null}
                </fieldset>
            </div>
            <div className="panel-footer">
                <button className="btn btn-success">
                    Submit
                </button>&nbsp;&nbsp;&nbsp;&nbsp;
                <button type="button" className="btn btn-danger" onClick={handleCancel}>
                    Cancel
                </button>
            </div>
        </form>
        </>


    return (
        <>
            <Toaster toastOptions={{ position: "top-center" }} />
            <section className="content-header">
                <h1><i className="fas fa-bank"></i> Receive Payments</h1>
            </section>
            <section className="content">
                <div className="box">
                    <div className="box-body">
                        {paySuccess ?
                            <PaymentReceipt data={paymentData} />
                            :
                            <div className="panel panel-default panel-br-4">
                                <div className="panel-heading">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <h4 className="panel-title">
                                                <span>Tenant Payments</span>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="panel-body">
                                    {paymentForm}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

export default TenantPayments