import React from 'react'
import './Unauthorized.css'
import { Link } from 'react-router-dom'
const Unauthorized = () => {
    return (
        <>
            {/* <section className="content-header">
                <h1>Unauthorized</h1>
            </section> */}

            <section className="content">
                <div className="box">
                    <div className="box-body">

                        {/* <div className="unauthorized"> */}
                            <div className="panel panel-success panel-br-4">
                                <div className="panel-heading">
                                    <h4 className="panel-title">
                                        <h1>Unauthorized User</h1>
                                    </h4>
                                </div>
                                <div className="panel-body">You are not authorized to use the system. Contact the System Admin throught this link <Link to={'/contact'}>sysadmin</Link> </div><br />
                            <div className="panel-footer">You can check your credentials and login again <Link to='/admin/login'>Login</Link></div>
                            </div>
                        {/* </div> */}
                    </div>
                </div>
            </section>
        </>
    )
}

export default Unauthorized