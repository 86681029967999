import React, { useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
	getYearlyPaymentById,
	updateYearlyPayment
} from "../../../../../api/apiServices";
import toast, { Toaster } from 'react-hot-toast'

const EditYearlyPayment = () => {
	const params = useParams();
	const typeId = parseInt(params.id)

	const navigate = useNavigate();
	const location = useLocation();
	const from = location.state?.from?.pathname || "/yearly_payments";

	const [year, setPaymentYear] = useState("");
	const [payment_code, setPaymentCode] = useState("");
	const [description, setDescription] = useState("");
	const [payment_type, setPaymentType] = useState("");
	const [rank, setRank] = useState("");
	const [type_amount, setTypeAmount] = useState("");

	const queryClient = useQueryClient();

	const {
		data: type,
		isLoading,
		isError,
		error,
		isFetching
	} = useQuery(["type", typeId], () => getYearlyPaymentById(typeId));

	const updateYearlyPaymentMutation = useMutation(updateYearlyPayment, {
		onSuccess: async (res) => {
			if (res.status) {
				toast.success(res.message, {
					position: toast.POSITION.TOP_CENTER,
					duration: 5000,
				})
				// Invalidate cached data
				queryClient.invalidateQueries("type")

				setPaymentYear("")
				setPaymentCode("")
				setPaymentType("")
				setDescription("")
				setTypeAmount("")
				setRank("")
				navigate(from, { replace: true });
			} else {
				toast.success(res.message, {
					position: toast.POSITION.TOP_CENTER,
					duration: 5000,
				})
			}
		},
		onError: async (err) => {
			toast.error(err.message, {
				position: toast.POSITION.TOP_CENTER,
				duration: 5000,
			})
		}
	})
	

	const handleSubmit = (e) => {
		e.preventDefault()

		const dataValues = {
			id: typeId,
			year: year,
			payment_code: payment_code.toLocaleUpperCase(),
			description: description.toLocaleUpperCase(),
			payment_type: payment_type.toLocaleUpperCase(),
			type_amount: parseFloat(type_amount).toFixed(2),
			rank: rank,
		}
		updateYearlyPaymentMutation.mutate(dataValues)
	}

	const handleCancel = () => {
		navigate(from, { replace: true });
	}

	let content
	if (!typeId) {
		content = <p>Select a type</p>
	} else if (isLoading) {
		content = <p>Loading type yealy payments</p>
	} else if (isError) {
		content = <p>{error.message}</p>
	} else {
		content = (
			<>
				<div className="panel panel-success panel-br-4">
					<div className="panel-heading">
						<div className="row">
							<div className="col-sm-9">
								<h4 className="panel-title">
									<span> Yearly Payment Update</span>
								</h4>
							</div>
						</div>
					</div>

					<div className="panel-body">
						<form onSubmit={handleSubmit}>
							<div className="form-group row">
								<label htmlFor="year" className="col-sm-2 col-form-label">
									Payment Year
								</label>
								<div className="col-sm-6">
									<input
										type="text"
										id="year"
										placeholder="Payment Year"
										autoComplete="off"
										style={{ textTransform: "uppercase" }}
										defaultValue={type?.year}
										onChange={(e) => setPaymentYear(e.target.value)}
										required
										name="year"
										className="form-control"
									/>
								</div>
							</div>
							<div className="form-group row">
								<label htmlFor="payment_code" className="col-sm-2 col-form-label">
									Payment Code
								</label>
								<div className="col-sm-6">
									<input
										type="text"
										id="payment_code"
										placeholder="Payment Code"
										autoComplete="off"
										style={{ textTransform: "uppercase" }}
										defaultValue={type?.payment_code}
										onChange={(e) => setPaymentCode(e.target.value)}
										required
										name="payment_code"
										className="form-control"
									/>
								</div>
							</div>
							<div className="form-group row">
								<label htmlFor="description" className="col-sm-2 col-form-label">
									Description
								</label>
								<div className="col-sm-6">
									<input
										type="text"
										id="description"
										placeholder="Description"
										autoComplete="off"
										defaultValue={type?.description}
										onChange={(e) => setDescription(e.target.value)}
										required
										style={{ textTransform: "uppercase" }}
										name="description"
										className="form-control"
									/>
								</div>
							</div>
							<div className="form-group row">
								<label htmlFor="payment_type" className="col-sm-2 col-form-label">
									Payment Type
								</label>
								<div className="col-sm-6">
									<input
										type="text"
										id="payment_type"
										placeholder="Payment Type"
										autoComplete="off"
										style={{ textTransform: "uppercase" }}
										defaultValue={type?.payment_type}
										onChange={(e) => setPaymentType(e.target.value)}
										required
										name="payment_type"
										className="form-control"
									/>
								</div>
							</div>
							<div className="form-group row">
								<label htmlFor="type_amount" className="col-sm-2 col-form-label">
									Payment Amount
								</label>
								<div className="col-sm-6">
									<input
										type="text"
										id="type_amount"
										placeholder="Payment Amount"
										autoComplete="off"
										defaultValue={type?.type_amount}
										onChange={(e) => setTypeAmount(e.target.value)}
										required
										style={{ textTransform: "uppercase" }}
										name="type_amount"
										className="form-control"
									/>
								</div>
							</div>
							<div className="form-group row">
								<label htmlFor="rank" className="col-sm-2 col-form-label">
									Payment Rank
								</label>
								<div className="col-sm-6">
									<input
										type="text"
										id="rank"
										placeholder="Payment Rank"
										autoComplete="off"
										defaultValue={type?.rank}
										onChange={(e) => setRank(e.target.value)}
										required
										style={{ textTransform: "uppercase" }}
										name="rank"
										className="form-control"
									/>
								</div>
							</div>
							<div className="panel-footer">
								<div className="form-group row">
									<div className="col-sm-1">
										<button type="submit" className="btn btn-danger" onClick={handleCancel}>
											Cancel
										</button>
									</div>
									<div className="col-sm-1">
										<button type="submit" className="btn btn-success">
											Update
										</button>
									</div>
								</div>
							</div>
						</form>

					</div>
				</div>

			</>
		)
	}
	return (
		<>
			<section className="content-header">
				<Toaster toastOptions={{ position: "top-center" }} />
				<h1>Yearly Payment</h1>
			</section>
			<section className="content">
				<div className="box">
					<div className="box-body">
						<div className="panel panel-default panel-br-4">
							<div className="panel-heading">
								<div className="row">
									<div className="col-sm-12">
										<h4 className="panel-title">
										</h4>
									</div>
								</div>
							</div>
							<div className="panel-body">
								{content}
							</div>
						</div>
					</div>
					<div className="box-footer"></div>
				</div>
			</section>
		</>

	)
}

export default EditYearlyPayment