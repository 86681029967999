import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import { filterReport } from "../../../api/apiServices";
import { useQuery, useMutation, useQueryClient } from 'react-query';
import toast, { Toaster } from 'react-hot-toast'


const ViewConsolidatedReport = () => {
	const [regional_report, setRegionalReport] = useState([])
	const [report_year, setReportYear] = useState("")
	const [quarter, setQuarter] = useState("")
	const [filter, setFilter] = useState(false)

	const year = (new Date()).getFullYear();
	const years = Array.from(new Array(20), (val, index) => index + year)


	const navigate = useNavigate();
	const location = useLocation();
	const from = location.state?.from?.pathname || "/regional_report_list";

	const handleClose = () => {
		navigate(from, { replace: true });
	}

	const queryClient = useQueryClient();

	const filterMutation = useMutation(filterReport, {
		onSuccess: async (res) => {
			if (res.status === false) {
				toast.error(res.message, {
					duration: 5000,
				})
			} else {
				setRegionalReport(res)
				setFilter(true)
			}
		},
		onError: async (err) => {
			toast.error(err.message)
		}
	})

	const handleFilter = (e) => {
		e.preventDefault();

		const filterData = {
			year: report_year,
			quarter: quarter
		}
		filterMutation.mutate(filterData)
	}

	const searchFilter = (
		<form onSubmit={handleFilter}>
			<div className="form-group row">
				<label htmlFor="fullname" className="col-sm-1 col-form-label">
					Year <span>*</span>
				</label>
				<div className="col-sm-4">
					<select
						type="text"
						name='report_year'
						id='report_year'
						required
						onChange={(e) => setReportYear(e.target.value)}
						value={report_year}
						className="form-control"
					>
						<option>Year</option>
						{years.map((year, index) =>
							<option value={year} key={`year${index}`}>{year}</option>
						)}
					</select>
				</div>
				<label htmlFor="fullname" className="col-sm-1 col-form-label">
					Quarter <span>*</span>
				</label>
				<div className="col-sm-4">
					<select
						type="text"
						name='quarter'
						id='quarter'
						required
						onChange={(e) => setQuarter(e.target.value)}
						value={quarter}
						className="form-control"
					>
						<option>Select Quarter</option>
						<option value="1st Quarter">1st Quarter</option>
						<option value="2nd Quarter">2nd Quarter</option>
						<option value="3rd Quarter">3rd Quarter</option>
						<option value="4th Quarter">4th Quarter</option>
					</select>
				</div>
				<div className="col-sm-2">
					<button className='btn btn-success'>Submit</button>
				</div>
			</div>
		</form>
	)

	let content = regional_report && filter ?
		regional_report.map(report =>

			<><div className="report-image">
				<div><img src="/images/logos/proforum.jpg" alt="" className="logo" /></div>
			</div><article className="regional_reports">
					<h3>{report.region} : REGIONAL QUARTER REPORT</h3>
					<div className="report-details">
						<h4>YEAR :</h4>
						<p>{report.year}</p>
						<h4>QUARTER :</h4>
						<p>{report.quarter}</p>
						<h4>REGIONAL ACTIVITIES :</h4>
						<p> {report.regional_activities}</p>
						<h4>CONSTITUENCY ACTIVITIES :</h4>
						<p> {report.constituency_activities}</p>
						<h4>BRANCH ACTIVITIES :</h4>
						<p> {report.branch_activities}</p>
					</div>
				</article></>


		)
		: (null)

	return (
		<><section className="content-header">
			<Toaster toastOptions={{ position: "top-center" }} />
			<h1>Consolidated Regional Operations Report</h1>
		</section>
			<section className="content">
				<div className="box">
					<div className="box-body">
						<div className="panel panel-default panel-br-4">
							<div className="panel-heading">
								<div className="row">
									<div className="col-sm-12">
										<h4 className="panel-title">
											<span>{searchFilter}</span>
										</h4>
									</div>
								</div>
							</div><br /><br />
							{filter ?
								<>
									<div className="panel panel-default panel-br-4">
										<div className="panel-heading">
											<div className="row">
												<div className="col-sm-12">
													<h4 className="panel-title">
														<span>CONSOLIDATED REGIONAL REPORT</span>
													</h4>
												</div>
											</div>
										</div>

										<div className="panel-body">
											{content}
										</div>
										<div className="panel-footer">
										</div>
										<div className="col-sm-6" style={{ display: "flex", justtifyContent: "right" }}>
											<span>
												<form onSubmit={handleClose}>
													<button className="btn btn-success">
														CLOSE REPORT
													</button>
												</form>
											</span>
										</div>
									</div></>
								: null
							}
						</div>
					</div>
					{/* <div className="box-footer"></div> */}
				</div>
			</section>
		</>

	)

}

export default ViewConsolidatedReport