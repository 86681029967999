import { useNavigate, useLocation, useParams } from 'react-router-dom';
import {
	getRegionalReportById
} from "../../../api/apiServices";
import { useQuery } from 'react-query';
import ViewReport from './ViewReport';

const ViewRegionalReport = () => {

	const params = useParams();
	const id = parseInt(params.id)

	const navigate = useNavigate();
	const location = useLocation();
	const from = location.state?.from?.pathname || "/regional_report_list";


	const {
		isLoading,
		isError,
		error,
		data: report,
	} = useQuery(['report', id ? id : ""], () => getRegionalReportById(id));


	let content
	if (isLoading) {
		content = <p>Loading Regional Report...</p>
	} else if (isError) {
		content = <p>{error.message}</p>
	} else {
		content = <ViewReport report={report} />
	}
	return content
}

export default ViewRegionalReport