import React from 'react';
import { Button } from '../Common/Button/Button';
import './HeroSection.css';

function HeroSection() {
	return (
		<div className='hero-container'>
			<video src='/videos/video-1.mp4' autoPlay loop muted />
			<h1>NII ODAI NTOW</h1>
			<h2> CUSTOMARY LAND SECRETARIAT</h2>
			<p>SALES & REGULARIZATION SYSTEM</p>
			<div className='hero-btns'>
				<Button
					className='btns'
					buttonStyle='btn--primary'
					buttonSize='btn--large'
				>
					USER LOGIN 
				</Button>
			</div>
		</div>
	);
}

export default HeroSection;
