import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { Link } from "react-router-dom";
import {
	getPortalUsers,
	addPortalUser,
	getPortalUserById,
	updatePortalUser,
	deletePortalUser,
	getRoles,
	resetMemberAccount,
} from '../../../api/apiServices';
import Pagination from '../../../services/Pagination'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import toast, { Toaster } from 'react-hot-toast'

import './Users.css'

const PortalUsers = () => {
	const [add, setAdd] = useState();
	const [view, setView] = useState();
	const [update, setUpdate] = useState();
	const [reset, setReset] = useState();

	const [user_data, setPortalUserData] = useState([]);
	// const [roles, setRoles] = useState([]);
	// const [user_id, setPortalUserId] = useState("");

	const [state, setState] = useState({
		id: "",
		pf_number: "",
		first_name: "",
		last_name: "",
		middle_name: "",
		email: "",
		mobile_number: "",
		account_blocked: "",
		role_id: ""
	})

	const [currentPage, setCurrentPage] = useState(1);
	const [postPerPage, setPostPerPage] = useState(10);

	const queryClient = useQueryClient();

	const {
		isLoading,
		isError,
		error,
		data: portal_users,
	} = useQuery('portal_users', getPortalUsers, {
		select: data => data.sort((a, b) => b.id - a.id)
	});

	const {
		data: roles,
	} = useQuery('roles', getRoles);

	const indexOfLastPage = currentPage * postPerPage;
	const indexOfFirstPage = indexOfLastPage - postPerPage;
	const currentPosts = portal_users?.slice(indexOfFirstPage, indexOfLastPage);

	const addPortalUserMutation = useMutation(addPortalUser, {
		onSuccess: async (res) => {
			if (res.status === false) {
				toast.error(res.message, {
					duration: 5000,
				})
			} else {
				// Invalidate cached data
				queryClient.invalidateQueries("portal_users")
				toast.success(res.message, {
					duration: 5000,
				})
				setState({
					id: "",
					pf_number: "",
					first_name: "",
					last_name: "",
					middle_name: "",
					email: "",
					mobile_number: "",
					account_blocked: "",
					role_id: ""
				})
				setAdd(false)
				setView(false)
				setUpdate(false)
				setReset(false)
			}
		},
		onError: async (err) => {
			toast.error(err.message)
		}
	})

	const viewPortalUserMutation = useMutation(getPortalUserById, {
		onSuccess: async (res) => {
			setPortalUserData(res, {
				duration: 3000,
			})
		},
		onError: async (err) => {
			toast.error(err, {
				duration: 3000,
			})
		}
	})

	const updatePortalUserMutation = useMutation(updatePortalUser, {
		onSuccess: async (res) => {
			// Invalidate cached data
			queryClient.invalidateQueries("portal_users")
			toast.success(res.message, {
				duration: 5000,
			})
			setState({
				id: "",
				pf_number: "",
				first_name: "",
				last_name: "",
				middle_name: "",
				email: "",
				mobile_number: "",
				account_blocked: "",
				role_id: ""
			})
			setAdd(false)
			setView(false)
			setUpdate(false)
			setPortalUserData([])
		},
		onError: async (err) => {
			toast.error(err.message, {
				duration: 3000,
			})
		}
	})
	const resetPortalUserMutation = useMutation(resetMemberAccount, {
		onSuccess: async (res) => {
			// Invalidate cached data
			queryClient.invalidateQueries("portal_users")
			toast.success(res.message, {
				duration: 5000,
			})
			setState({
				id: "",
				pf_number: "",
				first_name: "",
				last_name: "",
				middle_name: "",
				email: "",
				mobile_number: "",
				account_blocked: "",
				role_id: ""
			})
			setAdd(false)
			setView(false)
			setUpdate(false)
			setReset(false)
			setPortalUserData([])
		},
		onError: async (err) => {
			toast.error(err.message, {
				duration: 3000,
			})
		}
	})

	const deletePortalUserMutation = useMutation(deletePortalUser, {
		onSuccess: async (res) => {
			// Invalidate cached data
			toast.success(res.message, {
				duration: 3000,
			})
			queryClient.invalidateQueries("portal_users")
		},
		onError: async (err) => {
			toast.error(err.message, {
				duration: 3000,
			})
		}
	})
	const handleChange = (e) => {
		e.preventDefault();
		const value = e.target.value.toUpperCase()
		setState({
			...state,
			[e.target.name]: value
		})
		
	}

	const changeCase = (e) => {
		e.preventDefault();
		const changeValue = e.target.value.toUpperCase()
		setState({
			...state,
			[e.target.name]: changeValue
		})
	}

	const handleConfirm = (id) => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className='confirm-container'>
						<h3><i className='fa fa-warning'></i> Confirm Deletion</h3>
						<p>Are you sure you want to delete the portal user?</p>
						<br />
						<div className='confirm-buttons'>
							<button
								className="btn btn-danger"
								onClick={onClose}
							>
								No
							</button>
							&nbsp;&nbsp;&nbsp;&nbsp;
							<button
								className="btn btn-success"
								onClick={() => { deletePortalUserMutation.mutate(id); onClose(); }}
							>
								Yes
							</button>
						</div>
					</div>
				);
			}
		});
	}

	const handleSubmit = (e) => {
		e.preventDefault();

		const userData = {
			id: state.id,
			pf_number: state.pf_number,
			first_name: state.first_name,
			last_name: state.last_name,
			middle_name: state.middle_name,
			email: state.email,
			mobile_number: state.mobile_number,
			role_id: parseInt(state.role_id)
		}
		const resetData = {
			id: state.id,
			account_blocked: state.account_blocked,
		}

		add
			? addPortalUserMutation.mutate(userData)
			: reset
				? resetPortalUserMutation.mutate(resetData)
				: updatePortalUserMutation.mutate(userData)
	}

	const handleReset = (id) => {
		setState({
			...state,
			id: id
		})
		setAdd(false)
		setView(false)
		setUpdate(false)
		setReset(true)
		setPortalUserData([])
		viewPortalUserMutation.mutate(id);
	}

	const handleCancel = () => {
		setAdd(false)
		setView(false)
		setUpdate(false)
		setReset(false)
		setPortalUserData([])
	}
	const handleAdd = () => {
		setAdd(true)
		setView(false)
		setUpdate(false)
		setReset(false)
		setPortalUserData([])
	}
	const handleView = (id) => {
		setView(true)
		setAdd(false)
		setUpdate(false)
		setReset(false)
		viewPortalUserMutation.mutate(id);
	}

	const handleEdit = (id) => {
		setState({
			...state,
			id: id
		})
		setUpdate(true)
		setAdd(false)
		setView(false)
		viewPortalUserMutation.mutate(id);
	}

	const contentView = (
		<>
			<div className="table-responsive">
				<table className="table table-borderless">
					<tbody>
						<tr>
							<td>
								<label>First Name</label>
								<abbr title="required">
								</abbr>
								<strong ></strong>
							</td>
							<td >
								<label
									align="left"
									readOnly
									type="text"
									name="first_name"
									className="form-control" >{user_data?.first_name}
								</label>
							</td>
							<td >
								<label>Email</label>
								<abbr title="required">
								</abbr>
								<strong ></strong>
							</td>
							<td >
								<label
									align="left"
									readOnly type="text"
									name="email" className="form-control" >{user_data?.email} </label>
							</td>
						</tr>
						<tr>
							<td>
								<label>Middle Name</label>
								<abbr title="required">
								</abbr>
								<strong ></strong>
							</td>
							<td >
								<label align="" readOnly type="text" name="middle_name" className="form-control" >{user_data?.middle_name} </label>
							</td>
							<td >
								<label>Mobile Number</label>
								<abbr title="required">
								</abbr>
								<strong ></strong>
							</td>
							<td >
								<label align="left" readOnly type="text" name="mobile_number" className="form-control" >{user_data?.mobile_number} </label>
							</td>
						</tr>
						<tr>
							<td>
								<label>Last Name</label>
								<abbr title="required">
								</abbr>
								<strong ></strong>
							</td>
							<td >
								<label align="left" readOnly type="text" name="last_name" className="form-control" >{user_data?.last_name} </label>
							</td>
							<td >
								<label>Role</label>
								<abbr title="required">
								</abbr>
								<strong ></strong>
							</td>
							<td >
								<label align="left" readOnly type="text" name="role_name" className="form-control" >{user_data?.role?.role_name} </label>
							</td>
						</tr>
						<tr>
							<td>
								<label>PF Number</label>
								<abbr title="required">
								</abbr>
								<strong ></strong>
							</td>
							<td >
								<label align="left" readOnly type="text" name="pf_number" className="form-control" >{user_data?.pf_number}</label>
							</td>
							<td>
								<label>Date Created</label>
								<abbr title="required">
								</abbr>
								<strong ></strong>
							</td>
							<td >
								<label align="left" readOnly type="text" name="createdAt" className="form-control" >{user_data?.createdAt} </label>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</>
	)

	const addForm = (
		<>
			<div className="form-group">
				<label className="col-sm-2 control-label" htmlFor="first_name">
					<abbr aria-label="required"> First Name</abbr>
					<strong style={{ color: "red" }}> *</strong>
				</label>
				<div className="col-sm-4">
					<input

						type="text"
						title="required"
						aria-label="required"
						id="first_name"
						name="first_name"
						placeholder="FirstName"
						autoComplete='off'
						onKeyUp={changeCase}
						required
						value={state.first_name}
						onChange={handleChange}
						className="form-control"
					/>
				</div>
				<label className="col-sm-2 control-label" htmlFor="middle_name">
					<abbr aria-label="required"> Middle Name</abbr>
					<strong style={{ color: "red" }}></strong>
				</label>
				<div className="col-sm-4">
					<input
						type="text"
						id="middle_name"
						name="middle_name"
						placeholder="Middle name"
						autoComplete='off'
						onKeyUp={changeCase}
						value={state.middle_name}
						onChange={handleChange}
						className="form-control" />
				</div>
			</div>
			<div className="form-group">
				<label className="col-sm-2 control-label" htmlFor="last_name">
					<abbr aria-label="required"> Last Name</abbr>
					<strong style={{ color: "red" }}> *</strong>
				</label>

				<div className="col-sm-4">
					<input
						type="text"
						id="last_name"
						name="last_name"
						title="required"
						aria-label="required"
						placeholder="Last Name"
						autoComplete='off'
						onKeyUp={changeCase}
						required
						value={state.last_name}
						onChange={handleChange}
						
						className="form-control" />
				</div>
				<label className="col-sm-2 control-label" htmlFor="pf_number">
					<abbr aria-label="required"> PF Number</abbr>
					<strong style={{ color: "red" }}> *</strong>
				</label>

				<div className="col-sm-4">
					<input
						type="text"
						id="pf_number"
						name="pf_number"
						placeholder="Auto Generated PF Number"
						autoComplete='off'
						onKeyUp={changeCase}
						required
						disabled
						value={state.pf_number}
						onChange={handleChange}
						className="form-control" />
				</div>
			</div>
			<div className="form-group">
				<label className="col-sm-2 control-label" htmlFor="mobile_number">
					<abbr aria-label="required"> Mobile Number</abbr>
					<strong style={{ color: "red" }}> *</strong>
				</label>
				<div className="col-sm-4">
					<input
						type="number"
						id="mobile_number"
						name="mobile_number"
						title="required"
						aria-label="required"
						placeholder="Mobile Number"
						autoComplete='off'
						onKeyUp={changeCase}
						required
						value={state.mobile_number}
						onChange={handleChange}
						className="form-control" />
				</div>
				<label className="col-sm-2 control-label" htmlFor="email">
					<abbr aria-label="required"> Email</abbr>
					<strong style={{ color: "red" }}> *</strong>
				</label>
				<div className="col-sm-4">
					<input
						type="email"
						id="email"
						name="email"
						title="required"
						aria-label="required"
						placeholder="Email Address"
						autoComplete='off'
						required
						value={state.email}
						onChange={handleChange}
						className="form-control" />
				</div>
			</div>
			<div className="form-group">
				<label className="col-sm-2 control-label" htmlFor="role_id">
					<abbr aria-label="required"> PortalUser Role</abbr>
					<strong style={{ color: "red" }}> *</strong>
				</label>
				<div className="col-sm-4">
					<select
						type="text"
						id="role_id"
						name="role_id"
						title="required"
						aria-label="required"
						autoComplete='off'
						onKeyUp={changeCase}
						required
						value={state.role_id}
						onChange={handleChange}
						className="form-control" >
						<option value="">Select Role</option>
						{roles?.map((role) =>
							<>
								<option value={role.id} key={role.id}>
									{role.id} - {role.role_name}
								</option>
							</>
						)}
					</select>
				</div>
			</div>
		</>
	)

	const updateForm = (
		<>
			<div className="form-group">
				<label className="col-sm-2 control-label" htmlFor="first_name">
					<abbr aria-label="required"> First Name</abbr>
					<strong style={{ color: "red" }}> *</strong>
				</label>
				<div className="col-sm-4">
					<input

						type="text"
						title="required"
						aria-label="required"
						id="first_name"
						name="first_name"
						placeholder="FirstName"
						autoComplete='off'
						onKeyUp={changeCase}
						required
						defaultValue={user_data?.first_name}
						onChange={handleChange}
						className="form-control"
					/>
				</div>
				<label className="col-sm-2 control-label" htmlFor="middle_name">
					<abbr aria-label="required"> Middle Name</abbr>
					<strong style={{ color: "red" }}></strong>
				</label>
				<div className="col-sm-4">
					<input
						type="text"
						id="middle_name"
						name="middle_name"
						placeholder="Middle name"
						autoComplete='off'
						onKeyUp={changeCase}
						defaultValue={user_data?.middle_name}
						onChange={handleChange}
						className="form-control" />
				</div>
			</div>
			<div className="form-group">
				<label className="col-sm-2 control-label" htmlFor="last_name">
					<abbr aria-label="required"> Last Name</abbr>
					<strong style={{ color: "red" }}> *</strong>
				</label>

				<div className="col-sm-4">
					<input
						type="text"
						id="last_name"
						name="last_name"
						title="required"
						aria-label="required"
						placeholder="Last Name"
						autoComplete='off'
						onKeyUp={changeCase}
						required
						defaultValue={user_data?.last_name}
						onChange={handleChange}
						className="form-control" />
				</div>
				<label className="col-sm-2 control-label" htmlFor="pf_number">
					<abbr aria-label="required"> PF Number</abbr>
					<strong style={{ color: "red" }}> *</strong>
				</label>

				<div className="col-sm-4">
					<input
						type="text"
						id="pf_number"
						name="pf_number"
						placeholder="Auto Generated PF Number"
						autoComplete='off'
						onKeyUp={changeCase}
						required
						disabled
						value={user_data?.pf_number}
						onChange={handleChange}
						className="form-control" />
				</div>
			</div>
			<div className="form-group">
				<label className="col-sm-2 control-label" htmlFor="mobile_number">
					<abbr aria-label="required"> Mobile Number</abbr>
					<strong style={{ color: "red" }}> *</strong>
				</label>
				<div className="col-sm-4">
					<input
						type="number"
						id="mobile_number"
						name="mobile_number"
						title="required"
						aria-label="required"
						placeholder="Mobile Number"
						autoComplete='off'
						onKeyUp={changeCase}
						required
						defaultValue={user_data?.mobile_number}
						onChange={handleChange}
						className="form-control" />
				</div>
				<label className="col-sm-2 control-label" htmlFor="email">
					<abbr aria-label="required"> Email</abbr>
					<strong style={{ color: "red" }}> *</strong>
				</label>
				<div className="col-sm-4">
					<input
						type="email"
						id="email"
						name="email"
						title="required"
						aria-label="required"
						placeholder="Email Address"
						autoComplete='off'
						required
						defaultValue={user_data?.email}
						onChange={handleChange}
						className="form-control" />
				</div>
			</div>
			<div className="form-group">
				<label className="col-sm-2 control-label" htmlFor="current_role_id">
					<abbr aria-label="required"> Current Role</abbr>
					<strong style={{ color: "red" }}> *</strong>
				</label>
				<div className="col-sm-4">
					<input
						type="text"
						id="current_role_id"
						name="current_role_id"
						disabled
						value={user_data?.role?.role_name}
						className="form-control" />
				</div>
				<label className="col-sm-2 control-label" htmlFor="role_id">
					<abbr aria-label="required"> User Role</abbr>
					<strong style={{ color: "red" }}> *</strong>
				</label>
				<div className="col-sm-4">
					<select
						type="text"
						id="role_id"
						name="role_id"
						title="required"
						aria-label="required"
						autoComplete='off'
						onKeyUp={changeCase}
						required
						defaultValue={user_data?.role_id}
						onChange={handleChange}
						className="form-control" >
						<option value="">Select Role</option>
						{roles?.map((role) =>
							<>
								<option value={role.id} key={role.id}>
									{role.id} - {role.role_name}
								</option>
							</>
						)}
					</select>
				</div>
			</div>
		</>
	)
	const resetForm = (
		<>
			<div className="form-group">
				<label className="col-sm-2 control-label" htmlFor="first_name">
					<abbr aria-label="required"> First Name</abbr>
					<strong style={{ color: "red" }}> *</strong>
				</label>
				<div className="col-sm-4">
					<input

						type="text"
						title="required"
						aria-label="required"
						id="first_name"
						name="first_name"
						placeholder="FirstName"
						autoComplete='off'
						onKeyUp={changeCase}
						required
						readOnly
						defaultValue={user_data?.first_name}
						onChange={handleChange}
						className="form-control"
					/>
				</div>
				<label className="col-sm-2 control-label" htmlFor="middle_name">
					<abbr aria-label="required"> Middle Name</abbr>
					<strong style={{ color: "red" }}></strong>
				</label>
				<div className="col-sm-4">
					<input
						type="text"
						id="middle_name"
						name="middle_name"
						placeholder="Middle name"
						autoComplete='off'
						readOnly
						onKeyUp={changeCase}
						defaultValue={user_data?.middle_name}
						onChange={handleChange}
						className="form-control" />
				</div>
			</div>
			<div className="form-group">
				<label className="col-sm-2 control-label" htmlFor="last_name">
					<abbr aria-label="required"> Last Name</abbr>
					<strong style={{ color: "red" }}> *</strong>
				</label>

				<div className="col-sm-4">
					<input
						type="text"
						id="last_name"
						name="last_name"
						title="required"
						aria-label="required"
						placeholder="Last Name"
						autoComplete='off'
						onKeyUp={changeCase}
						required
						readOnly
						defaultValue={user_data?.last_name}
						onChange={handleChange}
						className="form-control" />
				</div>
				<label className="col-sm-2 control-label" htmlFor="pf_number">
					<abbr aria-label="required"> PF Number</abbr>
					<strong style={{ color: "red" }}> *</strong>
				</label>

				<div className="col-sm-4">
					<input
						type="text"
						id="pf_number"
						name="pf_number"
						placeholder="Auto Generated PF Number"
						autoComplete='off'
						onKeyUp={changeCase}
						required
						disabled
						readOnly
						value={user_data?.pf_number}
						onChange={handleChange}
						className="form-control" />
				</div>
			</div>
			<div className="form-group">
				<label className="col-sm-2 control-label" htmlFor="mobile_number">
					<abbr aria-label="required"> Mobile Number</abbr>
					<strong style={{ color: "red" }}> *</strong>
				</label>
				<div className="col-sm-4">
					<input
						type="number"
						id="mobile_number"
						name="mobile_number"
						title="required"
						aria-label="required"
						placeholder="Mobile Number"
						autoComplete='off'
						readOnly
						onKeyUp={changeCase}
						required
						defaultValue={user_data?.mobile_number}
						onChange={handleChange}
						className="form-control" />
				</div>
				<label className="col-sm-2 control-label" htmlFor="email">
					<abbr aria-label="required"> Email</abbr>
					<strong style={{ color: "red" }}> *</strong>
				</label>
				<div className="col-sm-4">
					<input
						type="email"
						id="email"
						name="email"
						title="required"
						aria-label="required"
						placeholder="Email Address"
						autoComplete='off'
						required
						readOnly
						defaultValue={user_data?.email}
						onChange={handleChange}
						className="form-control" />
				</div>
			</div>
			<div className="form-group">
				<label className="col-sm-2 control-label" htmlFor="current_role_id">
					<abbr aria-label="required"> Current Role</abbr>
					<strong style={{ color: "red" }}> *</strong>
				</label>
				<div className="col-sm-4">
					<input
						type="text"
						id="current_role_id"
						name="current_role_id"
						disabled
						readOnly
						value={user_data?.role?.role_name}
						className="form-control" />
				</div>
				<label className="col-sm-2 control-label" htmlFor="current_role_id">
					<abbr aria-label="required"> Account Status</abbr>
					<strong style={{ color: "red" }}> *</strong>
				</label>
				<div className="col-sm-4">
					<input
						type="text"
						id="current_role_id"
						name="current_role_id"
						disabled
						style={{ color: "brown", fontWeight: "bold" }}
						readOnly
						value={user_data?.account_blocked ? "Blocked" : "Active"}
						className="form-control" />
				</div>
			</div>
			<div className="form-group">
				<label className="col-sm-2 control-label" htmlFor="account_blocked">
					<abbr aria-label="required"> Activate Account</abbr>
					<strong style={{ color: "red" }}> *</strong>
				</label>
				<div className="col-sm-4">
					<select
						type="text"
						id="account_blocked"
						name="account_blocked"
						title="required"
						aria-label="required"
						autoComplete='off'
						onKeyUp={changeCase}
						required
						value={state.account_blocked}
						onChange={handleChange}
						className="form-control" >
						<option value={true}>Block</option>
						<option value={false}>UnBlock</option>
					</select>
				</div>
			</div>
		</>
	)

	const userForm = (
		<>
			{add || view || update || reset
				?
				<div className="panel panel-info panel-br-4">
					<div className="panel-heading">
						<div className="row">
							<div className="col-sm-9">
								<h4 className="panel-title">
									{view
										?
										<>
											<i className="fa fa-eye" aria-hidden="true"></i><span> PortalUser Details</span>
										</>

										: update
											?
											<>
												<i className="fa fa-edit" aria-hidden="true"></i><span> PortalUser Update</span>
											</>
											: reset
												?
												<>
													<i className="fa fa-unlock" aria-hidden="true"></i><span> Reset Account</span>
												</>
												: add
													?
													<>
														<i className="fa fa-plus" aria-hidden="true"></i><span> Add PortalUser </span>
													</>
													: null
									}
								</h4>
							</div>
						</div>
					</div>
					<form onSubmit={handleSubmit}>
						<div className="panel-body">
							<div className="form-group">
								{view
									? <>{contentView}</>
									: update
										? <>{updateForm}</>
										: reset
											? <>{resetForm}</>
											: add
												? <>{addForm}</>
												: null
								}
							</div>
						</div>
						<div className="panel-footer">
							{view
								?
								<button
									className="btn btn-success mr-10" onClick={handleCancel}>Exit
								</button>
								: update
									?

									<><button type="submit"
										className="btn btn-success mr-10">Update
									</button>
										&nbsp;&nbsp;&nbsp;&nbsp;
										<button
											className="btn btn-success mr-10" onClick={handleCancel}>Cancel
										</button></>
									: reset
										?

										<><button type="submit"
											className="btn btn-success mr-10">Reset Account
										</button>
											&nbsp;&nbsp;&nbsp;&nbsp;
											<button
												className="btn btn-success mr-10" onClick={handleCancel}>Cancel
											</button></>

										: add
											?
											<>
												<button type="submit"
													className="btn btn-success">Submit
												</button>&nbsp;&nbsp;&nbsp;&nbsp;
												<button
													className="btn btn-success" onClick={handleCancel}>Cancel
												</button>
											</>
											: null
							}
						</div>
					</form>
				</div >
				: null}
		</>
	)

	let content
	if (isLoading) {
		content = <p>Loading...</p>
	} else if (isError) {
		content = <p>{error.message}</p>
	} else {
		content =
			<>
				<div className="panel panel-default panel-br-4">
					<div className="panel-heading">
						<div className="row">
							<div className="col-sm-9">
								<h4 className="panel-title">
									<i className="fa fa-file-text" aria-hidden="true"></i>
									<span> PORTAL USERS LIST</span>
								</h4>
							</div>
						</div>
					</div>

					<div className="panel-body">
						<Link id="document-сreate-btn" className="btn btn-success mr-10" onClick={handleAdd}>
							<i className="fa fa-plus-circle"></i> Add Portal User
						</Link>
						{/* <Link id="document-сreate-btn" className="btn btn-success mr-10" onClick={handleAccountReset}>
							<i className="fa fa-plus-circle"></i> Reset PortalUser Account
						</Link> */}
						<br /><br />
						<div className="table-responsive">
							<table className="table table-sm table-borderless table-hover table-striped">
								<thead>
									<tr>
										<th style={{ textAlign: 'center' }}>#</th>
										<th>PF Number</th>
										<th>Full Name</th>
										<th>Role</th>
										<th>Email</th>
										<th>Mobile Number</th>
										<th>Status</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									{currentPosts.map((item, index) =>
										item.account_blocked
											?
											<tr key={item.id}>
												<td>{postPerPage * (currentPage - 1) + index + 1}</td>
												<td style={{ color: "brown", whiteSpace: 'nowrap' }}>{item?.pf_number}</td>
												<td style={{ color: "brown", whiteSpace: 'nowrap' }}>{item?.first_name}  {item?.middle_name != null ? item?.middle_name : " "}  {item?.last_name}</td>
												<td style={{ color: "brown", whiteSpace: 'nowrap' }}>{item?.role?.role_name}</td>

												<td style={{ color: "brown", whiteSpace: 'nowrap' }}>{item.email}</td>
												<td style={{ color: "brown", whiteSpace: 'nowrap' }}>{item?.mobile_number}</td>
												<td style={{ color: "brown", whiteSpace: 'nowrap' }}><i className='fa fa-lock'></i> Blocked</td>
												<td style={{ whiteSpace: 'nowrap' }}>
													<div style={{ display: 'inline-block' }}>
														<Link onClick={() => handleView(item?.id)} className="small"><i className='fa fa-eye'></i> view</Link>
													</div>&nbsp; &nbsp;
													<div style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
														<Link onClick={() => handleEdit(item?.id)} className="small mr-3">
															<i className='fa fa-edit'></i> edit
														</Link>
													</div>
													&nbsp;&nbsp;
													<div style={{ display: 'inline-block' }}>
														<Link className="small" onClick={() => handleConfirm(item?.id)}><i className='fa fa-trash'></i> delete</Link>
													</div>
													&nbsp;&nbsp;
													<div style={{ display: 'inline-block' }}>
														<Link className="small" onClick={() => handleReset(item?.id)}><i className='fa fa-unlock'></i> reset account</Link>
													</div>
												</td>
											</tr>
											:
											<tr key={item.id}>
												<td>{postPerPage * (currentPage - 1) + index + 1}</td>
												<td style={{ whiteSpace: 'nowrap' }}>{item?.pf_number}</td>
												<td style={{ whiteSpace: 'nowrap' }}>{item?.first_name}  {item?.middle_name != null ? item?.middle_name : " "}  {item?.last_name}</td>
												<td style={{ whiteSpace: 'nowrap' }}>{item?.role?.role_name}</td>

												<td style={{ whiteSpace: 'nowrap' }}>{item.email}</td>
												<td style={{ whiteSpace: 'nowrap' }}>{item?.mobile_number}</td>
												<td style={{ whiteSpace: 'nowrap' }}><i className='fa fa-unlock'></i> Active</td>												<td style={{ whiteSpace: 'nowrap' }}>
													<div style={{ display: 'inline-block' }}>
														<Link onClick={() => handleView(item?.id)} className="small"><i className='fa fa-eye'></i> view</Link>
													</div>&nbsp; &nbsp;
													<div style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
														<Link onClick={() => handleEdit(item?.id)} className="small mr-3">
															<i className='fa fa-edit'></i> edit
														</Link>
													</div>
													&nbsp;&nbsp;
													<div style={{ display: 'inline-block' }}>
														<Link className="small" onClick={() => handleConfirm(item?.id)}><i className='fa fa-trash'></i> delete</Link>
													</div>
												</td>
											</tr>
									)}
								</tbody>
							</table>
						</div>
					</div>
					<div className="panel-footer">
						<Pagination
							data={portal_users ? portal_users : {}}
							postPerPage={postPerPage}
							currentPage={currentPage}
							setCurrentPage={setCurrentPage}
							setPostPerPage={setPostPerPage} />
					</div>
				</div>
			</>
	}

	return (
		<>
			<section className="content-header">
				<Toaster toastOptions={{ position: "top-center" }} />
				<h1>Portal Users</h1>
			</section>
			<section className="content">
				<div className="box">
					<div className="box-body">
						{userForm}
						{content}
					</div>
					<div className="panel-footer"></div>
				</div>
			</section ></>
	);
};

export default PortalUsers;


