import React, { useState } from "react";
import { useQuery } from 'react-query';
import {
    getPaymentTypes,
} from "../../../../api/apiServices";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast'

const ReceivePayments = () => {
    const navigate = useNavigate();
    const [payment_type, setPaymentType] = useState('');

    const {
        data: payment_types,
    } = useQuery('payment_types', () => getPaymentTypes());

    const handleSubmit = (e) => {
        if (payment_type === "CC1000") {
            return navigate(`/sales_and_regularization`);
        }
        if (payment_type === "CC2000") {
            return navigate(`/ground_rents`);
        }
        if (payment_type === "CC3000") {
            return navigate(`/customary_drinks`);
        }
    }
    

    const content = (
        <>
            <form onSubmit={handleSubmit}>
                <div className="form-group row">
                    <fieldset className="col-sm-6">
                        <div className="form-group row">
                            <label htmlFor="payment_type" className="col-sm-3">Select Payment Type</label>
                            <div className="col-sm-9">
                                <select
                                    type="text"
                                    id="payment_type"
                                    autoComplete="off"
                                    style={{ textTransform: "uppercase" }}
                                    onChange={(e) => setPaymentType(e.target.value)}
                                    required
                                    value={payment_type}
                                    name="payment_type"
                                    className="form-control"
                                >
                                    <option></option>                         
                                    {payment_types?.map((type, index) => 
                                        <option key={index} value={type.type_code}>
                                            {type.description}
                                        </option>
                                        )}
                                </select>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div className="panel-footer">
                    <div className="form-group row">
                        <div className="col-sm-2">
                            <button className="btn btn-success btn-block">
                                OK
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )

    return (
        <>
            <section className="content-header">
                <Toaster toastOptions={{ position: "top-center" }} />
                <h1>Payment Receipts</h1>
            </section>
            <section className="content">
                <div className="box">
                    <div className="box-body">
                        <div className="panel panel-default panel-br-4">
                            <div className="panel-heading">
                                <div className="row">
                                    <div className="col-sm-12">
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="panel-body">
                                {content}
                            </div>
                        </div>
                    </div>
                    <div className="box-footer"></div>
                </div>
            </section >
        </>
    );
}

export default ReceivePayments;

