import React from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { useQuery } from 'react-query';
import { getLedgerByID } from '../../../../api/apiServices';

const ViewLedger = () => {
   
    const params = useParams();
    const id = parseInt(params.id)

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/ledger_account";

    const {
        isLoading,
        isError,
        error,
        data: tenantLedger
    } = useQuery(['tenantLedger', id], () => getLedgerByID(id), {
        keepPreviousData: true,
    });
    console.log(tenantLedger)

    const handleCancel = () => {
        navigate(-1);
    }


    let totalPayment = 0;
    let ledgerData = [];
    let paymentData = [];

    const FullName = tenantLedger?.tenant?.first_name + " " + tenantLedger?.tenant?.middle_name + " " + tenantLedger?.tenant?.last_name

    {
        tenantLedger?.ledger.map(ledg => {
            let ledgData = {
                year: ledg.year,
                tenant_code: ledg.tenant_code,
                transaction_date: ledg.transaction_date,
                opening_balance: ledg.opening_balance,
                expected_total_amount: ledg.expected_total_amount,
                total_amount_paid: ledg.total_amount_paid,
                closing_balance: ledg.closing_balance
            }
            ledgerData.push(ledgData);
        })
    }

    // Member Payments
    {
        tenantLedger?.payment.map(paymtData => {
            totalPayment += paymtData.payment_amount
            let payData = {
                year: paymtData.year,
                payment_date: paymtData.payment_date,
                description: paymtData.description.toUpperCase(),
                receipt_number: paymtData.receipt_number,
                payment_amount: paymtData.payment_amount.toLocaleString(undefined, { minimumFractionDigits: 2 }),
                // balance: nextBalance.toLocaleString(undefined, { minimumFractionDigits: 2 }),
            }
            paymentData.push(payData);
        })
    }


    const tenantInfo = (
        <>
            <div className="table-responsive-lg">
                <table className='table table-borderless'>
                    <tbody>
                        <tr>
                            <td>FULL NAME</td>
                            <td><b>{tenantLedger?.tenant?.first_name} {tenantLedger?.tenant?.middle_name} {tenantLedger?.tenant?.last_name}</b></td>
                            <td>MOBILE</td>
                            <td><b>{tenantLedger?.tenant?.contact_number}</b></td>
                        </tr>
                        <tr>
                            <td>TENANT CODE</td>
                            <td><b>{tenantLedger?.tenant?.tenant_code}</b></td>
                            <td>EMAIL ADDRESS</td>
                            <td><b>{tenantLedger?.tenant?.email}</b></td>
                        </tr>
                        <tr>
                            <td>LAND LOCATION</td>
                            <td><b>{tenantLedger?.tenant?.land_location}</b></td>
                            <td>ZONE</td>
                            <td><b>{tenantLedger?.tenant?.zone_code}</b></td>
                        </tr>
                        <tr>
                            <td>ONTACT ADDRESS</td>
                            <td><b>{tenantLedger?.tenant?.contact_address}</b></td>
                            <td>OCCUPATION</td>
                            <td><b>{tenantLedger?.tenant?.occupation}</b></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
    let content
    if (isLoading) {
        content = <p>Loading Members Data...</p>
    }
    else if (isError) {
        content = <p>{error.message}</p>
    } else {
        content =
            (
                <>
                    <legend>Payment Transactions</legend>
                    <div className="table-responsive-lg">
                        <table className='table table-bordered'>
                            <thead>
                                <tr>
                                    <th style={{ textAlign: "center" }}>Year</th>
                                    <th style={{ textAlign: "center" }}>Payment Date</th>
                                    <th style={{}}>Receipt Number</th>
                                    <th style={{}}>Payment Type</th>
                                    <th style={{ whiteSpace: "nowrap" }}>Description</th>
                                    <th style={{ textAlign: "right" }}>Amount (GHS)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paymentData.map(payment => (
                                    <tr key={payment.id}>
                                        <td style={{ textAlign: "center" }}>{payment.year}</td>
                                        <td style={{ textAlign: "center" }}>{payment.payment_date}</td>
                                        <td style={{ textAlign: "left" }}>{payment.receipt_number}</td>
                                        <td style={{ textTransform: "uppercase" }}>{payment.payment_type}</td>
                                        <td style={{ whiteSpace: "nowrap" }}>{payment.description}</td>
                                        <td style={{ textAlign: "right" }}>{payment.payment_amount.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                                    </tr>
                                ))}
                            </tbody>
                            <tbody>
                                <tr>
                                    <td colSpan="5"></td>
                                </tr>
                                <tr>
                                    <td colSpan="5" className="border-bottom" style={{ width: "55%", textAlign: "left", fontSize: "16px", fontWeight: "700", color: "darkred" }}>
                                        TOTAL PAYMENT
                                    </td>
                                    {totalPayment < 0 ? (
                                        <td className="border-bottom" style={{ width: "40%", textAlign: "right", fontSize: "16px", fontWeight: "700", color: "darkred" }}>
                                            ({(totalPayment * -1).toLocaleString(undefined, { minimumFractionDigits: 2 })}) </td>
                                    ) : (
                                        <td className="border-bottom" style={{ width: "40%", textAlign: "right", fontSize: "16px", fontWeight: "700", color: "darkred" }}>
                                            {totalPayment.toLocaleString(undefined, { minimumFractionDigits: 2 })} </td>
                                    )}
                                </tr>
                                <tr>
                                    <td colSpan="7" style={{ width: "95%", borderBottom: "5px" }}></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <br />

                    <legend>Ledger Account</legend>
                    <div className="table-responsive-lg">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th style={{ textAlign: "center" }}>Year</th>
                                    <th style={{ textAlign: "center" }}>Date</th>
                                    <th style={{ textAlign: "center" }}>Opening Balance</th>
                                    <th style={{ textAlign: "center" }}>Expected Payment</th>
                                    <th style={{ textAlign: "center" }}>Amount Paid</th>
                                    <th style={{ textAlign: "right" }}>Closing Balance </th>
                                </tr>
                            </thead>
                            <tbody>
                                {ledgerData.map(ledger => (
                                    <>
                                        <tr key={ledger?.id}>
                                            <td style={{ textAlign: "center" }}>{ledger?.year}</td>
                                            <td style={{ textAlign: "center" }}>{ledger?.transaction_date}</td>
                                            {ledger?.opening_balance < 0 ? (
                                                <td style={{ textAlign: "right" }}>({(ledger?.opening_balance * -1).toLocaleString(undefined, { minimumFractionDigits: 2 })}) </td>
                                            ) : (
                                                    <td style={{ textAlign: "right" }}>{ledger?.opening_balance.toLocaleString(undefined, { minimumFractionDigits: 2 })} </td>
                                            )}
                                            {ledger?.expected_total_amount < 0 ? (
                                                <td style={{ textAlign: "right" }}>({(ledger?.expected_total_amount * -1).toLocaleString(undefined, { minimumFractionDigits: 2 })}) </td>
                                            ) : (
                                                <td style={{ textAlign: "right" }}>{ledger?.expected_total_amount.toLocaleString(undefined, { minimumFractionDigits: 2 })} </td>
                                            )}
                                            <td style={{ textAlign: "right" }}>{ledger?.total_amount_paid.toLocaleString(undefined, { minimumFractionDigits: 2 })} </td>
                                            {ledger?.closing_balance < 0 ? (
                                                <td style={{ textAlign: "right" }}>({(ledger?.closing_balance * -1).toLocaleString(undefined, { minimumFractionDigits: 2 })}) </td>
                                            ) : (
                                                <td style={{ textAlign: "right" }}>{ledger?.closing_balance.toLocaleString(undefined, { minimumFractionDigits: 2 })} </td>
                                            )}
                                        </tr>
                                    </>
                                ))}
                            </tbody>
                            <tbody>
                                <tr>
                                    <td colSpan="6"></td>
                                </tr>
                                <tr>
                                    <td colSpan="7" style={{ width: "95%", borderBottom: "5px" }}></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={handleCancel}
                        > <i className="fa fa-times"></i> Cancel</button>
                    </div>
                </>
            )
    }
    return (
        <>
            <section className="content-header">
                <h1>Member Ledger Account - {FullName}</h1>
            </section>
            <section className="content">
                <div className="box">
                    <div className="box-body">
                        <div className="panel panel-default panel-br-4">
                            <div className="panel-heading">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <h4 className="panel-title">
                                            {tenantInfo}
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="panel-body">
                                {content}
                            </div>
                            <div className="panel-footer">
                            </div>
                        </div>
                        <div className="box-footer"></div>
                    </div>
                </div>
            </section>
        </>

    );
}

export default ViewLedger