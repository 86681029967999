import React, { useContext } from 'react'
import { useNavigate, Link } from "react-router-dom";
import useLogout from "../../../hooks/useLogout";
import AuthContext from "../../../context/AuthProvider";
// import { format} from "date-fns";

// const dateTime = `${format(new Date(), 'dd-MM-yyyy\t\t\tHH:mm:ss')}`;
// const date = new Date();



const AdminHeader = () => {

  // const [date, setDate] = useState(Date().toLocaleString());
  const { auth } = useContext(AuthContext)

  const navigate = useNavigate();
  const logout = useLogout();

  const logOut = async () => {
    await logout();
    navigate('/');
  }

  return (
    <header className="main-header">
      <Link className="logo" to={"/dashboard"}>
        <span className="logo-mini">
        </span>
        <div className="name-logo">
          <h4>NII ODAI NTOW</h4>
          {/* <img src="/logos/proforum.jpg" alt="Proforum" /> */}
        </div>
        
        <span className="logo-lg"><strong></strong></span>
      </Link>
      <nav className="navbar navbar-static-top" role="navigation">
        <Link className="sidebar-toggle" to={"#"} role="button" data-toggle="offcanvas">
          <span className="sr-only"></span>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>&nbsp;&nbsp;&nbsp;&nbsp;
          <span className="logo-mini"><strong>SALES & REGULARIZATION SYSTEM</strong></span>
        </Link>
        <div className="navbar-custom-menu">
          <ul className="nav navbar-nav">
            <li className="dropdown user user-menu">
              <Link className="dropdown-toggle" to={"#"} data-toggle="dropdown">
                <span className="hidden-xs">
                  <span><i className="fas fa-user"></i> {auth.username} ({auth.user_role})</span><span></span>
                </span>
              </Link>
            </li>
            <li className="user-footer">
              <Link className="logout" data-method="delete" data-placement="bottom" data-toggle="tooltip" onClick={logOut} rel="nofollow" title="Logout">
                <span className="hidden-xs"><i className="fa fa-sign-out"></i> Logout</span>
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  )
}

export default AdminHeader
