import React, { useState } from 'react'
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { getAllMessages, deleteMessage } from '../../../api/apiServices';
import Pagination from '../../../services/Pagination'
import toast, { Toaster } from 'react-hot-toast'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const ContactMessage = () => {

    const [search, setSearch] = useState(false);
    const [message, setMessage] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [postPerPage, setPostPerPage] = useState(15);
    const queryClient = useQueryClient();

    const {
        isLoading,
        isError,
        error,
        data: messages
    } = useQuery('messages', getAllMessages, {
        select: data => data.sort((a, b) => b.id - a.id)
    });

    let total_messages = messages?.length

    const indexOfLastPage = currentPage * postPerPage;
    const indexOfFirstPage = indexOfLastPage - postPerPage;
    const currentPosts = messages?.slice(indexOfFirstPage, indexOfLastPage);

    let content
    if (isLoading) {
        content = <p>Loading Messages...</p>
    } else if (isError) {
        content = <p>{error.message}</p>
    } else {
        content =
            currentPosts && currentPosts?.length <= 0 ? (
                <div className='table-responsive-lg'>
                    <table className="table table-bordered">
                        <tbody>
                            <tr>
                                <td style={{ fontSize: "20px", fontWeight: "700", color: "#000", textAlign: "center" }}>
                                    There are no messages
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            ) : (
                <div className='table-responsive'>
                    <table className="table table-bordered">
                        <thead className="align">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">PF Number</th>
                                <th scope="col">Full Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Message</th>
                                <th scope="col">Status</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentPosts.map((message, index) =>
                                <tr key={message.id}>
                                    <td style={{ textAlign: 'center' }}>{postPerPage * (currentPage - 1) + index + 1}</td>
                                    <td style={{ whiteSpace: 'nowrap' }}>{message.pf_number}</td>
                                    <td style={{ whiteSpace: 'nowrap' }}>{message.fullname}</td>
                                    <td style={{ whiteSpace: 'nowrap' }}>{message.email}</td>
                                    <td>{message.message}</td>
                                    {!message.status ? (
                                        <td style={{ whiteSpace: 'normal' }}>Pending</td>
                                    ) : (
                                        <td style={{ whiteSpace: 'normal' }}>Replied</td>
                                    )}
                                    <td>
                                        <div style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
                                            <Link to={`/reply_message/${message.id}`} className="small mr-3">
                                                <i className='fa fa-edit'></i> reply
                                            </Link>
                                        </div>
                                    </td>
                                </tr >
                            )}
                        </tbody>
                    </table>
                </div>
            )
    }
    const handleConfirm = (id) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <div className='confirm-delete'>
                            <h3>Confirm Delete</h3>
                            <p>Do you want to delete the member record ?</p>
                            <br />
                            <div className='confirm-buttons'>
                                <button
                                    style={{ width: "100px" }}
                                    className="btn btn-danger"
                                    onClick={onClose}
                                >
                                    No
                                </button>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <button
                                    style={{ width: "100px" }}
                                    className="btn btn-success"
                                    onClick={() => { deleteMessageMutation.mutate(id); onClose(); }}
                                >
                                    Yes
                                </button>
                            </div>
                        </div>
                    </div>
                );
            }
        });
    }

    const deleteMessageMutation = useMutation(deleteMessage, {
        onSuccess: () => {
            // Invalidate cached data
            queryClient.invalidateQueries("messages")
            toast.success("Message deleted successfully")
        }
    })

    return (
        <><section className="content-header">
            <Toaster toastOptions={{ position: "top-center" }} />
            <h1>Contact Messages : - {total_messages}</h1>
        </section>
            <section className="content">
                <div className="box">
                    <div className="box-body">
                        <div className="panel panel-default panel-br-4">
                            <div className="panel-heading">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <h4 className="panel-title">
                                            <i className="fa fa-file-text" aria-hidden="true"></i>
                                            <span> Messages</span>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="panel-body">
                                {content}
                            </div>
                            <div className="panel-footer">
                                {currentPosts?.length > 0 ? (
                                    <Pagination
                                        data={messages}
                                        postPerPage={postPerPage}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                        setPostPerPage={setPostPerPage}
                                    />
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="box-footer"></div>
                </div>
            </section>
        </>
    );
}

export default ContactMessage