import React, { useEffect, useState, useContext } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { useQuery } from 'react-query';
import {
    officePayment,
    getTenantPaymentByCode
} from '../../../api/apiServices';
import addCommas from "../../../services/addCommas";

const OfficePayment = ({ code }) => {

    const navigate = useNavigate();

    const {
        isLoading,
        isError,
        error,
        data: officePaymt
    } = useQuery(['officePaymt', code], () => officePayment(code));

    const {
        data: paymtTrans
    } = useQuery(['paymtTrans', code], () => getTenantPaymentByCode(code));

    let totalPayment = 0
    {
        paymtTrans?.payments?.map(paymtData => {
            totalPayment += parseFloat(paymtData.payment_amount)
        })
    }

    console.log(officePaymt)
    const handleCancel = () => {
        navigate(-1);
    }

    const fullname = officePaymt?.title + " " + officePaymt?.first_name + " " + officePaymt?.middle_name + " " + officePaymt?.last_name

    let content
    if (isLoading) {
        content = <p>Loading Member Payment Transactions...</p>
    }
    else if (isError) {
        content = <p>{error.message}</p>
    } else {
        content =
            (
                <>
                    {/* Total Payment Transactions  */}
                    <legend>Payment Summary</legend>
                    <div className="table-responsive lg">
                        <table className='table table-borderless table-striped'>
                            <thead>
                                <tr>
                                    <th style={{ textAlign: "center" }}>Payment Type</th>
                                    <th style={{ textAlign: "center" }}>Property Price</th>
                                    <th style={{ textAlign: "center" }}>Interest Amount</th>
                                    <th style={{ textAlign: "center" }}>Amount Payable</th>
                                    <th style={{ textAlign: "center" }}>Initial Deposit</th>
                                    <th style={{ textAlign: "center", whiteSpace: "nowrap" }}>Payment Mode</th>
                                    <th style={{ textAlign: "center" }}>Outstanding Balance (GHS)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {officePaymt?.office_use?.map(payment => (
                                    <tr key={payment.id}>
                                        <td style={{ textAlign: "center" }}>{payment.payment_type}</td>
                                        <td style={{ textAlign: "center" }}>{addCommas(payment.property_price).toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                                        <td style={{ textAlign: "center" }}>{addCommas(payment.interest_amount).toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                                        <td style={{ textAlign: "center" }}>{addCommas(parseFloat(payment.property_price) + parseFloat(payment.interest_amount)).toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                                        <td style={{ textAlign: "center" }}>{addCommas(payment.initial_deposit).toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                                        <td style={{ textAlign: "center" }}>{payment.payment_mode}</td>
                                        <td style={{ textAlign: "center" }}>{addCommas(payment.outstanding_balance).toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                                    </tr>
                                ))}
                            </tbody>
                            <tbody>
                                <tr>
                                    <td colSpan="6" style={{ backgroundColor: "white" }}></td>
                                </tr>
                                <tr>
                                    <td colSpan="5" className="border-bottom" style={{ width: "55%", textAlign: "left", fontSize: "16px", fontWeight: "700", color: "darkred" }}>
                                        TOTAL PAYMENT
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="7" style={{ width: "95%", borderBottom: "5px" }}></td>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                </>
            )
    }



    const payTransactions =
        (
            <>
                {/* Total Payment Transactions  */}
                <legend>Detailed Payment Transactions</legend>
                <div className="table-responsive lg">
                    <table className='table table-borderless table-striped'>
                        <thead>
                            <tr>
                                <th style={{ textAlign: "center" }}>Year</th>
                                <th style={{ whiteSpace: "nowrap" }}>Transaction ID</th>
                                <th style={{ textAlign: "center" }}>Payment Date</th>
                                <th style={{}}>Receipt Number</th>
                                <th style={{}}>Payment Type</th>
                                <th style={{ whiteSpace: "nowrap" }}>Payment Mode</th>
                                <th style={{ textAlign: "right" }}>Amount (GHS)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paymtTrans?.payments?.map(payment => (
                                <tr key={payment.id}>
                                    <td style={{ textAlign: "center" }}>{payment.year}</td>
                                    <td style={{ textAlign: "center" }}>{payment.transaction_id}</td>
                                    <td style={{ textAlign: "center" }}>{payment.payment_date}</td>
                                    <td style={{ textTransform: "uppercase" }}>{payment.receipt_number}</td>
                                    <td style={{ textTransform: "uppercase" }}>{payment.payment_type}</td>
                                    <td style={{ whiteSpace: "nowrap" }}>{payment.description}</td>
                                    <td style={{ textAlign: "right" }}>{addCommas(payment.payment_amount).toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                                </tr>
                            ))}
                        </tbody>
                        <tbody>
                            <tr>
                                <td colSpan="7" style={{ backgroundColor: "white" }}></td>
                            </tr>
                            <tr>
                                <td colSpan="6" className="border-bottom" style={{ width: "55%", textAlign: "left", fontSize: "16px", fontWeight: "700", color: "darkred" }}>
                                    TOTAL PAYMENT
                                </td>
                                {totalPayment < 0 ? (
                                    <td className="border-bottom" style={{ width: "40%", textAlign: "right", fontSize: "16px", fontWeight: "700", color: "darkred" }}>
                                        ({(totalPayment * -1).toLocaleString(undefined, { minimumFractionDigits: 2 })}) </td>
                                ) : (
                                    <td className="border-bottom" style={{ width: "40%", textAlign: "right", fontSize: "16px", fontWeight: "700", color: "darkred" }}>
                                        {totalPayment.toLocaleString(undefined, { minimumFractionDigits: 2 })} </td>
                                )}
                            </tr>
                            <tr>
                                <td colSpan="7" style={{ width: "95%", borderBottom: "5px" }}></td>
                            </tr>
                        </tbody>

                    </table>
                </div>
            </>
        )

    return (
        <>
            <section className="content-header">
                <h1>Payment Transactions - { fullname}</h1>
            </section>
            <section className="content">
                <div className="box">
                    <div className="box-body">
                        <div className="panel panel-default panel-br-4">
                            <div className="panel-heading">
                                <div className="row">
                                    <div className="col-sm-12">
                                    </div>
                                </div>
                            </div>
                            <div className="panel-body">
                                {content}
                            </div>
                            <div className="panel-body">
                                {payTransactions}
                            </div>
                            <div className="panel-footer">
                            </div>
                            <div className="box-footer">
                                <div className="col-sm-2">
                                    <button onClick={handleCancel} className="btn btn-success">
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default OfficePayment