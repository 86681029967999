import React, { useState } from 'react';
import toast, { Toaster } from 'react-hot-toast'
import { searchTenant } from '../../../api/apiServices';

const ActivationRequests = () => {
	const [search, setSearch] = useState(false);
	const [member, setMember] = useState([]);
	const [pf_number, setPFNumber] = useState(null);
	const [mobile_number, setMobileNumber] = useState(null);
	const [first_name, setFirstName] = useState(null);
	const [last_name, setLastName] = useState(null);

	const handleSearch = async (e) => {
		e.preventDefault();

		const searchData = {
			"pf_number": pf_number,
			"mobile_number": mobile_number,
			"first_name": first_name,
			"last_name": last_name,
		}
		try {
			await searchTenant(searchData).then((res) => {
				if (res.length > 0) {
					setMember(res)
					setSearch(true)
				} else {
					toast.info("Member not found")
				}
			}).catch((err) => {
				toast.error("least one search parameter ")
				return false
			})
		} catch (err) {
			toast.error(err)
		}
	}

	return (
		<><section className="content-header">
			<Toaster toastOptions={{ position: "top-center" }} />
			<h1>Requested Activations Responses</h1>
		</section>
			<section className="content">
				<div className="box">
					<div className="box-body">
						<div className="panel panel-default panel-br-4">
							<div className="panel-heading">
								<div className="row">
									<div className="col-sm-6">
										<h4 className="panel-title">
											<i className="fa fa-file-text" aria-hidden="true"></i>
											<span> Activation Requests</span>
										</h4>
									</div>
								</div>
							</div>
							<div className="panel-body">
								<h4>No Activation Requests</h4>
							</div>
							<div className="panel-footer"></div>
						</div>
					</div>
					<div className="box-footer"></div>
				</div>
			</section></>
	)
}

export default ActivationRequests