import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { Link } from "react-router-dom";
import {
	getRoles,
	addRole,
	getRoleById,
	updateRole,
	deleteRole
} from '../../../api/apiServices';
import Pagination from '../../../services/Pagination'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import toast, { Toaster } from 'react-hot-toast'

const Roles = () => {

	const [add, setAdd] = useState(false);
	const [view, setView] = useState(false);
	const [update, setUpdate] = useState(false);

	const [state, setState] = useState({
		id: "",
		role_name: "",
	});
	const [role_data, setRoleData] = useState([]);

	const [currentPage, setCurrentPage] = useState(1);
	const [postPerPage, setPostPerPage] = useState(10);

	const queryClient = useQueryClient();

	const {
		isLoading,
		isError,
		error,
		data: roles,
	} = useQuery('roles', getRoles, {
		select: data => data.sort((a, b) => b.id - a.id)
	});

	const indexOfLastPage = currentPage * postPerPage;
	const indexOfFirstPage = indexOfLastPage - postPerPage;
	const currentPosts = roles?.slice(indexOfFirstPage, indexOfLastPage);

	const addRoleMutation = useMutation(addRole, {
		onSuccess: async (res) => {
			// Invalidate cached data
			queryClient.invalidateQueries("roles")
			toast.success(res.message, {
				duration: 5000,
			})
			setState({
				...state,
				id: "",
				role_name: ""
			});
			setAdd(false)
			setView(false)
			setUpdate(false)
		},
		onError: async (err) => {
			toast.error(err)
		}
	})

	const viewRoleMutation = useMutation(getRoleById, {
		onSuccess: async (res) => {
			setRoleData(res, {
				duration: 3000,
			})
		},
		onError: async (err) => {
			toast.error(err, {
				duration: 3000,
			})
		}
	})
	const updateRoleMutation = useMutation(updateRole, {
		onSuccess: async (res) => {
			// Invalidate cached data
			queryClient.invalidateQueries("roles")
			toast.success(res, {
				duration: 5000,
			})
			setState({
				...state,
				id: "",
				role_name: ""
			});
			setAdd(false)
			setView(false)
			setUpdate(false)
			setRoleData([])
		},
		onError: async (err) => {
			toast.error(err, {
				duration: 3000,
			})
		}
	})

	const deleteRoleMutation = useMutation(deleteRole, {
		onSuccess: async (res) => {
			if (!res) {
				toast.error("Sorry, you do not have the authorization to delete record", {
					duration: 3000,
				})
				return false
			}
			// Invalidate cached data
			toast.success(res, {
				duration: 3000,
			})
			queryClient.invalidateQueries("roles")
		},
		onError: async (err) => {
			toast.error(err, {
				duration: 3000,
			})
		}
	})

	const changeCase = (event) => {
		event.preventDefault();

	}
	const handleChange = (e) => {
		e.preventDefault();
		const value = e.target.value.toUpperCase()
		setState({
			...state,
			[e.target.name]: value
		})

	}
	const handleConfirm = (id) => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className='confirm-container'>
						<h3><i className='fa fa-warning'></i> Confirm Deletion</h3>
						<p>Are you sure you want to delete the role?</p>
						<br />
						<div className='confirm-buttons'>
							<button
								className="btn btn-danger"
								onClick={onClose}
							>
								No
							</button>
							&nbsp;&nbsp;&nbsp;&nbsp;
							<button
								className="btn btn-success"
								onClick={() => { deleteRoleMutation.mutate(id); onClose(); }}
							>
								Yes
							</button>
						</div>
					</div>
				);
			}
		});
	}

	const handleSubmit = (e) => {
		e.preventDefault();

		const roleData = {
			id: state.id,
			role_name: state.role_name,
		}
		add
			? addRoleMutation.mutate(roleData)
			: updateRoleMutation.mutate(roleData)
	}

	const handleCancel = () => {
		setAdd(false)
		setView(false)
		setUpdate(false)
		setRoleData([])
	}
	const handleAdd = () => {
		setAdd(true)
		setView(false)
		setUpdate(false)
		setRoleData([])
	}
	const handleView = (id) => {
		setState({
			...state,
			id: id,
		});
		setView(true)
		setAdd(false)
		setUpdate(false)
		viewRoleMutation.mutate(id);
	}

	const handleEdit = (id) => {
		setState({
			...state,
			id: id,
		});
		setUpdate(true)
		setAdd(false)
		setView(false)
		viewRoleMutation.mutate(id);
	}

	const addForm = (
		<>
			<tr>
				<td colSpan="2">
					<label htmlFor="role_name">Role Name</label>
					<abbr title="required" style={{ textTransform: "uppercase" }}
						aria-label="required"></abbr>
					<strong style={{ color: "red" }}>*</strong>
				</td>
				<td>
					<input
						type="text"
						id="role_name"
						name="role_name"
						autoComplete='off'
						onKeyUp={changeCase}
						required
						value={state.role_name}
						onChange={handleChange}
						className="form-control" />
				</td>
			</tr>
		</>
	)
	const updateForm = (
		<>
			<tr>
				<td colSpan="2">
					<label htmlFor="role_name">Role Name</label>
					<abbr title="required" style={{ textTransform: "uppercase" }}
						aria-label="required"></abbr>
					<strong style={{ color: "red" }}>*</strong>
				</td>
				<td>
					<input
						type="text"
						id="role_name"
						name="role_name"
						autoComplete='off'
						onKeyUp={changeCase}
						required
						defaultValue={role_data?.role_name}
						onChange={handleChange}
						className="form-control" />
				</td>
			</tr>
		</>
	)

	const viewForm = (
		<>
			<tr>
				<td colSpan="2">
					<label htmlFor="role_name">Role Name</label>
					<abbr title="required" style={{ textTransform: "uppercase" }}
						aria-label="required"></abbr>
					<strong style={{ color: "red" }}>*</strong>
				</td>
				<td>
					<input
						type="text"
						id="role_name"
						name="role_name"
						readOnly
						value={role_data?.role_name}
						className="form-control" />
				</td>
			</tr>
		</>
	)


	const userForm = (
		<>
			{add || view || update
				?
				<div className="box-body">
					<div className="col-md-12 col-sm-12 col-xs-12">
						<div className="panel panel-default panel-br-4">
							<div className="panel-heading">
								<h4 className="heading">
									{view
										? 'Role View'
										: update
											? 'Edit Role'
											: add
												? 'Add Role'
												: null
									}
								</h4>
							</div>
							<div className="panel-body">
								<form onSubmit={handleSubmit}>
									<div className="form-group">
										<div className="data-table">
											<table className="table table-borderless">
												<tbody>

													{view
														?
														<>
															{viewForm}
														</>
														: update
															?
															<>{updateForm}</>
															: add
																? <>{addForm}</>
																: null
													}
												</tbody>
											</table>
										</div>
									</div>
									{view
										?
										<div className="box-footer">
											<div className="col-xs-2">
												<button
													className="btn btn-success btn-block" onClick={handleCancel}>Exit
												</button>
											</div>
										</div>
										: update
											?
											<div className="box-footer">
												<div className="row">
													<div className="col-xs-2">
														<button type="submit"
															className="btn btn-success btn-block">Update</button>
													</div>
													<div className="col-xs-2">
														<button
															className="btn btn-success btn-block" onClick={handleCancel}>Cancel
														</button>
													</div>
												</div>
											</div>
											: add
												? <div className="box-footer">
													<div className="row">
														<div className="col-xs-2">
															<button type="submit"
																className="btn btn-success btn-block">Submit</button>
														</div>
														<div className="col-xs-2">
															<button
																className="btn btn-success btn-block" onClick={handleCancel}>Cancel
															</button>
														</div>
													</div>
												</div>
												: null
									}
								</form>
							</div>
						</div>
					</div>
				</div>
				: null}
		</>
	)

	let content
	if (isLoading) {
		content = <p>Loading...</p>
	} else if (isError) {
		content = <p>{error.message}</p>
	} else {
		content =
			<>
				<div className="box-body">
					<div className="col-md-12 col-sm-12 col-xs-12">
						<Link onClick={handleAdd} className='btn btn-success'>
							<i className='fa fa-plus'></i> Add Role
						</Link><br /><br />
						<div className="panel panel-default panel-br-4">
							<div className="panel-heading">
								<h4 className="heading">System Roles List
								</h4>
							</div>
							<div className="panel-body">
								<div className="table-responsive lg">
									<table className="table table-sm table-borderless table-hover table-striped">
										<thead>
											<tr>
												<th style={{ textAlign: 'center' }}>#</th>
												<th>Role Name</th>
												<th>Date Created</th>
												<th>Date Updated</th>
												<th>Action</th>
											</tr>
										</thead>
										<tbody>
											{currentPosts.map((item, index) =>
												<tr key={item.id}>
													<td>{postPerPage * (currentPage - 1) + index + 1}</td>
													<td style={{ whiteSpace: 'nowrap' }}>{item.role_name}</td>
													<td style={{ whiteSpace: 'nowrap' }}>{item.createdAt}</td>
													<td style={{ whiteSpace: 'nowrap' }}>{item.updatedAt}</td>
													<td style={{ whiteSpace: 'nowrap' }}>
														<div style={{ display: 'inline-block' }}>
															<Link onClick={() => handleView(item.id)} className="small"><i className='fa fa-eye'></i> view</Link>
														</div>&nbsp; &nbsp;
														<div style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
															<Link onClick={() => handleEdit(item.id)} className="small mr-3">
																<i className='fa fa-edit'></i> edit
															</Link>
														</div>
														&nbsp;&nbsp;
														<div style={{ display: 'inline-block' }}>
															<Link className="small" onClick={() => handleConfirm(item.id)}><i className='fa fa-trash'></i> delete</Link>
														</div>
													</td>
												</tr>
											)}
										</tbody>
									</table>
								</div>
							</div>
							<div className="box-footer">
								<div style={{ float: "left" }}>
									<Pagination
										data={roles ? roles : {}}
										postPerPage={postPerPage}
										currentPage={currentPage}
										setCurrentPage={setCurrentPage}
										setPostPerPage={setPostPerPage} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
	}
	return (
		<><section className="content-header">
			<Toaster toastOptions={{ position: "top-center" }} />
			<h1>System Roles</h1>
		</section>
			<section className="content">
				<div className="box">
					<div className="box-body">
						{userForm}
						{content}
					</div>
				</div>
			</section >
		</>
	);
};

export default Roles;
