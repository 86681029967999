import React from "react";
import { useQuery } from 'react-query';
import {
    getPaymentByTransactionId
} from "../../../../api/apiServices";
import './Receipt.css'
import { useLocation, useNavigate } from "react-router-dom";
import addCommas from "../../../../services/addCommas";
import './ReceiptTemplate.css'

const ReceiptTemplate = ( data  ) => {
   
    const transaction_id = data.transaction_id
    let receiptDate = new Date().toLocaleDateString("sv-SE")
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/payment_receipts";

    const {
        data: tenantInfo
    } = useQuery('tenantInfo', () => getPaymentByTransactionId(transaction_id));
   
    let tenant = tenantInfo?.tenant[0]
    let payments = tenantInfo?.payment[0]

    const handleCancel = () => {
        navigate(from, { replace: true });
    }

    const content = (
        <>
            <div className="top-title">Tenant Information</div>
            <div className="info-details">
                <div className="info-text">
                    <div>NAME : <strong>{tenant?.fullname}</strong></div>
                    <div>TENANT CODE. : <strong>{tenant?.tenant_code}</strong></div>
                    <div>CONTACT NUMBER : <strong>{tenant?.contact_number}</strong></div>
                    <div>EMAILL ADDRESS : <strong>{tenant?.email}</strong></div>
                </div>
                <div className="info-text">
                    <div>ZONE. : <strong>{tenant?.zone}</strong></div>
                    <div>LOCATION : <strong>{tenant?.land_location}</strong></div>
                    <div>GPS ADDRESS : <strong>{tenant?.residential_address}</strong></div>
                    <div>NATIONALITY : <strong>{tenant?.nationality}</strong></div>
                </div>
                <div className="info-text">
                    <div>LAND USE : <strong>{tenant?.land_use}</strong></div>
                    <div>NUMBER OF PLOTS : <strong>{tenant?.number_of_plots}</strong></div>
                    <div>DEEDS NO. : <strong>{tenant?.deeds_number}</strong></div>
                </div>
            </div>

            <div className="top-title">Payment Information</div>
            <div className="payment-details">
                <div className="payment-text">
                    <div>PAYMENT TYPE : <strong>{payments?.payment_type}</strong></div>
                    <div>PAYMENT MODE : <strong>{payments?.description}</strong></div>
                    <div>AMOUNT : <strong>{"GHS" + payments?.payment_amount}</strong></div>
                    <div>AMOUNT IN WORDS : <strong>{payments?.amount_to_words}</strong></div>
                </div>
                <div className="payment-text">
                    <div>PAYMENT DATE : <strong>{payments?.payment_date}</strong></div>
                    <div>RECEIPT NO. : <strong>{payments?.receipt_number}</strong></div>
                    <div>TRANSACTION ID. : <strong style={{whiteSpace: "pre-wrap"}}>{payments?.transaction_id}</strong></div>
                    <div>RECEIVED BY : <strong>{"NII AYIDZAKU TACKIE "}</strong></div>
                </div>
            </div>
            <div className="receipt-signature">
                <div className="sign">SIGNATURE</div>
                <img src="/images/signatures/signature.jpg" alt="signature" />
            </div>
        </>
    )

    return (
        <div className="recpt-wrapper">
            <div className="panel panel-default panel-br-4">
                <div className="panel-heading">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="first-container">
                                <div className="receipt-text">
                                    <h2>Receipt No:</h2>
                                    <h3>{payments?.receipt_number}</h3>
                                </div>
                                <div className="receipt-text">
                                    <h2>Receipt Date:</h2>
                                    <h3>{`${receiptDate}`}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="receipt-image">
                        <img src='/images/logos/niiodaintow2.jpg' alt='niiodaintow' />
                        <div className="name-title">NII ODAI NTOW CUSTOMARY LAND SECRETARIAT</div>
                        <div className="recpt-title">PAYMENT RECEIPT [ORIGINAL]</div>
                    </div>
                </div>
                <div className="panel-body">
                    {content}
                </div>
            </div>
        </div>
    )
}

export default ReceiptTemplate