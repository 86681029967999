import useUpdateContext from "../../../../hooks/useUpdateContext"
import PersonalInformation from "./PersonalInformation"
import ContactInformation from "./ContactInformation"
import NextOfKin from "./NextOfKin"
import ContactPerson from "./ContactPerson"

const UpdateFormInputs = () => {

    const { page } = useUpdateContext()
   
    const display = {
        0: <PersonalInformation />,
        1: <ContactInformation />,
        2: <NextOfKin />,
        3: <ContactPerson />,
    }

    const content = (
        <div className="form-inputs flex-col">
            {display[page]}
        </div>

    )

    return content
}
export default UpdateFormInputs