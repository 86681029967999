import React, { useState } from 'react'
import car from './img-5.jpg';
import {
    useJsApiLoader,
    GoogleMap,
    Marker,
    InfoWindow,
} from "@react-google-maps/api";
import { useNavigate } from 'react-router-dom';


const center = {
    lat: 5.656265,
    lng: -0.233847,
};
const APIKEY = `${process.env.REACT_APP_GOOGLEMAP_APIKEY}`

const LocationTracker = ({ location }) => {

      const navigate = useNavigate();

    const handleExit = () => {
        navigate(-1);
    }


    const currentLocation = {
        address: location?.address,
        lat: location?.lat,
        lng: location?.lng,
    }

    
    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: APIKEY,// `AIzaSyAAFBEYl6VnHSyllckphcRWzJlomsoXosA`,
    });
    
    return (
        isLoaded ?
        <>
            <section className="content-header">
                <h1>Location View</h1>
            </section>
            <section className="content">
                <div className="box">
                    <div className="box-body">
                        <div className="panel panel-default panel-br-4">
                            <div className="panel-heading">
                                <div className="row">
                                    <div className="col-sm-10">
                                        <h4 className="panel-title">
                                            Tenant Location View - {location.address}
                                        </h4>
                                        </div>
                                        <div className="col-sm-2">
                                            
                                    <button type='button' className='btn btn-success' onClick={handleExit}>Exit Location View</button>
                                           </div>
                                    </div>
                            </div>
                            <div className="panel-body">
                                <GoogleMap
                                    center={center}
                                    zoom={12}
                                    mapContainerStyle={{ width: "100%", height: "100vh" }}
                                    options={{
                                        zoomControl: true,
                                        streetViewControl: true,
                                        mapTypeControl: true,
                                        fullscreenControl: true,
                                    }}
                                >
                                        <Marker
                                            position={currentLocation}
                                            label={location.address}
                                        >
                                    </Marker>
                                </GoogleMap>
                            </div>
                            <div className="panel-footer">
                                {/* <div className="location-view">
                                    <button type='button' className='btn btn-success' onClick={handleExit}>Exit Location View</button>
                                    <button type='button' className='btn btn-danger' onClick={handleSatelliteView}>Satellite Location View</button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    : 
        null
    );
}

export default LocationTracker