import React, { useContext } from 'react'
import AuthContext from "../../../context/AuthProvider";
import jwt_decode from "jwt-decode";

import { SuperAdminSidebarData } from './SuperAdminSidebarData';
import { SystemAdminSidebarData } from './SystemAdminSidebarData';
import { DirectorSidebarData } from './DirectorSidebarData';
import { OperationsSidebarData } from './OperationsSidebarData';
import { AccountsSidebarData } from './AccountsSidebarData';
import { LegalSidebarData } from './LegalSidebarData';
import { FrontDeskSidebarData } from './FrontDeskSidebarData';
import { Link } from 'react-router-dom';

const AdminSidebar = ({ allowedRoles }) => {
    let menuItems = []
    
    const { auth } = useContext(AuthContext)

    const decoded = auth?.accessToken
        ? jwt_decode(auth.accessToken)
        : undefined

    const role = decoded?.UserInfo?.role || []

    role === allowedRoles.SuperAdmin
        ? menuItems = SuperAdminSidebarData
        : role === allowedRoles.SystemAdmin
            ? menuItems = SystemAdminSidebarData
            : role === allowedRoles.Director
                ? menuItems = DirectorSidebarData
                : role === allowedRoles.OperationsMgr
                    ? menuItems = OperationsSidebarData
                    : role === allowedRoles.AccountsMgr
                        ? menuItems = AccountsSidebarData
                        : role === allowedRoles.LegalMgr
                            ? menuItems = LegalSidebarData
                            : role === allowedRoles.FrontDeskUser
                                ? menuItems = FrontDeskSidebarData
                                : menuItems = [{}]

    return (
        <div className="main-sidebar">
            <section className="sidebar">
                <ul className="sidebar-menu">
                    {menuItems?.map((item, index) =>
                        !item.subNav
                            ?
                            <li className="treeview" key={index}>
                                <Link to={item.path}>
                                    <span>{item.title}</span>
                                </Link>
                            </li>
                            :
                            <li className="treeview" key={index}>
                                <Link item={item.path}>
                                    <span>{item.title}</span>
                                    <span className="pull-right-container">
                                        <i className="fa fa-plus pull-right"></i>
                                    </span>
                                </Link>
                                <ul className="treeview-menu">
                                    {item.subNav.map((item, index) =>
                                        !item.subNav
                                            ?
                                            <li className="treeview" key={index}>
                                                <Link to={item.path}>
                                                    <i className="fa fa-minus"></i>
                                                    <span>{item.title}</span>
                                                </Link>
                                            </li>
                                            :
                                            <li className="treeview" key={index}>
                                                <Link item={item.path}>
                                                    <i className="fa fa-minus"></i>
                                                    <span>{item.title}</span>
                                                    <span className="pull-right-container">
                                                        <i className="fa fa-plus pull-right"></i>
                                                    </span>
                                                </Link>
                                                <ul className="treeview-menu">
                                                    {item.subNav.map((item, index) =>
                                                        <li className="treeview" key={index}>
                                                            <Link to={item.path}>
                                                                <i className="fa fa-minus"></i>
                                                                {item.title}
                                                            </Link>
                                                        </li>
                                                    )}
                                                </ul>
                                            </li>
                                    )}
                                </ul>
                            </li>
                    )}
                </ul>
            </section>
        </div >
    )
}

export default AdminSidebar


