import useSWR from 'swr'

import {
    getBankById,
    getBranchesByBankId,
    // banksUrlEndpoint as banksCacheKey,
    bankByIdUrlEndpoint as bankByIdCacheKey,
    branchesUrlEndpoint as branchesCacheKey
} from '../../../api/apiBankServices';

import Branch from './Branch';

const BranchesList = ({ currentBankId }) => {

    const {
        data: bankBranches,
    } = useSWR(
        [branchesCacheKey, currentBankId],
        ([url, BankId]) => getBranchesByBankId(url, BankId),
        { suspense: true }
    )

    const {
        data: bank
    } = useSWR(
        bankBranches?.branches?.length ? [bankByIdCacheKey, currentBankId] : null,
        ([url, bankId]) => getBankById(url, bankId),
        { suspense: true }
        )
    
    const content = (
        <main>
            {bankBranches?.branches.map(branches => {
                return <Branch key={branches.id} branches={branches} bank={bank} />
            })}
        </main>
    )

    return content
}
export default BranchesList