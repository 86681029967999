import useGeneralSearchContext from "../../../hooks/useGeneralSearchContext"
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

const GeneralSearch = () => {

    const {
        searchResult,
        handleSearch,
        handleChange,
        handlePhoneChange,
        handlePhoneInputName
    } = useGeneralSearchContext()

    let searchContent = (
        <>
            <form onSubmit={handleSearch}>
                <div className="form-group row">
                    <fieldset className="col-sm-12">
                        <div className="col-sm-2">
                            <input
                                type="text"
                                style={{ textTransform: "uppercase", height: "40px", borderRadius: "5px" }}
                                placeholder="Tenant Code"
                                onChange={handleChange}
                                value={searchResult.tenant_code}
                                name="tenant_code"
                                className="form-control"
                            />
                        </div>
                        <div className="col-sm-2">
                            <input
                                type="text"
                                style={{ textTransform: "uppercase", height: "40px", borderRadius: "5px" }}
                                placeholder="Indenture Number"
                                onChange={handleChange}
                                value={searchResult.indenture_number}
                                name="indenture_number"
                                className="form-control"
                            />
                        </div>
                        <div className="col-sm-3">
                            <PhoneInput
                                type="tel"
                                id="contact_number"
                                inputStyle={{ width: '100%', height: "20px" }}
                                country={"gh"}
                                placeholder="contact_number"
                                enableSearch={true}
                                value={searchResult.contact_number}
                                name="contact_number"
                                onChange={(e) => handlePhoneChange(e)}
                                onKeyDown={(e) => handlePhoneInputName(e)}
                            />
                        </div>
                        <div className="col-sm-2">
                            <input
                                type="text"
                                placeholder="FirstName"
                                style={{ textTransform: "uppercase", height: "40px", borderRadius: "5px" }}
                                onChange={handleChange}
                                value={searchResult.first_name}
                                name="first_name"
                                className="form-control"
                            />
                        </div>
                        <div className="col-sm-2">
                            <input
                                className="form-control"
                                type="text"
                                placeholder="LastName"
                                style={{ textTransform: "uppercase", height: "40px", borderRadius: "5px" }}
                                onChange={handleChange}
                                name="last_name"
                                value={searchResult.last_name}
                            />
                        </div>
                        <div className="col-sm-1">
                            <button
                                type="submit"
                                className="btn btn-success "
                            > <i className="fa fa-search"></i> Search
                            </button>
                        </div>
                    </fieldset>
                </div>
            </form>
        </>

    )
    return searchContent
}
export default GeneralSearch