import { useState, useRef, useEffect } from "react";
import useUpdateContext from "../../../../hooks/useUpdateContext"
import useGeneralSearchContext from "../../../../hooks/useGeneralSearchContext"
import { useQuery } from 'react-query';
import {
    getTitles,
    getNationality,
    getIdentification,
} from "../../../../api/apiServices";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

const PersonalInformation = () => {
    
    const inputRef = useRef();

    useEffect(() => {
        inputRef.current.focus();
    }, [])
    const {
        searchResult
    } = useGeneralSearchContext()

    let tenantData = []
    searchResult?.tenant?.map(tenant => {
        tenantData.push({
            persTitle: tenant.title,
            persLastname: tenant.last_name,
            persFirstname: tenant.first_name,
            persMiddlename: tenant.middle_name,
            persGender: tenant.gender,
            persDOB: tenant.date_of_birth,
            persNationality: tenant.nationality,
            persOccupation: tenant.occupation,
            persSpouseContactNumber: tenant.spouse_contact_number,
            persIdentificationType: tenant.identification_type,
            persIdNumber: tenant.id_number,
            persDateIssued: tenant.date_issued,
            persExpiryDate: tenant.expiry_date,
            persMaritalStatus: tenant.marital_status,
            persPhoto: tenant.photo,
        })
    })

    const { handleChange} = useUpdateContext()

    const [image, setImage] = useState();
    function handleImage(e) {
        if (e.target.files.length !== 0) {
            setImage(URL.createObjectURL(e.target.files[0]));
        }
    }
    const {
        data: titles,
    } = useQuery('titles', getTitles);
    const {
        data: citizenship,
    } = useQuery('nationality', getNationality);

    const {
        data: identity,
    } = useQuery('identity', getIdentification);

    const content = (
        <>
            <div className="tenant-photo">
                <img src={image} alt='' />
            </div>
            <div className="form-group row">
                <fieldset className="col-sm-6">
                    <div className="form-group row">
                        <label htmlFor="current_title" className="col-sm-3 col-form-label">Current Title<span>&nbsp;&nbsp;*</span></label>
                        <div className="col-sm-9">
                            <input
                                type="text"
                                id="current_title"
                                name="current_title"
                                readOnly
                                value={tenantData[0].persTitle}
                                className='form-control'
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="tenant_title" className="col-sm-3 col-form-label">New Title ?<span>&nbsp;&nbsp;*</span></label>
                        <div className="col-sm-9">
                            <select
                                type="text"
                                id="tenant_title"
                                autoComplete="off"
                                style={{ textTransform: "uppercase" }}
                                onChange={handleChange}                                
                                defaultValue={tenantData[0].persTitle}
                                name="persTitle"
                                className='form-control'
                            >
                                <option value="">Select Option</option>
                                {titles?.map((item, index) =>
                                    <option value={item.title} key={index}>
                                        {item.title}
                                    </option>
                                )}
                            </select>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="last_name" className="col-sm-3 col-form-label">Last Name<span>&nbsp;&nbsp;*</span></label>
                        <div className="col-sm-9">
                            <input
                                ref={inputRef}
                                type="text"
                                placeholder="Last Name"
                                id="last_name"
                                autoComplete="off"
                                onChange={handleChange}                                
                                style={{ textTransform: "uppercase" }}
                                defaultValue={tenantData[0].persLastname}
                                name="persLastname"
                                className='form-control'
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="middle_name" className="col-sm-3 col-form-label">Middle Name</label>
                        <div className="col-sm-9">
                            <input
                                type="text"
                                placeholder="Middle Name"
                                id="middle_name"
                                autoComplete="off"
                                onChange={handleChange}                                
                                style={{ textTransform: "uppercase" }}
                                defaultValue={tenantData[0].persMiddlename}
                                name="persMiddlename"
                                className='form-control'
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="first_name" className="col-sm-3 col-form-label">First Name<span>&nbsp;&nbsp;*</span></label>
                        <div className="col-sm-9">
                            <input
                                type="text"
                                placeholder="First Name"
                                id="first_name"
                                autoComplete="off"
                                onChange={handleChange}                                
                                style={{ textTransform: "uppercase" }}
                                defaultValue={tenantData[0].persFirstname}
                                name="persFirstname"
                                className='form-control'
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="gender" className="col-sm-3 col-form-label">Gender<span>&nbsp;&nbsp;*</span></label>
                        <div className="col-sm-9">
                            <input
                                type="text"
                                id="gender"
                                autoComplete="off"
                                style={{ textTransform: "uppercase" }}
                                onChange={handleChange}                                
                                readOnly
                                defaultValue={tenantData[0].persGender}
                                name="persGender"
                                className='form-control'
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="date_of_birth" className="col-sm-3 col-form-label">Date of Birth<span>&nbsp;&nbsp;*</span></label>
                        <div className="col-sm-9">
                            <input
                                type="text"
                                id="date_of_birth"
                                autoComplete="off"
                                onChange={handleChange}                                
                                readOnly
                                defaultValue={tenantData[0].persDOB}
                                name="persDOB"
                                className='form-control'
                            />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label htmlFor="nationality" className="col-sm-3 col-form-label">Nationality<span>&nbsp;&nbsp;*</span></label>
                        <div className="col-sm-9">
                            <input
                                type="text"
                                id="nationality"
                                autoComplete="off"
                                readOnly
                                style={{ textTransform: "uppercase" }}
                                onChange={handleChange}                                
                                defaultValue={tenantData[0].persNationality}
                                name="persNationality"
                                className='form-control'
                            />
                        </div>
                    </div>
                </fieldset>
                <fieldset className="col-sm-6">
                    <div className="form-group row">
                        <label htmlFor="spouse_contact_number" className="col-sm-3 col-form-label">Spouse Contact Number<span>&nbsp;&nbsp;*</span></label>
                        <div className="col-sm-9">
                            <input
                                type="number"
                                id="spouse_contact_number"
                                required
                                defaultValue={tenantData[0].persSpouseContactNumber}
                                name="persSpouseContactNumber"
                                onChange={handleChange}                                
                                className='form-control'
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="identification_type" className="col-sm-3 col-form-label">Identification Type<span>&nbsp;&nbsp;*</span></label>
                        <div className="col-sm-9">
                            <input
                                type="text"
                                id="identification_type"
                                autoComplete="off"
                                readOnly
                                onChange={handleChange}                                
                                style={{ textTransform: "uppercase" }}
                                defaultValue={tenantData[0].persIdentificationType}
                                name="persIdentificationType"
                                className='form-control'
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="id_number" className="col-sm-3 col-form-label">ID Number<span>&nbsp;&nbsp;*</span></label>
                        <div className="col-sm-9">
                            <input
                                type="text"
                                id="id_number"
                                placeholder='ID Number'
                                autoComplete="off"
                                readOnly
                                style={{ textTransform: "uppercase" }}
                                onChange={handleChange}                                
                                defaultValue={tenantData[0].persIdNumber}
                                name="persIdNumber"
                                className='form-control'
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="date_issued" className="col-sm-3 col-form-label">Date Issued<span>&nbsp;&nbsp;*</span></label>
                        <div className="col-sm-9">
                            <input
                                type="date"
                                id="date_issued"
                                autoComplete="off"
                                readOnly
                                onChange={handleChange}                                
                                defaultValue={tenantData[0].persDateIssued}
                                name="persDateIssued"
                                className='form-control'
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="expiry_date" className="col-sm-3 col-form-label">Expiry Date<span>&nbsp;&nbsp;*</span></label>
                        <div className="col-sm-9">
                            <input
                                type="date"
                                id="expiry_date"
                                readOnly
                                autoComplete="off"
                                onChange={handleChange}                                
                                defaultValue={tenantData[0].persExpiryDate}
                                name="persExpiryDate"
                                className='form-control'
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="marital_status" className="col-sm-3 col-form-label">Marital Status<span>&nbsp;&nbsp;*</span></label>
                        <div className="col-sm-9">
                            <select
                                type="text"
                                id="marital_status"
                                autoComplete="off"
                                onChange={handleChange}                                
                                defaultValue={tenantData[0].persMaritalStatus}
                                style={{ textTransform: "uppercase" }}
                                name="persMaritalStatus"
                                className='form-control'
                            >
                                <option value="">Select Option</option>
                                <option value="Single">Single</option>
                                <option value='Married'>Married</option>
                                <option value='Divorced'>Divorced</option>
                                <option value='Separated'>Separated</option>
                                <option value='Widowed'>Widowed</option>
                                <option value='Prefer Not to Say'>Prefer Not to Say</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="occupation" className="col-sm-3 col-form-label">Occupation<span>&nbsp;&nbsp;*</span></label>
                        <div className="col-sm-9">
                            <input
                                type="text"
                                placeholder="Occupation"
                                id="occupation"
                                autoComplete="off"
                                style={{ textTransform: "uppercase" }}
                                onChange={handleChange}                                
                                defaultValue={tenantData[0].persOccupation}
                                name="persOccupation"
                                className='form-control'
                            />
                        </div>
                    </div>
                </fieldset>
            </div>
        </>
    )

    return content
}
export default PersonalInformation