import React, { useState, useRef } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import {
    getPaymentModes,
    getBankBranchBySortcode,
    savePayment
} from "../../../../api/apiServices";
import { useQuery, useMutation } from 'react-query';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import toast, { Toaster } from 'react-hot-toast'
import useSpecialSearchContext from "../../../../hooks/useSpecialSearchContext"
import SpecialSearch from "../../../Common/Search/SpecialSearch";
import generateTransactionId from "../../../../services/generateTransactionId";
import addCommas from "../../../../services/addCommas";
import PaymentReceipt from "./PaymentReceipt.js";

const GroundRents = () => {
    const year = 1990;
    const years = Array.from(new Array(40), (val, index) => index + year)

    let tenant_id
    let transaction_id = ""
    const [paySuccess, setPaySuccess] = useState(false);
    const [paymentData, setPaymentData] = useState([]);

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/receive_payments";

    const [payment_year, setPaymentYear] = useState("");
    const [payment_type, setPaymentType] = useState("GROUND RENT");
    const [payment_amount, setPaymentAmount] = useState("");
    const [payment_mode, setPaymentMode] = useState("");

    const [isChequeAdvice, setIsChequeAdvice] = useState(false);
    const [cheque_advice_number, setChequeAdviceNumber] = useState("")
    const [bank_branch_name, setBankBranchName] = useState("")
    const [cheque_advice_date, setChequeAdviceDate] = useState("")

    const { searchResult, setSearchResult, handleCancel } = useSpecialSearchContext()
    const tenant = searchResult?.tenant
    let tenantCode = tenant[0]?.tenant_code;
    let tenantData = []
    for (var i = 0; i < tenant?.length; i++) {
        tenant_id = tenant[i]?.id;
        tenantData.push({
            payment_type: payment_type,
            payment_amount: payment_amount,
            first_name: tenant[i]?.first_name.toLocaleUpperCase(),
            contact_number: tenant[i].contact_number,
            email: tenant[i].email,
        })
    }

    const {
        data: paymentMode,
    } = useQuery('paymentMode', () => getPaymentModes());

    const saveMutation = useMutation(savePayment, {
        onSuccess: async (res) => {
            if (res.status === true) {
                toast.success(res.message, {
                    duration: 5000
                });
                setPaymentData({
                    year: payment_year,
                    first_name: tenant[0]?.first_name.toLocaleUpperCase(),
                    email: tenant[0].email,
                    transaction_id: res.transaction_id,
                    receipt_number: res.receipt_number,
                    payment_type: payment_type,
                    payment_amount: addCommas(payment_amount),
                });
                setPaySuccess(true)
                setSearchResult(prevData => ({
                    ...prevData,
                    tenant: [],
                    search: false,
                    tenant_code: "",
                    contact_number: "",
                    indenture_number: "",
                }))
                setPaymentYear("");
                setPaymentType("");
                setPaymentMode("");
                setPaymentAmount("");
            } else {
                toast.error(res.message, {
                    duration: 5000
                })
            }
        },
        onError: async (err) => {
            toast.error(err.message, {
                duration: 5000,
            })
        }
    })

    const handlePaymentMode = e => {
        const value = e.target.value
        if (value === "CHEQUE" || value.replace(" ", "_") === "BANKERS_DRAFT") {
            setPaymentMode(value)
            setIsChequeAdvice(true)
        } else {
            setPaymentMode(value)
            setIsChequeAdvice(false)
        }
    }
    const handleChequeNumber = (e) => {
        const value = e.target.value
        let bankSortcode = value.substring(0, 6)
        getBankBranchBySortcode(bankSortcode).then((bank) => {
            setBankBranchName(bank.branch_name)
        })
    }

    const handleOnCancel = () => {
        setSearchResult(prevData => ({
            ...prevData,
            tenant: [],
            search: false,
            tenant_code: "",
            contact_number: "",
            indenture_number: "",
        }))
        navigate(from, { replace: true });
    }

    const handleConfirm = (e) => {
        e.preventDefault();
        generateTransactionId().then((result) => {
            transaction_id = result
        })

        if (payment_mode === "") {
            toast.error("Payment mode is required. Select Payment Mode to continue", {
                duration: 5000
            })
            return false;
        }
        else {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className='custom-ui'>
                            <div>
                                <div style={{ textAlign: 'center' }}><h3>Payment Confirmation</h3></div>
                            </div>

                            <table className="table table-borderless">

                                <tbody>
                                    <tr key={tenant[0]?.id}>
                                        <td>Full Name</td>
                                        <td >{tenant[0]?.title} {tenant[0]?.first_name} {tenant[0]?.middle_name} {tenant[0]?.last_name}</td>
                                    </tr>
                                    <tr>
                                        <td>Tenant Code</td>
                                        <td>{tenant[0]?.tenant_code}</td>
                                    </tr>
                                    <tr>
                                        <td>Contact Number</td>
                                        <td>{tenant[0]?.contact_number}</td>
                                    </tr>
                                    <tr>
                                        <td>Payment Type</td>
                                        <td >{payment_type}</td>
                                    </tr>
                                    <tr>
                                        <td>Payment Amount</td>
                                        <td>{addCommas(payment_amount).toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                                    </tr>
                                    <tr>
                                        <td>Mode of Payment</td>
                                        <td>{payment_mode}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <br />
                            <div>
                                <button
                                    onClick={() => { onClose(); handleSubmit(); }} className="btn btn-success"
                                >
                                    Confirm
                                </button>&nbsp;&nbsp;&nbsp;&nbsp;
                                <button className="btn btn-danger" onClick={onClose}> Cancel
                                </button>
                            </div>
                        </div>
                    );
                }
            });
        }
    }

    const handleSubmit = (e) => {
        const transData = {
            tenant_id: tenant_id,
            tenant_code: tenantCode,
            contact_number: tenant[0].contact_number,
            indenture_number: tenant[0].indenture_number,
            year: payment_year,
            transaction_id: transaction_id,
            payment_type: payment_type,
            payment_mode: payment_mode.toLocaleUpperCase(),
            payment_amount: parseFloat(payment_amount),
            cheque_advice_number: cheque_advice_number,
            bank_branch_name: bank_branch_name,
            cheque_advice_date: cheque_advice_date,
        }
        saveMutation.mutate(transData)
    }


    const tenantDetails = searchResult?.search && tenant.length > 0 ?
        <div className="col-md-12 col-sm-12 col-xs-12">
            <legend style={{ color: "white" }}>Personal Information</legend>
            <div className="table-responsive-lg">
                <table className="table table-borderless">
                    {tenant.map((mem, index) =>
                        <tbody>
                            <tr key={index + 1}>
                                <td >Name</td>
                                <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "left", whiteSpace: "pre-wrap" }}> {mem.title} {mem.first_name} {mem.middle_name} {mem.last_name}</td>
                                <td >Zone</td>
                                <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "left", whiteSpace: "pre-wrap" }}> {mem.zone_code}</td>
                            </tr>
                            <tr>
                                <td >Tenant Code</td>
                                <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "left", whiteSpace: "pre-wrap" }}> {mem.tenant_code}</td>
                                <td >Land Location</td>
                                <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "left", whiteSpace: "pre-wrap" }}> {mem.land_location}</td>
                            </tr>
                            <tr>
                                <td >Contact Number</td>
                                <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "left", whiteSpace: "pre-wrap" }}> {mem.contact_number}</td>
                                <td >Residential Address</td>
                                <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "left", whiteSpace: "pre-wrap" }}> {mem.residential_address}</td>
                            </tr>
                        </tbody>
                    )}
                </table>
            </div>
        </div>
        : null

    const otherPayments = (
        <>
            <legend>Receive Payment</legend>
            <form onSubmit={handleConfirm}>
                <div className="form-group row">
                    <fieldset className="col-sm-6">
                        <div className="form-group row">
                            <label htmlFor="payment_type" className="col-sm-3">Payment Type</label>
                            <div className="col-sm-9">
                                <input
                                    type="text"
                                    id="payment_type"
                                    autoComplete="off"
                                    style={{ fontWeight: "bold", textTransform: "capitalize" }}
                                    onChange={(e) => setPaymentType(e.target.value)}
                                    required
                                    disabled
                                    value={payment_type}
                                    name="payment_type"
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="payment_year" className="col-sm-3">Year</label>
                            <div className="col-sm-9">
                                <select
                                    type="text"
                                    id="payment_year"
                                    autoComplete="off"
                                    style={{ textTransform: "capitalize" }}
                                    onChange={(e) => setPaymentYear(e.target.value)}
                                    required
                                    value={payment_year}
                                    name="payment_year"
                                    className="form-control"
                                >
                                    <option>Select Year</option>
                                    {years.map((year, index) => <option value={year} key={`year${index}`}>{year}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="pf_number" className="col-sm-3">Payment Amount</label>
                            <div className="col-sm-9">
                                <input
                                    type="number"
                                    id="payment_amount"
                                    autoFocus
                                    autoComplete="off"
                                    onChange={(e) => setPaymentAmount(e.target.value)}
                                    value={payment_amount}
                                    required
                                    name="payment_amount"
                                    className="form-control" />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="pf_number" className="col-sm-3">Payment Mode</label>
                            <div className="col-sm-9">
                                <select
                                    type="text"
                                    id="payment_mode"
                                    placeholder='payment_mode'
                                    autoComplete="off"
                                    style={{ textTransform: "capitalize" }}
                                    onChange={handlePaymentMode}
                                    required
                                    value={payment_mode}
                                    name="payment_mode"
                                    className="form-control"
                                >
                                    <option>Select Option</option>
                                    {paymentMode?.map(mode =>
                                        <option key={mode.id} value={mode.description}>
                                            {mode.description}
                                        </option>
                                    )}
                                </select>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset className="col-sm-6">
                        {isChequeAdvice ?
                            <>
                                <div className="form-group row">
                                    <label htmlFor="cheque_advice_number" className="col-sm-3 col-form-label">Cheque/Advice Number<span>&nbsp;&nbsp;*</span></label>
                                    <div className="col-sm-9">
                                        <input
                                            type="text"
                                            placeholder="Cheque/Advice Number"
                                            id="cheque_advice_number"
                                            autoComplete="off"
                                            onBlur={handleChequeNumber}
                                            onChange={(e) => setChequeAdviceNumber(e.target.value)}
                                            style={{ textTransform: "uppercase" }}
                                            value={cheque_advice_number}
                                            name="cheque_advice_number"
                                            className='form-control' />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="bank_branch_name" className="col-sm-3 col-form-label">Bank Name<span>&nbsp;&nbsp;*</span></label>
                                    <div className="col-sm-9">
                                        <input
                                            type="text"
                                            id="bank_branch_name"
                                            autoComplete="off"
                                            style={{ textTransform: "uppercase" }}
                                            onChange={(e) => setBankBranchName(e.target.value)}
                                            disabled
                                            value={bank_branch_name}
                                            name="bank_branch_name"
                                            className='form-control'
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="cheque_advice_date" className="col-sm-3 col-form-label">Cheque Date<span>&nbsp;&nbsp;*</span></label>
                                    <div className="col-sm-9">
                                        <input
                                            type="date"
                                            id="cheque_advice_date"
                                            autoComplete="off"
                                            onChange={(e) => setChequeAdviceDate(e.target.value)}
                                            style={{ textTransform: "uppercase" }}
                                            value={cheque_advice_date}
                                            name="cheque_advice_date"
                                            className='form-control' />
                                    </div>
                                </div>
                            </>
                            : null}
                    </fieldset>
                </div>
                <div className="panel-footer">
                    <button className="btn btn-success">
                        Submit
                    </button>&nbsp;&nbsp;&nbsp;&nbsp;
                    <button type="button" className="btn btn-danger" onClick={handleOnCancel}>
                        Cancel
                    </button>
                </div>
            </form>
        </>
    )

    return (
        <>
            <section className="content-header">
                <Toaster toastOptions={{ position: "top-center" }} />
                <h1>Ground Rents</h1>
            </section>
            <section className="content">
                <div className="box">
                    <div className="box-body">
                        {paySuccess ?
                            <PaymentReceipt data={paymentData}/>
                            :
                            <div className="panel panel-default panel-br-4">
                                <div className="panel-heading">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <SpecialSearch />
                                        </div>
                                    </div>
                                </div>
                                <br />
                                {searchResult?.search && tenant.length > 0 ?
                                    <>
                                        <div className="panel-heading">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    {tenantDetails}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="panel-body">
                                            {otherPayments}
                                        </div>
                                    </>
                                    : null}
                            </div>
                        }
                        <div className="box-footer"></div>
                    </div>
                </div>
            </section >
        </>
    );
}

export default GroundRents;


