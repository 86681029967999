const ErrorFallback = ({ error, resetErrorBoundary }) => {
    return (
        <>
            <section className="content-header">
            </section>
            <section className="content">
                <div className="panel panel-success panel-br-4">
                    <div className="panel-heading">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4 className="panel-title">
                                    <i className="fa fa-file-text" aria-hidden="true"></i>
                                    <span>Oops!!!</span>
                                </h4>
                            </div>
                        </div>
                    </div>

                    <div className="panel-body">
                        <div className="error">
                            <p>Something went wrong</p>
                            <pre>{error.message}</pre>
                        </div>
                    </div>
                    <div className="panel-footer">
                        <button className="btn btn-danger" onClick={resetErrorBoundary}>Try again</button>
                    </div>
                </div>
            </section>
        </>
    )
}
export default ErrorFallback