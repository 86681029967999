const Branch = ({ branches, bank }) => {
    // Error Example
    // if (bank.id === 2) throw new Error('Error #2')
    return (
        <article className="branch">
            <h4>Branch Name: {branches.branch_name}</h4>
            <p>Branch ID: {branches.id}</p>
            <p>{bank.Joe}</p>
            <p>Branch SortCode: {branches.branch_sortcode}</p>
            <p>Date Created: {branches.createdAt}</p>
            <p>Date Updated: {branches.updatedAt}</p><br/>
        </article>
    )
}
export default Branch