import useRegistrationContext from "../../../hooks/useRegistrationContext"
import { useQuery } from 'react-query';
import {
    getRelationship
} from "../../../api/apiServices";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { useEffect } from "react";

const ContactPerson = () => {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    const { data, handleOnPhoneInput, handleChange, handlePhoneInput } = useRegistrationContext()
    const {
        data: relation,
    } = useQuery('relation', () => getRelationship());

    const content = (
        <>
            <div className="form-group row">
                <fieldset className="col-sm-6">
                    <div className="form-group row">
                        <label htmlFor="contact_person" className="col-sm-4 col-form-label">Contact Person/Reference<span>&nbsp;&nbsp;*</span></label>
                        <div className="col-sm-8">
                            <input
                                type="text"
                                id="contact_person"
                                placeholder='Contact Person or Reference'
                                autoComplete="off"
                                style={{
                                    height: "40px",
                                    textTransform: "uppercase",
                                }}
                                onChange={handleChange}
                                required
                                value={data.refpContactPerson}
                                name="refpContactPerson"
                                className='form-control'
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="contact_telephone" className="col-sm-4 col-form-label">Contact Telephone<span>&nbsp;&nbsp;*</span></label>
                        <div className="col-sm-8">
                            <PhoneInput
                                type="tel"
                                id="contact_telephone"
                                inputStyle={{ width: '100%', height: "34px" }}
                                placeholder='refpContactNumber'
                                country={"gh"}
                                enableSearch={true}
                                value={data.refpContactNumber}
                                name="refpContactNumber"
                                onChange={(e) => handlePhoneInput(e)}
                                onKeyDown={(e) => handleOnPhoneInput(e)}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="contact_address" className="col-sm-4 col-form-label">Address /GPS<span>&nbsp;&nbsp;*</span></label>
                        <div className="col-sm-8">
                            <input
                                type="text"
                                id="contact_address"
                                placeholder='Contact Person / Reference Address'
                                autoComplete="off"
                                style={{
                                    height: "40px",
                                    textTransform: "uppercase",
                                }}
                                onChange={handleChange}
                                required
                                value={data.refpContactAddress}
                                name="refpContactAddress"
                                className='form-control'
                            />
                            <div className="input-info">GPS Address: GE-155-7289</div>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="contact_relationship" className="col-sm-4 col-form-label">Relationship<span>&nbsp;&nbsp;*</span></label>
                        <div className="col-sm-8">
                            <select
                                type="text"
                                id="contact_relationship"
                                placeholder='Relationship'
                                autoComplete="off"
                                style={{
                                    height: "40px",
                                    textTransform: "uppercase",
                                }}
                                onChange={handleChange}
                                required
                                value={data.refpRelationship}
                                name="refpRelationship"
                                className='form-control'
                            >
                                <option>Select Option</option>
                                {relation?.map((item) =>
                                    <option key={item.id} value={item.relationship}>{item.relationship}</option>)}
                            </select>
                        </div>
                    </div>
                </fieldset>
            </div>
        </>
    )

    return content
}
export default ContactPerson