import useRegistrationContext from "../../../hooks/useRegistrationContext"
import { useQuery } from 'react-query';
import {
    getRelationship
} from "../../../api/apiServices";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { useEffect } from "react";

const NextOfKin = () => {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    const { data, handleOnPhoneInput, handleChange, handlePhoneInput } = useRegistrationContext()
    const {
        data: relation,
    } = useQuery('relation', () => getRelationship());

    const content = (
        <>
            <div className="form-group row">
                <label htmlFor="next_of_kin" className="col-sm-3 col-form-label">Next of Kin<span>&nbsp;&nbsp;*</span></label>
                <div className="col-sm-9">
                    <input
                        type="text"
                        id="next_of_kin"
                        placeholder='Name of Next of Kin'
                        autoComplete="off"
                        style={{
                            height: "40px",
                            textTransform: "uppercase",
                        }}
                        onChange={handleChange}
                        required
                        value={data.nkinNextOfKin}
                        name="nkinNextOfKin"
                        className='form-control'
                    />
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="kin_contact_number" className="col-sm-3 col-form-label">Contact Number<span>&nbsp;&nbsp;*</span></label>
                <div className="col-sm-9">
                    <PhoneInput
                        type="tel"                      
                        id="kin_contact_number"
                        inputStyle={{ width: '100%', height: "34px" }}
                        country={"gh"}
                        placeholder='nkinContactNumber'
                        enableSearch={true}
                        value={data.nkinContactNumber}
                        name="nkinContactNumber"
                        onChange={(e) => handlePhoneInput(e)}
                        onKeyDown={(e) => handleOnPhoneInput(e)}
                    />
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="kin_relationship" className="col-sm-3 col-form-label">Relationship<span>&nbsp;&nbsp;*</span></label>
                <div className="col-sm-9">
                    <select
                        type="text"
                        id="kin_relationship"
                        placeholder='Relationship'
                        autoComplete="off"
                        style={{
                            height: "40px",
                            textTransform: "uppercase",
                        }}
                        onChange={handleChange}
                        required
                        value={data.nkinRelationship}
                        name="nkinRelationship"
                        className='form-control'
                    >
                        <option>Select Option</option>
                        {relation?.map((item) =>
                            <option key={item.id} value={item.relationship}>{item.relationship}</option>)}
                    </select>
                </div>
            </div>
        </>
    )

    return content
}
export default NextOfKin