import React from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';

import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from '../../AdminPortal/AdminErrorFallback/ErrorFallback';
import SkeletonAdmin from '../../AdminPortal/AdminSkeletons/SkeletonAdmin';
import { lazy, Suspense } from 'react';

import Home from '../../Home/Home';

// LAYOUTS
import MainLayout from '../Layout/MainLayout';
import AdminLayout from '../../Common/Layout/AdminLayout';

// REGISTRATION
import RegistrationForm from '../../AdminPortal/Registration/RegistrationForm';

// APPROVAL
import PendingApprovals from '../../AdminPortal/Approval/PendingApprovals';
import ViewPendingApproval from '../../AdminPortal/Approval/ViewPendingApproval';
import ApprovalForm from '../../AdminPortal/Approval/ApprovalForm';


// OPERATIONS
import OperationsReport from '../../AdminPortal/Operations/OperationsReport';
import ActivationRequests from '../../AdminPortal/Operations/ActivationRequests';
import UpdateRequests from '../../AdminPortal/Operations/UpdateRequests';
import ContactMessage from '../../AdminPortal/Operations/ContactMessage';
import ReplyMessage from '../../AdminPortal/Operations/ReplyMessage';
import RegionalReportList from '../../AdminPortal/Operations/RegionalReportList';
import ViewRegionalReport from '../../AdminPortal/Operations/ViewRegionalReport';
import ViewConsolidatedReport from '../../AdminPortal/Operations/ViewConsolidatedReport';


// import Users from '../../AdminPortal/Users/Users';
import UserRoles from '../../../hooks/userRoles';
import PersistLogin from '../../AdminPortal/PersistLogin/PersistLogin';
import RequireAuth from '../../AdminPortal/RequireAuth/RequireAuth';
import Login from '../../AdminPortal/Login/Login';
import PasswordSetup from '../../AdminPortal/Login/PasswordSetup';
import Roles from '../../AdminPortal/Roles/Roles';
import Unauthorized from '../../AdminPortal/Unauthorized/Unauthorized';

import BankHome from '../Banks/BankHome';
import '../../../App.css';

import PortalUsers from '../../AdminPortal/Users/PortalUsers';

// ACCOUNTS
import ReceivePayments from '../../AdminPortal/Accounts/Receipts/ReceivePayments';
import SalesRegularization from '../../AdminPortal/Accounts/Receipts/SalesRegularization';
import GroundRents from '../../AdminPortal/Accounts/Receipts/GroundRents';
import CustomaryDrinks from '../../AdminPortal/Accounts/Receipts/CustomaryDrinks';
import RefundPayment from '../../AdminPortal/Accounts/Refunds/RefundPayment';
import PaymentTransactions from '../../AdminPortal/Accounts/Payments/PaymentTransactions'
import PaymentByPeriod from '../../AdminPortal/Accounts/Payments/PaymentByPeriod'
import ViewPayment from '../../AdminPortal/Accounts/Payments/ViewPayment'
import PaymentByMonth from '../../AdminPortal/Accounts/Payments/PaymentByMonth'
import PaymentByMode from '../../AdminPortal/Accounts/Payments/PaymentByMode';
import PaymentClearance from '../../AdminPortal/Accounts/Payments/PaymentClearance';

// PAYMENT TYPES
import PaymentTypes from '../../AdminPortal/Accounts/Payments/PaymentTypes/PaymentTypes';
import AddPaymentType from '../../AdminPortal/Accounts/Payments/PaymentTypes/AddPaymentType';
import EditPaymentType from '../../AdminPortal/Accounts/Payments/PaymentTypes/EditPaymentType';
import ViewPaymentType from '../../AdminPortal/Accounts/Payments/PaymentTypes/ViewPaymentType';

// YEARLY PAYMENT
import YearlyPayments from '../../AdminPortal/Accounts/Payments/YearlyPayments/YearlyPayments';
import AddYearlyPayment from '../../AdminPortal/Accounts/Payments/YearlyPayments/AddYearlyPayment';
import EditYearlyPayment from '../../AdminPortal/Accounts/Payments/YearlyPayments/EditYearlyPayment';
import ViewYearlyPayment from '../../AdminPortal/Accounts/Payments/YearlyPayments/ViewYearlyPayment';

import LedgerAccounts from '../../AdminPortal/Accounts/Ledgers/LedgerAccounts'
import GoodStanding from '../../AdminPortal/Accounts/Ledgers/GoodStanding'
import InArrears from '../../AdminPortal/Accounts/Ledgers/InArrears'
import ViewLedger from '../../AdminPortal/Accounts/Ledgers/ViewLedger'
import Logout from '../../AdminPortal/Logout/Logout';
import LocationMap from '../LocationMap/LocationMap';
import ViewPurchaseTenant from '../../AdminPortal/Tenants/Purchase/ViewPurchaseTenant';
import ViewRegularizationTenant from '../../AdminPortal/Tenants/Regularization/ViewRegularizationTenant';
import ViewTransferTenant from '../../AdminPortal/Tenants/Transfer/ViewTransferTenant';
import UpdateForm from '../../AdminPortal/Tenants/Updates/UpdateForm';
import SatelliteView from '../LocationMap/SatelliteView';
import ApprovedTenants from '../../AdminPortal/Approval/ApprovedTenants';
import RejectedTenants from '../../AdminPortal/Approval/RejectedTenants';
import ViewApprovedTenant from '../../AdminPortal/Approval/ViewApprovedTenant';
import ViewRejectedTenant from '../../AdminPortal/Approval/ViewRejectedTenant';
import RejectedApprovalForm from '../../AdminPortal/Approval/RejectedApprovalForm';
import LocationTracker from '../LocationTracker/LocationTracker';
import PreviewReceipt from '../../AdminPortal/Accounts/Receipts/PreviewReceipt';
// import PdfTemplate from '../../AdminPortal/PdfStatement/PdfTemplate';
// import PaymentReceipt from '../../AdminPortal/PdfStatement/Receipts/PaymentRecipt';
// import PdfReceipt from '../../AdminPortal/Accounts/Receipts/PdfReceipt';
import ViewPaymentStatus from '../../AdminPortal/OfficeUse/ViewPaymentStatus';
import OfficePayment from '../../AdminPortal/Tenants/OfficePayment';
import TenantPayments from '../../AdminPortal/Accounts/Receipts/TenantPayments';



const Dashboard = lazy(() => import('../../AdminPortal/Dashboard/Dashboard'));
const Tenants = lazy(() => import('../../AdminPortal/Tenants/GlobalTenants/GlobalTenants'));
const OthersTenants = lazy(() => import('../../AdminPortal/Tenants/Others/OthersTenants'));
const TransferTenants = lazy(() => import('../../AdminPortal/Tenants/Transfer/TransferTenants'));
const RegularizationTenants = lazy(() => import('../../AdminPortal/Tenants/Regularization/RegularizationTenants'));
const PurchaseTenants = lazy(() => import('../../AdminPortal/Tenants/Purchase/PurchaseTenants'));
const ViewTenant = lazy(() => import('../../AdminPortal/Tenants/GlobalTenants/ViewTenant'));

const GeneralSetup = lazy(() => import('../../AdminPortal/Setup/GeneralSetup/GeneralSetup'));
const AdminUsers = lazy(() => import('../../AdminPortal/Users/AdminUsers'));
const FindZone = lazy(() => import('../../AdminPortal/Tenants/RegionalTenants/FindZone'));

function App() {
	const location = useLocation();
	const fullPath = location.pathname;
	const navigate = useNavigate();
	let userLogin = window.location.search.substring(6).split("&");
	let tenantCode = fullPath.split("/")[2]

	let code = window.location.search.substring(6).split("&");
	const tCode = decodeURI(code[0])

	let tenantLocation = window.location.search.substring(5).split("&");

	const address = decodeURI(tenantLocation[0])
	const lat = decodeURI(tenantLocation[1])
	const lng = decodeURI(tenantLocation[2])

	const mapLocation = ({
		address: address,
		lat: Number(lat),
		lng: Number(lng),
	})

	let receiptData = window.location.search.substring(8).split("&");


	return (
		<Routes>
			<Route path='/login' element={<Login />} />
			<Route path="/set_password" element={< PasswordSetup user={userLogin} />} />
			<Route path="/unauthorized" element={< Unauthorized />} />
			<Route path="/logout" element={<Logout />} />
			{/* PUBLIC ROUTES */}
			<Route path="/" exact element={<MainLayout />}>
				<Route path='/' element={<Home />} />
				<Route path='/home' element={<Home />} />
			</Route>

			{/* ADMIN PRIVATE ROUTES */}
			<Route path="/" exact element={<AdminLayout />}>
				<Route element={<PersistLogin />}>
					<Route element={<RequireAuth
						allowedRoles={
							[
								UserRoles.SuperAdmin,
								UserRoles.SystemAdmin,
								UserRoles.Director,
								UserRoles.OperationsMgr,
								UserRoles.AccountsMgr,
								UserRoles.LegalMgr,
								UserRoles.FrontDeskUser,
							]
						}
					/>}>

						<Route path='/system_roles' element={<Roles />} />
						<Route path="/banks_home" element={<BankHome />} />

						{/* ADMIN DASHBOARD */}
						<Route path="/admin/dashboard" element={
							<ErrorBoundary
								FallbackComponent={ErrorFallback}
								onReset={() => navigate('/')}>
								<Suspense fallback={<SkeletonAdmin />}>
									<Dashboard allowedRoles={UserRoles} />
								</Suspense>
							</ErrorBoundary>}
						/>
						{/* REGISTRATION */}
						<Route path='/sales_regularization' exact element={< RegistrationForm />} />
						<Route path='/pending_approvals' exact element={< PendingApprovals />} />
						<Route path='/view_pending_approval/:id' exact element={< ViewPendingApproval />} />
						<Route path='/approval_form/:id' exact element={< ApprovalForm />} />
						<Route path='/approved_tenants' exact element={< ApprovedTenants />} />
						<Route path='/view_approved_tenant/:id' exact element={< ViewApprovedTenant />} />
						<Route path='/rejected_tenants' exact element={< RejectedTenants />} />
						<Route path='/view_rejected_tenant/:id' exact element={< ViewRejectedTenant />} />
						<Route path='/rejected_approval_form/:id' exact element={< RejectedApprovalForm />} />

						{/* OPERATIONS */}
						<Route path='/regional_report_list' exact element={< RegionalReportList />} />
						<Route path='/view_regional_report/:id' exact element={< ViewRegionalReport />} />
						<Route path='/view_consolidated_report' exact element={< ViewConsolidatedReport />} />
						<Route path='/regional_reports' exact element={< OperationsReport />} />
						<Route path='/activation_response' exact element={< ActivationRequests />} />
						<Route path='/update_response' exact element={< UpdateRequests />} />
						<Route path='/messages_response' exact element={< ContactMessage />} />
						<Route path='/reply_message/:id' exact element={< ReplyMessage />} />
						<Route path='/location_map' exact element={< LocationMap location={mapLocation} zoomLevel={17} />} />
						<Route path='/satellite_view' exact element={< SatelliteView location={mapLocation} zoomLevel={20} />} />
						<Route path='/location_tracker' exact element={< LocationTracker location={mapLocation} />} />

						<Route path='/view_payment_status/:id' exact element={< ViewPaymentStatus />} />
						<Route path='/office_payment' exact element={< OfficePayment code={tCode} />} />


						{/* TENANTS */}
						<Route path='/tenant_updates' exact element={< UpdateForm />} />
						<Route path="/view_tenant/:id" element={
							<ErrorBoundary
								FallbackComponent={ErrorFallback}
								onReset={() => navigate('/')}>
								<Suspense fallback={<SkeletonAdmin />}>
									< ViewTenant />
								</Suspense>
							</ErrorBoundary>}
						/>
						{/* PURCHASE TENANTS */}
						<Route path='/view_purchase_tenant/:id' exact element={< ViewPurchaseTenant />} />
						<Route path="/new_purchase_tenants" element={
							<ErrorBoundary
								FallbackComponent={ErrorFallback}
								onReset={() => navigate('/')}>
								<Suspense fallback={<SkeletonAdmin />}>
									< PurchaseTenants />
								</Suspense>
							</ErrorBoundary>}
						/>
						{/* REGULARIZATION TENANTS */}
						<Route path='/view_regularization_tenant/:id' exact element={< ViewRegularizationTenant />} />
						<Route path="/regularization_tenants" element={
							<ErrorBoundary
								FallbackComponent={ErrorFallback}
								onReset={() => navigate('/')}>
								<Suspense fallback={<SkeletonAdmin />}>
									< RegularizationTenants />
								</Suspense>
							</ErrorBoundary>}
						/>
						{/* TRANSFER TENANTS */}
						<Route path='/view_transfer_tenant/:id' exact element={< ViewTransferTenant />} />
						<Route path="/transfer_tenants" element={
							<ErrorBoundary
								FallbackComponent={ErrorFallback}
								onReset={() => navigate('/')}>
								<Suspense fallback={<SkeletonAdmin />}>
									< TransferTenants />
								</Suspense>
							</ErrorBoundary>}
						/>
						{/* OTHERS TENANTS */}
						<Route path='/view_others_tenant/:id' exact element={< ViewTransferTenant />} />
						<Route path="/others_tenants" element={
							<ErrorBoundary
								FallbackComponent={ErrorFallback}
								onReset={() => navigate('/')}>
								<Suspense fallback={<SkeletonAdmin />}>
									< OthersTenants />
								</Suspense>
							</ErrorBoundary>}
						/>

						{/* GENERAL SETUP */}
						<Route path="/general_setup" element={
							<ErrorBoundary
								FallbackComponent={ErrorFallback}
								onReset={() => navigate('/')}>
								<Suspense fallback={<SkeletonAdmin />}>
									<GeneralSetup />
								</Suspense>
							</ErrorBoundary>}
						/>

						{/* ADMIN USERS */}
						<Route path="/system_users" element={
							<ErrorBoundary
								FallbackComponent={ErrorFallback}
								onReset={() => navigate('/')}>
								<Suspense fallback={<SkeletonAdmin />}>
									<AdminUsers />
								</Suspense>
							</ErrorBoundary>}
						/>
						{/* MEMBERSHIP */}
						<Route path="/global_tenants" element={
							<ErrorBoundary
								FallbackComponent={ErrorFallback}
								onReset={() => navigate('/')}>
								<Suspense fallback={<SkeletonAdmin />}>
									<Tenants />
								</Suspense>
							</ErrorBoundary>}
						/>
						<Route path="/zonal_tenants" element={
							<ErrorBoundary
								FallbackComponent={ErrorFallback}
								onReset={() => navigate('/')}>
								<Suspense fallback={<SkeletonAdmin />}>
									< FindZone />
								</Suspense>
							</ErrorBoundary>}
						/>

						{/* PORTAL USERS */}
						<Route path="/portal_users" element={
							<ErrorBoundary
								FallbackComponent={ErrorFallback}
								onReset={() => navigate('/')}>
								<Suspense fallback={<SkeletonAdmin />}>
									<PortalUsers />
								</Suspense>
							</ErrorBoundary>}
						/>

						{/* ACCOUNTS */}

						{/* <Route path='/pdf_receipt' exact element={< PdfReceipt/>} /> */}
						<Route path='/preview_receipt/:id' exact element={< PreviewReceipt code={tenantCode} />} />
						<Route path='/print_receipt' exact element={< PreviewReceipt code={receiptData} />} />

						<Route path='/tenant_payments' exact element={< TenantPayments />} />
						<Route path='/receive_payments' exact element={< ReceivePayments />} />
						<Route path='/sales_and_regularization' exact element={< SalesRegularization />} />
						<Route path='/ground_rents' exact element={< GroundRents />} />
						<Route path='/customary_drinks' exact element={< CustomaryDrinks />} />
						<Route path='/refund_payment' exact element={< RefundPayment />} />
						<Route path='/payment_transactions' exact element={< PaymentTransactions />} />
						<Route path='/view_payment/:id' exact element={< ViewPayment tenantCode={tenantCode} />} />
						<Route path='/payments_by_period' exact element={< PaymentByPeriod />} />
						<Route path='/payments_by_month' exact element={< PaymentByMonth />} />
						<Route path='/payments_by_mode' exact element={< PaymentByMode />} />
						<Route path='/payment_clearance' exact element={< PaymentClearance />} />

						{/* PAYMENT TYPES */}
						<Route path='/payment_types' exact element={< PaymentTypes />} />
						<Route path='/add_payment_type' exact element={< AddPaymentType />} />
						<Route path='/edit_payment_type/:id' exact element={< EditPaymentType />} />
						<Route path='/view_payment_type/:id' exact element={< ViewPaymentType />} />

						{/* YEARLY PAYMENT */}
						<Route path='/yearly_payments' exact element={< YearlyPayments />} />
						<Route path='/add_yearly_payment' exact element={< AddYearlyPayment />} />
						<Route path='/view_yearly_payment/:id' exact element={< ViewYearlyPayment />} />
						<Route path='/edit_yearly_payment/:id' exact element={< EditYearlyPayment />} />

						{/* LEDGER ACCOUNT */}
						<Route path='/ledger_accounts' exact element={< LedgerAccounts />} />
						<Route path='/view_ledger/:id' exact element={< ViewLedger />} />
						<Route path='/good_standing_members' exact element={< GoodStanding />} />
						<Route path='/arrears_members' exact element={< InArrears />} />

						{/* <Route path='/view_ledger/:id' exact element={< ViewLedger />} />
						<Route path='/create_ledger_accounts' exact element={< CreateLedgers />} />

						<Route path='/expenditure' exact element={< Expenditure />} />
						<Route path='/view_expenditure/:id' exact element={< ViewExpenditure />} />
						<Route path='/edit_expenditure/:id' exact element={< EditExpenditure />} />

						<Route path='/revenue' exact element={< Revenue />} />
						<Route path='/account_reports' exact element={< AccountReports />} /> */}
						{/* <Route path='/pdf_template' exact element={< PdfTemplate />} /> 
						<Route path='/test_receipt' exact element={< PaymentReceipt />} /> 
						
						<Route path='/preview_receipt' exact element={< PreviewReceipt data={"6676383073"} />} /> */}
						<Route path='/preview_receipt' exact element={< PreviewReceipt data={"3491706664"} />} />


					</Route>
				</Route>
			</Route>
		</Routes>
	);
}

export default App;
