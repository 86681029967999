import React from 'react';
import HeroSection from '../HeroSection/HeroSection';


function Home() {
  return (
    <>
      <HeroSection />
    </>
  );
}

export default Home;
