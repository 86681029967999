import React, { useState } from 'react';
import UpdateFormInputs from './UpdateFormInputs'
import useUpdateContext from "../../../../hooks/useUpdateContext"
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import {
    updateTenant,
} from "../../../../api/apiServices";
import toast, { Toaster } from 'react-hot-toast'

import useSpecialSearchContext from "../../../../hooks/useSpecialSearchContext"
import SpecialSearch from '../../../Common/Search/SpecialSearch';

const UpdateFormSetup = () => {

    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/registration_form";
    const [confirm, setConfirm] = useState(false);
    const {
        searchResult
    } = useSpecialSearchContext()

    let tenant_id = searchResult?.tenant[0]?.id

    const {
        page,
        setPage,
        data,
        title,
        canSubmit,
        disablePrev,
        disableNext,
        prevHide,
        nextHide,
        submitHide
    } = useUpdateContext()


    const updateTenantMutation = useMutation(updateTenant, {
        onSuccess: async (res) => {
            if (res.status === false) {
                toast.error(res.message, {
                    duration: 10000,
                })
            } else {
                // Invalidate cached data
                queryClient.invalidateQueries("tenants")
                toast.success(res.message, {
                    duration: 10000,
                })
            }
        },
        onError: async (err) => {
            toast.error(err.message)
        }
    })

    const handlePrevious = () => setPage(prev => prev - 1)

    const handleNext = () => setPage(prev => prev + 1)

    const handleConfirm = (e) => {
        e.preventDefault();
        setConfirm(true)
    }

    const handleSubmit = async () => {
        const updateData = {
            id: tenant_id,
            title: data.persTitle,
            last_name: data.persLastname,
            first_name: data.persFirstname,
            middle_name: data.persMiddlename,
            gender: data.persGender,
            date_of_birth: data.persDOB,
            nationality: data.persNationality,
            occupation: data.persOccupation,
            spouse_contact_number: data.persSpouseContactNumber,
            identification_type: data.persIdentificationType,
            id_number: data.persIdNumber,
            date_issued: data.persDateIssued,
            expiry_date: data.persExpiryDate,
            marital_status: data.persMaritalStatus,
            contact_number: data.infoContactNumber,
            email: data.infoEmail,
            postal_address: data.infoPostalAddress,
            residential_address: data.infoResidentialAddress,
            latitude: data.infoLatitude,
            longitude: data.infoLongitude,
            zone_code: data.infoZoneCode,
            next_of_kin: data.nkinNextOfKin,
            kin_contact_number: data.nkinContactNumber,
            kin_relationship: data.nkinRelationship,
            contact_person: data.refpContactPerson,
            contact_address: data.refpContactAddress,
            contact_relationship: data.refpRelationship,
            contact_telephone: data.refpContactNumber,
        }
        
        updateTenantMutation.mutate(updateData);
    }

    const content = (
        <>
            <section className="content-header">
                <Toaster toastOptions={{ position: "top-center" }} />
                <h1>Tenant Updates</h1>
            </section>
            <section className="content">
                <div className="box">
                    <div className="box-body">
                        {!searchResult.search ?
                            <div className="panel panel-default panel-br-4">
                                <div className="panel-heading">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            {<SpecialSearch />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="panel panel-default panel-br-4">
                                <div className="panel-heading">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <h4 className="panel-title">
                                                {title[page]}
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="panel-body">
                                    <form onSubmit={handleSubmit}>
                                        <UpdateFormInputs />
                                        <div className="panel-footer">
                                            <div className="button-container">
                                                <button
                                                    type="button"
                                                    className={`btn btn-info ${prevHide}`} onClick={handlePrevious}
                                                    disabled={disablePrev}>
                                                    Previous
                                                </button>
                                                <button
                                                    type="button"
                                                    className={`btn btn-danger ${nextHide}`}
                                                    onClick={handleNext}
                                                    disabled={disableNext}>
                                                    Next
                                                </button>
                                                <button
                                                    type="submit"
                                                    className={`btn btn-success ${submitHide}`}
                                                    disabled={!canSubmit}>
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </section>
        </>
    )
    return content
}
export default UpdateFormSetup