import React, { useState } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { allZones, addRegionalReport,getRegionName } from "../../../api/apiServices";

import { useQuery, useMutation, useQueryClient } from 'react-query';
import toast, { Toaster } from 'react-hot-toast'

const OperationsReport = () => {
	
	const queryClient = useQueryClient();

	const navigate = useNavigate();
	const location = useLocation();
	const from = location.state?.from?.pathname || "/regional_report";

	const [region, setRegion] = useState("");
	const [report_year, setReportYear] = useState("");
	const [quarter, setQuarter] = useState("");
	const [regional_report, setRegionalReport] = useState("");
	const [regional_activities, setRegionalActivities] = useState("");
	const [constituency_activities, setConstituencyActivities] = useState("");
	const [branch_activities, setBranchActivities] = useState("");
	const [remarks, setRemarks] = useState("");
	const [recommendations, setRecommendations] = useState("");

	const {
		data: regions,
	} = useQuery('regions', allZones, {
		select: data => data.sort((a, b) => b.id - a.id),
		keepPreviousData: true,
	});

	const year = (new Date()).getFullYear();
	const years = Array.from(new Array(20), (val, index) => index + year)

	const addReportMutation = useMutation(addRegionalReport, {
		onSuccess: async (res) => {
			// Invalidate cached data
			toast.success("Regional report successfully submitted")
			navigate(from, { replace: true });
			queryClient.invalidateQueries("momo");
		}
	})
	const checkFileSize = (e) => {
		let file = e.target.files[0];
		let size = 2000000;
		let err = [];
		for (var i = 0; i < file.length; i++) {
			if (file[i].size > size) {
				err[i] = file[i].type + ' is too large. Please pick a small image\n';
			};
		};
		for (var x = 0; x < err.length; x++) {
			e.target.value = null;
			toast.error(err[x])
		}
		return true;
	}

	const handleFileChange = (e) => {
		let file = e.target.files[0];
		if (file) {
			if (checkFileSize(e)) {
				setRegionalReport(file)
			}
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		let formData = new FormData();
		formData.append('file', regional_report)
		formData.set("region", region.toLocaleUpperCase());
		formData.set("year", report_year);
		formData.set("quarter", quarter.toLocaleUpperCase());
		formData.set("regional_activities", regional_activities);
		formData.set("constituency_activities", constituency_activities);
		formData.set("branch_activities", branch_activities);
		formData.set("remarks", remarks);
		formData.set("recommendations", recommendations);

		addReportMutation.mutate(formData);
		setRegion("");
		setReportYear("");
		setQuarter("");
		setRegionalActivities("");
		setConstituencyActivities("");
		setBranchActivities("");
		setRemarks("");
		setRecommendations("");
	}

	return (

		        <><section className="content-header">
			<Toaster toastOptions={{ position: "top-center" }} />
			<h1>Regional Activity Report</h1>
		</section>
			<section className="content">
				<div className="box">
					<div className="box-body">
						<form onSubmit={handleSubmit}>
							<div className="panel panel-default panel-br-4">
								<div className="panel-heading">
									<div className="row">
										<div className="col-sm-12">
											<h4 className="panel-title">
												<i className="fa fa-file-text" aria-hidden="true"></i>
												<span>Submission of Regional Activity Report</span>
											</h4>
										</div>
									</div>
								</div>
								<div className="panel-body">

									<div className="form-group row">
										<label htmlFor="region" className="col-sm-2 col-form-label">
											Region <span>*</span>
										</label>
										<div className="col-sm-4">
											<select
												type="text"
												id="region"
												name="region"
												value={region}
												onChange={(e) => setRegion(e.target.value)}
												required
												className='form-control'
											>
												<option value="">Select Region</option>
												{regions?.map((region, index) =>
													<option value={region.region} key={index}>
														{region.region} - {region.region_code}
													</option>
												)}
											</select>
										</div>
									</div>
									<div className="form-group row">
										<label htmlFor="report_year" className="col-sm-2 col-form-label">
											Year <span>*</span>
										</label>
										<div className="col-sm-4">
											<select
												type="text"
												id="report_year"
												value={report_year}
												name="report_year"
												autoComplete="off"
												onChange={(e) => setReportYear(e.target.value)}
												required
												className="form-control"
											>
												<option value=''>Select Year</option>
												{years.map((year, index) =>
													<option value={year} key={`year${index}`}>{year}</option>
												)}
											</select>
										</div>
									</div>
									<div className="form-group row">
										<label htmlFor="quarter" className="col-sm-2 col-form-label">
											Quarter <span>*</span>
										</label>
										<div className="col-sm-4">
											<select
												type="text"
												name='quarter'
												id='quarter'
												style={{ textTransform: "capitalize" }}
												required
												onChange={(e) => setQuarter(e.target.value)}
												value={quarter}
												className="form-control"
											>
												<option>Select Quarter</option>
												<option value="1st Quarter">1st Quarter</option>
												<option value="2nd Quarter">2nd Quarter</option>
												<option value="3rd Quarter">3rd Quarter</option>
												<option value="4th Quarter">4th Quarter</option>
											</select>
										</div>
									</div>
									<div className="form-group row">
										<label htmlFor="regional_activities" className="col-sm-2 col-form-label">
											Regional Activities <span>*</span>
										</label>
										<div className="col-sm-6">
											<textarea
												type='text'
												id='regional_activities'
												name='regional_activities'
												autoComplete="off"
												onChange={(e) => setRegionalActivities(e.target.value)}
												value={regional_activities}
												required
												placeholder="Regional activities"
												rows="6"
												className="form-control"
											/>
										</div>
									</div>
									<div className="form-group row">
										<label htmlFor="constituency_activities" className="col-sm-2 col-form-label">
											Constituency Activities<span></span>
										</label>
										<div className="col-sm-6">
											<textarea
												type='text'
												id='constituency_activities'
												name='constituency_activities'
												autoComplete="off"
												onChange={(e) => setConstituencyActivities(e.target.value)}
												value={constituency_activities}
												placeholder="Constituency activities"
												rows="6"
												className="form-control"
											/>
										</div>
									</div>
									<div className="form-group row">
										<label htmlFor="branch_activities" className="col-sm-2 col-form-label">
											Branch Activities<span></span>
										</label>
										<div className="col-sm-6">
											<textarea
												type="text"
												id='branch_activities'
												name='branch_activities'
												autoComplete="off"
												onChange={(e) => setBranchActivities(e.target.value)}
												value={branch_activities}
												placeholder="Branch activities"
												rows="6"
												className="form-control"
											/>
										</div>
									</div>
									<div className="form-group row">
										<label htmlFor="remarks" className="col-sm-2 col-form-label">
											Remarks <span></span>
										</label>
										<div className="col-sm-6">
											<textarea
												type='textarea'
												id='remarks'
												name='remarks'
												autoComplete="off"
												onChange={(e) => setRemarks(e.target.value)}
												value={remarks}
												placeholder="Regional remarks"
												rows="6"
												className="form-control"
											/><br />
										</div>
									</div>

									<div className="form-group row">
										<label htmlFor="recommendations" className="col-sm-2 col-form-label">
											Recommendations <span></span>
										</label>
										<div className="col-sm-6">
											<textarea
												type='textarea'
												id='recommendations'
												name='recommendations'
												autoComplete="off"
												onChange={(e) => setRecommendations(e.target.value)}
												value={recommendations}
												placeholder="Regional recommendations"
												rows="6"
												className="form-control"
											/>
										</div>
									</div>
									<div className="form-group row">
										<label htmlFor="remarks" className="col-sm-2 col-form-label">
											Other Supporting Document <span></span>
										</label>
										<div className="col-sm-6">
											<input
												type="file"
												autoComplete="off"
												onChange={handleFileChange}
												className="form-control"
											/>
										</div>
									</div>
									<div className="panel-footer">
										<button className='btn btn-success'>Submit</button>
									</div>
								</div>
							</div>
						</form>
					</div>
					<div className="box-footer"></div>
				</div>
			</section>
			</>

	)
}

export default OperationsReport