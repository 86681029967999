import useSWR from 'swr'

import {
    getBanks,
    banksUrlEndpoint as usersCacheKey
} from '../../../api/apiBankServices';

const Nav = ({ currentBankId, setCurrentBankId }) => {
    const {
        isLoading,
        error,
        data: banks,
    } = useSWR(usersCacheKey, getBanks)

    let options
    if (isLoading) {
        options = <option>Loading...</option>
    } else if (!error) {
        options = banks.map(bank => {
            return (
                <option key={`opt${bank.id}`} value={bank.id}>
                    {bank.bank_sortcode} - {bank.bank_name}
                </option>
            )
        })
        const titleValue = <option key="opt0" value="0">Select Bank</option>
        options.push(titleValue)
    }

    const onChangeBank = (e) => setCurrentBankId(e.target.value)

    let content
    if (error) {
        content = <p>{error.message}</p>
    } else {
        content = (
            <select
                name="selectMenu"
                id="selectMenu"
                className="form-control"
                value={currentBankId}
                aria-label="Bank Name"
                onChange={onChangeBank}

            >{options}</select>
        )
    }

    return content
}
export default Nav