import React from "react";
import { Outlet } from 'react-router-dom';
import Footer from '../../AdminPortal/Footer/Footer';
import AdminHeader from "../../Common/Header/AdminHeader";
import UserRoles from '../../../hooks/userRoles';
import AdminSidebar from "../../Common/Sidebar/AdminSidebar";

const AdminLayout = () => {
  return (
    <div className="wrapper">
      <AdminHeader />
      <AdminSidebar allowedRoles={UserRoles} />
      <div className="content-wrapper">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default AdminLayout;
