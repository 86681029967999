import React, { useState } from "react";
import { Link, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import {
    approvedTenants
} from "../../../api/apiServices";
import Pagination from '../../../services/Pagination'
import { Toaster } from 'react-hot-toast'
import useGeneralSearchContext from "../../../hooks/useGeneralSearchContext";
import GeneralSearch from '../../Common/Search/GeneralSearch';

import './Registration.css'

const ApprovedTenants = () => {

    const { searchResult, handleCancel } = useGeneralSearchContext()
    const params = useParams();
    const id = params.id ? params.id : undefined

    const [currentPage, setCurrentPage] = useState(1);
    const [postPerPage, setPostPerPage] = useState(15);

    const {
        isLoading,
        isError,
        error,
        data: apprTenants,
    } = useQuery('apprTenants', approvedTenants);

    
    
    let total_apprTenants = 0;
    let currentPosts = []
    const indexOfLastPage = currentPage * postPerPage;
    const indexOfFirstPage = indexOfLastPage - postPerPage;
    if (!searchResult?.search) {
        total_apprTenants = apprTenants ? apprTenants?.length : 0;
        currentPosts = apprTenants?.slice(indexOfFirstPage, indexOfLastPage);
    } else {
        total_apprTenants = searchResult?.tenant.length
        currentPosts = searchResult?.tenant?.slice(indexOfFirstPage, indexOfLastPage);
    }

    let content
    if (isLoading) {
        content = <p>Loading Approved Tenants Data...</p>
    }
    else if (isError) {
        content = <p>{error.message}</p>
    } else {
        content = apprTenants?.length <= 0 ? (
            <div className="table-responsive lg">
                <table className="table table-bordered">
                    <tbody>
                        <tr>
                            <td style={{ fontSize: "20px", fontWeight: "700", color: "#000", textAlign: "center" }}>
                                There are no approved tenants
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        ) : (
            <>
                <div className='table-responsive lg'>
                    <table className="table table-bordered">
                        <thead className="align">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Tenant Code</th>
                                <th scope="col">Full Name</th>
                                <th scope="col">Purpose</th>
                                <th scope="col">Contact Number</th>
                                <th scope="col">Email</th>
                                <th scope="col">Zone</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentPosts?.map((mem, index) =>
                                <tr key={index}>
                                    <td>{postPerPage * (currentPage - 1) + index + 1}</td>
                                    <td>{mem.tenant_code}</td>
                                    <td style={{ whiteSpace: 'nowrap' }}>{mem.fullname.toUpperCase()}</td>
                                    <td style={{ whiteSpace: 'nowrap' }}>{mem.purpose}</td>
                                    <td style={{ whiteSpace: 'nowrap' }}>{mem.contact_number}</td>
                                    <td>{mem.email}</td>
                                    <td style={{ whiteSpace: 'nowrap' }}>{mem.zone_name}</td>
                                    <td style={{ whiteSpace: 'nowrap' }}>
                                        <div style={{ display: 'inline-block' }}>
                                            <Link to={`/view_approved_tenant/${mem.id}`} className="small mr-3"> <i className='fa fa-eye'></i> view</Link>
                                        </div>&nbsp; &nbsp;
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </>
        )
    }

    return (
        <>
            <section className="content-header">
                <h1>Approved Tenants : {total_apprTenants}</h1>
            </section>
            <Toaster toastOptions={{ position: "top-center" }} />
            <section className="content">
                <div className="box">
                    <div className="box-body">
                        <div className="panel panel-default panel-br-4">
                            <div className="panel-heading">
                                <div className="row">
                                    <div className="col-sm-12">
                                        {currentPosts && currentPosts?.length > 0 ?
                                            <>
                                                {< GeneralSearch />}
                                            </>
                                            : null
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="panel-body">
                                {content}
                            </div>
                            <div className="panel-footer">
                                {apprTenants?.length > 0 && !searchResult?.search ?
                                    <div className="panel-footer">
                                        <Pagination
                                            data={apprTenants}
                                            postPerPage={postPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            setPostPerPage={setPostPerPage} />
                                    </div>
                                    : null
                                }
                                {searchResult?.tenant?.length > 0 && searchResult?.search ?
                                    <div className="panel-footer">
                                        <div className="search-group">
                                            <button
                                                type="button"
                                                className="btn btn-danger "
                                                onClick={handleCancel}
                                            > <i className="fa fa-search"></i> Cancel</button>
                                        </div>
                                    </div>
                                    : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );

}

export default ApprovedTenants;


