import useUpdateContext from "../../../../hooks/useUpdateContext"
import useGeneralSearchContext from "../../../../hooks/useGeneralSearchContext"
import { useQuery } from 'react-query';
import {
    getRelationship
} from "../../../../api/apiServices";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
const ContactPerson = () => {
    const {
        searchResult
    } = useGeneralSearchContext()

    let tenantData = []
    searchResult?.tenant?.map(tenant => {
        tenantData.push({
            refpContactPerson: tenant.contact_person,
            refpContactAddress: tenant.contact_address,
            refpRelationship: tenant.contact_relationship,
            refpContactNumber: tenant.contact_telephone,
        })
    })

    const { handleChange } = useUpdateContext()
    const {
        data: relation,
    } = useQuery('relation', getRelationship);

    const content = (
        <>
            <div className="form-group row">
                <label htmlFor="contact_person" className="col-sm-3 col-form-label">Contact Person/Reference<span>&nbsp;&nbsp;*</span></label>
                <div className="col-sm-9">
                    <input
                        type="text"
                        id="contact_person"
                        placeholder='Contact Person or Reference'
                        autoComplete="off"
                        style={{ textTransform: "uppercase" }}
                        onChange={handleChange}
                        defaultValue={tenantData[0].refpContactPerson}
                        name="refpContactPerson"
                        className='form-control'
                    />
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="contact_address" className="col-sm-3 col-form-label">Address /GPS<span>&nbsp;&nbsp;*</span></label>
                <div className="col-sm-9">
                    <input
                        type="text"
                        id="contact_address"
                        placeholder='Contact Person / Reference Address'
                        autoComplete="off"
                        style={{ textTransform: "uppercase" }}
                        onChange={handleChange}
                        defaultValue={tenantData[0].refpContactAddress}
                        name="refpContactAddress"
                        className='form-control'
                    />
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="contact_relationship" className="col-sm-3 col-form-label">Relationship<span>&nbsp;&nbsp;*</span></label>
                <div className="col-sm-9">
                    <select
                        type="text"
                        id="contact_relationship"
                        placeholder='Relationship'
                        autoComplete="off"
                        style={{ textTransform: "uppercase" }}
                        onChange={handleChange}
                        defaultValue={tenantData[0].refpRelationship}
                        name="refpRelationship"
                        className='form-control'
                    >
                        <option>Select Option</option>
                        {relation?.map((item) =>
                            <option key={item.id} value={item.relationship}>{item.relationship}</option>)}
                    </select>
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="contact_telephone" className="col-sm-3 col-form-label">Contact Telephone<span>&nbsp;&nbsp;*</span></label>
                <div className="col-sm-9">
                    <input
                        type="tel"
                        id="contact_telephone"
                        defaultValue={tenantData[0].refpContactNumber}
                        name="refpContactNumber"
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>
            </div>
        </>
    )

    return content
}
export default ContactPerson