import useRegistrationContext from "../../../hooks/useRegistrationContext"
import Purpose from "./Purpose"
import PersonalInformation from "./PersonalInformation"
import ContactInformation from "./ContactInformation"
import NextOfKin from "./NextOfKin"
import ContactPerson from "./ContactPerson"
import LandDetails from "./LandDetails"
import ModeOfPayment from "./ModeOfPayment"
import OfficeUseOnly from "./OfficeUseOnly"
import TenantType from "./TenantType"

const IndividualFormInputs = () => {

    const { page } = useRegistrationContext()

    const display = {
        0: <TenantType />,
        1: <Purpose />,
        2: <OfficeUseOnly />,
        3: <PersonalInformation />,
        4: <ContactInformation />,
        5: <NextOfKin />,
        6: <ContactPerson />,
        7: <LandDetails />,
        8: <ModeOfPayment />,
    }


    const content = (
        <div className="form-inputs flex-col">
            {display[page]}
        </div>
    )

    return content
}
export default IndividualFormInputs