import React, { useState} from "react";
import { Link } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
    getYearlyPayments,
    deleteYearlyPayment
} from '../../../../../api/apiServices';
import Pagination from '../../../../../services/Pagination'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import toast, { Toaster } from 'react-hot-toast'

const YearlyPayments = () => {

    const [currentPage, setCurrentPage] = useState(1);
    const [postPerPage, setPostPerPage] = useState(10);
    const queryClient = useQueryClient();

    const {
        data: yearly_payments,
        isLoading,
        isError,
        error } = useQuery('yearly_payments', getYearlyPayments, {
            select: data => data.sort((a, b) => b.id - a.id),
            retry: true,
        });

    const deleteYearlyPaymentMutation = useMutation(deleteYearlyPayment, {
        onSuccess: async (res) => {
            // Invalidate cached data
            toast.success(res.message, {
                position: toast.POSITION.TOP_CENTER,
                duration: 5000,
            })
            queryClient.invalidateQueries("yearly_payments")
        },
        onError: async (err) => {
            toast.error(err.message, {
                position: toast.POSITION.TOP_CENTER,
                duration: 5000,
            })
        }
    })
    const handleConfirm = (id) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='confirm-container'>
                        <h3><i className='fa fa-warning'></i> Confirm Deletion</h3>
                        <p>Are you sure you want to delete the yearly payment?</p>
                        <br />
                        <div className='confirm-buttons'>
                            <button
                                className="btn btn-danger"
                                onClick={onClose}
                            >
                                No
                            </button>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <button
                                className="btn btn-success"
                                onClick={() => { deleteYearlyPaymentMutation.mutate(id); onClose(); }}
                            >
                                Yes
                            </button>
                        </div>
                    </div>
                );
            }
        });
    }

    const indexOfLastPage = currentPage * postPerPage;
    const indexOfFirstPage = indexOfLastPage - postPerPage;
    const currentPosts = yearly_payments?.slice(indexOfFirstPage, indexOfLastPage);

    let content
    if (isLoading) {
        return <p>Loading yearly payments list...</p>
    } else if (isError) {
        return <p>{error.message}</p>
    } else content = (
        <>
            <div className="panel panel-success panel-br-4">
                <div className="panel-heading">
                    <div className="row">
                        <div className="col-sm-9">
                            <h4 className="panel-title">
                                <span> Yearly Payments List</span>
                            </h4>
                        </div>
                    </div>
                </div>

                <div className="panel-body">
                    <Link to={`/add_yearly_payment`} className="pull-left btn btn-success">
                        <i className="fa fa-plus"></i> Add Yearly Payment</Link>
                    <br /><br />
                    <div className="table-responsive-lg">
                        <table className="table table-bordered">
                            <thead className="align">
                                <tr>
                                    <th scope="col">#</th>
                                    <th style={{ textAlign: "center" }} scope="col">Year</th>
                                    <th scope="col">Payment Type</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Payment Amount</th>
                                    <th style={{ textAlign: "center" }} scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentPosts?.map((pay, index) =>
                                    <tr key={index}>
                                        <td>{postPerPage * (currentPage - 1) + index + 1}</td>
                                        <td style={{ textAlign: "center", whiteSpace: "pre-wrap" }}>{pay.year}</td>
                                        <td style={{ textAlign: "left", whiteSpace: "pre-wrap" }}>{pay.payment_type}</td>
                                        <td style={{ textAlign: "left", whiteSpace: "pre-wrap" }}>{pay.description}</td>
                                        <td style={{ textAlign: "right", whiteSpace: "pre-wrap" }}>{pay.type_amount.toFixed(2)}</td>
                                        <td style={{ textAlign: "center", whiteSpace: 'nowrap' }}>
                                            <div style={{ display: 'inline-block' }}>
                                                <Link to={`/view_yearly_payment/${pay.id}`} className="small">View</Link>
                                            </div>&nbsp; &nbsp;
                                            <div style={{ display: 'inline-block', color: 'green' }}>
                                                <Link to={`/edit_yearly_payment/${pay.id}`} className="small" >Edit</Link>
                                            </div>&nbsp; &nbsp;
                                            <div style={{ display: 'inline-block' }}>
                                                <Link className="small" onClick={() => handleConfirm(pay.id)}><i className='fa fa-trash'></i> delete</Link>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="panel-footer">
                    <Pagination
                        data={yearly_payments}
                        postPerPage={postPerPage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        setPostPerPage={setPostPerPage}
                    />
                </div>
            </div>
        </>
    )

    return (
        <>
            <section className="content-header">
                <Toaster toastOptions={{ position: "top-center" }} />
                <h1>Yearly Payment</h1>
            </section>
            <section className="content">
                <div className="box">
                    <div className="box-body">
                        <div className="panel panel-default panel-br-4">
                            <div className="panel-heading">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <h4 className="panel-title">
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="panel-body">
                                {content}
                            </div>
                        </div>
                    </div>
                    <div className="box-footer"></div>
                </div>
            </section>
        </>
    )
}

export default YearlyPayments