import useAuth from "./useAuth";
import { adminLogout } from "../api/apiServices"

const useLogout = () => {
    const { setAuth } = useAuth();

    const logout = async () => {
        setAuth({});
  
        try {
            adminLogout();          
        } catch (err) {
            console.error(err);
        }
    }
    return logout;
}

export default useLogout;
