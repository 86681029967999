import React, { useState } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { getMessageById, updateMessage } from '../../../api/apiServices';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import toast, { Toaster } from 'react-hot-toast'



const ReplyMessage = () => {
	const queryClient = useQueryClient();

	const navigate = useNavigate();
	const location = useLocation();
	const from = location.state?.from?.pathname || "/messages_response";

	const { id } = useParams();

	const [fullname, setFullname] = useState("");
	const [pf_number, setPfNumber] = useState("");
	const [email, setEmail] = useState("");
	const [mobile_number, setMobileNumber] = useState("");
	const [msg, setMsg] = useState("");
	const [reply, setReply] = useState("");

	const {
		data: message,
	} = useQuery(['message', id], async () => {
		const getMsg = await getMessageById(id)
		if (getMsg) {
			setFullname(getMsg.fullname);
			setPfNumber(getMsg.pf_number)
			setEmail(getMsg.email);
			setMobileNumber(getMsg.mobile_number);
			setMsg(getMsg.message);
		} else {
			console.log("Message not found")
		}
	});

	const replyMutation = useMutation(updateMessage, {
		onSuccess: async (res) => {
			// Invalidate cached data
			toast.success("Message reply successfully sent")
			navigate(from, { replace: true });
			queryClient.invalidateQueries("messages");
		}
	})
	const handleSubmit = (e) => {
		e.preventDefault();

		let replyData = {
			id: id,
			reply: reply
		}
		replyMutation.mutate(replyData);
	}
	const handleCancel = () => {
		navigate(from, { replace: true });
	}

	const content = (
		<>
		</>
	)

	return (
		<>
			<section className="content-header">
				<Toaster toastOptions={{ position: "top-center" }} />
				<h1>Contact Message Response</h1>
			</section>
			<section className="content">
				<div className="box">
					<div className="box-body">
						<div className="panel panel-default panel-br-4">
							<div className="panel-heading">
								<div className="row">
									<div className="col-sm-6">
										<h4 className="panel-title">
											<i className="fa fa-file-text" aria-hidden="true"></i>
											<span> Message Response</span>
										</h4>
									</div>
								</div>
							</div>

							<div className="panel-body">
								<form onSubmit={handleSubmit}>
									<div className="form-group row">
										<label htmlFor='fullname' className='col-sm-2'>Full Name</label>
										<div className="col-sm-6">
											<input
												type="text"
												id="fullname"
												readOnly
												value={fullname}
												name="fullname"
												className="form-control"
											/>
										</div>
									</div>
									<div className="form-group row">
										<label htmlFor='fullname' className='col-sm-2'>PF Number</label>
										<div className="col-sm-6">
											<input
												type="text"
												id="pfNumber"
												readOnly
												value={pf_number}
												name="pf_number"
												className="form-control"
											/>
										</div>
									</div>
									<div className="form-group row">
										<label htmlFor='fullname' className='col-sm-2'>Email Address</label>
										<div className="col-sm-6">
											<input
												type="text"
												id="email"
												readOnly
												value={email}
												name="email"
												className="form-control"
											/>
										</div>
									</div>
									<div className="form-group row">
										<label htmlFor='fullname' className='col-sm-2'>Mobile Number</label>
										<div className="col-sm-6">
											<input
												type="text"
												name='mobile_number'
												id='mobile_number'
												readOnly
												value={mobile_number}
												className="form-control"
											/>
										</div>
									</div>
									<div className="form-group row">
										<label htmlFor='fullname' className='col-sm-2'>Message</label>
										<div className="col-sm-6">
											<textarea
												type='text'
												id='msg'
												name='msg'
												readOnly
												value={msg}
												rows="6"
												className="form-control"
											/>
										</div>
									</div>
									<div className="form-group row">
										<label htmlFor='fullname' className='col-sm-2'>Message Response</label>
										<div className="col-sm-6">
											<textarea
												type='text'
												id='reply'
												name='reply'
												autoComplete="off"
												onChange={(e) => setReply(e.target.value)}
												value={reply}
												placeholder="Reply Message"
												rows="6"
												style={{ width: '40vw' }}
												className="form-control"
											/>
										</div>
									</div>
									<div className="panel-footer">
										<div className="col-sm-2"></div>
										<div className="form-group row">
											<button type="submit" className='btn btn-success'>Submit</button>&nbsp; &nbsp;&nbsp; &nbsp;
											<button className='btn btn-danger' onClick={handleCancel}>Cancel</button>
										</div>
									</div>
								</form>

							</div>
						</div>
					</div>
					<div className="box-footer"></div>
				</div>
			</section>
		</>
	)
}

export default ReplyMessage