import { createContext, useState } from "react"
import { useMutation } from 'react-query';
import {
    specialSearch,
} from "../api/apiServices";
import toast, { Toaster } from 'react-hot-toast'
import { useNavigate, useLocation } from 'react-router-dom';

const SpecialSearchContext = createContext({})

export const SpecialSearchProvider = ({ children }) => {

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname;

    const [searchResult, setSearchResult] = useState({
        search: false,
        tenant: [],
        tenant_code: "",
        contact_number: "",
        indenture_number: "",
    })

    const [phone_input_name, setPhoneInputName] = useState("")

    const searchMutation = useMutation(specialSearch, {
        onSuccess: async (res) => {
            if (res.status === true) {
                setSearchResult(prevData => ({
                    ...prevData,
                    tenant: res.tenant,
                    search: true,
                    tenant_code: "",
                    contact_number: "",
                    indenture_number: "",
                }))
            } else {
                toast.error(res.message, {
                    duration: 6000
                })
                setSearchResult(prevData => ({
                    ...prevData,
                    tenant: [],
                    search: false
                }))
                return false
            }
        },
        onError: async (err) => {

        }
    })

    const handleSearch = async (e) => {
        e.preventDefault();

        const searchData = {
            tenant_code: searchResult.tenant_code,
            contact_number: searchResult.contact_number,
            indenture_number: searchResult.indenture_number,
        }
        searchMutation.mutate(searchData)
    }

    const handlePhoneInputName = (e) => {
        const name = e.target.placeholder
        setPhoneInputName(name)
    }

    const handlePhoneChange = (e) => {
        const value = e
        const name = phone_input_name ? phone_input_name : undefined
        setSearchResult(prevData => ({
            ...prevData,
            [name]: value
        }))
    }

    const handleChange = e => {
        const name = e.target.name
        const value = e.target.value
        setSearchResult(prevData => ({
            ...prevData,
            [name]: value
        }))
    }

    const handleCancel = (e) => {
        setSearchResult(prevData => ({
            ...prevData,
            search: false,
            tenant: [],
        }))
        navigate(-1);
    }

    return (
        <SpecialSearchContext.Provider value={{
            searchResult,
            setSearchResult,
            handleSearch,
            handlePhoneInputName,
            handlePhoneChange,
            handleChange,
            handleCancel
        }}>
            {children}
        </SpecialSearchContext.Provider>
    )
}

export default SpecialSearchContext 