import React from "react";
import { Outlet } from 'react-router-dom';
import Navbar from "../Navbar/Navbar";


const MainLayout = () => {
  return (
   <>
    <Navbar />
      <div className="App">
        <Outlet />
      </div>
    </>
  );
};

export default MainLayout;
