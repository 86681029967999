import React from "react";
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import {
    getTenantById,
    approveRegistration
} from "../../../api/apiServices";
import 'react-confirm-alert/src/react-confirm-alert.css';
import toast, { Toaster } from 'react-hot-toast'


const RejectedApprovalForm = () => {

    const params = useParams();
    const id = parseInt(params.id)

    const {
        data: tenantApproval
    } = useQuery(['tenantApproval', id], () => getTenantById(id), {
        keepPreviousData: true,
    });
    let fullname = tenantApproval?.title + " " + tenantApproval?.first_name + " " + tenantApproval?.middle_name + " " + tenantApproval?.last_name

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/rejected_tenants";

    const approvalMutation = useMutation(approveRegistration, {
        onSuccess: async (res) => {
                toast.success(res.message, {
                duration: 5000,
            })
            navigate(from, { replace: true });
        },
        onError: async (err) => {
            toast.error(err.message, {
                duration: 5000,
            })
        }
    })
    
    const handleApprove = () => {
        const approvalData = {
            id: tenantApproval.id,
            approved_status: true,
        }
        approvalMutation.mutate(approvalData)
    }
    
    const handleDecline = () => {
        const declineData = {
            id: tenantApproval.id,
            approved_status: false,
        }
        approvalMutation.mutate(declineData)    }

    const handleCancel = (e) => {
        e.preventDefault();
        navigate(from, { replace: true });
    }

    const content = (
        <div className="panel panel-default panel-br-4">
            <div className="panel-heading">
                <div className="row">
                    <div className="col-sm-9">
                        <h4 className="panel-title">
                            <span></span>
                        </h4>
                    </div>
                </div>
            </div>
            <div className="panel-body">
                <form>
                    <legend style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        color: "var(--white)",
                        background: "var(--brown)"
                    }}>PURPOSE</legend>
                    <fieldset>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Purpose</label>
                            <div className="col-sm-4">
                                <input
                                    type="text"
                                    style={{ textTransform: "uppercase" }}
                                    value={tenantApproval?.purpose}
                                    readOnly
                                    className='form-control'
                                />
                            </div>
                        </div>
                    </fieldset>
                    {/* <fieldset>
                        <legend style={{
                            fontWeight: "bold",
                            color: "var(--white)",
                            background: "var(--brown)"
                        }}>PERSONAL INFORMATION</legend>
                    </fieldset>
                    <div className="form-group row">
                        <fieldset className="col-sm-6">
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">Title</label>
                                <div className="col-sm-4">
                                    <input
                                        type="text"
                                        style={{ textTransform: "uppercase" }}
                                        value={tenantApproval?.title}
                                        readOnly
                                        className='form-control'
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">Last Name</label>
                                <div className="col-sm-9">
                                    <input
                                        type="text"
                                        value={tenantApproval?.last_name}
                                        readOnly
                                        style={{ textTransform: "uppercase" }}
                                        className='form-control'
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">Middle Name</label>
                                <div className="col-sm-9">
                                    <input
                                        type="text"
                                        value={tenantApproval?.middle_name}
                                        style={{ textTransform: "uppercase" }}
                                        readOnly
                                        className='form-control'
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">First Name</label>
                                <div className="col-sm-9">
                                    <input
                                        type="text"
                                        value={tenantApproval?.first_name}
                                        readOnly
                                        style={{ textTransform: "uppercase" }}
                                        className='form-control'
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">Gender</label>
                                <div className="col-sm-6">
                                    <input
                                        type="text"
                                        style={{ textTransform: "uppercase" }}
                                        readOnly
                                        value={tenantApproval?.gender}
                                        className='form-control'
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">Date of Birth</label>
                                <div className="col-sm-6">
                                    <input
                                        type="date"
                                        readOnly
                                        value={tenantApproval?.date_of_birth}
                                        className='form-control'
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">Nationality</label>
                                <div className="col-sm-6">
                                    <input
                                        type="text"
                                        style={{ textTransform: "uppercase" }}
                                        value={tenantApproval?.nationality}
                                        readOnly
                                        className='form-control'
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">Occupation</label>
                                <div className="col-sm-9">
                                    <input
                                        type="text"
                                        style={{ textTransform: "uppercase" }}
                                        value={tenantApproval?.occupation}
                                        readOnly
                                        className='form-control'
                                    />
                                </div>
                            </div>
                        </fieldset>
                        <fieldset className="col-sm-6">
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">Spouse Contact Number</label>
                                <div className="col-sm-8">
                                    <input
                                        text="number"
                                        readOnly
                                        value={tenantApproval?.spouse_contact_number}
                                        className='form-control'
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">Identification Type</label>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        readOnly
                                        style={{ textTransform: "uppercase" }}
                                        value={tenantApproval?.identification_type}
                                        className='form-control'
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">ID Number</label>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        style={{ textTransform: "uppercase" }}
                                        readOnly
                                        value={tenantApproval?.id_number}
                                        className='form-control'
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">Date Issued</label>
                                <div className="col-sm-8">
                                    <input
                                        type="date"
                                        readOnly
                                        value={tenantApproval?.date_issued}
                                        className='form-control'
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">Expiry Date</label>
                                <div className="col-sm-8">
                                    <input
                                        type="date"
                                        readOnly
                                        value={tenantApproval?.expiry_date}
                                        className='form-control'
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">Marital Status</label>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        readOnly
                                        value={tenantApproval?.marital_status}
                                        style={{ textTransform: "uppercase" }}
                                        className='form-control'
                                    />
                                </div>
                            </div>
                        </fieldset>
                    </div>
                    <fieldset>
                        <legend style={{
                            fontWeight: "bold",
                            color: "var(--white)",
                            background: "var(--brown)"
                        }}>CONTACT INFORMATION</legend>
                    </fieldset>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label">Contact Number</label>
                        <div className="col-sm-4">
                            <input
                                value={tenantApproval?.contact_number}
                                readOnly
                                className='form-control'
                            />
                        </div>
                        <label className="col-sm-2 col-form-label">Email Address</label>
                        <div className="col-sm-4">
                            <input
                                type="email"
                                readOnly
                                value={tenantApproval?.email}
                                className='form-control'
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label">Postal Address</label>
                        <div className="col-sm-4">
                            <input
                                type="text"
                                style={{ textTransform: "uppercase" }}
                                readOnly
                                value={tenantApproval?.postal_address}
                                className='form-control'
                            />
                        </div>
                        <label className="col-sm-2 col-form-label">Residential Address (GPS)</label>
                        <div className="col-sm-4">
                            <input
                                type="text"
                                style={{ textTransform: "uppercase" }}
                                readOnly
                                value={tenantApproval?.residential_address}
                                className='form-control'
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label">Zone<span>&nbsp;&nbsp;*</span></label>
                        <div className="col-sm-4">
                            <input
                                type="text"
                                style={{ textTransform: "uppercase" }}
                                readOnly
                                value={tenantApproval?.zone_name}
                                className='form-control'
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <fieldset className="col-sm-6">
                            <legend style={{
                                fontWeight: "bold",
                                color: "var(--white)",
                                background: "var(--brown)"
                            }}>NEXT OF KIN</legend>
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">Next of Kin<span>&nbsp;&nbsp;*</span></label>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        style={{ textTransform: "uppercase" }}
                                        readOnly
                                        value={tenantApproval?.next_of_kin}
                                        className='form-control'
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">Contact Number<span>&nbsp;&nbsp;*</span></label>
                                <div className="col-sm-8">
                                    <input
                                        value={tenantApproval?.kin_contact_number}
                                        readOnly
                                        className='form-control'
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">Relationship</label>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        style={{ textTransform: "uppercase" }}
                                        readOnly
                                        value={tenantApproval?.kin_relationship}
                                        className='form-control'
                                    />
                                </div>
                            </div>
                        </fieldset>
                        <fieldset className="col-sm-6">
                            <legend style={{
                                fontWeight: "bold",
                                color: "var(--white)",
                                background: "var(--brown)"
                            }}>CONTACT PERSON / REFERENCE</legend>
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">Contact Person/Reference</label>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        style={{ textTransform: "uppercase" }}
                                        readOnly
                                        value={tenantApproval?.contact_person}
                                        className='form-control'
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">Address /GPS<span>&nbsp;&nbsp;*</span></label>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        style={{ textTransform: "uppercase" }}
                                        readOnly
                                        value={tenantApproval?.contact_address}
                                        className='form-control'
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">Relationship</label>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        style={{ textTransform: "uppercase" }}
                                        readOnly
                                        value={tenantApproval?.contact_relationship}
                                        className='form-control'
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">Contact Telephone</label>
                                <div className="col-sm-8">
                                    <input
                                        value={tenantApproval?.contact_telephone}
                                        readOnly
                                        className='form-control'
                                    />
                                </div>
                            </div>
                        </fieldset>
                    </div> */}

                    <fieldset>
                        <legend style={{
                            textAlign: "center",
                            fontWeight: "bold",
                            color: "var(--white)",
                            background: "var(--brown)"
                        }}>LAND</legend>
                    </fieldset>
                    <div className="form-group row">
                        <fieldset className="col-sm-6">
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">Deeds Registration No. / Land Title Certificate</label>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        style={{ textTransform: "uppercase" }}
                                        value={tenantApproval?.deeds_number}
                                        readOnly
                                        className='form-control'
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">Building Permit Number</label>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        readOnly
                                        style={{ textTransform: "uppercase" }}
                                        value={tenantApproval?.building_permit}
                                        className='form-control'
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">Approved Date</label>
                                <div className="col-sm-8">
                                    <input
                                        type="date"
                                        readOnly
                                        value={tenantApproval?.approved_date}
                                        className='form-control'
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">New Identure Number</label>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        readOnly
                                        style={{ textTransform: "uppercase" }}
                                        value={tenantApproval?.new_indenture_number}
                                        className='form-control'
                                    />
                                </div>
                            </div>
                        </fieldset>
                        <fieldset className="col-sm-6">
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">Date of Aquisition</label>
                                <div className="col-sm-8">
                                    <input
                                        type="date"
                                        readOnly
                                        value={tenantApproval?.acquisition_date}
                                        className='form-control'
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">Number of Plots</label>
                                <div className="col-sm-8">
                                    <input
                                        type="number"
                                        readOnly
                                        value={tenantApproval?.number_of_plots}
                                        className='form-control'
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">Land Use</label>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        readOnly
                                        style={{ textTransform: "uppercase" }}
                                        value={tenantApproval?.land_use}
                                        className='form-control'
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">Land Location</label>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        readOnly
                                        style={{ textTransform: "uppercase" }}
                                        value={tenantApproval?.land_location}
                                        className='form-control'
                                    />
                                </div>
                            </div>
                        </fieldset>
                    </div>
                    <fieldset>
                        <legend style={{
                            textAlign: "center",
                            fontWeight: "bold",
                            color: "var(--white)",
                            background: "var(--brown)"
                        }}>MODE OF PAYMENT</legend>
                    </fieldset>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label">Installments</label>
                        <div className="col-sm-4">
                            <input
                                type="text"
                                readOnly
                                style={{ textTransform: "uppercase" }}
                                value={tenantApproval?.payment_type}
                                className='form-control'
                            />
                        </div>
                    </div>                    
                    <fieldset>
                        <legend style={{
                            textAlign: "center",
                            fontWeight: "bold",
                            color: "var(--white)",
                            background: "var(--brown)"
                        }}>PAYMENT DETAILS</legend>
                    </fieldset>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label">Duration</label>
                        <div className="col-sm-4">
                            <input
                                type="text"
                                readOnly
                                value={tenantApproval?.payment_duration}
                                style={{ textTransform: "uppercase" }}
                                className='form-control'
                            />
                        </div>
                        <label className="col-sm-2 col-form-label">Initial Deposit</label>
                        <div className="col-sm-4">
                            <input
                                type="number"
                                readOnly
                                style={{ textTransform: "uppercase" }}
                                value={tenantApproval?.initial_deposit}
                                className='form-control'
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2">Payment Mode</label>
                        <div className="col-sm-4">
                            <input
                                type="text"
                                style={{ textTransform: "uppercase" }}
                                readOnly
                                value={tenantApproval?.payment_mode}
                                className="form-control"
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div >
    )


    return (
        <>
            <section className="content-header">
                <h1>Registration Approval - { fullname}</h1>
            </section>
            <Toaster toastOptions={{ position: "top-center" }} />
            <section className="content">
                <div className="box">
                    <div className="box-body">
                        <div className="panel panel-default panel-br-4">
                            <div className="panel-heading">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <h4 className="panel-title">
                                            Registration Details
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="panel-body">
                                {content}
                            </div>
                            <div className="panel-footer">
                                <div className="form-group row">
                                    <div className="col-sm-1">
                                        <button type="submit" className="btn btn-danger" onClick={handleApprove}>
                                            Approve
                                        </button>
                                    </div>
                                    <div className="col-sm-1">
                                        <button type="submit" className="btn btn-success" onClick={handleDecline}>
                                            Decline
                                        </button>
                                    </div>
                                    <div className="col-sm-2">
                                        <button type="submit" className="btn btn-warning" onClick={handleCancel}>
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default RejectedApprovalForm;