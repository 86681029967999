import React, { useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { getAllRegionalReports, updateRegionalReport, deleteRegionalReport } from "../../../api/apiServices";
import { useQuery, useMutation, useQueryClient } from 'react-query';
import toast, { Toaster } from 'react-hot-toast'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Pagination from '../../../services/Pagination'


const RegionalReportList = () => {

	const navigate = useNavigate();
	const location = useLocation();
	const from = location.state?.from?.pathname || "/regional_membership";


	const [currentPage, setCurrentPage] = useState(1);
	const [postPerPage, setPostPerPage] = useState(15);

	const queryClient = useQueryClient();

	const {
		isLoading,
		isError,
		error,
		data: regionals
	} = useQuery('regionals', getAllRegionalReports, {
		select: data => data.sort((a, b) => b.id - a.id)
	});

	const indexOfLastPage = currentPage * postPerPage;
	const indexOfFirstPage = indexOfLastPage - postPerPage;
	const currentPosts = regionals?.slice(indexOfFirstPage, indexOfLastPage);

	const updatePaymentMutation = useMutation(updateRegionalReport, {
		onSuccess: () => {
			toast.success('Payment cleared and member account credited successfully')
			// Invalidate cached data
			queryClient.invalidateQueries("clearance")
		}
	})

	const deletePaymentMutation = useMutation(deleteRegionalReport, {
		onSuccess: () => {
			toast.error('Payment successfully deleted')
			// Invalidate cached data
			queryClient.invalidateQueries("clearance")
		}
	})

	const handleConfirm = (id) => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className='custom-ui'>
						<div className='confirm-delete'>
							<h3>Confirm Delete</h3>
							<p>Do you want to delete the member record ?</p>
							<br />
							<div className='confirm-buttons'>
								<button
									style={{ width: "100px" }}
									className="btn btn-danger"
									onClick={onClose}
								>
									No
								</button>
								&nbsp;&nbsp;&nbsp;&nbsp;
								<button
									style={{ width: "100px" }}
									className="btn btn-success"
									onClick={() => { deletePaymentMutation.mutate(id); onClose(); }}
								>
									Yes
								</button>
							</div>
						</div>
					</div>
				);
			}
		});
	}

	let content
	if (isLoading) {
		content = <tr><td>Loading...</td></tr>
	} else if (isError) {
		content = <tr><td>{error.message}</td></tr>
	} else {
		content = currentPosts && currentPosts?.length <= 0 ? (

			<div className="table-responsive">
				<table className="table table-bordered">
					<tbody>
						<tr>
							<td style={{ fontSize: "20px", fontWeight: "700", color: "#000", textAlign: "center" }}>
								There are no Regional Reports
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		) : (
			<div className="table-responsive">
				<table className="table table-bordered">
					<thead>
						<tr>
							<th scope="col" style={{ textAlign: 'center' }} >#</th>
							<th scope="col">Region</th>
							<th scope="col">Year</th>
							<th scope="col">Quarter</th>
							<th scope="col" style={{ whiteSpace: 'normal' }}>Attached Report</th>
							<th scope="col" style={{ whiteSpace: 'normal' }}>Date</th>
							<th scope="col" style={{ textAlign: "center" }}>ACTION</th>
						</tr>
					</thead>
					<tbody>
						{currentPosts?.map((regional, index) =>
							<tr key={regional.id}>
								<td style={{ textAlign: 'center' }}>{postPerPage * (currentPage - 1) + index + 1}</td>
								<td style={{ whiteSpace: 'nowrap' }}>{regional.region}</td>
								<td style={{ whiteSpace: 'nowrap' }}>{regional.year}</td>
								<td style={{ whiteSpace: 'nowrap' }}>{regional.quarter}</td>
								{regional.regional_report ? (
									<td style={{ whiteSpace: 'nowrap' }}>
										<a
											rel="noreferrer"
											target="_blank"
											href={`${regional.regional_report}`}
										>
											Supporting Document
										</a>{" "}
									</td>
								) : (<td style={{ whiteSpace: 'nowrap' }}>
									{"No Supporting Document"}
								</td>)
								}

								<td style={{ whiteSpace: 'nowrap' }}>{regional.date_submitted}</td>
								<td style={{ whiteSpace: 'nowrap', textAlign: "center" }}>
									<div style={{ display: 'inline-block' }}>
										<Link to={`/view_regional_report/${regional.id}`} className="small"><i className='fa fa-eye'></i> view</Link>
									</div>&nbsp; &nbsp;
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>
		)

	}

	return (
		<><section className="content-header">
			<Toaster toastOptions={{ position: "top-center" }} />
			<h1>Regional Report List</h1>
		</section>
			<section className="content">
				<div className="box">
					<div className="box-body">
						<div className="panel panel-default panel-br-4">
							<div className="panel-heading">
								<div className="row">
									<div className="col-sm-12">
										<h4 className="panel-title">
											<i className="fa fa-file-text" aria-hidden="true"></i>
											<span> Regional Reports</span>
										</h4>
									</div>
								</div>
							</div>
							<div className="panel-body">
								<div>
									<Link className="btn btn-success" to="/view_consolidated_report"><i className='fa fa-upload'></i>&nbsp; &nbsp;Generate Global Report</Link>&nbsp; &nbsp;
								</div><br />
								{content}
							</div>
							<div className="panel-footer">
								{currentPosts?.length > 0 ? (
									<Pagination
										data={regionals}
										postPerPage={postPerPage}
										currentPage={currentPage}
										setCurrentPage={setCurrentPage}
										setPostPerPage={setPostPerPage}
									/>
								) : (
									""
								)}
							</div>
						</div>
					</div>
					<div className="box-footer"></div>
				</div>
			</section>
		</>
	)
}

export default RegionalReportList