import { useState } from "react"
import Header from "../../Common/Banks/Header"
import BranchesList from "./BranchesList"

import { Suspense } from "react"
import SkeletonPost from "../../Common/Banks/skeletons/SkeletonPost"
import { ErrorBoundary } from "react-error-boundary"
import ErrorFallback from "../../Common/Banks/ErrorFallback"

function BankHome() {
    const [currentBankId, setCurrentBankId] = useState(0)

    const content = currentBankId === 0
        ? <h2 className="message">Select a Bank to view Branches</h2>
        : (
            <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onReset={() => setCurrentBankId(0)}
                resetKeys={[currentBankId]}
            >
                <Suspense fallback={[...Array(10).keys()].map(i => <SkeletonPost key={i} />)}>
                    <BranchesList currentBankId={currentBankId} />
                </Suspense>
            </ErrorBoundary>
        )

    return (
        <>
            <section className="content-header">
                <h1>Banks & Branches</h1>
            </section>
            <section className="content">
                <div className="box">
                    <div className="box-body">
                        <div className="panel panel-success panel-br-4">
                            <div className="panel-heading">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <h4 className="panel-title">
                                            <i className="fa fa-file-text" aria-hidden="true"></i>
                                            <span> Banks
                                            </span>
                                        </h4>
                                    </div>
                                </div>
                            </div>

                            <div className="panel-body">
                                <Header
                                    currentBankId={currentBankId}
                                    setCurrentBankId={setCurrentBankId}
                                />
                            </div>
                            <div className="panel-heading">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <h4 className="panel-title">
                                            <i className="fa fa-file-text" aria-hidden="true"></i>
                                            <span> Bank Branches
                                            </span>
                                        </h4>
                                    </div>
                                </div>
                            </div>

                            <div className="panel-body">
                                {content}
                            </div>
                            <div className="panel-footer">PANEL FOOTER</div>
                        </div>
                        <div className="box-footer">BOX FOOTER</div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default BankHome
