import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
	addYearlyPayment,
	getPaymentTypes,
	getPaymentTypeByCode,
	getPreviousYearPayment
} from "../../../../../api/apiServices";
import toast, { Toaster } from 'react-hot-toast'

const AddYearlyPayment = () => {

	const navigate = useNavigate();
	const location = useLocation();
	const from = location.state?.from?.pathname || "/yearly_payments";

	const [payment_year, setPaymentYear] = useState("");
	const [type_code, setTypeCode] = useState("");
	const [payment_code, setPaymentCode] = useState("");
	const [description, setDescription] = useState("");
	const [payment_type, setPaymentType] = useState("");
	const [rank, setRank] = useState("");
	const [type_amount, setTypeAmount] = useState("");

	const year = (new Date()).getFullYear() - 5;
	const years = Array.from(new Array(20), (val, index) => index + year)

	const queryClient = useQueryClient();
	const {
		data: payment_types,
	} = useQuery('payment_types', getPaymentTypes);

	const addYealyPaymentMutation = useMutation(addYearlyPayment, {
		onSuccess: async (res) => {
			// Invalidate cached data
			toast.success(res.message, {
				position: toast.POSITION.TOP_CENTER,
				duration: 5000,
			})
			queryClient.invalidateQueries("yearly_payments")
			setPaymentYear("")
			setPaymentCode("")
			setPaymentType("")
			setDescription("")
			setTypeAmount("")
			setRank("")
		},
		onError: async (err) => {
			toast.error(err.message, {
				position: toast.POSITION.TOP_CENTER,
				duration: 5000,
			})
		}
	})

	const handleDescription = (e) => {
		const typeDescrpt = e.target.value
		const typeValue = typeDescrpt.replaceAll(" ", "_");
		setDescription(typeDescrpt)
		if (typeValue === "ADMISSION_FEE") {
			setPaymentCode("ADF")
		} else if (typeValue === "ANNUAL_SUBSCRIPTION") {

			setPaymentCode("ASF")
		} else if (typeValue === "PARTY_SUPPORT_LEVY") {
			setPaymentCode("PSL")
		} else if (typeValue === "SPECIAL_LEVY") {
			setPaymentCode("SL")
		} else if (typeValue === "DONATION") {
			setPaymentCode("DN")
		} else if (typeValue === "VOLUNTARY_CONTRIBUTION") {
			setPaymentCode("VC")
		} else if (typeValue === "FUNERAL_DONATION") {
			setPaymentCode("FD")
		}
	}

	const handleTypeChange = (e) => {
		const typeCode = e.target.value
		setTypeCode(typeCode)
		if (typeCode === "MP") {
			getPaymentTypeByCode(typeCode).then(res => {
				setPaymentType(res.description)
			})
		}
		if (typeCode === "SL") {
			getPaymentTypeByCode(typeCode).then(res => {
				setPaymentType(res.description)
			})
		}
		if (typeCode === "DN") {
			getPaymentTypeByCode(typeCode).then(res => {
				setPaymentType(res.description)
			})
		}
		if (typeCode === "VC") {
			getPaymentTypeByCode(typeCode).then(res => {
				setPaymentType(res.description)
			})
		}
		if (typeCode === "FD") {
			getPaymentTypeByCode(typeCode).then(res => {
				setPaymentType(res.description)
			})
		}
	}

	const handleSameAsPreviousYear = () => {
		const previousYear = payment_year
		console.log(previousYear)
		getPreviousYearPayment(previousYear)
		navigate(from, { replace: true });
	}
	const handleSubmit = (e) => {
		e.preventDefault()

		const dataValues = {
			year: year,
			description: description.toUpperCase(),
			type_code: type_code.toUpperCase(),
			payment_code: payment_code.toUpperCase(),
			payment_type: payment_type.toUpperCase(),
			type_amount: parseFloat(type_amount).toFixed(2),
			rank: parseInt(rank),
		}
		addYealyPaymentMutation.mutate(dataValues)
		toast.success("Payment_type successfully updated")
		navigate(from, { replace: true });
	}
	const handleCancel = () => {
		navigate(from, { replace: true });
	}

	const content = (
		<>
			<div className="panel panel-success panel-br-4">
				<div className="panel-heading">
					<div className="row">
						<div className="col-sm-9">
							<h4 className="panel-title">
								<i className="fa fa-file-text" aria-hidden="true"></i>
								<span> Yearly Payments Setup</span>
							</h4>
						</div>
					</div>
				</div>
				<form onSubmit={handleSubmit}>
					<div className="panel-body">
						<div className="form-group row">
							<label htmlFor="payment_year" className="col-sm-2 col-form-label">
								Payment Year
							</label>
							<div className="col-sm-6">
								<select
									type="text"
									id="payment_year"
									placeholder='Payment Year'
									autoComplete="off"
									style={{ textTransform: "capitalize" }}
									onChange={(e) => setPaymentYear(e.target.value)}
									required
									value={payment_year}
									name="payment_year"
									className="form-control"
								>
									<option>Select Year</option>
									{years.map((year, index) => <option value={year} key={`year${index}`}>{year}</option>
									)}
								</select>
							</div>
						</div>
						<div className="form-group row">
							<label htmlFor="description" className="col-sm-2 col-form-label">
								Payment Description
							</label>
							<div className="col-sm-6">
								<input
									type="text"
									id="description"
									autoComplete="off"
									value={description}
									onChange={handleDescription}
									required
									style={{ textTransform: "uppercase" }}
									name="description"
									className="form-control"
								/>
							</div>
						</div>
						<div className="form-group row">
							<label htmlFor="type_code" className="col-sm-2 col-form-label">
								Payment type
							</label>
							<div className="col-sm-6">
								<select
									type="text"
									id="payment_code"
									autoComplete="off"
									// style={{ textTransform: "uppercase" }}
									value={type_code}
									onChange={handleTypeChange}
									required
									name="type_code"
									className="form-control"
								>
									<option>Select Option</option>
									{payment_types?.map((type, index) =>
										<option value={type.payment_code} key={type.id}>{type.payment_code} - {type.description}</option>
									)}
								</select>
							</div>
						</div>
						<div className="form-group row">
							<label htmlFor="rank" className="col-sm-2 col-form-label">
								Payment Rank
							</label>
							<div className="col-sm-6">
								<select
									type="text"
									id="rank"
									autoComplete="off"
									value={rank}
									onChange={(e) => setRank(e.target.value)}
									required
									name="rank"
									className="form-control"
								>
									<option>Select Option</option>
									<option value={1}>1</option>
									<option value={2}>2</option>
									<option value={3}>3</option>
									<option value={4}>4</option>
									<option value={5}>5</option>
								</select>
							</div>
						</div>
						<div className="form-group row">
							<label htmlFor="type_amount" className="col-sm-2 col-form-label">
								Payment Amount
							</label>
							<div className="col-sm-6">
								<input
									type="number"
									id="type_amount"
									autoComplete="off"
									value={type_amount}
									onChange={(e) => setTypeAmount(e.target.value)}
									required
									style={{ textTransform: "uppercase" }}
									name="type_amount"
									className="form-control"
								/>
							</div>
						</div>
						<div className="panel-footer">
							<div className="form-group row">

								<div className="col-sm-1">
									<button type="submit" className="btn btn-danger" onClick={handleCancel}>
										Cancel
									</button>
								</div>
								<div className="col-sm-1">
									<button type="submit" className="btn btn-success">
										Submit
									</button>
								</div>
								<div className="col-sm-1">
									<button onClick={handleSameAsPreviousYear} className="btn btn-warning">
										Same As Previous Year
									</button>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
		</>
	)

	return (
		<>
			<section className="content-header">
				<Toaster toastOptions={{ position: "top-center" }} />
				<h1>Add Yearly Payment</h1>
			</section>
			<section className="content">
				<div className="box">
					<div className="box-body">
						<div className="panel panel-default panel-br-4">
							<div className="panel-heading">
								<div className="row">
									<div className="col-sm-12">
										<h4 className="panel-title">
										</h4>
									</div>
								</div>
							</div>
							<div className="panel-body">
								{content}
							</div>
						</div>
					</div>
					<div className="box-footer"></div>
				</div>
			</section>
		</>
	)
}

export default AddYearlyPayment