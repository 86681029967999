import React, { useEffect } from "react";
import useRegistrationContext from "../../../hooks/useRegistrationContext"
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { Link } from "react-router-dom";

const ContactInformation = () => {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    const { data, handleOnPhoneInput, handleChange, handlePhoneInput } = useRegistrationContext()

    const content = (
        <>
            <div className="form-group row">
                <fieldset className="col-sm-6">
                    <div className="form-group row">
                        <label htmlFor="contact_number" className="col-sm-3 col-form-label">Contact Number<span>&nbsp;&nbsp;*</span></label>
                        <div className="col-sm-9">
                            <PhoneInput
                                type="tel"
                                id="contact_number"
                                inputStyle={{ width: '100%', height: "34px" }}
                                country={"gh"}
                                placeholder='infoContactNumber'
                                enableSearch={true}
                                value={data.infoContactNumber}
                                name="infoContactNumber"
                                onChange={(e) => handlePhoneInput(e)}
                                onKeyDown={(e) => handleOnPhoneInput(e)}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="email" className="col-sm-3 col-form-label">Email Address<span>&nbsp;&nbsp;*</span></label>
                        <div className="col-sm-9">
                            <input
                                type="email"
                                id="email"
                                placeholder='Email Address'
                                autoComplete="off"
                                onChange={handleChange}
                                style={{
                                    height: "40px",
                                    textTransform: "lowercase",
                                }}
                                required
                                value={data.infoEmail}
                                name="infoEmail"
                                className='form-control'
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="postal_address" className="col-sm-3 col-form-label">Postal Address</label>
                        <div className="col-sm-9">
                            <input
                                type="text"
                                id="postal_address"
                                placeholder='Postal Address'
                                autoComplete="off"
                                style={{
                                    height: "40px",
                                    textTransform: "uppercase",
                                }}
                                onChange={handleChange}
                                value={data.infoPostalAddress}
                                name="infoPostalAddress"
                                className='form-control'
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="zone_code" className="col-sm-3 col-form-label">Zone<span>&nbsp;&nbsp;*</span></label>
                        <div className="col-sm-9">
                            <input
                                type="text"
                                id="zone_code"
                                placeholder='Zone'
                                autoComplete="off"
                                style={{
                                    height: "40px",
                                    textTransform: "uppercase",
                                }}
                                onChange={handleChange}
                                disabled
                                value={data.officeZoneName}
                                name="officeZoneName"
                                className='form-control'
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="longitude" className="col-sm-3 col-form-label">Land Location</label>
                        <div className="col-sm-9">
                            <input
                                type="text"
                                placeholder="Land Location"
                                id="land_location"
                                autoComplete="off"
                                onChange={handleChange}
                                required
                                disabled
                                style={{
                                    height: "40px",
                                    textTransform: "uppercase",
                                }}
                                value={data.officeLandLocation}
                                name="officeLandLocation"
                                className='form-control'
                            />
                        </div>
                    </div>
                </fieldset>
                <fieldset className="col-sm-6">
                    <div className="form-group row">
                        <label htmlFor="residential_address" className="col-sm-3 col-form-label">Residential Address (GPS)<span>&nbsp;&nbsp;*</span></label>
                        <div className="col-sm-9">
                            <input
                                type="text"
                                id="residential_address"
                                placeholder='Residential Address'
                                autoComplete="off"
                                style={{
                                    height: "40px",
                                    textTransform: "uppercase",
                                }}
                                onChange={handleChange}
                                required
                                value={data.infoResidentialAddress}
                                name="infoResidentialAddress"
                                className='form-control'
                            />
                            <div className="gps-location">
                                <div className="input-info">
                                    GPS Address: GE-155-7289
                                </div>
                                <div>
                                    <Link
                                        className="a-link"
                                        rel="noreferrer"
                                        target="_target"
                                        to="https://ghanapostgps.com/map/">
                                        Click to get Coordinates
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="latitude" className="col-sm-3 col-form-label">Latitude</label>
                        <div className="col-sm-9">
                            <input
                                type="text"
                                id="latitude"
                                placeholder='Latitude'
                                autoComplete="off"
                                style={{
                                    height: "40px",
                                    textTransform: "uppercase",
                                }}
                                onChange={handleChange}
                                value={data.infoLatitude}
                                name="infoLatitude"
                                className='form-control'
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="longitude" className="col-sm-3 col-form-label">Longitude</label>
                        <div className="col-sm-9">
                            <input
                                type="text"
                                id="longitude"
                                placeholder='Longitude'
                                autoComplete="off"
                                style={{
                                    height: "40px",
                                    textTransform: "uppercase",
                                }}
                                onChange={handleChange}
                                value={data.infoLongitude}
                                name="infoLongitude"
                                className='form-control'
                            />
                        </div>
                    </div>
                </fieldset>
            </div >
        </>
    )

    return content
}
export default ContactInformation