import { utils, writeFile } from 'xlsx';

const ExcelServices = (props) => {
    console.log(props)
    const wb = utils.book_new();
    const ws = utils.json_to_sheet([]);
    utils.sheet_add_aoa(ws, props.headings);
    utils.sheet_add_json(ws, props.excelData, { origin: 'A2', skipHeader: true });
    utils.book_append_sheet(wb, ws, 'Report');
    writeFile(wb, props.filename);
}

export default ExcelServices