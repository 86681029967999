import React from "react";
// import generateReceipt from '../../../../services/GenerateReceipt'
// import GenerateTryPaymentReceipt from "../../../../services/GenerateTryPaymentReceipt";
import { useQuery } from 'react-query';
import {
    getPaymentByTransactionId
} from "../../../../api/apiServices";
import './Receipt.css'
import { useLocation, useNavigate } from "react-router-dom";
import addCommas from "../../../../services/addCommas";

const PreviewReceipt = ({ data }) => {
    const transaction_id = data
    let receiptDate = new Date().toLocaleDateString("sv-SE")

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/payment_receipts";

    const {
        data: tenantInfo
    } = useQuery('tenantInfo', () => getPaymentByTransactionId(transaction_id));

    let tenant = tenantInfo?.tenant[0]
    let payments = tenantInfo?.payment[0]

    const handleCancel = () => {
        navigate(from, { replace: true });
    }

    const content = (
        <>
            <div class="metadata table-responsive m-b-30">
                <table cellpadding="0" cellspacing="0" border="0" className="table table-borderless">
                    <tr>
                        <td valign="top">
                            <p>NAME : <strong>{tenant?.fullname}</strong></p>
                            <p>TENANT CODE. : <strong>{tenant?.tenant_code}</strong></p>
                            <p>CONTACT NUMBER : <strong>{tenant?.contact_number}</strong></p>
                            <p>EMAILL ADDRESS : <strong>{tenant?.email}</strong></p>
                        </td>
                        <td valign="top">
                            <p>ZONE. : <strong>{tenant?.zone}</strong></p>
                            <p>LOCATION : <strong>{tenant?.land_location}</strong></p>
                            <p>GPS ADDRESS : <strong>{tenant?.residential_address}</strong></p>
                            <p>NATIONALITY : <strong>{tenant?.nationality}</strong></p>
                        </td>
                        <td valign="top">
                            <p>LAND USE : <strong>{tenant?.land_use}</strong></p>
                            <p>NUMBER OF PLOTS : <strong>{tenant?.number_of_plots}</strong></p>
                            <p>DEEDS NUMBER : <strong>{tenant?.deeds_number}</strong></p>
                            <p>INDENTURE NUMBER : <strong>{tenant?.indenture_number}</strong></p>
                        </td>
                    </tr>
                </table>
            </div>
            <div className="payment-container">
                <h3>Payment Information</h3>

                <div class="table-responsive lg">
                    <table className="table table-borderless">
                        <tbody>
                            <tr>
                                <td valign="top">
                                    <p>AMOUNT : <strong>{"GHS" + payments?.payment_amount}</strong></p>
                                </td>
                                <td valign="top">
                                    <p>AMOUNT IN WORDS : <strong>{payments?.amount_to_words}</strong></p>
                                </td>
                            </tr>
                            <tr>
                                <td valign="top">
                                    <p>PAYMENT TYPE : <strong>{payments?.payment_type}</strong></p>
                                </td>
                                <td valign="top">
                                    <p>PAYMENT MODE : <strong>{payments?.description}</strong></p>
                                </td>
                            </tr>
                            <tr>
                                <td valign="top">
                                    <p>PAYMENT DATE : <strong>{payments?.payment_date}</strong></p>
                                </td>
                                <td valign="top">
                                    <p>RECEIPT NO. : <strong>{payments?.receipt_number}</strong></p>
                                </td>
                            </tr>
                            <tr>
                                <td valign="top">
                                    <p>RECEIVED BY : <strong>{"SAMUEL TACKEY COMMEY"}</strong></p>
                                </td>

                                <td valign="top">
                                    <p style={{ }}>SIGNATURE</p>
                                    <p style={{ }}>
                                        <input type="textarea" row="20" style={{ height: "100px", width:"300px", border: "0.2px solid black "}} />
                                    </p>
                                </td>
                            
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div >

            {/* <div className="panel-footer">
                <div className="preview-footer">
                    <button
                        className="btn btn-success"
                        onClick={() => { GenerateTryPaymentReceipt(tenantInfo); }}
                    >
                        Download New Receipt
                    </button>
                    <button
                        className="btn btn-success"
                        onClick={() => { GenerateTryPaymentReceipt(tenantInfo); }}
                    >
                        Download Receipt
                    </button>
                    <button
                        className="btn btn-success"
                        onClick={() => { GenerateTryPaymentReceipt(tenantInfo); handleCancel(); }}
                    >
                        Send Receipt
                    </button>
                    <button
                        className="btn btn-success"
                        onClick={() => handleCancel()}
                    >
                        Cancel
                    </button>
                </div>
            </div> */}
        </>
    )

    return (
        <>
            <div className="panel panel-default panel-br-4">
                <div className="panel-heading">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="top-container">
                                <div><strong>Receipt No:</strong> {payments?.receipt_number}</div>
                                <div><strong>Receipt Date:</strong> {`${receiptDate}`}</div>
                            </div>
                            <div className="image-container">
                                <img src='/images/logos/niiodaintow2.jpg' alt='niiodaintow' />
                                <h3>NII ODAI NTOW CUSTOMARY LAND SECRETARIAT</h3>
                                <h4>Payment Receipt</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="panel-body">
                    {content}
                </div>
            </div>
        </>
    )
}

export default PreviewReceipt