import React, { useRef } from 'react'
import jsPDF from 'jspdf';
import ReceiptTemplate from './ReceiptTemplate'
import { useLocation, useNavigate } from "react-router-dom";
import { savePaymentReceipt } from '../../../../api/apiServices'
import { useMutation } from 'react-query';
import toast, { Toaster } from 'react-hot-toast'

const PaymentReceipt = (data) => {
    const transaction_id = data.data.transaction_id
    const filename = data.data.transaction_id + ".pdf";
    const templateReportRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();

    const from = location.state?.from?.pathname || "/tenant_payments";

    const saveReceiptMutation = useMutation(savePaymentReceipt, {
        onSuccess: async (res) => {
            if (res.status === true) {
                toast.success(res.message, {
                    duration: 5000,
                    className: 'toast-message'
                });
            } else {
                toast.error(res.message, {
                    duration: 5000,
                    className: 'toast-message'
                })
            }
        },
        onError: async (err) => {
            toast.error(err.message, {
                duration: 5000,
                className: 'toast-message'
            })
        }
    })

    const handleGenerateReceipt = () => {
        // Page in Pixels
        const doc = new jsPDF({
            orientation: 'landscape',
            format: [600, 385],
            unit: 'px',
        });

        doc.html(templateReportRef.current, {
            async callback(doc) {
                await doc.autoPrint();
                await doc.save(filename);
                navigate(from, { replace: true });
            },
        });
    };

    const sendEmailReceipt = () => {
        var pdf = new jsPDF({
            orientation: 'landscape',
            format: [600, 385],
            unit: 'px',
        });

        pdf.html(templateReportRef.current, {
            // const blob = pdf.output('blob');
            async callback(doc) {
                const blob = new File(
                    [pdf.output("blob")],
                    filename,
                    { type: "pdf" }
                )
                const formData = new FormData();
                formData.append('file', blob);
                formData.set('year', data.data.year);
                formData.set('fullname', data.data.fullname);
                formData.set('tenant_code', data.data.tenant_code);
                formData.set('indenture_number', data.data.indenture_number);
                formData.set('email', data.data.email);
                formData.set('transaction_id', data.data.transaction_id);
                formData.set('receipt_number', data.data.receipt_number);
                formData.set('payment_type', data.data.payment_type);
                formData.set('payment_amount', data.data.payment_amount);
                saveReceiptMutation.mutate(formData)
            }
        });
    };

    const handleCancel = () => {
        navigate(from, { replace: true });
    }

    return (
        <>
            <section className="content-header">
                <Toaster toastOptions={{ position: "top-center" }} />
                <h1>Payment Receipt</h1>
            </section>
            <section className="content">
                <div className="box">
                    <div className="box-body">
                        <div ref={templateReportRef}>
                            <ReceiptTemplate transaction_id={transaction_id} />
                        </div>
                    </div>
                    <br /><br /><br /><br /><br /><br />
                    <p></p>
                    <p></p>
                    <div className="panel-footer">
                        <button
                            type='button'
                            className="btn btn-success"
                            onClick={handleGenerateReceipt}
                        >
                            Print Receipt
                        </button>&nbsp;&nbsp;&nbsp;&nbsp;
                        <button
                            type='button'
                            className="btn btn-success"
                            onClick={sendEmailReceipt}
                        >
                            Email Receipt
                        </button>&nbsp;&nbsp;&nbsp;&nbsp;
                        <button
                            type='button'
                            className="btn btn-danger"
                            onClick={() => handleCancel()}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </section>
        </>
    );
}

export default PaymentReceipt