import React, { useState } from "react";
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { getPaymentTypes, savePayment } from "../../../../api/apiServices";
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import toast, { Toaster } from 'react-hot-toast'
import useSpecialSearchContext from "../../../../hooks/useSpecialSearchContext"
import SpecialSearch from "../../../Common/Search/SpecialSearch";

const RefundPayment = () => {

    const { searchResult, handleCancel } = useSpecialSearchContext()

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/refund_payment";


    const [payment_year, setPaymentYear] = useState("");
    const [payment_type, setPaymentType] = useState([]);
    const [payment_amount, setPaymentAmount] = useState("");
    const [payment_mode, setPaymentMode] = useState("");

    const [checked_amount, setCheckedAmount] = useState(false);
    const [amount_payable, setAmountPayable] = useState("");
    const [total_amount_payable, setTotalAmountPayable] = useState("");
    const [over_message, setOverMessage] = useState("");

    const year = (new Date()).getFullYear() - 5;
    const years = Array.from(new Array(20), (val, index) => index + year)

    const {
        data: payment_types
    } = useQuery('payment_types', getPaymentTypes);

    let tenant = searchResult?.tenant
    let payments = searchResult?.tenant[0]?.payments
    let ledgers = searchResult?.tenant[0]?.ledgers
    console.log(payments)
    let tenant_id
    let tenant_pf_number = ""
    let account_id
    let ledgerYear = ""

    for (var i = 0; i < tenant.length; i++) {
        tenant_id = tenant[i].id;
        tenant_pf_number = tenant[i].pf_number;
        account_id = tenant[i].ledgers[i].id;
        ledgerYear = tenant[i].ledgers[i].year;
    }

    const queryClient = useQueryClient();

    const updateExpenditureMutation = useMutation("updateExpenditure", {
        onSuccess: () => {
            // Invalidate cached data
            queryClient.invalidateQueries("")
        }
    })
    const deleteExpenditureMutation = useMutation("deleteExpenditure", {
        onSuccess: () => {
            // Invalidate cached data
            queryClient.invalidateQueries("members")
        }
    })

    const getCurrentAvail = (e,j) => {
        let sum = 0;
        let sum1 = 0;
        let contrAmt = parseFloat(payment_amount).toFixed(2);
        let amtPayable = parseFloat(amount_payable).toFixed(2);
        for (var i = 0; i <= j; i++) {
            if (e.target.checked_amount[i].checked) {
                sum = (parseFloat(sum) + parseFloat(payment_amount[i])).toFixed(2);
            }
            total_amount_payable = parseFloat(sum).toLocaleString(undefined, { minimumFractionDigits: 2 });

            if (sum > amtPayable) {
                sum1 = parseFloat(sum).toFixed(2)
                sum = parseFloat(sum) - parseFloat(total_amount_payable[j]);
                checked_amount[j].checked = false;
                
                over_message = `Sum of the selected amount of ${sum1} cannot be greater than the payment amount of ${contrAmt}. Kindly check and to continue`;
                
                    over_message = "";
                    total_amount_payable = "";
                checked_amount[i].checked = false;
                ;
                //return false;
            }
            total_amount_payable = parseFloat(sum).toLocaleString(undefined, { minimumFractionDigits: 2 });
        }
    }
    

    const handleConfirm = (e) => {
        e.preventDefault();
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <div>
                            <div style={{ textAlign: 'center' }}><h3>Payment Confirmation</h3></div>
                        </div>

                        <table className="table table-borderless">
                            {tenant.map((mem, index) => <tbody>
                                <tr key={index}>
                                    <td>Full Name</td>
                                    <td >{mem.title} {mem.first_name} {mem.middle_name} {mem.last_name}</td>
                                </tr>
                                <tr>
                                    <td>PF Number</td>
                                    <td>{mem.pf_number}</td>
                                </tr>
                                <tr>
                                    <td>Mobile Number</td>
                                    <td>{mem.contact_number}</td>
                                </tr>
                                <tr>
                                    <td>Payment Year</td>
                                    <td >{payment_year}</td>
                                </tr>
                                <tr>
                                    <td>Payment Amount</td>
                                    <td>{payment_amount.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                                </tr>
                                <tr>
                                    <td>Mode of Payment</td>
                                    <td>{payment_mode === "CC" ? "CASH"
                                        : payment_mode === "QQ" ? "CHEQUE"
                                            : payment_mode === "MM" ? "CHEQUE"
                                                : payment_mode === "BD" ? "CHEQUE"
                                                    : payment_mode === "DD" ? "CHEQUE" :
                                                        "ONLINE"
                                    }
                                    </td>
                                </tr>
                            </tbody>
                            )}
                        </table>
                        <br />
                        <div>
                            <button className="btn btn-danger" onClick={onClose}> Cancel
                            </button>&nbsp;&nbsp;&nbsp;&nbsp;
                            <button
                                onClick={() => { handleSubmit(); onClose(); }} className="btn btn-success"
                            >
                                Confirm
                            </button>
                        </div>
                    </div>
                );
            }
        });
    }

    const handleSubmit = async (e) => {
        if (payment_year === "") {
            toast.warning("Payment Year is required")
            return false;
        } else if (payment_mode === "") {
            toast.warning("Payment mode is required")
            return false;
        } else if (payment_amount === "") {
            toast.warning("Payment amount is required")
            return false;
        }
        else {
            try {
                const data = {
                    tenant_id: tenant_id,
                    account_id: account_id,
                    pf_number: tenant_pf_number,
                    contact_number: tenant[0].contact_number,
                    year: payment_year,
                    payment_type: payment_type,
                    payment_mode: payment_mode.toLocaleUpperCase(),
                    payment_amount: parseFloat(payment_amount),
                }

                savePayment(data).then((res) => {
                    toast.success("Tenant payment successfully refunded");
                    setPaymentYear("");
                    setPaymentType("");
                    setPaymentMode("");
                    setPaymentAmount("");
                })
                navigate(from, { replace: true });
            } catch (err) {
                if (err.response?.status === 400) {
                    toast.error(err.response);
                } else if (err.response?.status === 401) {
                    toast.error(err.response);
                } else {
                    console.log(err)
                }
            }
        }
    }
    const content =
        <>
            <div className="col-md-12 col-sm-12 col-xs-12">
                <legend >Payment Transactions</legend>
                <div className="table-responsive lg">
                    <table className='table table-borderless table-striped'>
                        <thead>
                            <tr>
                                <th scope="col" style={{ textAlign: "center" }}>Year</th>
                                <th scope="col" style={{ whiteSpace: 'normal', textAlign: "center" }}>Payment Date</th>
                                <th scope="col" style={{ whiteSpace: 'normal', textAlign: "center" }}>Receipt Number</th>
                                <th scope="col" style={{ whiteSpace: 'normal', textAlign: "center" }}>Amount Paid</th>
                                <th scope="col" style={{ whiteSpace: 'normal', textAlign: "center" }}>Payment Mode</th>
                                <th scope="col" style={{ textAlign: "center" }}>REFUND</th>
                            </tr>
                        </thead>
                        <tbody>
                            {payments?.map((paymt, index) =>

                                <tr key={paymt.id}>
                                    <td style={{ textAlign: 'center' }}>{paymt?.year}</td>
                                    <td style={{ textAlign: 'center' }}>{paymt?.payment_date}</td>
                                    <td style={{ textAlign: 'center' }}>{paymt?.receipt_number}</td>
                                    <td style={{ textAlign: 'right' }}>{paymt?.payment_amount.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                                    <td style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>{paymt?.payment_mode}</td>
                                    <td style={{ whiteSpace: 'nowrap', textAlign: "center" }}>
                                        <div style={{ display: 'inline-block' }}>
                                            <article key={paymt.id}>
                                                <div className='expenditure'>
                                                    <input
                                                        type="checkbox"
                                                        value={paymt.payment_amount}
                                                        onClick={getCurrentAvail(index)} name='checked_amount'
                                                        checked={false}
                                                        id={paymt.id}
                                                        onChange={() => updateExpenditureMutation.mutate({ ...paymt, completed: !paymt.completed })} />
                                                    <label htmlFor={paymt.id}>{paymt.name}</label>
                                                </div>
                                            </article>
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody >
                    </table >
                </div>
            </div>
        </>

    const tenantDetails = searchResult?.search && tenant.length > 0 ?
        <div className="col-md-12 col-sm-12 col-xs-12">
            <legend style={{ color: "white" }}>Personal Information</legend>
            <div className="table-responsive-lg">
                <table className="table table-borderless">
                    {tenant.map((mem, index) =>
                        <tbody>
                            <tr key={index}>
                                <td >Name</td>
                                <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "left", whiteSpace: "pre-wrap" }}> {mem.title} {mem.first_name} {mem.middle_name} {mem.last_name}</td>
                                <td >Purpose</td>
                                <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "left", whiteSpace: "pre-wrap" }}> {mem.purpose}</td>
                            </tr>
                            <tr>
                                <td >Tenant Code</td>
                                <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "left", whiteSpace: "pre-wrap" }}> {mem.tenant_code}</td>
                                <td >Gender</td>
                                <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "left", whiteSpace: "pre-wrap" }}> {mem.gender}</td>
                            </tr>
                            <tr>
                                <td >Contact Number</td>
                                <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "left", whiteSpace: "pre-wrap" }}> {mem.contact_number}</td>
                                <td >Nationality</td>
                                <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "left", whiteSpace: "pre-wrap" }}> {mem.nationality}</td>
                            </tr>
                            <tr>
                                <td >Email Address</td>
                                <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "left", whiteSpace: "pre-wrap" }}> {mem.email}</td>
                                <td >Occupation</td>
                                <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "left", whiteSpace: "pre-wrap" }}> {mem.occupation}</td>
                            </tr>
                            <tr>
                                <td >Land Location</td>
                                <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "left", whiteSpace: "pre-wrap" }}> {mem.land_location}</td>
                                <td >Residential Address</td>
                                <td style={{ fontSize: "14px", fontWeight: "500", textAlign: "left", whiteSpace: "pre-wrap" }}> {mem.residential_address}</td>
                            </tr>
                        </tbody>
                    )}
                </table>
            </div>
        </div>
        : null

    return (
        <>
            <section className="content-header">
                <Toaster toastOptions={{ position: "top-center" }} />
                <h1>Payments Refund</h1>
            </section>
            <section className="content">
                <div className="box">
                    <div className="box-body">
                        <div className="panel panel-default panel-br-4">
                            <div className="panel-heading">
                                <div className="row">
                                    <div className="col-sm-12">
                                        < SpecialSearch />
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="panel-heading">
                                <div className="row">
                                    <div className="col-sm-12">
                                        {tenantDetails}
                                    </div>
                                </div>
                            </div>                        
                            <div className="panel-body">
                                {content}
                            </div>
                        </div>
                    </div>
                    <div className="box-footer"></div>
                </div>
            </section>
        </>
    );
}

export default RefundPayment;

