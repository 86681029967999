import { useEffect } from "react";
import useRegistrationContext from "../../../hooks/useRegistrationContext"

const TenantType = () => {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    const {
        data,
        handleTenantType,
    } = useRegistrationContext()
    
    const content = (
        <>
            <div className="form-group row">
                <fieldset className="col-sm-6">
                    <div className="form-group row">
                        <label htmlFor="tenant_type" className="col-sm-3 col-form-label">Select Tenant Type<span>&nbsp;&nbsp;*</span></label>
                        <div className="col-sm-9">
                            <select
                                type="text"
                                id="tenant_type"
                                autoComplete="off"
                                required
                                style={{ height: "40px", textTransform: "uppercase" }}
                                value={data.tenantType}
                                onChange={handleTenantType}
                                name="tenantType"
                                className='form-control'
                            >
                                <option value=""></option>
                                <option value="individual">Individual</option>
                                <option value="institution">Institution</option>
                            </select>
                        </div>
                    </div>
                </fieldset>
            </div>
        </>
    )

    return content
}
export default TenantType