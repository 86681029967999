import useSpecialSearchContext from "../../../hooks/useSpecialSearchContext"
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

const SpecialSearch = () => {
    const {
        searchResult,
        handleChange,
        handleSearch,
        handleCancel,
        handlePhoneChange,
        handlePhoneInputName
    } = useSpecialSearchContext()


    let searchContent = (
        <>
            <form onSubmit={handleSearch}>
                <div className="form-group row">
                    <fieldset className="col-sm-12">
                        <div className="col-sm-3">
                            <input
                                type="text"
                                style={{ textTransform: "uppercase", height: "40px", borderRadius: "5px" }}
                                placeholder="Tenant Code"
                                onChange={handleChange}
                                value={searchResult.tenant_code}
                                name="tenant_code"
                                className="form-control"
                            />
                        </div>
                        <div className="col-sm-3">
                            <input
                                type="text"
                                style={{ textTransform: "uppercase", height: "40px", borderRadius: "5px" }}
                                placeholder="Indenture Number"
                                onChange={handleChange}
                                value={searchResult.indenture_number}
                                name="indenture_number"
                                className="form-control"
                            />
                        </div>
                        <div className="col-sm-3">
                            <PhoneInput
                                type="tel"
                                id="contact_number"
                                inputStyle={{ width: '100%', height: "20px" }}
                                country={"gh"}
                                enableSearch={true}
                                placeholder="contact_number"
                                value={searchResult.contact_number}
                                name="contact_number"
                                onChange={(e) => handlePhoneChange(e)}
                                onKeyDown={(e) => handlePhoneInputName(e)}/>
                        </div>
                        <div className="col-sm-3">
                            <button
                                type="submit"
                                className="btn btn-success "
                            > <i className="fa fa-search"></i> Search Tenant
                            </button>
                        </div>
                    </fieldset>
                </div>
            </form>
            <div className="panel-footer">
                <button
                    type="button"
                    onClick={handleCancel}
                    className="btn btn-success"
                > <i className="fa fa-close"></i> Cancel
                </button>
            </div>
        </>

    )
    return searchContent
}
export default SpecialSearch