import React, { useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import {
	getYearlyPaymentById
} from "../../../../../api/apiServices";
import toast, { Toaster } from 'react-hot-toast'

const ViewYearlyPayment = () => {
	const params = useParams();
	const typeId = parseInt(params.id)

	const navigate = useNavigate();
	const location = useLocation();
	const from = location.state?.from?.pathname || "/yearly_payments";

	const {
		data: type,
		isLoading,
		isError,
		error
	} = useQuery(["type", typeId], () => getYearlyPaymentById(typeId), {
		enabled: Boolean(typeId)
	});


	if (!typeId) {
		return 'Select a type';
	}
	if (isLoading) {
		return 'Loading type detailes...';
	}
	if (isError) {
		return 'Someting went wrong...';
	}

	const handleCancel = () => {
		navigate(from, { replace: true });
	}

	const content = (
		<>
			<div className="panel panel-success panel-br-4">
				<div className="panel-heading">
					<div className="row">
						<div className="col-sm-9">
							<h4 className="panel-title">
								<span> Yearly Payment View</span>
							</h4>
						</div>
					</div>
				</div>

				<div className="panel-body">
					<form >
						<div className="form-group row">
							<label htmlFor="payment_year" className="col-sm-2 col-form-label">
								Payment Year
							</label>
							<div className="col-sm-6">
								<input
									type="text"
									id="payment_year"
									autoComplete="off"
									style={{ textTransform: "uppercase" }}
									value={type?.year}
									readOnly
									name="payment_year"
									className="form-control"
								/>
							</div>
						</div>
						<div className="form-group row">
							<label htmlFor="description" className="col-sm-2 col-form-label">
								Description
							</label>
							<div className="col-sm-6">
								<input
									type="text"
									id="description"
									autoComplete="off"
									value={type?.description}
									readOnly
									style={{ textTransform: "uppercase" }}
									name="description"
									className="form-control"
								/>
							</div>
						</div>
						<div className="form-group row">
							<label htmlFor="payment_type" className="col-sm-2 col-form-label">
								Payment Type
							</label>
							<div className="col-sm-6">
								<input
									type="text"
									id="payment_type"
									autoComplete="off"
									style={{ textTransform: "uppercase" }}
									value={type?.payment_type}
									readOnly
									name="payment_type"
									className="form-control"
								/>
							</div>
						</div>
						<div className="form-group row">
							<label htmlFor="type_amount" className="col-sm-2 col-form-label">
								Payment Amount
							</label>
							<div className="col-sm-6">
								<input
									type="text"
									id="type_amount"
									autoComplete="off"
									value={type?.type_amount}
									readOnly
									style={{ textTransform: "uppercase" }}
									name="type_amount"
									className="form-control"
								/>
							</div>
						</div>
						<div className="form-group row">
							<label htmlFor="rank" className="col-sm-2 col-form-label">
								Payment Rank
							</label>
							<div className="col-sm-6">
								<input
									type="text"
									id="rank"
									autoComplete="off"
									value={type?.rank}
									readOnly
									style={{ textTransform: "uppercase" }}
									name="rank"
									className="form-control"
								/>
							</div>
						</div>
						<div className="panel-footer">
							<div className="form-group row">
								<div className="col-sm-1">
									<button type="submit" className="btn btn-danger" onClick={handleCancel}>
										Cancel
									</button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</>
	)


	return (
		<>
			<section className="content-header">
				<Toaster toastOptions={{ position: "top-center" }} />
				<h1>Yearly Payment</h1>
			</section>
			<section className="content">
				<div className="box">
					<div className="box-body">
						<div className="panel panel-default panel-br-4">
							<div className="panel-heading">
								<div className="row">
									<div className="col-sm-12">
										<h4 className="panel-title">
										</h4>
									</div>
								</div>
							</div>
							<div className="panel-body">
								{content}
							</div>
						</div>
					</div>
					<div className="box-footer"></div>
				</div>
			</section>
		</>

	)
}

export default ViewYearlyPayment