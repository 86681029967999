import axios from '../api/axios';

const delay = () => new Promise((res) => setTimeout(() => res(), 3000));

// AUTH SERVICES
const LOGIN = '/auth/login';
const MEMBER_LOGIN = '/auth/tenant_login';
const LOGOUT = '/auth/logout';
const MEMBER_LOGOUT = '/auth/tenant_logout';
const CHECK_EMAIL = '/auth/check_email';
const CHECK_PFNUMBER = '/auth/check_pf_number';
const SET_ADMIN_PASSWORD = '/auth/set_admin_password';
const SET_MEMBER_PASSWORD = '/auth/set_tenant_password';
const RESET_ADMIN_ACCOUNT = '/auth/reset_admin_account';
const RESET_MEMBER_ACCOUNT = '/auth/reset_tenant_account';

export async function adminLogin(data) {
    const response = await axios.post(LOGIN, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function tenantLogin(data) {
    const response = await axios.post(MEMBER_LOGIN, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}

export async function adminLogout() {
    await axios.get(LOGOUT,
        {
            withCredentials: true
        }
    );
    return
}
export async function tenantLogout() {
    await axios.get(MEMBER_LOGOUT,
        {
            withCredentials: true
        }
    );
    return
}

export async function checkAdminEmail(email) {
    const response = await axios.post(CHECK_EMAIL, { email: email })
    return response.data;
}

export async function setAdminPassword(data) {
    const response = await axios.post(SET_ADMIN_PASSWORD, data)
    return response.data;
}
export async function resetAdminAccount(data) {
    const response = await axios.patch(`${RESET_ADMIN_ACCOUNT}/${data.id}`, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function resetMemberAccount(data) {
    const response = await axios.patch(`${RESET_MEMBER_ACCOUNT}/${data.id}`, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}

// REFRESH TOKEN
const ADMIN_REFRESH_TOKEN = '/token/admin_refresh_token';
const MEMBER_REFRESH_TOKEN = '/token/tenant_refresh_token';

export async function adminRefreshToken() {
    const response = await axios.get(ADMIN_REFRESH_TOKEN, {
        withCredentials: true
    })
    return response.data;
}
export async function tenantRefreshToken() {
    const response = await axios.get(MEMBER_REFRESH_TOKEN, {
        withCredentials: true
    })
    return response.data;
}

// ADMIN USERS  API
const ADMIN_USERS = '/users/get_admin_users';
const GET_ADMIN_USER_BYID = '/users/get_admin_user';
const ADD_ADMIN_USER = '/users/add_admin_user';
const UPDATE_ADMIN_USER = '/users/update_admin_user';
const DELETE_ADMIN_USER = '/users/delete_admin_user';
const CHECK_ADMIN_USERNAME = '/users/check_admin_username';

export async function getAdminUsers() {
    await delay()
    const response = await axios.get(ADMIN_USERS, {
        withCredentials: true
    })
    return response.data;
}

export async function addAdminUser(data) {
    const response = await axios.post(ADD_ADMIN_USER, data, {
        withCredentials: true
    })
    return response.data;
}

export async function updateAdminUser(data) {
    const response = await axios.patch(`${UPDATE_ADMIN_USER}/${data.id}`, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function getAdminUserById(id) {

    const response = await axios.get(`${GET_ADMIN_USER_BYID}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}

export async function deleteAdminUser(id) {

    const response = await axios.delete(`${DELETE_ADMIN_USER}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );

    return response.data;
}

export async function checkAdminUsername(data) {
    const response = await axios.post(CHECK_ADMIN_USERNAME, { username: data },
        {
            withCredentials: true,
        })
    return response.data;
}

// PORTAL USERS  API
const PORTAL_USERS = '/users/get_portal_users';
const GET_PORTAL_USER_BYID = '/users/get_portal_user';
const ADD_PORTAL_USER = '/users/add_portal_user';
const UPDATE_PORTAL_USER = '/users/update_portal_user';
const DELETE_PORTAL_USER = '/users/delete_portal_user';


export async function getPortalUsers() {
    const response = await axios.get(PORTAL_USERS, {
        withCredentials: true
    })
    return response.data;
}

export async function addPortalUser(data) {
    const response = await axios.post(ADD_PORTAL_USER, data, {
        withCredentials: true
    })
    return response.data;
}

export async function updatePortalUser(data) {
    const response = await axios.patch(`${UPDATE_PORTAL_USER}/${data.id}`, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function getPortalUserById(id) {

    const response = await axios.get(`${GET_PORTAL_USER_BYID}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}

export async function deletePortalUser(id) {

    const response = await axios.delete(`${DELETE_PORTAL_USER}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );

    return response.data;
}

// ROLES  API
const ROLES = '/roles/get_roles';
const GET_ROLE_BYID = '/roles/get_role';
const ADD_ROLE = '/roles/add_role';
const UPDATE_ROLE = '/roles/update_role';
const DELETE_ROLE = '/roles/delete_role';

export async function getRoles() {
    const response = await axios.get(ROLES, {
        withCredentials: true
    })
    return response.data;
}

export async function addRole(data) {
    const response = await axios.post(ADD_ROLE, data, {
        withCredentials: true
    })
    return response.data;
}

export async function updateRole(data) {
    const response = await axios.patch(`${UPDATE_ROLE}/${data.id}`, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function getRoleById(id) {

    const response = await axios.get(`${GET_ROLE_BYID}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}

export async function deleteRole(id) {

    const response = await axios.delete(`${DELETE_ROLE}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}

// PERMISSIONS  API
const PERMISSIONS = '/permissions/get_permissions';
const GET_PERMISSION_BYID = '/permissions/get_permission';
const ADD_PERMISSION = '/permissions/add_permission';
const UPDATE_PERMISSION = '/permissions/update_permission';
const DELETE_PERMISSION = '/permissions/delete_permission';

export async function getPermissions() {
    const response = await axios.get(PERMISSIONS, {
        withCredentials: true
    })
    return response.data;
}

export async function addPermission(data) {
    const response = await axios.post(ADD_PERMISSION, data, {
        withCredentials: true
    })
    return response.data;
}

export async function updatePermission(data) {
    const response = await axios.patch(`${UPDATE_PERMISSION}/${data.id}`, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function getPermissionById(id) {

    const response = await axios.get(`${GET_PERMISSION_BYID}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}

export async function deletePermission(id) {

    const response = await axios.delete(`${DELETE_PERMISSION}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}


// NATIONALITY API
const NATIONALITY = '/nationality/get_nationality';
const GET_NATIONALITY_BYID = '/nationality/get_nationality';
const ADD_NATIONALITY = '/nationality/add_nationality';
const UPDATE_NATIONALITY = '/nationality/update_nationality';
const DELETE_NATIONALITY = '/nationality/delete_nationality';

export async function getNationality() {
    const response = await axios.get(NATIONALITY, {
        withCredentials: true
    })
    return response.data;
}

export async function addNationality(data) {
    const response = await axios.post(ADD_NATIONALITY, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function updateNationality(data) {
    const response = await axios.patch(`${UPDATE_NATIONALITY}/${data.id}`, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function getNationalityById(id) {

    const response = await axios.get(`${GET_NATIONALITY_BYID}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function deleteNationality(id) {
    const response = await axios.delete(`${DELETE_NATIONALITY}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}

// IDENTIFICATION API
const IDENTIFICATION = '/identification/get_identification';
const GET_IDENTIFICATION_BYID = '/identification/get_identification';
const ADD_IDENTIFICATION = '/identification/add_identification';
const UPDATE_IDENTIFICATION = '/identification/update_identification';
const DELETE_IDENTIFICATION = '/identification/delete_identification';

export async function getIdentification() {
    const response = await axios.get(IDENTIFICATION, {
        withCredentials: true
    })
    return response.data;
}

export async function addIdentification(data) {
    const response = await axios.post(ADD_IDENTIFICATION, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function updateIdentification(data) {
    const response = await axios.patch(`${UPDATE_IDENTIFICATION}/${data.id}`, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function getIdentificationById(id) {

    const response = await axios.get(`${GET_IDENTIFICATION_BYID}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function deleteIdentification(id) {
    const response = await axios.delete(`${DELETE_IDENTIFICATION}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
// RELATIONSHIP API
const RELATIONSHIP = '/relationship/get_relationship';
const GET_RELATIONSHIP_BYID = '/relationship/get_relationship';
const ADD_RELATIONSHIP = '/relationship/add_relationship';
const UPDATE_RELATIONSHIP = '/relationship/update_relationship';
const DELETE_RELATIONSHIP = '/relationship/delete_relationship';

export async function getRelationship() {
    const response = await axios.get(RELATIONSHIP, {
        withCredentials: true
    })
    return response.data;
}

export async function addRelationship(data) {
    const response = await axios.post(ADD_RELATIONSHIP, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function updateRelationship(data) {
    const response = await axios.patch(`${UPDATE_RELATIONSHIP}/${data.id}`, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function getRelationshipById(id) {

    const response = await axios.get(`${GET_RELATIONSHIP_BYID}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function deleteRelationship(id) {
    const response = await axios.delete(`${DELETE_RELATIONSHIP}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
// ZONE API
const ALL_ZONES = '/zones/all_zones';
const GET_ZONE_BYID = '/zones/get_zone';
const GET_ZONE_BYNAME = '/zones/get_zone_by_name';
const ADD_ZONE = '/zones/add_zone';
const UPDATE_ZONE = '/zones/update_zone';
const DELETE_ZONE = '/zones/delete_zone';

export async function allZones() {
    const response = await axios.get(ALL_ZONES, {
        withCredentials: true
    })
    return response.data;
}

export async function addZone(data) {
    const response = await axios.post(ADD_ZONE, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function updateZone(data) {
    const response = await axios.patch(`${UPDATE_ZONE}/${data.id}`, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function getZoneById(id) {

    const response = await axios.get(`${GET_ZONE_BYID}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}

export async function getZoneByName(id) {
    const response = await axios.post(GET_ZONE_BYNAME, {id: id},
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}

export async function deleteZone(id) {
    const response = await axios.delete(`${DELETE_ZONE}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}

// LOCATIONS API
const ALL_LOCATIONS = '/locations/all_locations';
const GET_LOCATION_BYID = '/locations/get_location';
const GET_LOCATION_BYZONE_NAME = '/locations/get_location_by_zone_name';
const GET_LOCATION_BYNAME = '/locations/get_location_by_name';
const ADD_LOCATION = '/locations/add_location';
const UPDATE_LOCATION = '/locations/update_location';
const DELETE_LOCATION = '/locations/delete_location';

export async function allLocations() {
    const response = await axios.get(ALL_LOCATIONS, {
        withCredentials: true
    })
    return response.data;
}

export async function addLocation(data) {
    const response = await axios.post(ADD_LOCATION, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function updateLocation(data) {
    const response = await axios.patch(`${UPDATE_LOCATION}/${data.id}`, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function getLocationById(id) {

    const response = await axios.get(`${GET_LOCATION_BYID}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function getLocationByName(name) {

    const response = await axios.post(GET_LOCATION_BYNAME, { zone_name: name },
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function getLocationByZoneName(zone_name) {

    const response = await axios.post(GET_LOCATION_BYZONE_NAME, { zone_name: zone_name },
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function deleteLocation(id) {
    const response = await axios.delete(`${DELETE_LOCATION}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}

// HOLIDAYS API
const HOLIDAYS = '/holidays/get_holidays';
const GET_HOLIDAY_BYID = '/holidays/get_holiday';
const ADD_HOLIDAY = '/holidays/add_holiday';
const UPDATE_HOLIDAY = '/holidays/update_holiday';
const DELETE_HOLIDAY = '/holidays/delete_holiday';

export async function getHolidays() {
    const response = await axios.get(HOLIDAYS, {
        withCredentials: true
    })
    return response.data;
}

export async function addHoliday(data) {
    const response = await axios.post(ADD_HOLIDAY, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function updateHoliday(data) {
    const response = await axios.patch(`${UPDATE_HOLIDAY}/${data.id}`, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function getHolidayById(id) {

    const response = await axios.get(`${GET_HOLIDAY_BYID}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function deleteHoliday(id) {
    const response = await axios.delete(`${DELETE_HOLIDAY}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
// BROKERS API
const BROKERS = '/brokers/get_brokers';
const GET_BROKER_BYID = '/brokers/get_broker';
const ADD_BROKER = '/brokers/add_broker';
const UPDATE_BROKER = '/brokers/update_broker';
const DELETE_BROKER = '/brokers/delete_broker';

export async function getBrokers() {
    const response = await axios.get(BROKERS, {
        withCredentials: true
    })
    return response.data;
}

export async function addBroker(data) {
    const response = await axios.post(ADD_BROKER, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function updateBroker(data) {
    const response = await axios.patch(`${UPDATE_BROKER}/${data.id}`, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function getBrokerById(id) {

    const response = await axios.get(`${GET_BROKER_BYID}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function deleteBroker(id) {
    const response = await axios.delete(`${DELETE_BROKER}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}

// BANKS API
const BANKS = '/banks/get_banks';
const GET_BANK_BYID = '/banks/get_bank';
const ADD_BANK = '/banks/add_bank';
const UPDATE_BANK = '/banks/update_bank';
const DELETE_BANK = '/banks/delete_bank';
const SEARCH_BANK = '/banks/search_bank';

export async function getBanks() {
    await delay()
    const response = await axios.get(BANKS, {
        withCredentials: true
    })
    return response.data;
}

export async function addBank(data) {
    const response = await axios.post(ADD_BANK, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function updateBank(data) {
    const response = await axios.patch(`${UPDATE_BANK}/${data.id}`, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function getBankById(id) {

    // await delay()
    const response = await axios.get(`${GET_BANK_BYID}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function deleteBank(id) {
    const response = await axios.delete(`${DELETE_BANK}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function searchBank(data) {
    const response = await axios.post(SEARCH_BANK,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
        },
        { data: data }
    );
    return response.data
}
// BANK BRANCHES API
const BANK_BRANCHES = '/banks/get_branches';
const ADD_BANK_BRANCH = '/banks/add_branch';
const GET_BANK_BRANCH_BYID = '/banks/get_branch';
const GET_BANK_BRANCH_BY_SORTCODE = '/banks/get_branch_by_sortcode';
const UPDATE_BANK_BRANCH = '/banks/update_branch';
const DELETE_BANK_BRANCH = '/banks/delete_branch';


export async function getBankBranches() {
    const response = await axios.get(BANK_BRANCHES)
    return response.data;
}

export async function addBankBranch(data) {
    const response = await axios.post(ADD_BANK_BRANCH, data)
    return (response.data);
}

export async function getBankBranchById(id) {
    const response = await axios.get(`${GET_BANK_BRANCH_BYID}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function getBankBranchBySortcode(code) {
    console.log(code)
    const response = await axios.post(GET_BANK_BRANCH_BY_SORTCODE, { branch_sortcode: code })
    return response.data
}

export async function updateBankBranch({ id, data }) {
    const response = await axios.patch(`${UPDATE_BANK_BRANCH}/${id}`, data);
    return (response.data)
}

export async function deleteBankBranch(id) {
    const response = await axios.post(`${DELETE_BANK_BRANCH}/${id}`);
    return response.data
}


// STATUS API
const STATUS = '/status/get_status';
const GET_STATUS_BYID = '/status/get_status';
const ADD_STATUS = '/status/add_status';
const UPDATE_STATUS = '/status/update_status';
const DELETE_STATUS = '/status/delete_status';

export async function getStatus() {
    const response = await axios.get(STATUS, {
        withCredentials: true
    })
    return response.data;
}

export async function addStatus(data) {
    const response = await axios.post(ADD_STATUS, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function updateStatus(data) {
    const response = await axios.patch(`${UPDATE_STATUS}/${data.id}`, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function getStatusById(id) {

    const response = await axios.get(`${GET_STATUS_BYID}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function deleteStatus(id) {
    const response = await axios.delete(`${DELETE_STATUS}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
// INVESTMENT CATEGORIES API
const INVESTMENT_CATEGORIES = '/investment_category/get_investment_categories';
const GET_INVESTMENT_CATEGORY_BYID = '/investment_category/get_investment_category';
const ADD_INVESTMENT_CATEGORY = '/investment_category/add_investment_category';
const UPDATE_INVESTMENT_CATEGORY = '/investment_category/update_investment_category';
const DELETE_INVESTMENT_CATEGORY = '/investment_category/delete_investment_category';

export async function getInvestmentCategories() {
    const response = await axios.get(INVESTMENT_CATEGORIES, {
        withCredentials: true
    })
    return response.data;
}

export async function addInvestmentCategories(data) {
    const response = await axios.post(ADD_INVESTMENT_CATEGORY, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function updateInvestmentCategories(data) {
    const response = await axios.patch(`${UPDATE_INVESTMENT_CATEGORY}/${data.id}`, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function getInvestmentCategoriesById(id) {

    const response = await axios.get(`${GET_INVESTMENT_CATEGORY_BYID}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function deleteInvestmentCategories(id) {
    const response = await axios.delete(`${DELETE_INVESTMENT_CATEGORY}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}

// TITLE API
const TITLES = '/titles/get_titles';
const GET_TITLE_BYID = '/titles/get_title';
const ADD_TITLE = '/titles/add_title';
const UPDATE_TITLE = '/titles/update_title';
const DELETE_TITLE = '/titles/delete_title';

export async function getTitles() {
    const response = await axios.get(TITLES, {
        withCredentials: true
    })
    return response.data;
}

export async function addTitle(data) {
    const response = await axios.post(ADD_TITLE, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function updateTitle(data) {
    const response = await axios.patch(`${UPDATE_TITLE}/${data.id}`, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function getTitleById(id) {

    const response = await axios.get(`${GET_TITLE_BYID}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function deleteTitle(id) {
    const response = await axios.delete(`${DELETE_TITLE}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}


// FILE UPLOADS

const UPLOAD_MEMBERS = "/file_uploads/upload_tenants"
const UPLOAD_REGIONS = "/file_uploads/upload_regions"
const UPLOAD_CONSTITUENCIES = "/file_uploads/upload_constituencies"
const UPLOAD_BRANCHES = "/api/uploads/upload_branches"
const ACCOUNTS = "/file_uploads/upload_accounts"
const UPLOAD_CARDS = "/file_uploads/upload_cards"
const EMAILS = "/file_uploads/upload_emails"
const UPLOAD_TITLES = "/file_uploads/upload_titles"
const UPLOAD_EMAILS = "/file_uploads/upload_emails"
const UPLOAD_BANKS = "/file_uploads/upload_banks"
const UPLOAD_BANK_BRANCHES = "/file_uploads/upload_bank_branches"

export async function uploadMembers(data) {
    const response = await axios.post(UPLOAD_MEMBERS, data,
        {
            withCredentials: true
        }
    );
    return response.data;
}
export async function uploadRegions(data) {
    const response = await axios.post(UPLOAD_REGIONS, data,
        {
            withCredentials: true
        }
    );
    return response.data;
}
export async function uploadConstituencies(data) {
    const response = await axios.post(UPLOAD_CONSTITUENCIES, data,
        {
            withCredentials: true
        }
    );
    return response.data;
}
export async function uploadBranches(data) {
    const response = await axios.post(UPLOAD_BRANCHES, data,
        {
            withCredentials: true
        }
    );
    return response.data;
}
export async function uploadTitles(data) {
    const response = await axios.post(UPLOAD_TITLES, data,
        {
            withCredentials: true
        }
    );
    return response.data;
}
export async function uploadBanks(data) {
    const response = await axios.post(UPLOAD_BANKS, data,
        {
            withCredentials: true
        }
    );
    return response.data;
}
export async function uploadBankBranches(data) {
    const response = await axios.post(UPLOAD_BANK_BRANCHES, data,
        {
            withCredentials: true
        }
    );
    return response.data;
}
export async function uploadEmails(data) {
    const response = await axios.post(UPLOAD_EMAILS, data,
        {
            withCredentials: true
        }
    );
    return response.data;
}


// CATEGORY  API
const CATEGORIES = '/categories/get_categories';
const GET_CATEGORY_BYID = '/categories/get_category';
const ADD_CATEGORY = '/categories/add_category';
const UPDATE_CATEGORY = '/categories/update_category';
const DELETE_CATEGORY = '/categories/delete_category';

export async function getCategories() {
    const response = await axios.get(CATEGORIES, {
        withCredentials: true
    })
    return response.data;
}

export async function addCategory(data) {
    const response = await axios.post(ADD_CATEGORY, data, {
        withCredentials: true
    })
    return response.data;
}

export async function updateCategory(data) {
    const response = await axios.patch(`${UPDATE_CATEGORY}/${data.id}`, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function getCategoryById(id) {
    const response = await axios.get(`${GET_CATEGORY_BYID}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}

export async function deleteCategory(id) {
    const response = await axios.delete(`${DELETE_CATEGORY}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}

// PRODUCTS  API
const PRODUCTS = '/products/get_products';
const GET_PRODUCT_BYID = '/products/get_product';
const ADD_PRODUCT = '/products/add_product';
const UPDATE_PRODUCT = '/products/update_product';
const DELETE_PRODUCT = '/products/delete_product';

export async function getProducts() {
    const response = await axios.get(PRODUCTS, {
        withCredentials: true
    })
    return response.data;
}

export async function addProduct(data) {
    const response = await axios.post(ADD_PRODUCT, data, {
        withCredentials: true
    })
    return response.data;
}

export async function updateProduct(data) {
    const response = await axios.patch(`${UPDATE_PRODUCT}/${data.id}`, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function getProductById(id) {
    const response = await axios.get(`${GET_PRODUCT_BYID}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}

export async function deleteProduct(id) {
    const response = await axios.delete(`${DELETE_PRODUCT}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}

// SUPPLIERS  API
const SUPPLIERS = '/suppliers/get_suppliers';
const GET_SUPPLIER_BYID = '/suppliers/get_supplier';
const ADD_SUPPLIER = '/suppliers/add_supplier';
const UPDATE_SUPPLIER = '/suppliers/update_supplier';
const DELETE_SUPPLIER = '/suppliers/delete_supplier';

export async function getSuppliers() {
    const response = await axios.get(SUPPLIERS, {
        withCredentials: true
    })
    return response.data;
}

export async function addSupplier(data) {
    const response = await axios.post(ADD_SUPPLIER, data, {
        withCredentials: true
    })
    return response.data;
}

export async function updateSupplier(data) {
    const response = await axios.patch(`${UPDATE_SUPPLIER}/${data.id}`, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function getSupplierById(id) {
    const response = await axios.get(`${GET_SUPPLIER_BYID}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}

export async function deleteSupplier(id) {
    const response = await axios.delete(`${DELETE_SUPPLIER}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}



const ALL_LEDGERS = '/ledgers/all_ledgers';
const GET_LEDGER_BY_ID = '/ledgers/get_ledger';
const GET_LEDGER = '/ledgers/get_ledger';
const UPDATE_LEDGER = '/ledgers/update_ledger';
const DELETE_LEDGER = '/ledgers/delete_ledger';
const SAVE_LEDGER = '/ledgers/save_ledger';
const CHECK_LEDGER_CONTROL = '/ledgers/check_ledger_control';
const CREATE_YEAR_LEDGERS = '/ledgers/create_year_ledgers';
const GOOD_STANDING_MEMBERS = "/ledgers/tenants_in_goodstanding"
const MEMBERS_IN_ARREARS = "/ledgers/tenants_in_arrears"

const SEARCH_LEDGER = '/ledgers/search_ledger';
const SEARCH_ARREARS = '/ledgers/search_arrears';
const SEARCH_GOODSTANDING = '/ledgers/search_goodstanding';

export async function getAllLedgers() {
    const response = await axios.get(ALL_LEDGERS)
    return response.data;
}
export async function getLedgerByID(id) {
    const response = await axios.get(GET_LEDGER_BY_ID + `/${id}`);
    return response.data;
}
export async function saveLedger(data) {
    const response = await axios.post(SAVE_LEDGER, data)
    return response.data;
}
export async function getLedger(data) {
    const response = await axios.post(GET_LEDGER, data)
    return response.data;
}

export async function updateLedger(data) {
    const response = await axios.post(UPDATE_LEDGER, data);
    return response.data
}
export async function deleteLedger(data) {
    const response = await axios.post(DELETE_LEDGER, data);
    return response.data
}
export async function searchMemberLedger(data) {
    const response = await axios.post(SEARCH_LEDGER, data);
    return response.data
}
export async function searchArrears(data) {
    const response = await axios.post(SEARCH_ARREARS, data);
    return response.data
}
export async function searchGoodStanding(data) {
    const response = await axios.post(SEARCH_GOODSTANDING, data);
    return response.data
}
export async function checkLedgerControl(year) {
    const response = await axios.post(CHECK_LEDGER_CONTROL, { year: year });
    return response.data
}
export async function createYearLedgers(data) {
    const response = await axios.post(CREATE_YEAR_LEDGERS, data);
    return response.data
}

export const getGoodStandingMembers = async () => {
    const response = await axios.get(GOOD_STANDING_MEMBERS)
    return response.data
}
export const getArrearsMembers = async () => {
    const response = await axios.get(MEMBERS_IN_ARREARS)
    return response.data
}



// DASHBOARD APIs
const VERIFY_USER = '/dashboard/verify_user';
const DASHBOARD_INFO = '/dashboard/get_dashboard_info';
const DASHBOARD = '/dashboard/get_dashboard';
const MEMBERSHIP_SUMMARY = '/dashboard/tenantship_summary';

export async function verifyUser() {
    const response = await axios.get(VERIFY_USER)
    return response.data;
}

export async function getDashboardInfo() {
    const response = await axios.get(DASHBOARD_INFO)
    return response.data
}
export async function getDashboard() {
    const response = await axios.get(DASHBOARD)
    return response.data
}
export async function tenantshipSummary() {
    const response = await axios.get(MEMBERSHIP_SUMMARY)
    return response.data
}

const ALL_PRESIDENTS = '/presidents/get_presidents';
const GET_PRESIDENT_BYID = '/presidents/get_president';
const ADD_PRESIDENT = '/presidents/add_president';
const UPDATE_PRESIDENT = '/presidents/update_president';
const DELETE_PRESIDENT = '/presidents/delete_president';
const FIND_EMAIL = "/presidents/find_email"
const FIND_USER_BYEMAIL = "/presidents/find_user_email"

export async function getPresidents(res, req, next) {
    const response = await axios.get(ALL_PRESIDENTS)
    return (response.data);
}

export async function getPresidentById(id) {
    const response = await axios.get(GET_PRESIDENT_BYID + `/${id}`)
    return (response.data)
}
export function savePresident(data) {
    return data.id ? updatePresident(data) : createPresident(data)
}

export async function createPresident(data) {
    const response = await axios.post(ADD_PRESIDENT, data)
    return (response.data);
}
async function updatePresident(data) {
    const response = await axios.patch(UPDATE_PRESIDENT + `/${data.id}`, data);
    return (response.data)
}

export async function deletePresident(id) {
    const response = await axios.delete(DELETE_PRESIDENT + `/${id}`);
    return (response.data)
}
export async function findPresidentByEmail(email) {
    const response = await axios.post(FIND_EMAIL, { email: email })
    return response.data
}
export async function findUserByEmail(email) {
    const response = await axios.post(FIND_USER_BYEMAIL, { email: email })
    return response.data
}

// TENANT API
const ALL_TENANTS = '/tenants/all_tenants';
const GET_TENANT_BYID = '/tenants/get_tenant';
const ADD_TENANT = '/tenants/add_tenant';
const UPDATE_TENANT = '/tenants/update_tenant';
const DELETE_TENANT = '/tenants/delete_tenant';

const SEARCH_TENANT = '/tenants/search_tenant';
const GENERAL_SEARCH = '/tenants/general_search';
const SPECIAL_SEARCH = '/tenants/special_search';

const SEARCH_TENANT_PAYMENT = '/tenants/search_tenant_payment';
const SEARCH_TENANT_ACCOUNT = '/tenants/search_tenant_account';

const PENDING_APPROVALS = '/tenants/pending_approvals';
const APPROVE_REGISTRATION = '/tenants/approve_registration';
const GET_APPROVED_TENANT = '/tenants/get_approved_tenant';
const APPROVED_TENANTS = '/tenants/approved_tenants';
const REJECTED_TENANTS = '/tenants/rejected_tenants';
const FIND_TENANT_BYCODE = "/tenants/find_tenant_by_code"
const FIND_TENANT_BYPHONE = "/tenants/find_tenant_by_phone"

const PURCHASE_TENANTS = "/tenants/get_purchase_tenants"
const REGULARIZATION_TENANTS = "/tenants/get_regularization_tenants"
const TRANSFER_TENANTS = "/tenants/get_transfer_tenants"
const OTHERS_TENANTS = "/tenants/get_others_tenants"
const GET_ZONAL_TENANTS = "/tenants/get_zonal_tenants"
const OFFICE_PAYMENT = "/tenants/office_payment"

const TENANT_EXISTS = "/tenants/tenant_exists"


export async function AllTenants() {
    await delay()
    const response = await axios.get(ALL_TENANTS);
    return response.data;
}
export async function getTenantById(id) {
    const response = await axios.get(GET_TENANT_BYID + `/${id}`)
    return response.data;
}
export async function addTenant(data) {
    const response = await axios.post(ADD_TENANT, data);
    return response.data;
}

export async function updateTenant(data) {
    const response = await axios.patch(UPDATE_TENANT +`/${data.id}`, data);
    return (response.data)
}

export async function deleteTenant(id) {
    const response = await axios.delete(DELETE_TENANT + `/${id}`);
    return (response.data)
}

export async function searchTenant(data) {
    const response = await axios.post(SEARCH_TENANT, data)
    return (response.data)
}
export async function generalSearch(data) {
    const response = await axios.post(GENERAL_SEARCH, data)
    return (response.data)
}
export async function specialSearch(data) {
    const response = await axios.post(SPECIAL_SEARCH, data)
    return (response.data)
}
export async function searchTenantPayment(data) {
    const response = await axios.post(SEARCH_TENANT_PAYMENT, data)
    return (response.data)
}
export async function searchTenantAccount(data) {
    const response = await axios.post(SEARCH_TENANT_ACCOUNT, data)
    return (response.data)
}
export async function findTenantByCode(code) {
    const response = await axios.post(FIND_TENANT_BYCODE, { tenant_code: code })
    return response.data
}
export async function findTenantByPhone(phone) {
    const response = await axios.post(FIND_TENANT_BYPHONE, { contact_number: phone })
    return response.data
}

export async function getPurchaseTenants() {
    const response = await axios.get(PURCHASE_TENANTS)
    return response.data;
}
export async function getRegularizationTenants() {
    const response = await axios.get(REGULARIZATION_TENANTS)
    return response.data;
}
export async function getTransferTenants() {
    const response = await axios.get(TRANSFER_TENANTS)
    return response.data;
}
export async function getOthersTenants() {
    const response = await axios.get(OTHERS_TENANTS)
    return response.data;
}

export async function pendingApprovals() {
    const response = await axios.get(PENDING_APPROVALS);
    return response.data
}
export async function approveRegistration(data) {
    const response = await axios.post(APPROVE_REGISTRATION, data);
    return response.data
}
export async function getApproveTenant(id) {
    const response = await axios.post(GET_APPROVED_TENANT, { id: id });
    return response.data
}
export async function approvedTenants() {
    const response = await axios.get(APPROVED_TENANTS);
    return response.data
}
export async function rejectedTenants() {
    const response = await axios.get(REJECTED_TENANTS);
    return response.data
}

export const getZonalTenants = async (code) => {
    const response = await axios.post(GET_ZONAL_TENANTS, { zone_code: code })
    return response.data
}
export const officePayment = async (code) => {
    const response = await axios.post(OFFICE_PAYMENT, { tenant_code: code })
    return response.data
}
export const tenantExists = async (data) => {
    const response = await axios.post(TENANT_EXISTS,  data)
    return response.data
}




const ALL_REGIONAL_REPORTS = '/reports/all_regional_reports';
const ADD_REGIONAL_REPORT = '/reports/add_regional_report';
const GET_REGIONAL_REPORT_BY_ID = '/reports/get_regional_report';
const UPDATE_REGIONAL_REPORT = '/reports/update_regional_report';
const DELETE_REGIONAL_REPORT = '/reports/delete_record';
const FILTER_REPORT = '/reports/filter_report';


export async function getAllRegionalReports() {
    const response = await axios.get(ALL_REGIONAL_REPORTS)
    return response.data;
}

export async function addRegionalReport(data) {
    const response = await axios.post(ADD_REGIONAL_REPORT, data)
    return response.data;
}

export async function getRegionalReportById(id) {
    const response = await axios.get(GET_REGIONAL_REPORT_BY_ID + `/${id}`)
    return response.data
}

export async function updateRegionalReport({ id, data }) {
    const response = await axios.post(UPDATE_REGIONAL_REPORT + `/${id}`, data);
    return (response.data)
}

export async function deleteRegionalReport(id) {
    const response = await axios.post(DELETE_REGIONAL_REPORT + `/${id}`);
    return response.data
}
export async function filterReport(data) {
    const response = await axios.post(FILTER_REPORT, data);
    return response.data
}

const ALL_MESSAGES = '/messages/all_messages';
const GET_MESSAGE = '/messages/get_message';
const ADD_MESSAGE = '/messages/add_message';
const UPDATE_MESSAGE = '/messages/update_message';
const DELETE_MESSAGE = '/messages/delete_record';

export async function getAllMessages() {
    const response = await axios.get(ALL_MESSAGES)
    return response.data;
}

export async function getMessageById(id) {
    const response = await axios.get(GET_MESSAGE + `/${id}`)
    return response.data
}

export async function addMessage(data) {
    const response = await axios.post(ADD_MESSAGE, data)
    return response.data;
}

export async function updateMessage(data) {
    const response = await axios.post(UPDATE_MESSAGE, data);
    return response.data
}

export async function deleteMessage(id) {
    const response = await axios.post(DELETE_MESSAGE, { id: id });
    return (response.data)
}


const CARDS = '/cards/all_cards';
const GET_CARD_BYID = '/cards/get_card';
const ADD_CARD = '/cards/add_card';
const UPDATE_CARD = '/cards/update_card';
const DELETE_CARD = '/cards/delete_record';

const CARDSPAGE = '/cards/all_cards?page=';
const FETCH_CARDS = '/cards/fetch_cards?page=';
const SENT_TO_PRINTER = '/cards/sent_to_printer';
const PENDING_CARDS = '/cards/pending_cards';

const SEARCH_CARD = '/cards/search_card';
const SEND_TO_PRINTER = '/cards/send_to_printer';
const UPDATE_SENT_TO_PRINTER = '/cards/update_send_to_printer';

export async function getCards() {
    const response = await axios.get(CARDS)
    return response.data;
}
export async function getCardById(id) {
    const response = await axios.get(GET_CARD_BYID + `/${id}`)
    return response.data;
}

export async function searchCard(data) {
    const response = await axios.post(SEARCH_CARD, data)
    return (response.data)
}

export async function addCard(data) {
    const response = await axios.post(ADD_CARD, data)
    return (response.data);
}

export async function updateCard(data) {
    const response = await axios.post(UPDATE_CARD, data);
    return (response.data)
}

export async function deleteCard(id) {
    const response = await axios.post(DELETE_CARD, { id: id });
    return response.data
}

export const getCardsPage = async (pageParam = 1) => {
    const response = await axios.get(CARDSPAGE + `${pageParam}?limit=20`);
    return response.data
}

export async function fetchCards(page = 1) {
    const response = await axios.get(CARDSPAGE + `${page}`)
    return response.data
}
export async function toPrinter() {
    const response = await axios.get(SENT_TO_PRINTER)
    return response.data
}
export async function pendingCards() {
    const response = await axios.get(PENDING_CARDS)
    return response.data
}

export async function sendToPrinter() {
    const response = await axios.get(SEND_TO_PRINTER)
    return response.data
}
export async function updateSentToPrinter() {
    const response = await axios.post(UPDATE_SENT_TO_PRINTER);
    return (response.data)
}
export const getCardsPage1 = async (pageParam = 1, options = {}) => {
    const response = await axios.get(FETCH_CARDS + `${pageParam}`, options);
    return response.data
}


const YEARLY_PAYMENTS = '/yearly_payments/get_yearly_payments';
const GET_YEARLY_PAYMENT_BYID = '/yearly_payments/get_yearly_payment';
const ADD_YEARLY_PAYMENT = '/yearly_payments/add_yearly_payment';
const UPDATE_YEARLY_PAYMENT = '/yearly_payments/update_yearly_payment';
const DELETE_YEARLY_PAYMENT = '/yearly_payments/delete_yearly_payment';
const GET_PREVIOUS_YEAR_PAYMENT = '/yearly_payments/get_previous_year_payment';


export async function getYearlyPayments() {
    const response = await axios.get(YEARLY_PAYMENTS)
    return response.data;
}

export async function getYearlyPaymentById(id) {
    const response = await axios.get(GET_YEARLY_PAYMENT_BYID + `/${id}`)
    return response.data
}

export async function addYearlyPayment(data) {
    const response = await axios.post(ADD_YEARLY_PAYMENT, data)
    return response.data;
}

export async function updateYearlyPayment(data) {
    const response = await axios.patch(UPDATE_YEARLY_PAYMENT + `/${data.id}`, data);
    return response.data
}

export async function deleteYearlyPayment(id) {
    const response = await axios.delete(DELETE_YEARLY_PAYMENT + `/${id}`);
    return response.data
}
export async function getPreviousYearPayment(year) {
    const response = await axios.post(GET_PREVIOUS_YEAR_PAYMENT, { year: year })
    return response.data;
}


const PAYMENT_TYPES = '/yearly_payments/get_payment_types';
const OTHER_PAYMENT_TYPES = '/yearly_payments/get_other_payment_type';
const GET_PAYMENT_TYPE_BYID = '/yearly_payments/get_payment_type';
const ADD_PAYMENT_TYPE = '/yearly_payments/add_payment_type';
const UPDATE_PAYMENT_TYPE = '/yearly_payments/update_payment_type';
const DELETE_PAYMENT_TYPE = '/yearly_payments/delete_payment_type';
const GET_PAYMENT_TYPE_BYCODE = '/yearly_payments/get_payment_type';


export async function getPaymentTypes() {
    const response = await axios.get(PAYMENT_TYPES)
    return response.data;
}
export async function getOtherPaymentTypes() {
    const response = await axios.get(OTHER_PAYMENT_TYPES)
    return response.data;
}

export async function getPaymentTypeById(id) {
    const response = await axios.get(GET_PAYMENT_TYPE_BYID + `/${id}`)
    return response.data
}

export async function addPaymentType(data) {
    const response = await axios.post(ADD_PAYMENT_TYPE, data)
    return response.data;
}

export const updatePaymentType = async (data) => {
    const response = await axios.patch(UPDATE_PAYMENT_TYPE + `/${data.id}`, data);
    return response.data
}

export async function deletePaymentType(id) {
    const response = await axios.delete(DELETE_PAYMENT_TYPE + `/${id}`);
    return response.data
}

export async function getPaymentTypeByCode(code) {
    const response = await axios.post(GET_PAYMENT_TYPE_BYCODE, { type_code: code })
    return response.data;
}

const PAYMENT_MODES = '/pay_modes/get_payment_modes';
const GET_PAYMENT_MODE_BYID = '/pay_modes/get_payment_mode';
const ADD_PAYMENT_MODE = '/pay_modes/add_payment_mode';
const UPDATE_PAYMENT_MODE = '/pay_modes/update_payment_mode';
const DELETE_PAYMENT_MODE = '/pay_modes/delete_payment_mode';
const GET_PAYMENT_MODE_BYCODE = '/pay_modes/get_payment_mode_by_code';


export async function getPaymentModes() {
    const response = await axios.get(PAYMENT_MODES)
    return response.data;
}

export async function getPaymentModeById(id) {
    const response = await axios.get(GET_PAYMENT_MODE_BYID + `/${id}`)
    return response.data
}

export async function addPaymentMode(data) {
    const response = await axios.post(ADD_PAYMENT_MODE, data)
    return response.data;
}

export const updatePaymentMode = async (data) => {
    const response = await axios.patch(UPDATE_PAYMENT_MODE + `/${data.id}`, data);
    return response.data
}

export async function deletePaymentMode(id) {
    const response = await axios.delete(DELETE_PAYMENT_MODE + `/${id}`);
    return response.data
}

export async function getPaymentModeByCode(code) {
    const response = await axios.post(GET_PAYMENT_MODE_BYCODE, { payment_mode: code })
    return response.data;
}



const TYPES = '/payments/all_types';
const GET_TYPE = '/payments/get_type';
const ADD_TYPE = '/payments/add_type';
const UPDATE_TYPE = '/payments/update_type';
const DELETE_TYPE = '/payments/delete_record';
const GET_TYPE_NAME = '/payments/get_type_name';
const GET_TYPE_AMOUNT = '/payments/get_type_amount';

const GET_ALL_PAYMENTS = '/payments/get_all_payments';
const SAVE_PAYMENT = '/payments/save_payment';
const GET_PAYMENT_BYPF = '/payments/get_payment';
const GET_PAYMENTS = '/payments/get_payments';
const GET_PAYMENTS_BY_MODE = '/payments/get_payments_by_mode';
const GET_PAYMENTS_BY_CODE = '/payments/get_payments_by_code';
const GET_PAYMENTS_BY_TRANS_ID = '/payments/get_payment_by_transaction_id';
const GET_ACCOUNT = '/payments/get_account';
const UPDATE_ACCOUNT = '/payments/update_account';
const DETAILED_STATEMENT = '/payments/detailed_statement';
const SUMMARY_STATEMENT = '/payments/summary_statement';
const GENERATE_PDF = '/payments/generate_pdf';

const GET_TYPES = '/payments/get_paytypes';
const GET_TYPECODE = '/payments/get_typecode';
const ADD_TYPES = '/payments/add_paytypes';
const UPDATE_TYPES = '/payments/update_paytypes';

const GENERATE_DETAILED_STATEMENT = '/payments/generate_detailed_statement';
const GENERATE_SUMMARY_STATEMENT = '/payments/generate_summary_statement';
const SEARCH_PAYMENT = '/payments/search_payment';


export async function getType(id) {
    const response = await axios.get(GET_TYPE + `/${id}`)
    return response.data
}
export function saveType(id, data) {

    return id ? updateType(data) : createType(data)
}

export async function createType(data) {
    const response = await axios.post(ADD_TYPE, data)
    return response.data;
}

async function updateType(data) {
    const response = await axios.post(UPDATE_TYPE, data);
    return response.data
}

export async function deleteType(id) {
    const response = await axios.post(DELETE_TYPE, { id: id });
    return response.data
}
export async function getTypeName(code) {
    const response = await axios.post(GET_TYPE_NAME, { type_code: code });
    return response.data
}
export async function getTypeAmount(code) {
    const response = await axios.post(GET_TYPE_AMOUNT, { payment_code: code });
    return response.data
}

// PAYMENT SECTION
export async function getAllPayments() {
    const response = await axios.get(GET_ALL_PAYMENTS)
    return response.data;
}
export async function getAccount(id) {
    const response = await axios.get(GET_ACCOUNT + `/${id}`);
    return (response.data);
}
export async function savePayment(data) {
    const response = await axios.post(SAVE_PAYMENT, data)
    return response.data;
}
export async function getPayments(data) {
    const response = await axios.post(GET_PAYMENTS, data)
    return (response.data);
}
export async function getPaymentsByMode(data) {
    const response = await axios.post(GET_PAYMENTS_BY_MODE, data)
    return (response.data);
}
export async function getTenantPaymentByCode(code) {
    const response = await axios.post(GET_PAYMENTS_BY_CODE, { tenant_code: code })
    return (response.data);
}
export async function getPaymentByTransactionId(trans_id) {
    const response = await axios.post(GET_PAYMENTS_BY_TRANS_ID, { transaction_id: trans_id })
    return (response.data);
}
export async function getPaymentByPf(pf_number) {
    const response = await axios.post(GET_PAYMENT_BYPF, { pf_number: pf_number });
    return response.data;
}

export async function updateAccount(data) {
    const response = await axios.post(UPDATE_ACCOUNT, data);
    return (response.data)
}

export async function detailedStatement(data) {
    const response = await axios.post(DETAILED_STATEMENT, data)
    return (response.data);
}
export async function summaryStatement(data) {
    const response = await axios.post(SUMMARY_STATEMENT, data)
    return (response.data);
}
export async function generatePDF(data) {
    const response = await axios.post(GENERATE_PDF, data)
    return (response.data);
}

export async function getPayTypes() {
    const response = await axios.get(GET_TYPES)
    return (response.data)
}
export async function getPayTypeCode(code) {
    const response = await axios.post(GET_TYPECODE, { description: code })
    return response.data
}
export function savePayTypes(id, data) {
    return id ? updatePayTypes(data) : createPayTypes(data)
}

export async function createPayTypes(data) {
    const response = await axios.post(ADD_TYPES, data)
    return (response.data);
}

async function updatePayTypes(data) {
    const response = await axios.post(UPDATE_TYPES, data);
    return response.data
}

export async function generateDetailedStatement(data) {
    const response = await axios.post(GENERATE_DETAILED_STATEMENT, data)
    return (response.data);
}
export async function generateSummaryStatement(data) {
    const response = await axios.post(GENERATE_SUMMARY_STATEMENT, data)
    return (response.data);
}


// Card/Momo/Cheque Payment
const GET_PAYMENT_CLEARANCE = '/payments/get_payment_clearance';
const ADD_CARD_PAYMENT = '/payments/add_card_payment';
const ADD_MOMO_PAYMENT = '/payments/add_momo_payment';
const ADD_CHEQUE_PAYMENT = '/payments/add_cheque_payment';
const SAVE_ONLINE_PAYMENT = '/payments/save_online_payment';
const UPDATE_PAYMENT_CLEARANCE = '/payments/update_payment_clearance';
const DELETE_PAYMENT_CLEARANCE = '/payments/delete_payment_clearance';

export async function getPaymentClearance() {
    const response = await axios.get(GET_PAYMENT_CLEARANCE)
    return response.data;
}
export async function getPaymentClearanceById(id) {
    const response = await axios.get(GET_PAYMENT_CLEARANCE + `/${id}`)
    return response.data;
}

export async function addCardPayment(data) {
    const response = await axios.post(ADD_CARD_PAYMENT, data)
    return response.data;
}
export async function addMomoPayment(data) {
    const response = await axios.post(ADD_MOMO_PAYMENT, data)
    return response.data;
}
export async function addChequePayment(data) {
    const response = await axios.post(ADD_CHEQUE_PAYMENT, data)
    return response.data;
}
export async function saveOnlinePayment(data) {
    const response = await axios.post(SAVE_ONLINE_PAYMENT, data)
    return response.data;
}
export async function updatePaymentClearance(id) {
    return await axios.patch(UPDATE_PAYMENT_CLEARANCE + `/${id}`);

}
export async function deletePaymentClearance(id) {
    return await axios.delete(DELETE_PAYMENT_CLEARANCE + `/${id}`);

}

export async function searchPayment(data) {
    const response = await axios.post(SEARCH_PAYMENT, data)
    return response.data
}


// INTEREST API
const INTEREST_RATES = '/rates/interest_rates';
const INTEREST_BYID = '/rates/interest_rate';
const ADD_INTEREST = '/rates/add_interest_rate';
const UPDATE_INTEREST = '/rates/update_interest_rate';
const DELETE_INTEREST = '/rates/delete_interest_rate';

export async function getInterestRates() {
    const response = await axios.get(INTEREST_RATES, {
        withCredentials: true
    })
    return response.data;
}

export async function addInterestRates(data) {
    const response = await axios.post(ADD_INTEREST, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function updateInterestRates(data) {
    const response = await axios.patch(`${UPDATE_INTEREST}/${data.id}`, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function getInterestRatesById(id) {

    const response = await axios.get(`${INTEREST_BYID}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}

export async function deleteInterestRates(id) {
    const response = await axios.delete(`${DELETE_INTEREST}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}



// SALES REPS API
const SALES_REPS = '/sales_reps/get_sales_reps';
const GET_SALES_REP_BYID = '/sales_reps/get_sales_rep';
const ADD_SALES_REP = '/sales_reps/add_sales_rep';
const UPDATE_SALES_REP = '/sales_reps/update_sales_rep';
const DELETE_SALES_REP = '/sales_reps/delete_sales_rep';

export async function getSalesReps() {
    const response = await axios.get(SALES_REPS, {
        withCredentials: true
    })
    return response.data;
}

export async function addSalesRep(data) {
    const response = await axios.post(ADD_SALES_REP, data, {
        withCredentials: true
    })
    return response.data;
}

export async function updateSalesRep(data) {
    const response = await axios.patch(`${UPDATE_SALES_REP}/${data.id}`, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function getSalesRepById(id) {

    const response = await axios.get(`${GET_SALES_REP_BYID}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}

export async function deleteSalesRep(id) {

    const response = await axios.delete(`${DELETE_SALES_REP}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}


// SIGNATORIES API
const SIGNATURES = '/signatures/get_signatures';
const GET_SIGNATURE_BYID = '/signatures/get_signature';
const ADD_SIGNATURE = '/signatures/add_signature';
const UPDATE_SIGNATURE = '/signatures/update_signature';
const DELETE_SIGNATURE = '/signatures/delete_signature';

export async function getSignatures() {
    const response = await axios.get(SIGNATURES, {
        withCredentials: true
    })
    return response.data;
}

export async function addSignature(data) {
    const response = await axios.post(ADD_SIGNATURE, data, {
        withCredentials: true
    })
    return response.data;
}

export async function updateSignature(data) {
    const response = await axios.patch(`${UPDATE_SIGNATURE}/${data.id}`, data,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}
export async function getSignatureById(id) {

    const response = await axios.get(`${GET_SIGNATURE_BYID}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}

export async function deleteSignature(id) {

    const response = await axios.delete(`${DELETE_SIGNATURE}/${id}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    return response.data;
}

// INDENTURE NUMBER GENERATION
const GET_LAST_INDENTURE_NUMBER = '/indenture/get_last_indenture_number';
const GENERATE_INDENTURE_NUMBER = '/indenture/generate_indenture_number';

export async function getLastIndentureNumber() {
    const response = await axios.get(GET_LAST_INDENTURE_NUMBER, {
        withCredentials: true
    })
    return response.data;
}
export async function generateIndentureNumber() {
    const response = await axios.get(GENERATE_INDENTURE_NUMBER, {
        withCredentials: true
    })
    return response.data;
}


const SAVE_RECEIPT = '/receipts/save_payment_receipt';

export async function savePaymentReceipt(data) {
    const response = await axios.post(SAVE_RECEIPT, data)
    return response.data;
}

