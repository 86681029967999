import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { getArrearsMembers, searchArrears } from "../../../../api/apiServices";
import Pagination from '../../../../services/Pagination';
import 'react-confirm-alert/src/react-confirm-alert.css';
import toast, { Toaster } from 'react-hot-toast'
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


const InArrears = () => {

	const navigate = useNavigate();
	const location = useLocation();
	const from = location.state?.from?.pathname || "/ledger_accounts";

	const [search, setSearch] = useState(false);
	const [member, setMember] = useState([]);
	const [pf_number, setPFNumber] = useState(null);
	const [mobile_number, setMobileNumber] = useState(null);
	const [first_name, setFirstName] = useState(null);
	const [last_name, setLastName] = useState(null);

	const [currentPage, setCurrentPage] = useState(1);
	const [postPerPage, setPostPerPage] = useState(20);

	const {
		isLoading,
		isError,
		error,
		data: arrears,
	} = useQuery('arrears', getArrearsMembers, {
		select: data => data.sort((a, b) => b.id - a.id),
	});

	let total_arrears = arrears?.length

	const handleSearch = async (e) => {
		e.preventDefault();

		const searchData = {
			"pf_number": pf_number,
			"mobile_number": mobile_number,
			"first_name": first_name,
			"last_name": last_name,
		}
		try {
			await searchArrears(searchData).then((res) => {
				if (res.length > 0) {
					setMember(res)
					setPFNumber("");
					setMobileNumber("");
					setFirstName("");
					setLastName("");
					setSearch(true)
				} else {
					toast.info("No Ledger found for member")
					setSearch(false)
				}
			}).catch((err) => {
				toast.error("least one search parameter ")
				return false
			})
		} catch (err) {
			toast.error(err)
		}
	}

	const handleCancel = () => {
		setPFNumber("");
		setMobileNumber("");
		setFirstName("");
		setLastName("");
		setSearch(false);
		navigate(-1);
	}

	let currentPosts = []
	const indexOfLastPage = currentPage * postPerPage;
	const indexOfFirstPage = indexOfLastPage - postPerPage;
	if (!search) {
		currentPosts = arrears?.slice(indexOfFirstPage, indexOfLastPage);
	} else {
		currentPosts = member?.slice(indexOfFirstPage, indexOfLastPage);
	}

	let searchContent = (
		<form onSubmit={handleSearch} >
			<div className="form-group row">
				<div className="col-sm-2">
					<input
						className="form-control"
						type="text"
						placeholder="PF Number"
						style={{ textTransform: "uppercase" }}
						onChange={(e) => setPFNumber(e.target.value)}
						value={pf_number}
					/>
				</div>
				<div className="col-sm-2">
					<input
						className="form-control"
						type="text"
						// style={{ textTransform: "uppercase" }}
						placeholder="Mobile Number"
						onChange={(e) => setMobileNumber(e.target.value)}
						value={mobile_number}
					/>
				</div>
				<div className="col-sm-3">
					<input
						className="form-control"
						type="text"
						placeholder="FirstName"
						style={{ textTransform: "uppercase" }}
						onChange={(e) => setFirstName(e.target.value)}
						value={first_name}
					/>
				</div>
				<div className="col-sm-3">
					<input
						className="form-control"
						type="text"
						placeholder="LastName"
						style={{ textTransform: "uppercase" }}
						onChange={(e) => setLastName(e.target.value)}
						value={last_name}
					/>
				</div>
				<button
					type="submit"
					className="btn btn-success "
				> <i className="fa fa-search"></i> Search Member</button>
			</div>

		</form>
	)

	let content
	if (isLoading) {
		content = <p>Loading Arrears Data...</p>
	}
	else if (isError) {
		content = <p>{error.message}</p>
	} else {
		content = (
			currentPosts && currentPosts?.length <= 0 ? (
				<div className="table-responsive-lg">
					<table className="table table-bordered">
						<tbody>
							<tr>
								<td style={{ fontSize: "20px", fontWeight: "700", color: "#000", textAlign: "center" }}>
									There are no members in arrears
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			) : (
				<div className="table-responsive-lg">
					<table className="table table-bordered">
						<thead className="align">
							<tr>
								<th scope="col" style={{ textAlign: 'center' }} >#</th>
								<th scope="col" style={{ whiteSpace: 'nowrap' }}>PF Number</th>
								<th scope="col" style={{ whiteSpace: 'nowrap' }}>Name</th>
								<th scope="col" style={{ whiteSpace: 'normal', textAlign: "center" }}>Opening Balance</th>
								<th scope="col" style={{ whiteSpace: 'normal', textAlign: "center" }}>Expected Amount</th>
								<th scope="col" style={{ whiteSpace: 'normal', textAlign: "center" }}>Amount Paid</th>
								<th scope="col" style={{ whiteSpace: 'normal', textAlign: "center" }}>Closing Balance</th>
								<th scope="col" style={{ textAlign: "center" }}>ACTION</th>
							</tr>
						</thead>
						<tbody>
							{currentPosts.map((mem, index) =>
								<tr key={index}>
									<td>{postPerPage * (currentPage - 1) + index + 1}</td>
									<td>{mem.members.pf_number}</td>
									<td style={{ whiteSpace: 'nowrap' }}>{mem.members.first_name.toUpperCase()}&nbsp;{mem.members.middle_name.toUpperCase()}&nbsp;{mem.members.last_name.toUpperCase()}</td>

									{mem.opening_balance < 0 ? (
										<td style={{ fontSize: "14px", fontWeight: "500", textAlign: "right", whiteSpace: "pre-wrap" }}>
											({(mem.opening_balance * -1).toLocaleString(undefined, { minimumFractionDigits: 2 })}) </td>
									) : (
										<td style={{ fontSize: "14px", fontWeight: "500", textAlign: "right", whiteSpace: "pre-wrap" }}>
											{mem.opening_balance.toLocaleString(undefined, { minimumFractionDigits: 2 })} </td>
									)}

									{mem.expected_total_amount < 0 ? (
										<td style={{ fontSize: "14px", fontWeight: "500", textAlign: "right", whiteSpace: "pre-wrap" }}>
											({(mem.expected_total_amount * -1).toLocaleString(undefined, { minimumFractionDigits: 2 })}) </td>
									) : (
										<td style={{ fontSize: "14px", fontWeight: "500", textAlign: "right", whiteSpace: "pre-wrap" }}>
											{mem.expected_total_amount.toLocaleString(undefined, { minimumFractionDigits: 2 })} </td>
									)}
									<td style={{ fontSize: "14px", fontWeight: "500", textAlign: "right", whiteSpace: "pre-wrap" }}>{mem.total_amount_paid.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>

									{mem.closing_balance < 0 ? (
										<td style={{ fontSize: "14px", fontWeight: "500", textAlign: "right", whiteSpace: "pre-wrap" }}>
											({(mem.closing_balance * -1).toLocaleString(undefined, { minimumFractionDigits: 2 })}) </td>
									) : (
										<td style={{ fontSize: "14px", fontWeight: "500", textAlign: "right", whiteSpace: "pre-wrap" }}>
											{mem.closing_balance.toLocaleString(undefined, { minimumFractionDigits: 2 })} </td>
									)}
									<td style={{ whiteSpace: 'nowrap', textAlign: "center" }}>
										<div style={{ display: 'inline-block' }}>
											<Link to={`/view_ledger/${mem.id}`} className="small"><i className='fa fa-eye'></i> view</Link>
										</div>
									</td>

								</tr>)}
						</tbody>
					</table>
				</div>
			)
		)
	}
	return (
		<>
			<section className="content-header">
				<Toaster toastOptions={{ position: "top-center" }} />
				<h1>Members In Arrears - {total_arrears}</h1>
			</section>
			<section className="content">
				<div className="box">
					<div className="box-body">
						<div className="panel panel-success panel-br-4">
							<div className="panel-heading">
								<div className="row">
									<div className="col-sm-12">
										<h4 className="panel-title">
											{searchContent}
										</h4>
									</div>
								</div>
							</div>
							<div className="panel-body"> {content}</div>
							<div className="panel-footer">
								{arrears?.length > 0 && !search ? (
									<><Pagination
										data={arrears}
										postPerPage={postPerPage}
										currentPage={currentPage}
										setCurrentPage={setCurrentPage}
										setPostPerPage={setPostPerPage} /><button
											type="submit"
											className="btn btn-success"
											onClick={handleCancel}
										> <i className="fa fa-times"></i> Cancel</button></>

								) : (
									<div className="search-group">
										<button
											type="submit"
											className="btn btn-danger "
											onClick={handleCancel}
										> <i className="fa fa-search"></i> Cancel</button>
									</div>
								)}
							</div>
						</div>
					</div>
					<div className="box-footer"></div>
				</div>
			</section>
		</>
	);
}

export default InArrears;


