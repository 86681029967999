import React, { useState, useEffect } from 'react'
import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
    getPaymentClearance,
    deletePaymentClearance,
    updatePaymentClearance
} from '../../../../api/apiServices';
import { Link } from 'react-router-dom';
import Pagination from '../../../../services/Pagination';
import toast, { Toaster } from 'react-hot-toast'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const PaymentClearance = () => {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    const [search, setSearch] = useState(false);

    const [transYear, setTransYear] = useState("");
    const [type, setType] = useState("");
    const [name, setName] = useState("");
    const [amount, setAmount] = useState("");
    const [transaction_date, setTransactionDate] = useState("");
    const [payment_mode, setPaymentMode] = useState("");

    const year = (new Date()).getFullYear();
    const years = Array.from(new Array(20), (val, index) => index + year)

    const [currentPage, setCurrentPage] = useState(1);
    const [postPerPage, setPostPerPage] = useState(20);


    const queryClient = useQueryClient();

    const {
        isLoading,
        isError,
        error,
        data: clearance
    } = useQuery('clearance', getPaymentClearance, {
        select: data => data.sort((a, b) => b.id - a.id)
    });

    const indexOfLastPage = currentPage * postPerPage;
    const indexOfFirstPage = indexOfLastPage - postPerPage;
    const currentPosts = clearance?.slice(indexOfFirstPage, indexOfLastPage);


    const updatePaymentMutation = useMutation(updatePaymentClearance, {
        onSuccess: () => {
            toast.success('Payment cleared and member account credited successfully')
            // Invalidate cached data
            queryClient.invalidateQueries("clearance")
        }
    })

    const deletePaymentMutation = useMutation(deletePaymentClearance, {
        onSuccess: () => {
            toast.error('Payment successfully deleted')
            // Invalidate cached data
            queryClient.invalidateQueries("clearance")
        }
    })

    const handleConfirm = (id) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <div className='confirm-delete'>
                            <h3>Confirm Delete</h3>
                            <p>Do you want to delete the member record ?</p>
                            <br />
                            <div className='confirm-buttons'>
                                <button
                                    style={{ width: "100px" }}
                                    className="btn btn-danger"
                                    onClick={onClose}
                                >
                                    No
                                </button>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <button
                                    style={{ width: "100px" }}
                                    className="btn btn-success"
                                    onClick={() => { deletePaymentMutation.mutate(id); onClose(); }}
                                >
                                    Yes
                                </button>
                            </div>
                        </div>
                    </div>
                );
            }
        });
    }

    let content
    if (isLoading) {
        content = <tr><td>Loading...</td></tr>
    } else if (isError) {
        content = <tr><td>{error.message}</td></tr>
    } else {
        content =
            currentPosts && currentPosts?.length <= 0 ? (
                <div className="table-responsive">

                    <table className="table table-bordered">
                        <tbody>
                            <tr>
                                <td style={{ fontSize: "20px", fontWeight: "700", color: "#000", textAlign: "center" }}>
                                    There are no payments pending clearance
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            ) : (
                <div className="table-responsive-lg">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col" style={{ textAlign: 'center' }} >#</th>
                                <th scope="col">DATE</th>
                                <th scope="col">PF NUMBER</th>
                                <th scope="col">MOBILE NUMBER</th>
                                <th scope="col">Payment Mode</th>
                                <th scope="col">Transaction Id</th>
                                <th scope="col" style={{ textAlign: "right" }}>AMOUNT</th>
                                <th scope="col" style={{ textAlign: "center" }}>ACTION</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentPosts?.map((payment, index) =>
                                <tr key={payment.id}>
                                    <td style={{ textAlign: 'center' }}>{index + 1}</td>
                                    <td style={{ whiteSpace: 'nowrap' }}>{payment.payment_date}</td>
                                    <td style={{ whiteSpace: 'nowrap' }}>{payment.pf_number}</td>
                                    <td style={{ whiteSpace: 'nowrap' }}>{payment.mobile_number}</td>
                                    {payment.payment_mode === "M" ? (
                                        <td style={{ whiteSpace: 'nowrap' }}>MoMo</td>

                                    ) : payment.payment_mode === "C" ? (
                                        <td style={{ whiteSpace: 'nowrap' }}>Card</td>
                                    ) : (
                                        <td style={{ whiteSpace: 'nowrap' }}>Cheque</td>
                                    )}

                                    <td style={{ whiteSpace: 'nowrap' }}>{payment.transaction_id}</td>
                                    <td style={{ textAlign: "right" }}>{payment.payment_amount.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                                    <td style={{ whiteSpace: 'nowrap', textAlign: "center" }}>
                                        <div style={{ display: 'inline-block' }}>
                                            <Link to={`/view_pending_payment/${payment.id}`} className="small"><i className='fa fa-eye'></i> view</Link>
                                        </div>&nbsp; &nbsp;

                                        <div style={{ display: 'inline-block' }}>
                                            <Link onClick={() => updatePaymentMutation.mutate(payment.id)}
                                            ><i className='fa fa-edit'></i> clear</Link>
                                        </div>&nbsp; &nbsp;

                                        <div style={{ display: 'inline-block' }}>
                                            <Link onClick={(e) => handleConfirm(payment.id)}><i className='fa fa-trash'></i> delete</Link>
                                        </div>
                                    </td>
                                </tr >
                            )}
                        </tbody>
                    </table>
                </div>
            )
    }

    return (
        <>
            <section className="content-header">
                <Toaster toastOptions={{ position: "top-center" }} />
                <h1>Payment Clearance Management</h1>
            </section>
            <section className="content">
                <div className="box">
                    <div className="box-body">
                        <div className="panel panel-default panel-br-4">
                            <div className="panel-heading">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <h4 className="panel-title">
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="panel-body">
                                {content}
                            </div>
                            <div className="panel-footer">
                                {clearance?.length > 0 ? (
                                    <Pagination
                                        data={clearance}
                                        postPerPage={postPerPage}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                        setPostPerPage={setPostPerPage}
                                    />
                                ) : (
                                    ""
                                )}

                            </div>
                        </div>
                    </div>
                    <div className="box-footer"></div>
                </div>
            </section>
        </>
    )
}

export default PaymentClearance